import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import '../components/modal/index.scss';
import {
  fetchNotifications,
  updateNotifications,
  toggleNotificationsModal,
  setNotificationToRead,
} from 'notification/redux/actions/notificationActions';
import Notification from '../components/notification/index';
import NoNotifications from '../components/noNotifications/index';

const defaultProps = {
  notifications: [],
  updateNotifications: () => null,
  toggleNotificationsModal: () => null,
  setNotificationToRead: () => null,
};

const propTypes = {
  notifications: PropTypes.array,
  isUnread: PropTypes.bool.isRequired,
  updateNotifications: PropTypes.func,
  toggleNotificationsModal: PropTypes.func,
  setNotificationToRead: PropTypes.func,
};

const mapStateToProps = state => ({
  notifications: state.notifications.data,
  isVisible: state.notifications.isModalVisible,
  isUnread: state.notifications.isUnread,
});

const mapDispatchToProps = dispatch => ({
  fetchNotifications: bindActionCreators(fetchNotifications, dispatch),
  updateNotifications: bindActionCreators(updateNotifications, dispatch),
  setNotificationToRead: bindActionCreators(setNotificationToRead, dispatch),
  toggleNotificationsModal: bindActionCreators(
    toggleNotificationsModal,
    dispatch,
  ),
});

class NotificationsModal extends Component {
  constructor(props) {
    super(props);

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentWillMount() {
    this.getUserNotifications();
  }

  componentDidMount() {
    const { isUnread, updateNotifications } = this.props;

    if (isUnread) {
      updateNotifications();
    }

    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentDidUpdate() {
    const { isVisible, toggleNotificationsModal } = this.props;

    window.onpopstate = e => {
      if (window.innerHeight < 992 && isVisible) {
        e.preventDefault();
        toggleNotificationsModal();
      }
    };
  }

  componentWillUnmount() {
    const { isUnread, updateNotifications } = this.props;

    document.removeEventListener('mousedown', this.handleClickOutside);

    if (isUnread) {
      updateNotifications();
    }
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  getUserNotifications = () => {
    fetchNotifications();
  };

  handleNotificationClick = notification => {
    const { setNotificationToRead } = this.props;
    if (!notification.opened) {
      setNotificationToRead(notification.id);
    }
  };

  handleClickOutside(event) {
    const { toggleNotificationsModal } = this.props;

    if (
      this.wrapperRef &&
      !this.wrapperRef.contains(event.target) &&
      event.target.className !== 'notifications-bell'
    ) {
      toggleNotificationsModal();
    }
  }

  render() {
    const { notifications, toggleNotificationsModal } = this.props;

    const notificationsModalClasses = classNames({
      'notifications-modal': true,
    });

    return (
      <div className={notificationsModalClasses} ref={this.setWrapperRef}>
        {(notifications.length > 0 &&
          notifications.map(notification => (
            <Notification
              key={notification.id}
              notification={notification}
              toggleNotificationsModal={toggleNotificationsModal}
              handleNotificationClick={this.handleNotificationClick}
            />
          ))) || <NoNotifications />}
      </div>
    );
  }
}

NotificationsModal.defaultProps = defaultProps;

NotificationsModal.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationsModal);
