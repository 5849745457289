import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Linkify from 'linkifyjs/react';
import renderHTML from 'react-render-html';
import UserAvatar from 'common/components/userAvatar';
import FromNow from 'common/components/fromNow';
import Button from 'common/components/button';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import './index.scss';

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
});

const defaultProps = {
  isCommentEditable: () => null,
};

const propTypes = {
  comment: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]),
  ).isRequired,
  deleteComment: PropTypes.func.isRequired,
  isCommentEditable: PropTypes.func,
};

class Comment extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
  }

  state = {
    isCommentMenuVisible: false,
  };

  componentWillMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  handleClick = e => {
    if (this.node.current) {
      const { comment } = this.props;
      if (
        this.node.current.contains(e.target) ||
        e.target.id === `comment-menu-button-${comment.id}`
      ) {
        return;
      }
      this.handleClickOutside();
    }
  };

  handleClickOutside = () => {
    this.toggleCommentMenu();
  };

  toggleCommentMenu = () => {
    const { isCommentMenuVisible } = this.state;
    this.setState({ isCommentMenuVisible: !isCommentMenuVisible });
  };

  render() {
    const { comment, deleteComment, isCommentEditable, translate } = this.props;
    const { isCommentMenuVisible } = this.state;

    const linkOption = {
      className: 'inpost-link',
      defaultProtocol: 'http',
      format(value) {
        return value.replace(/(?:http:\/\/|https:\/\/)/g, '');
      },
      formatHref(href) {
        return href;
      },
      target: {
        url: '_blank',
      },
    };

    if(comment.text && comment.text.length > 0) {
      var regexp = /(#\S+)/gi;
      var matches_array = comment.text.match(regexp);

      if(matches_array && matches_array.length > 0) {
        matches_array.forEach((item, i) => {
          let newItem = item.replace(/ö/g, 'o').replace(/ä/g, 'a').replace(/ü/g, 'u');
          if(newItem !== item) {
            comment.text = comment.text.replace(item, newItem);
          }
        })
      }
    }

    return (
      <div className="comment">
        <div className="comment__avatar">
          <UserAvatar
            id={comment.author.id}
            image={comment.author.avatar}
            overflow
            offset={80}
          />
        </div>
        <div className="comment__main-content">
          <div className="comment__author-content">
            <Link
              className="comment__link"
              to={`/profile/${comment.author.id}`}
            >
              <span className="comment__author">{comment.author.name}</span>
            </Link>
            <FromNow time={comment.created_at} />
          </div>
          <div className="comment__text">
            {comment.text && comment.text.length > 0 && (
              <Linkify options={linkOption}>
                {renderHTML(
                  comment.text &&
                    comment.text.replace(/(?:\r\n|\r|\n)/g, '<br>'),
                )}
              </Linkify>
            )}
          </div>
        </div>
        {isCommentEditable(comment) && (
          <Button
            className="comment__edit-button"
            onClick={this.toggleCommentMenu}
            id={`comment-menu-button-${comment.id}`}
          />
        )}
        {isCommentMenuVisible && (
          <div className="comment__menu" ref={this.node}>
            <Button
              onClick={() => deleteComment(comment.id)}
              className="comment__menu-item comment__menu-item--delete"
            >
              {translate('common.deleteObject', {
                object: translate('common.reaction.comment'),
              })}
            </Button>
          </div>
        )}
      </div>
    );
  }
}

Comment.propTypes = propTypes;
Comment.defaultProps = defaultProps;

export default connect(mapStateToProps)(Comment);
