import React from 'react';
import PropTypes from 'prop-types';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import './index.scss';

const defaultProps = {
  translate: () => null,
};

const propTypes = {
  translate: PropTypes.func,
};

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
});

const HashtagNoMorePosts = ({ translate }) => (
  <div className="newsfeed-no-more-posts">
    <p className="newsfeed-no-more-posts__message">
      {translate('common.noMore', {
        object: translate('common.reaction.posts'),
      })}
    </p>
  </div>
);

HashtagNoMorePosts.defaultProps = defaultProps;

HashtagNoMorePosts.propTypes = propTypes;

export default connect(mapStateToProps)(HashtagNoMorePosts);
