import has from 'common/utils/objectUtils';

export function createActionCreator(type, ...argNames) {
  return (...args) => {
    const action = { type, payload: {} };
    argNames.forEach((arg, index) => {
      action.payload[argNames[index]] = args[index];
    });
    return action;
  };
}

export function createReducer(initialState, handlers) {
  return function reducer(state = initialState, action) {
    if (has.call(handlers, action.type)) {
      return handlers[action.type](state, action);
    }
    return state;
  };
}
