import axios from 'axios';
import * as types from 'hashtag/redux/types';
import { createActionCreator } from 'common/utils/reduxUtils';

const {
  FETCH_HASHTAG_REQUEST,
  FETCH_HASHTAG_SUCCESS,
  FETCH_HASHTAG_FAILURE,
  UPDATE_HASHTAGFEED,
  REMOVE_HASHTAGFEED,
} = types;

const fetchHashtagfeedRequest = createActionCreator(FETCH_HASHTAG_REQUEST);
const fetchHashtagfeedSuccess = createActionCreator(
  FETCH_HASHTAG_SUCCESS,
  'data',
);
const fetchHashtagfeedFailure = createActionCreator(FETCH_HASHTAG_FAILURE);

export const fetchHashtagfeed = (page, tag) => (dispatch, getState) => {
  const { currentTimestamp } = getState().hashtagfeed;

  dispatch(fetchHashtagfeedRequest());

  return axios
    .get(`${process.env.REACT_APP_API_URL}/hashtagfeed`, {
      params: {
        page,
        to: currentTimestamp,
        tag,
      },
    })
    .then(response => {
      dispatch(fetchHashtagfeedSuccess(response.data));
    })
    .catch(() => {
      dispatch(fetchHashtagfeedFailure());
    });
};

export const updateHashtagfeed = createActionCreator(
  UPDATE_HASHTAGFEED,
  'data',
);

export const removeHashtagfeed = createActionCreator(REMOVE_HASHTAGFEED);
