import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  fetchUserNewsfeed,
  removeUserNewsfeed,
} from 'profile/redux/actions/userNewsfeedActions';
import { removeComments } from 'post/redux/actions/commentsActions';
import ProfileView from 'profile/components/profileView';
import disableScroll from 'common/utils/disableScroll';

const defaultProps = {
  userNewsfeed: [],
  token: '',
  userData: null,
  nextPageIndex: null,
};

const propTypes = {
  token: PropTypes.string,
  userData: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
  ),
  userNewsfeed: PropTypes.arrayOf(PropTypes.object),
  nextPageIndex: PropTypes.number,
  getUserNewsfeed: PropTypes.func.isRequired,
  removeUserNewsfeed: PropTypes.func.isRequired,
  removeComments: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  userNewsfeed: state.userNewsfeed.data,
  nextPageIndex: state.userNewsfeed.nextPageIndex,
  token: state.auth.token,
  userData: state.userData.data,
});

const mapDispatchToProps = dispatch => ({
  getUserNewsfeed: bindActionCreators(fetchUserNewsfeed, dispatch),
  removeUserNewsfeed: bindActionCreators(removeUserNewsfeed, dispatch),
  removeComments: bindActionCreators(removeComments, dispatch),
});

class Profile extends Component {
  state = {
    isInitialPageLoading: false,
  };

  componentDidMount() {
    const { getUserNewsfeed, token, userData, history, match } = this.props;
    const { userId } = match.params;
    if (!token && !userData) {
      history.replace('/auth');
    }
    this.toggleInitialPageLoading();
    getUserNewsfeed(userId).then(() => {
      this.toggleInitialPageLoading();
    });
    disableScroll(false);
  }

  componentDidUpdate(prevProps) {
    const { match: prevMatch } = prevProps;
    const { userId: prevUserId } = prevMatch.params;
    const { match, userData, history } = this.props;
    const { userId } = match.params;

    if (!userData) {
      history.replace('/auth');
    }

    if (prevUserId !== userId) {
      const { getUserNewsfeed, removeUserNewsfeed } = this.props;
      removeUserNewsfeed();
      this.toggleInitialPageLoading();
      getUserNewsfeed(userId).then(() => {
        this.toggleInitialPageLoading();
      });
    }
  }

  componentWillUnmount() {
    const { removeUserNewsfeed, removeComments } = this.props;
    removeUserNewsfeed();
    removeComments();
  }

  toggleInitialPageLoading = () => {
    const { isInitialPageLoading } = this.state;
    this.setState({ isInitialPageLoading: !isInitialPageLoading });
  };

  getUserNewsfeedNextPage = () => {
    const { getUserNewsfeed, nextPageIndex, match } = this.props;
    const { userId } = match.params;

    getUserNewsfeed(userId, nextPageIndex);
  };

  render() {
    const { userData, userNewsfeed, nextPageIndex, match } = this.props;

    const { userId } = match.params;
    const { isInitialPageLoading } = this.state;
    return (
      <ProfileView
        isInitialPageLoading={isInitialPageLoading}
        newsfeed={userNewsfeed}
        nextPageIndex={nextPageIndex}
        getUserNewsfeedNextPage={this.getUserNewsfeedNextPage}
        isProfileOwner={!!(userData && userData.id === Number(userId))}
      />
    );
  }
}

Profile.defaultProps = defaultProps;

Profile.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Profile);
