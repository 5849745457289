import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import disableScroll from 'common/utils/disableScroll';
import ImagePreviewModal from 'common/components/imagePreviewModal';
import './index.scss';

const defaultProps = {
  profileImage: '',
  isUserProfile: false,
};

const propTypes = {
  profileImage: PropTypes.string,
  isUserProfile: PropTypes.bool,
};

class ProfileImage extends Component {
  state = {
    isImagePreviewModalVisible: false,
  };

  toggleImagePreviewModal = () => {
    const { isImagePreviewModalVisible } = this.state;
    this.setState(
      {
        isImagePreviewModalVisible: !isImagePreviewModalVisible,
      },
      () => disableScroll(!isImagePreviewModalVisible),
    );
  };

  render() {
    const { isUserProfile, profileImage } = this.props;
    const { isImagePreviewModalVisible } = this.state;
    const imageClasses = classNames({
      'profile-image__image': true,
      'profile-image__image--user': isUserProfile,
      'profile-image__image--active': profileImage,
    });
    return (
      <Fragment>
        <div className="profile-image">
          <div
            className={imageClasses}
            style={
              profileImage
                ? {
                    backgroundImage: `url(${profileImage}?w=220&h=220&fit=crop)`,
                    backgroundSize: 'cover',
                  }
                : {}
            }
            role="button"
            tabIndex="0"
            onClick={profileImage ? this.toggleImagePreviewModal : () => null}
            onKeyPress={
              profileImage ? this.toggleImagePreviewModal : () => null
            }
          />
        </div>
        {isImagePreviewModalVisible && (
          <ImagePreviewModal
            imageObj={{
              url: profileImage,
            }}
            parent="profile"
            onClose={this.toggleImagePreviewModal}
          />
        )}
      </Fragment>
    );
  }
}

ProfileImage.defaultProps = defaultProps;

ProfileImage.propTypes = propTypes;

export default ProfileImage;
