import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import MainButton from 'common/components/mainButton';
import SetNewAdminModal from 'common/containers/setNewAdminModal';
import DeleteFarmModal from 'common/containers/deleteFarmModal';
import DeleteFarmMemberModal from 'common/containers/deleteFarmMemberModal';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchUserData } from 'common/redux/actions/userDataActions';
import { fetchFarmMembers } from 'farm/redux/actions/farmMembersActions';
import disableScroll from 'common/utils/disableScroll';
import { getTranslate } from 'react-localize-redux';

const defaultProps = {
  userData: {},
  membersResultsNumber: 0,
};

const propTypes = {
  userData: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
  ),
  membersResultsNumber: PropTypes.number,
  getUserData: PropTypes.func.isRequired,
  getFarmMembers: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  userData: state.userData.data,
  membersResultsNumber: state.farmMembers.resultsNumber,
  translate: getTranslate(state.localize),
});

const mapDispatchToProps = dispatch => ({
  getUserData: bindActionCreators(fetchUserData, dispatch),
  getFarmMembers: bindActionCreators(fetchFarmMembers, dispatch),
});

class LeaveFarmButton extends Component {
  state = {
    isNewAdminModalVisible: false,
    isDeleteFarmModalVisible: false,
    isLeaveFarmInfoModalVisible: false,
  };

  componentDidMount() {
    const { getUserData, getFarmMembers, userData } = this.props;
    getUserData();
    const { member_in: farmId } = userData;
    getFarmMembers(farmId);
  }

  handleLeaveFarmClick = () => {
    const { userData, membersResultsNumber } = this.props;
    const { admin_in: adminIn } = userData;

    if (membersResultsNumber === 1) {
      // Only Member of the farm - Delete this farm
      this.toggleDeleteFarmModal();
    } else if (adminIn) {
      // Is the Admin of the farm - select new user as admin
      this.toggleNewAdminModal();
    } else {
      // Regular Farm Member - delete from farm
      this.toggleFarmLeaveInfoModal();
    }
  };

  toggleNewAdminModal = () => {
    const { isNewAdminModalVisible } = this.state;
    this.setState(
      { isNewAdminModalVisible: !isNewAdminModalVisible },
      disableScroll(!isNewAdminModalVisible),
    );
  };

  toggleDeleteFarmModal = () => {
    const { isDeleteFarmModalVisible } = this.state;
    this.setState(
      { isDeleteFarmModalVisible: !isDeleteFarmModalVisible },
      disableScroll(!isDeleteFarmModalVisible),
    );
  };

  toggleFarmLeaveInfoModal = () => {
    const { isLeaveFarmInfoModalVisible } = this.state;
    this.setState(
      { isLeaveFarmInfoModalVisible: !isLeaveFarmInfoModalVisible },
      disableScroll(!isLeaveFarmInfoModalVisible),
    );
  };

  render() {
    const {
      isNewAdminModalVisible,
      isDeleteFarmModalVisible,
      isLeaveFarmInfoModalVisible,
    } = this.state;

    const { userData, getUserData, membersResultsNumber, translate } = this.props;

    return (
      <Fragment>
        <MainButton onClick={this.handleLeaveFarmClick}>
          {membersResultsNumber === 1
            ? translate('modal.deleteFarm.deleteYourFarm')
            : translate('modal.deleteFarm.leaveThisFarm')}
        </MainButton>
        {isNewAdminModalVisible && (
          <SetNewAdminModal closeModal={this.toggleNewAdminModal} />
        )}
        {isDeleteFarmModalVisible && (
          <DeleteFarmModal
            closeModal={this.toggleDeleteFarmModal}
            farmId={userData.admin_in}
            heading={translate('modal.deleteFarm.heading')}
            message={translate('modal.deleteFarm.message')}
          />
        )}
        {isLeaveFarmInfoModalVisible && (
          <DeleteFarmMemberModal
            closeModal={this.toggleFarmLeaveInfoModal}
            heading="Are you sure you want to leave this farm?"
            message="Your posts will not be delted."
            memberId={userData.id}
            onSuccess={getUserData}
          />
        )}
      </Fragment>
    );
  }
}

LeaveFarmButton.defaultProps = defaultProps;

LeaveFarmButton.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LeaveFarmButton);
