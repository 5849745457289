import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import UserAvatar from '../../../common/components/userAvatar/index';
import FromNow from '../../../common/components/fromNow/index';

import './index.scss';

const propTypes = {
  notification: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
  handleNotificationClick: PropTypes.func.isRequired,
  toggleNotificationsModal: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
});

const Notification = ({
  notification,
  translate,
  handleNotificationClick,
  toggleNotificationsModal,
}) => {
  const wrapper = classNames({
    'notification-wrapper': true,
    'notification-wrapper__active': notification.opened,
  });

  const message =
    ((!notification.likes || notification.likes === 1) &&
      translate(`notification.${notification.type_action}`)) ||
    (notification.likes === 2 &&
      translate(`notification.likes.one.${notification.type_action}`, {
        object: 1,
      })) ||
    translate(`notification.likes.many.${notification.type_action}`, {
      object: notification.likes - 1,
    });

  let notificationLink = '';

  if (notification.type_action.indexOf('article') === 0) {
    notificationLink = notification.type_action = '/marketplace/';
  } else {
    notificationLink =
      notification.type_action !== 'farm_member_request' ? '/post/' : '/farms/';
  }

  return (
    <div className={wrapper}>
      <div className="notification-avatar">
        <div className="notification-avatar__container">
          <span
            onClick={() => {
              toggleNotificationsModal();
            }}
            onKeyDown={() => {}}
            role="presentation"
          >
            <UserAvatar
              offset={1000}
              id={notification.author.id}
              image={
                notification.author.avatar
                  ? notification.author.avatar.avatar
                  : null
              }
            />
          </span>
        </div>
      </div>

      <div className="name-time-ago">
        <Link
          to={`/profile/${notification.author.id}`}
          onClick={() => {
            toggleNotificationsModal();
          }}
        >
          <span className="user-name">
            {notification.author.first_name} 
            {' '}
            {notification.author.last_name}
          </span>
        </Link>
        <FromNow time={notification.time} />
      </div>
      <span className="action-type">
        <Link
          onClick={() => {
            handleNotificationClick(notification);
            toggleNotificationsModal();
          }}
          to={`${notificationLink}${notification.entity_id}`}
        >
          {message}
        </Link>
        {notification.comment && (
          <span className="notification-comment">{notification.comment}</span>
        )}
      </span>
    </div>
  );
};

Notification.propTypes = propTypes;

export default connect(mapStateToProps)(Notification);
