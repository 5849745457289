import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import ForgotAccountView from 'userAuth/components/forgotAccountView';
import { forgotAccount } from 'userAuth/api/authApi';
import setForgotAccountErrors from 'userAuth/utils/forgotAccountErrors';
import { setUserEmail } from 'userAuth/redux/actions/authActions';

const defaultProps = {
  history: {},
};

const propTypes = {
  setUserEmail: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  history: PropTypes.object,
};

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
});

const mapDispatchToProps = dispatch => ({
  setUserEmail: bindActionCreators(setUserEmail, dispatch),
});

class ForgotAccount extends Component {
  cancel = () => {
    const { history } = this.props;
    history.push('/');
  };

  forgotAccountForm = (values, actions) => {
    const { email } = values;
    const { setUserEmail, history, translate } = this.props;

    forgotAccount({
      email,
    })
      .then(() => {
        setUserEmail(email);
        history.push('/forgot-resend');
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 404) {
            actions.setErrors({
              email: translate('form.error.invalidEmail'),
            });
          } else {
            actions.setErrors(setForgotAccountErrors(error));
          }
        } else {
          actions.setErrors({
            email: 'ERROR',
          });
        }

        actions.setSubmitting(false);
      });
  };

  render() {
    return (
      <ForgotAccountView
        forgotAccountForm={this.forgotAccountForm}
        cancel={this.cancel}
      />
    );
  }
}

ForgotAccount.propTypes = propTypes;
ForgotAccount.defaultProps = defaultProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ForgotAccount));
