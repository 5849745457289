import * as Yup from 'yup';
import localizeYup from 'common/utils/localizeYupDefaults.js';
import translateMessage from 'common/utils/translateMessage.js';

localizeYup();

export const farmValidationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2)
    .max(64)
    .required(),
  address: Yup.string()
    .min(2)
    .max(191)
    .required(),
  city: Yup.string()
    .min(2)
    .max(64)
    .nullable(),
  postCode: Yup.string()
    .min(4)
    .max(10)
    .matches(/^[0-9]{4,10}$/, translateMessage('form.error.invalidFormat'))
    .nullable(),
  farmArea: Yup.string()
    .matches(/^\d+([,.]\d{1,2})?$/, translateMessage('form.error.onlyNumbers'))
    .nullable(),
  farmCategoryId: Yup.string().required(),
  description: Yup.string().max(
    350,
    translateMessage('form.error.descriptionMax'),
  ),
});

export default farmValidationSchema;
