import React from 'react';
import PropTypes from 'prop-types';
import TextareaAutosize from 'react-autosize-textarea';
import SharedContent from 'post/components/sharedContent';
import MainButton from 'common/components/mainButton';
import CloseButton from 'common/components/closeButton';
import UserAvatar from 'common/components/userAvatar';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import './index.scss';

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
});

const propTypes = {
  post: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
      PropTypes.object,
    ]),
  ).isRequired,
  createShare: PropTypes.func.isRequired,
  updateShareMessage: PropTypes.func.isRequired,
  shareMessage: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  isShareInProgress: PropTypes.bool.isRequired,
  userData: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
  ).isRequired,
};

const ShareModalView = ({
  post,
  createShare,
  shareMessage,
  updateShareMessage,
  closeModal,
  isShareInProgress,
  translate,
  userData,
}) => (
  <div className="share-modal">
    <div className="share-modal__header">
      <span className="share-modal__title">
        {translate('common.shareObject', {
          object: translate('common.reaction.post'),
        })}
      </span>
      <CloseButton onClick={closeModal} />
    </div>
    <div className="share-modal__content">
      <div className="share-modal__message">
        <div className="share-modal__message-field">
          <div className="share-modal__avatar">
            <UserAvatar image={userData.avatar} />
          </div>
          <TextareaAutosize
            className="share-modal__textarea"
            placeholder={translate('common.createPostPlaceholder')}
            autoFocus
            onChange={updateShareMessage}
            value={shareMessage}
          />
        </div>
      </div>
      <div className="share-modal__shared-content">
        <SharedContent
          sharedContent={post.shared_content ? post.shared_content : post}
        />
      </div>
    </div>
    <div className="share-modal__footer">
      <MainButton
        onClick={() =>
          createShare(
            post.shared_content ? post.shared_content.id : post.id,
            shareMessage,
          )
        }
        disabled={isShareInProgress}
        large
        fullWidth
      >
        {translate('common.action.publish')}
      </MainButton>
    </div>
  </div>
);

ShareModalView.propTypes = propTypes;

export default connect(mapStateToProps)(ShareModalView);
