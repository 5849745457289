export default function setArticleErrors(error) {
  let errors = {};
  if (error.response.data.message) {
    errors = {
      submit: error.response.data.message,
    };
  }
  if (error.response.data.errors) {
    if (error.response.data.errors.price) {
      errors = {
        ...errors,
        ...{ price: error.response.data.errors.price.join(' ') },
      };
    }
    if (error.response.data.errors.title) {
      errors = {
        ...errors,
        ...{ title: error.response.data.errors.title.join(' ') },
      };
    }
    if (error.response.data.errors.city) {
      errors = {
        ...errors,
        ...{
          city: error.response.data.errors.city.join(' '),
        },
      };
    }
    if (error.response.data.errors.post_code) {
      errors = {
        ...errors,
        ...{ postCode: error.response.data.errors.post_code.join(' ') },
      };
    }
    if (error.response.data.errors.category_id) {
      errors = {
        ...errors,
        ...{ categoryId: error.response.data.errors.category_id.join(' ') },
      };
    }
    if (error.response.data.errors.phone) {
      errors = {
        ...errors,
        ...{ phone: error.response.data.errors.phone.join(' ') },
      };
    }
    if (error.response.data.errors.text) {
      errors = {
        ...errors,
        ...{ text: error.response.data.errors.text.join(' ') },
      };
    }
  }

  return errors;
}
