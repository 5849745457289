import React from 'react';
import PropTypes from 'prop-types';
import MainButton from 'common/components/mainButton';
import Button from 'common/components/button';
import { Translate } from 'react-localize-redux';
import './index.scss';

const defaultProps = {
  categories: [],
};

const propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object),
  handleCategoryChange: PropTypes.func.isRequired,
  toggleSellArticleModal: PropTypes.func.isRequired,
};

const MarketplaceMobileBarView = ({
  categories,
  handleCategoryChange,
  toggleSellArticleModal,
  categoryId,
}) => {
  const buttons = categories.map(
    (category, i) =>
      i < 3 && (
        <Button
          key={category.id}
          onClick={() => handleCategoryChange(category.id)}
          className={`marketplace-sidebar__button ${
            category.id === categoryId ? 'active-category' : ''
          } marketplace-sidebar__button--${category.id}`}
        >
          <Translate id={`common.action.${category.name}`} />
        </Button>
      ),
  );

  return (
    <div className="marketplace-sidebar__mobile">
      <div className="marketplace-sidebar__filter-buttons">{buttons}</div>
      <div className="marketplace-sidebar__sell-button">
        <MainButton onClick={toggleSellArticleModal} fullWidth>
          <Translate id="common.action.sellArticle" />
        </MainButton>
      </div>
    </div>
  );
};

MarketplaceMobileBarView.defaultProps = defaultProps;

MarketplaceMobileBarView.propTypes = propTypes;

export default MarketplaceMobileBarView;
