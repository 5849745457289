import React, { Component } from 'react';
import ResendForgotAccountView from 'userAuth/components/resendForgotAccountView';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { forgotAccount } from 'userAuth/api/authApi';

const defaultProps = {
  email: '',
};

const propTypes = {
  email: PropTypes.string,
};

const mapStateToProps = state => ({
  email: state.auth.email,
});

class ResendForgotAccount extends Component {
  resendEmail = () => {
    const { email } = this.props;
    forgotAccount({ email });
  };

  wrongEmail = () => {
    const { history } = this.props;
    history.push('/forgot');
  };

  render() {
    const { email } = this.props;
    return (
      <ResendForgotAccountView
        resendEmail={this.resendEmail}
        wrongEmail={this.wrongEmail}
        email={email}
      />
    );
  }
}

ResendForgotAccount.defaultProps = defaultProps;

ResendForgotAccount.propTypes = propTypes;

export default connect(mapStateToProps)(ResendForgotAccount);
