import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Input from 'common/components/input/index';
import Button from 'common/components/mainButton';
import registrationValidationSchema from 'userAuth/validators/registerValidators';
import './index.scss';
import { Translate, getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import Loader from 'common/components/loader';

const propTypes = {
  submitRegisterForm: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
});

const largeBreakpoint = 992;

class UserRegisterView extends Component {
  state = { width: null };

  componentDidMount() {
    this.setScreenWidth();
    window.addEventListener('resize', this.setScreenWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setScreenWidth);
  }

  setScreenWidth = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { submitRegisterForm, translate, loader } = this.props;
    const { width } = this.state;
    const {
      location: { hash },
    } = window;

    return (
      <div className="user-register">
        {loader && (
          <div className="loader-wrapper">
            <Loader message={translate('form.common.verifyingEmail')} />
          </div>
        )}
        <div className="user-register__headings-wrapper">
          <h2 className="user-register__heading">
            <Translate
              id={!hash ? 'page.signUp.title' : 'guest.registrationTitle'}
            />
          </h2>
          <p className="user-register__sub-heading">
            <Translate
              id={
                !hash
                  ? 'page.signUp.subHeading'
                  : 'guest.registrationDescription'
              }
            />
          </p>
        </div>
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            passwordConfirmation: '',
            birthdateDay: '',
            birthdateMonth: '',
            birthdateYear: '',
            postCode: '',
            gdpr: true,
            terms: true,
          }}
          validationSchema={registrationValidationSchema}
          onSubmit={(values, { setErrors, resetForm, setSubmitting }) =>
            submitRegisterForm(values, { setErrors, resetForm, setSubmitting })
          }
          render={({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form
              onSubmit={handleSubmit}
              noValidate
              className="user-register__form"
            >
              <div className="user-register__row user-register__row--wrap">
                <div className="user-register__input user-register__input--full-width user-register__input--space-right-large">
                  <Input
                    type="text"
                    value={values.firstName}
                    error={errors.firstName}
                    touched={touched.firstName}
                    name="firstName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={translate('form.common.firstName')}
                    withLabel={width < largeBreakpoint}
                    fullWidth
                  />
                </div>
                <div className="user-register__input user-register__input--full-width user-register__input--space-left-large">
                  <Input
                    value={values.lastName}
                    error={errors.lastName}
                    touched={touched.lastName}
                    type="text"
                    name="lastName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={translate('form.common.lastName')}
                    withLabel={width < largeBreakpoint}
                    fullWidth
                  />
                </div>
              </div>
              <div className="user-register__row">
                <div className="user-register__input user-register__input--full-width">
                  <Input
                    value={values.email}
                    error={errors.email}
                    touched={touched.email}
                    type="email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={translate('form.common.eMail')}
                    withLabel={width < largeBreakpoint}
                    fullWidth
                  />
                </div>
              </div>
              <div className="user-register__row">
                <div className="user-register__input user-register__input--full-width">
                  <Input
                    value={values.password}
                    error={errors.password}
                    touched={touched.password}
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={translate('form.authentication.password8')}
                    withLabel={width < largeBreakpoint}
                    fullWidth
                  />
                </div>
              </div>
              <div className="user-register__row">
                <div className="user-register__input user-register__input--full-width">
                  <Input
                    value={values.passwordConfirmation}
                    error={errors.passwordConfirmation}
                    touched={touched.passwordConfirmation}
                    name="passwordConfirmation"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={translate(
                      'form.authentication.passwordRepeat',
                    )}
                    withLabel={width < largeBreakpoint}
                    fullWidth
                  />
                </div>
              </div>
              <div className="user-register__row">
                <p className="user-register__label ">
                  <Translate id="form.common.birthdate" />
                </p>
                <div className="user-register__row user-register__row--full-width">
                  <div className="user-register__input user-register__input--full-width user-register__input--space-right-large">
                    <Input
                      value={values.birthdateDay}
                      error={errors.birthdateDay}
                      touched={touched.birthdateDay}
                      name="birthdateDay"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="01"
                      maxLength={2}
                      fullWidth
                    />
                  </div>
                  <div className="user-register__input user-register__input--full-width user-register__input--space-middle-large">
                    <Input
                      value={values.birthdateMonth}
                      error={errors.birthdateMonth}
                      touched={touched.birthdateMonth}
                      name="birthdateMonth"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="01"
                      maxLength={2}
                      fullWidth
                    />
                  </div>
                  <div className="user-register__input user-register__input--full-width user-register__input--space-left-large">
                    <Input
                      value={values.birthdateYear}
                      error={errors.birthdateYear}
                      touched={touched.birthdateYear}
                      name="birthdateYear"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="2000"
                      maxLength={4}
                      fullWidth
                    />
                  </div>
                </div>
              </div>
              <div className="user-register__row">
                {errors.birthDate && (
                  <p className="user-register__error--birth-date">
                    {errors.birthDate}
                  </p>
                )}
              </div>
              <div className="user-register__row">
                <p className="user-register__label ">
                  <Translate id="form.common.postCode" />
                </p>
                <div className="user-register__row user-register__row--full-width">
                  <div className="user-register__input user-register__input--full-width user-register__input--center">
                    <Input
                      value={values.postCode}
                      error={errors.postCode}
                      touched={touched.postCode}
                      name="postCode"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="_ _ _ _"
                      maxLength={10}
                      fullWidth
                    />
                  </div>
                </div>
              </div>
              <div className="user-register__row">
                <div className="registration-terms">
                  <p>
                    {translate(
                      'form.terms.termsAndConditions',
                      {
                        terms: translate('form.terms.terms'),
                        conditions: translate('form.terms.conditions'),
                      },
                      { renderInnerHtml: true },
                    )}
                    &nbsp;
                    {translate(
                      'form.terms.policyPart',
                      {
                        policy: translate('form.terms.privacy'),
                      },
                      { renderInnerHtml: true },
                    )}
                    &nbsp;
                    {translate(
                      'form.terms.cookiesPart',
                      {
                        cookies: translate('form.terms.cookies'),
                      },
                      { renderInnerHtml: true },
                    )}
                    &nbsp;
                    {translate('form.terms.notifications')}
                  </p>
                </div>
              </div>
              <div className="user-register__submit-button">
                <Button type="submit" disabled={isSubmitting} fullWidth>
                  <Translate id="common.action.signUpFree" />
                </Button>
                {errors.submit && (
                  <p className="user-register__error--submit">
                    {errors.submit}
                  </p>
                )}
              </div>
              <div className="account-deletion">
                <Translate id="form.common.accountDeletion" />
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}

UserRegisterView.propTypes = propTypes;

export default connect(mapStateToProps)(UserRegisterView);
