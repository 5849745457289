import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import disableScroll from 'common/utils/disableScroll';
import ArticleDetailsModalView from 'post/components/articleDetailsModalView';
import InfoModal from 'common/components/infoModal';
import ContactSellerFormModal from 'post/components/contactSellerFormModal';
import SellArticleModal from 'post/containers/sellArticleModal';
import ActivitiesModal from 'post/containers/activitiesModal';
import { markArticleAsSold, sendMessageToSeller } from 'common/api/postsApi';
import { updateNewsfeed } from 'newsfeed/redux/actions/newsfeedActions';
import { updateUserNewsfeed } from 'profile/redux/actions/userNewsfeedActions';
import { getTranslate } from 'react-localize-redux';

import { addNotification } from 'notification/api/notificationsApi';

const defaultProps = {
  userData: {},
  toggleGuestModal: () => null,
  translate: () => null,
  match: {},
};

const propTypes = {
  newsfeed: PropTypes.arrayOf(PropTypes.object).isRequired,
  post: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
      PropTypes.object,
    ]),
  ).isRequired,
  toggleArticleDetailsModal: PropTypes.func.isRequired,
  updateNewsfeed: PropTypes.func.isRequired,
  updateUserNewsfeed: PropTypes.func.isRequired,
  userData: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
  ),
  toggleGuestModal: PropTypes.func,
  translate: PropTypes.func,
  match: PropTypes.object,
};

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
  userData: state.userData.data,
});

const mapDispatchToProps = dispatch => ({
  updateNewsfeed: bindActionCreators(updateNewsfeed, dispatch),
  updateUserNewsfeed: bindActionCreators(updateUserNewsfeed, dispatch),
});

const breakpoint = 992;

class ArticleDetailsModal extends Component {
  state = {
    isActivitiesModalVisible: false,
    isEditArticleModalVisible: false,
    isPhoneNumberVisible: false,
    isContactSellerFormVisible: true,
    isContactSellerModalVisible: false,
    isConfirmationModalVisible: false,
    isUpdateInProgress: false,
    width: null,
    isCommentSectionVisible: false,
  };

  componentDidMount() {
    this.setWidth();
    window.addEventListener('resize', this.setWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setWidth);
    disableScroll(false);
  }

  setWidth = () => {
    this.setState({ width: window.innerWidth });
  };

  toggleActivitiesModal = () => {
    if (window.innerWidth < 992) {
      const { isActivitiesModalVisible } = this.state;
      this.setState(
        {
          isActivitiesModalVisible: !isActivitiesModalVisible,
        },
        () => disableScroll(!isActivitiesModalVisible),
      );
    }
  };

  showPhoneNumber = () => {
    this.setState({ isPhoneNumberVisible: true });
  };

  submitContactForm = values => {
    const { post, userData } = this.props;
    sendMessageToSeller(post.article.id, values.text).then(() => {
      if (userData.id) {
        addNotification({
          authorId: post.author.id,
          typeAction: 'article_contacted',
          comment: values.text,
          entityId: post.article.id,
        });
      }

      if (window.innerWidth < breakpoint) {
        this.toggleContactSellerModal();
        this.toggleConfirmationModal();
      } else {
        this.toggleContactSellerForm();
      }
    });
  };

  toggleContactSellerForm = () => {
    const { isContactSellerFormVisible } = this.state;
    this.setState({ isContactSellerFormVisible: !isContactSellerFormVisible });
  };

  toggleContactSellerModal = () => {
    const { isContactSellerModalVisible } = this.state;
    this.setState({
      isContactSellerModalVisible: !isContactSellerModalVisible,
    });
  };

  toggleConfirmationModal = () => {
    const { isConfirmationModalVisible } = this.state;
    this.setState({ isConfirmationModalVisible: !isConfirmationModalVisible });
  };

  markArticleAsSold = () => {
    const {
      match,
      newsfeed,
      post,
      updateNewsfeed,
      updateUserNewsfeed,
    } = this.props;
    const { path } = match;
    this.setState({ isUpdateInProgress: true });
    markArticleAsSold(post.article.id)
      .then(() => {
        this.setState({ isUpdateInProgress: false });
        const updated = newsfeed.map(item => {
          if (item.id === post.id) {
            return {
              ...post,
              article: {
                ...post.article,
                is_sold: true,
              },
            };
          }
          if (item.shared_content && item.shared_content.id === post.id) {
            return {
              ...item,
              shared_content: {
                ...item.shared_content,
                article: {
                  ...item.shared_content.article,
                  is_sold: true,
                },
              },
            };
          }
          return item;
        });
        if (path === '/') {
          updateNewsfeed(updated);
        }
        if (path.indexOf('/profile') > -1) {
          updateUserNewsfeed(updated);
        }
      })
      .catch(() => {
        this.setState({ isUpdateInProgress: false });
      });
  };

  toggleEditArticleModal = () => {
    const { isEditArticleModalVisible } = this.state;
    this.setState({ isEditArticleModalVisible: !isEditArticleModalVisible });
  };

  toggleCommentsSection = () => {
    const { userData, toggleGuestModal } = this.props;
    if (userData.id) {
      if (window.innerWidth >= 992) {
        this.setState(({ isCommentSectionVisible }) => ({
          isCommentSectionVisible: !isCommentSectionVisible,
        }));
      } else {
        this.toggleActivitiesModal();
      }
    } else {
      toggleGuestModal();
    }
  };

  render() {
    const {
      newsfeed,
      post,
      toggleArticleDetailsModal,
      userData,
      translate,
      toggleGuestModal,
    } = this.props;
    const {
      isConfirmationModalVisible,
      isContactSellerFormVisible,
      isContactSellerModalVisible,
      isEditArticleModalVisible,
      isPhoneNumberVisible,
      isSendingInProgress,
      isUpdateInProgress,
      width,
      isCommentSectionVisible,
      isActivitiesModalVisible,
    } = this.state;
    return (
      <Fragment>
        <ArticleDetailsModalView
          post={post}
          closeModal={toggleArticleDetailsModal}
          showPhoneNumber={this.showPhoneNumber}
          isPhoneNumberVisible={isPhoneNumberVisible}
          submitForm={this.submitContactForm}
          toggleContactSellerForm={this.toggleContactSellerForm}
          isContactSellerFormVisible={isContactSellerFormVisible}
          toggleContactSellerModal={this.toggleContactSellerModal}
          isArticleOwner={
            userData && userData.id && userData.id === post.author.id
          }
          markArticleAsSold={this.markArticleAsSold}
          isUpdateInProgress={isUpdateInProgress}
          toggleEditArticleModal={this.toggleEditArticleModal}
          isSendingInProgress={isSendingInProgress}
          newsfeed={newsfeed}
          toggleGuestModal={toggleGuestModal}
          width={width}
          isCommentSectionVisible={isCommentSectionVisible}
          toggleCommentsSection={this.toggleCommentsSection}
        />
        {isContactSellerModalVisible && (
          <ContactSellerFormModal
            closeModal={this.toggleContactSellerModal}
            submitForm={this.submitContactForm}
          />
        )}
        {isConfirmationModalVisible && (
          <InfoModal
            heading={translate(
              'marketplace.articlePost.contactSellerConfirmation.heading',
            )}
            message={translate(
              'marketplace.articlePost.contactSellerConfirmation.message',
              { name: post.author.name },
            )}
            primaryOptionButton={translate(
              'marketplace.articlePost.contactSellerConfirmation.sendAnother',
            )}
            handlePrimaryOptionButtonClick={() => {
              this.toggleConfirmationModal();
              this.toggleContactSellerModal();
            }}
            closeModal={this.toggleConfirmationModal}
            isCancelButtonVisible
          />
        )}
        {isEditArticleModalVisible && (
          <SellArticleModal
            closeModal={this.toggleEditArticleModal}
            post={post}
          />
        )}
        {isActivitiesModalVisible && (
          <ActivitiesModal
            post={post}
            closeModal={this.toggleActivitiesModal}
            activeSection="comments"
          />
        )}
      </Fragment>
    );
  }
}

ArticleDetailsModal.defaultProps = defaultProps;

ArticleDetailsModal.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ArticleDetailsModal));
