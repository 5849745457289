import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PostCommentsView from 'post/components/postCommentsView';
import {
  fetchComments,
  updateCommentsList,
  removeSingleComment,
  removeSingleCommentsList,
  saveOpenCommentId,
  removeOpenCommentId,
} from 'post/redux/actions/commentsActions';
import { updateNewsfeed } from 'newsfeed/redux/actions/newsfeedActions';
import { updateFarmNewsfeed } from 'farm/redux/actions/farmNewsfeedActions';
import { updateUserNewsfeed } from 'profile/redux/actions/userNewsfeedActions';
import { updateMarketplace } from 'marketplace/redux/actions/marketplaceActions';
import { createComment, deleteComment, getComment } from 'post/api/commentsApi';
import { addNotification } from 'notification/api/notificationsApi';

const defaultProps = {
  height: null,
  isFixedHeight: false,
  match: {},
  userData: {},
  toggleGuestModal: () => null,
};

const propTypes = {
  fetchComments: PropTypes.func.isRequired,
  comments: PropTypes.objectOf(PropTypes.object).isRequired,
  height: PropTypes.number,
  updateCommentsList: PropTypes.func.isRequired,
  post: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
      PropTypes.object,
    ]),
  ).isRequired,
  saveOpenCommentId: PropTypes.func.isRequired,
  removeOpenCommentId: PropTypes.func.isRequired,
  openCommentsIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  removeSingleComment: PropTypes.func.isRequired,
  removeSingleCommentsList: PropTypes.func.isRequired,
  isFixedHeight: PropTypes.bool,
  newsfeed: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateNewsfeed: PropTypes.func.isRequired,
  farmNewsfeed: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateFarmNewsfeed: PropTypes.func.isRequired,
  userNewsfeed: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateUserNewsfeed: PropTypes.func.isRequired,
  marketplace: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateMarketplace: PropTypes.func.isRequired,
  match: PropTypes.object,
  userData: PropTypes.object,
  toggleGuestModal: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
  fetchComments: bindActionCreators(fetchComments, dispatch),
  updateCommentsList: bindActionCreators(updateCommentsList, dispatch),
  saveOpenCommentId: bindActionCreators(saveOpenCommentId, dispatch),
  removeOpenCommentId: bindActionCreators(removeOpenCommentId, dispatch),
  removeSingleComment: bindActionCreators(removeSingleComment, dispatch),
  removeSingleCommentsList: bindActionCreators(
    removeSingleCommentsList,
    dispatch,
  ),
  updateNewsfeed: bindActionCreators(updateNewsfeed, dispatch),
  updateFarmNewsfeed: bindActionCreators(updateFarmNewsfeed, dispatch),
  updateUserNewsfeed: bindActionCreators(updateUserNewsfeed, dispatch),
  updateMarketplace: bindActionCreators(updateMarketplace, dispatch),
});

const mapStateToProps = state => ({
  comments: state.comments.data,
  userData: state.userData.data,
  openCommentsIds: state.comments.ids,
  newsfeed: state.newsfeed.data,
  farmNewsfeed: state.farmNewsfeed.data,
  userNewsfeed: state.userNewsfeed.data,
  marketplace: state.marketplace.data,
});

class Comments extends Component {
  state = {
    commentMessage: '',
    isInitialPageLoading: false,
    initialPageLoaded: false,
    isNextPageLoading: false,
    initialUpdate: false,
    commentCreationInProgress: false,
  };

  componentDidMount() {
    const {
      fetchComments,
      post,
      comments,
      openCommentsIds,
      saveOpenCommentId,
    } = this.props;
    if (
      !comments[post.id] ||
      (comments[post.id] && comments[post.id].data.length === 0)
    ) {
      this.setState({ isInitialPageLoading: true });
      fetchComments(post.id).then(() => {
        this.setState({
          isInitialPageLoading: false,
          initialPageLoaded: true,
        });
      });
    }
    if (!openCommentsIds.includes(post.id)) {
      window.Echo.channel(`comments.post.${post.id}`).listen(
        `CommentCreatedEvent`,
        response => {
          this.updateComments(response.id);
        },
      );
    }
    saveOpenCommentId(post.id);
  }

  componentDidUpdate() {
    const {
      comments,
      post,
      newsfeed,
      updateNewsfeed,
      userNewsfeed,
      updateUserNewsfeed,
      marketplace,
      updateMarketplace,
      farmNewsfeed,
      updateFarmNewsfeed,
      match,
    } = this.props;
    const { path } = match;
    const { initialUpdate } = this.state;
    if (
      comments[post.id] &&
      comments[post.id].resultsNumber &&
      !initialUpdate
    ) {
      this.setInitialUpdateAsDone();
      if (comments[post.id].resultsNumber !== post.comments_count) {
        const createUpdatedNewsfeed = newsfeed =>
          newsfeed.map(item => {
            if (item.id === post.id) {
              return {
                ...post,
                comments_count: comments[post.id].resultsNumber,
              };
            }
            if (item.shared_content && item.shared_content.id === post.id) {
              return {
                ...item,
                shared_content: {
                  ...item.shared_content,
                  comments_count: comments[post.id].resultsNumber,
                },
              };
            }
            return item;
          });
        if (path === '/') {
          updateNewsfeed(createUpdatedNewsfeed(newsfeed));
        }
        if (window.location.pathname === '/hashtag') {
          updateNewsfeed(createUpdatedNewsfeed(newsfeed));
        }
        if (path.indexOf('/profile') > -1) {
          updateUserNewsfeed(createUpdatedNewsfeed(userNewsfeed));
        }
        if (path.indexOf('/farms') > -1) {
          updateFarmNewsfeed(createUpdatedNewsfeed(farmNewsfeed));
        }
        if (path.indexOf('/marketplace') > -1) {
          updateMarketplace(createUpdatedNewsfeed(marketplace));
        }
      }
    }
  }

  componentWillUnmount() {
    const {
      post,
      removeOpenCommentId,
      openCommentsIds,
      removeSingleCommentsList,
    } = this.props;
    const includes = openCommentsIds.filter(comment => comment === post.id);
    removeOpenCommentId(post.id);
    if (includes.length === 1) {
      removeSingleCommentsList(post.id);
    }
    window.Echo.leaveChannel(`comments.post.${post.id}`);
  }

  setInitialUpdateAsDone = () => this.setState({ initialUpdate: true });

  createNewComment = () => {
    const {
      updateCommentsList,
      post,
      newsfeed,
      updateNewsfeed,
      userNewsfeed,
      updateUserNewsfeed,
      marketplace,
      updateMarketplace,
      farmNewsfeed,
      updateFarmNewsfeed,
      match,
    } = this.props;
    const { path } = match;
    const { commentMessage } = this.state;
    this.setState({ commentCreationInProgress: true });
    if (commentMessage) {
      createComment(commentMessage, post.id)
        .then(response => {
          let notificationType = false;

          if (
            post.type === 'farm_cover_image' ||
            post.type === 'farm_title_image'
          ) {
            notificationType = `${post.type}_commented`;
          } else if (post.type === 'farm_farmtour') {
            notificationType = 'farmtour_commented';
          } else if (post.article) {
            notificationType = 'article_commented';
          } else if (post.author.type === 'user') {
            notificationType = 'user_post_commented';
          } else {
            notificationType = 'farm_post_commented';
          }

          addNotification({
            authorId:
              post.author.type === 'user'
                ? post.author.id
                : post.user_author.id,
            typeAction: notificationType,
            comment: commentMessage,
            entityId: post.article ? post.article.id : post.id,
          });

          this.setState({
            commentMessage: '',
            commentCreationInProgress: false,
          });
          updateCommentsList(response.data.created, post.id);
          const createUpdatedNewsfeed = newsfeed =>
            newsfeed.map(item => {
              if (item.id === post.id) {
                return {
                  ...post,
                  comments_count: post.comments_count + 1,
                };
              }
              if (item.shared_content && item.shared_content.id === post.id) {
                return {
                  ...item,
                  shared_content: {
                    ...item.shared_content,
                    comments_count: item.shared_content.comments_count + 1,
                  },
                };
              }
              return item;
            });
          if (path === '/' || window.location.pathname.includes('post')) {
            updateNewsfeed(createUpdatedNewsfeed(newsfeed));
          }
          if (window.location.pathname === '/hashtag') {
            updateNewsfeed(createUpdatedNewsfeed(newsfeed));
          }
          if (path.indexOf('/profile') > -1) {
            updateUserNewsfeed(createUpdatedNewsfeed(userNewsfeed));
          }
          if (path.indexOf('/farms') > -1) {
            updateFarmNewsfeed(createUpdatedNewsfeed(farmNewsfeed));
          }
          if (path.indexOf('/marketplace') > -1) {
            updateMarketplace(createUpdatedNewsfeed(marketplace));
          }
        })
        .catch(() => {
          this.setState({ commentCreationInProgress: false });
        });
    }
  };

  updateCommentMessage = e => {
    this.setState({
      commentMessage: e.target.value,
    });
  };

  getCommentsNextPage = () => {
    const { fetchComments, post, comments } = this.props;
    const { nextPageIndex } = comments[post.id];
    this.setState({ isNextPageLoading: true });
    fetchComments(post.id, nextPageIndex).then(() => {
      this.setState({ isNextPageLoading: false });
    });
  };

  updateComments = commentId => {
    const {
      updateCommentsList,
      comments,
      post,
      newsfeed,
      updateNewsfeed,
      userNewsfeed,
      updateUserNewsfeed,
      marketplace,
      updateMarketplace,
      farmNewsfeed,
      updateFarmNewsfeed,
      match,
    } = this.props;
    const { path } = match;
    let find;
    if (comments[post.id]) {
      find = comments[post.id].data.find(comment => comment.id === commentId);
    }
    if (!find) {
      getComment(commentId).then(response => {
        updateCommentsList(response.data, post.id);
        const createUpdatedNewsfeed = newsfeed =>
          newsfeed.map(item => {
            if (item.id === post.id) {
              return {
                ...post,
                comments_count: post.comments_count + 1,
              };
            }
            if (item.shared_content && item.shared_content.id === post.id) {
              return {
                ...item,
                shared_content: {
                  ...item.shared_content,
                  comments_count: item.shared_content.comments_count + 1,
                },
              };
            }
            return item;
          });
        if (path === '/' || window.location.pathname.includes('post')) {
          updateNewsfeed(createUpdatedNewsfeed(newsfeed));
        }
        if (window.location.pathname === '/hashtag') {
          updateNewsfeed(createUpdatedNewsfeed(newsfeed));
        }
        if (path.indexOf('/profile') > -1) {
          updateUserNewsfeed(createUpdatedNewsfeed(userNewsfeed));
        }
        if (path.indexOf('/farms') > -1) {
          updateFarmNewsfeed(createUpdatedNewsfeed(farmNewsfeed));
        }
        if (path.indexOf('/marketplace') > -1) {
          updateMarketplace(createUpdatedNewsfeed(marketplace));
        }
      });
    }
  };

  isCommentEditable = comment => {
    const { userData, post } = this.props;
    return (
      userData.id === comment.author.id ||
      post.author.id === userData.id ||
      post.author.id === userData.admin_in
    );
  };

  deleteComment = id => {
    const {
      post,
      removeSingleComment,
      newsfeed,
      updateNewsfeed,
      userNewsfeed,
      updateUserNewsfeed,
      marketplace,
      updateMarketplace,
      farmNewsfeed,
      updateFarmNewsfeed,
      match,
    } = this.props;
    const { path } = match;
    deleteComment(id).then(() => {
      removeSingleComment(post.id, id);
      const createUpdatedNewsfeed = newsfeed =>
        newsfeed.map(item => {
          if (item.id === post.id) {
            return {
              ...post,
              comments_count: post.comments_count - 1,
            };
          }
          if (item.shared_content && item.shared_content.id === post.id) {
            return {
              ...item,
              shared_content: {
                ...item.shared_content,
                comments_count: item.shared_content.comments_count - 1,
              },
            };
          }
          return item;
        });
      if (path === '/' || window.location.pathname.includes('post')) {
        updateNewsfeed(createUpdatedNewsfeed(newsfeed));
      }
      if (window.location.pathname === '/hashtag') {
        updateNewsfeed(createUpdatedNewsfeed(newsfeed));
      }
      if (path.indexOf('/profile') > -1) {
        updateUserNewsfeed(createUpdatedNewsfeed(userNewsfeed));
      }
      if (path.indexOf('/farms') > -1) {
        updateFarmNewsfeed(createUpdatedNewsfeed(farmNewsfeed));
      }
      if (path.indexOf('/marketplace') > -1) {
        updateMarketplace(createUpdatedNewsfeed(marketplace));
      }
    });
  };

  render() {
    const {
      comments,
      post,
      height,
      isFixedHeight,
      toggleGuestModal,
    } = this.props;
    const {
      commentMessage,
      isInitialPageLoading,
      initialPageLoaded,
      isNextPageLoading,
      commentCreationInProgress,
    } = this.state;
    return (
      <Fragment>
        <PostCommentsView
          comments={comments[post.id] && comments[post.id].data}
          createNewComment={this.createNewComment}
          commentMessage={commentMessage}
          updateCommentMessage={this.updateCommentMessage}
          getCommentsNextPage={this.getCommentsNextPage}
          nextPageIndex={comments[post.id] && comments[post.id].nextPageIndex}
          isInitialPageLoading={isInitialPageLoading}
          initialPageLoaded={initialPageLoaded}
          height={height}
          isFixedHeight={isFixedHeight}
          postId={post.id}
          isNextPageLoading={isNextPageLoading}
          isCommentEditable={this.isCommentEditable}
          deleteComment={this.deleteComment}
          commentCreationInProgress={commentCreationInProgress}
          toggleGuestModal={toggleGuestModal}
        />
      </Fragment>
    );
  }
}

Comments.defaultProps = defaultProps;

Comments.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Comments));
