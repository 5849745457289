import axios from 'axios';

export const addNotification = data => {
  axios.post(`${process.env.REACT_APP_API_URL}/notifications`, {
    type_action: data.typeAction,
    author_id: data.authorId,
    comment: data.comment,
    entity_id: data.entityId,
  });
};
