export default function setFarmErrors(error) {
  let errors = {};
  if (error.response.data.message) {
    errors = {
      submit: error.response.data.message,
    };
  }
  if (error.response.data.errors) {
    if (error.response.data.errors.name) {
      errors = {
        ...errors,
        ...{ name: error.response.data.errors.name.join(' ') },
      };
    }
    if (error.response.data.errors.adress) {
      errors = {
        ...errors,
        ...{ address: 'form.error.noAddress' },
      };
    }

    if (error.response.data.errors.location) {
      errors = {
        ...errors,
        ...{ location: 'form.error.noLocation' },
      };
    }
    if (error.response.data.errors.city) {
      errors = {
        ...errors,
        ...{
          city: error.response.data.errors.city.join(' '),
        },
      };
    }
    if (error.response.data.errors.post_code) {
      errors = {
        ...errors,
        ...{ postCode: error.response.data.errors.post_code.join(' ') },
      };
    }
    if (error.response.data.errors.farm_area) {
      errors = {
        ...errors,
        ...{ farmArea: error.response.data.errors.farm_area.join(' ') },
      };
    }
    if (error.response.data.errors.farm_category_id) {
      errors = {
        ...errors,
        ...{
          farmCategoryId: error.response.data.errors.farm_category_id.join(' '),
        },
      };
    }
    if (error.response.data.errors.description) {
      errors = {
        ...errors,
        ...{ description: error.response.data.errors.description.join(' ') },
      };
    }
  }
  return errors;
}
