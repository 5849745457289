import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Input from 'common/components/input/index';
import Textarea from 'common/components/textarea';
import Select from 'common/components/select';
import ModalContainer from 'common/components/modalContainer';
import MainButton from 'common/components/mainButton';
import DeleteButton from 'common/components/deleteButton';
import LeaveFarmButton from 'common/containers/leaveFarmButton';
import Loader from 'common/components/loader';
import DeleteAccountButton from 'common/containers/deleteAccountButton';
import { profileValidationSchema as validationSchema } from 'profile/validators/profileValidators';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './index.scss';

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
});

const defaultProps = {
  profileImageUrl: '',
  profileImageToken: '',
  profileImageError: '',
  uploadProgress: 0,
  translate: () => null,
};

const propTypes = {
  closeModal: PropTypes.func.isRequired,
  submitEditProfileForm: PropTypes.func.isRequired,
  addProfileImage: PropTypes.func.isRequired,
  profileImageUrl: PropTypes.string,
  profileImageError: PropTypes.string,
  profileImageToken: PropTypes.string,
  initialValues: PropTypes.objectOf(PropTypes.string).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isAdminOrMember: PropTypes.bool.isRequired,
  toggleJoinFarmModal: PropTypes.func.isRequired,
  toggleCreateFarmModal: PropTypes.func.isRequired,
  deleteProfilePicture: PropTypes.func.isRequired,
  profileImageDelete: PropTypes.bool.isRequired,
  toggleDeleteFarmModal: PropTypes.func.isRequired,
  membersResultsNumber: PropTypes.number.isRequired,
  uploadProgress: PropTypes.number,
  translate: PropTypes.func,
};

const EditProfileModalView = ({
  addProfileImage,
  closeModal,
  submitEditProfileForm,
  profileImageUrl,
  profileImageError,
  profileImageToken,
  initialValues,
  isAdmin,
  isAdminOrMember,
  toggleJoinFarmModal,
  toggleCreateFarmModal,
  deleteProfilePicture,
  profileImageDelete,
  translate,
  toggleDeleteFarmModal,
  membersResultsNumber,
  imageLoading,
  uploadProgress,
}) => (
  <ModalContainer handleOutsideClick={closeModal}>
    <div className="edit-profile-modal">
      <div className="edit-profile-modal__scroll-container">
        <div className="edit-profile-modal__profile-data">
          <p className="edit-profile-modal__heading edit-profile-modal__heading--mobile">
            Profile
          </p>
          <div className="edit-profile-modal__labels">
            <div className="edit-profile-modal__label-container">
              <label
                htmlFor="profile-image-input"
                className="edit-profile-modal__label"
              >
                <div className="edit-profile-modal__profile-image-container">
                  {imageLoading ? (
                    <div className="circle-loader-wrapper small-circle">
                      <CircularProgressbar
                        value={uploadProgress}
                        strokeWidth={5}
                        styles={buildStyles({
                          pathTransitionDuration: 0.5,
                          pathColor: '#707070',
                          trailColor: '#E1E1E1',
                        })}
                      />
                      <Loader message="" />
                    </div>
                  ) : (
                    <div
                      className="edit-profile-modal__profile-image"
                      style={
                        profileImageUrl
                          ? {
                              backgroundImage: `url(${profileImageUrl})`,
                              backgroundSize: 'cover',
                            }
                          : {}
                      }
                    />
                  )}
                </div>
                <input
                  name="profile-image-input"
                  id="profile-image-input"
                  className="edit-profile-modal__file-input"
                  type="file"
                  accept=".jpeg, .JPEG, .JPG, .jpg, .png, .PNG, .gif, .GIF"
                  onChange={e => addProfileImage(e, 'profile')}
                />
                <div className="edit-profile-modal__label-inner-container">
                  <span className="edit-profile-modal__label-text">
                    {profileImageUrl
                      ? translate('common.changeObject', {
                          object: translate('page.userProfile.profileImage'),
                        })
                      : translate('common.addObject', {
                          object: translate('page.userProfile.profileImage'),
                        })}
                  </span>
                  {profileImageUrl && (
                    <div className="edit-profile-modal__delete-picture-button">
                      <DeleteButton
                        onClick={e => {
                          deleteProfilePicture();
                          e.preventDefault();
                        }}
                        text={translate('common.deleteObject', {
                          object: translate('common.reaction.image'),
                        })}
                      />
                    </div>
                  )}
                </div>
              </label>
              {profileImageError && (
                <p className="edit-profile-modal__error">{profileImageError}</p>
              )}
            </div>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={(values, { setErrors, setSubmitting }) =>
              submitEditProfileForm(values, { setErrors, setSubmitting })
            }
            render={({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form
                onSubmit={handleSubmit}
                noValidate
                className="edit-profile-modal__form"
              >
                <div className="edit-profile-modal__form-content">
                  <p className="edit-profile-modal__heading edit-profile-modal__heading--desktop">
                    {translate('common.profile')}
                  </p>
                  <div className="edit-profile-modal__row">
                    <Input
                      type="text"
                      value={values.firstName}
                      error={errors.firstName}
                      touched={touched.firstName}
                      name="firstName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={translate('form.common.firstName')}
                      fullWidth
                      withLabel
                    />
                  </div>
                  <div className="edit-profile-modal__row">
                    <Input
                      type="text"
                      value={values.lastName}
                      error={errors.lastName}
                      touched={touched.lastName}
                      name="lastName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={translate('form.common.lastName')}
                      fullWidth
                      withLabel
                    />
                  </div>
                  <div className="edit-profile-modal__row">
                    <Input
                      type="text"
                      value={values.email}
                      error={errors.email}
                      touched={touched.email}
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={translate('form.common.eMail')}
                      fullWidth
                      disabled
                      withLabel
                    />
                  </div>
                  <div className="edit-profile-modal__row edit-profile-modal__row--inline edit-profile-modal__row--post-code">
                    <p className="edit-profile-modal__form-label">
                      {translate('form.common.postCode')}
                    </p>
                    <Input
                      type="text"
                      value={values.postCode}
                      error={errors.postCode}
                      touched={touched.postCode}
                      name="postCode"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="_ _ _ _"
                      fullWidth
                      maxLength={10}
                    />
                  </div>
                  <div className="edit-profile-modal__row edit-profile-modal__row--inline">
                    <p className="edit-profile-modal__form-label">
                      {translate('form.common.birthdate')}
                    </p>
                    <div className="edit-profile-modal__birthdate-inputs-container">
                      <div className="edit-profile-modal__birthdate-input">
                        <Input
                          value={values.birthdateDay}
                          error={errors.birthdateDay}
                          touched={touched.birthdateDay}
                          name="birthdateDay"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="01"
                          maxLength={2}
                          fullWidth
                        />
                      </div>
                      <div className="edit-profile-modal__birthdate-input">
                        <Input
                          value={values.birthdateMonth}
                          error={errors.birthdateMonth}
                          touched={touched.birthdateMonth}
                          name="birthdateMonth"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="01"
                          maxLength={2}
                          fullWidth
                        />
                      </div>
                      <div className="edit-profile-modal__birthdate-input">
                        <Input
                          value={values.birthdateYear}
                          error={errors.birthdateYear}
                          touched={touched.birthdateYear}
                          name="birthdateYear"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="2000"
                          maxLength={4}
                          fullWidth
                        />
                      </div>
                    </div>
                  </div>
                  <div className="edit-profile-modal__row edit-profile-modal__row--inline">
                    <p className="edit-profile-modal__form-label">
                      {translate('form.gender.title')}
                    </p>
                    <Select
                      value={values.gender}
                      error={errors.gender}
                      touched={touched.gender}
                      name="gender"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={translate('form.gender.unspecifieed')}
                      fullWidth
                    >
                      <option value="F">
                        {translate('form.gender.female')}
                      </option>
                      <option value="M">{translate('form.gender.male')}</option>
                    </Select>
                  </div>
                  <div className="edit-profile-modal__row">
                    <Textarea
                      type="text"
                      value={values.description}
                      error={errors.description}
                      touched={touched.description}
                      name="description"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={translate('form.common.description')}
                      fullWidth
                      withLabel
                      textLength={
                        values.description ? values.description.length : 0
                      }
                    />
                  </div>
                </div>
                <div className="edit-profile-modal__form-content">
                  <div className="edit-profile-modal__farm">
                    <p className="edit-profile-modal__heading edit-profile-modal__heading--profile">
                      {translate('common.reaction.farm')}
                    </p>
                    <div className="edit-profile-modal__farm-buttons">
                      {!isAdminOrMember && (
                        <Fragment>
                          <div className="edit-profile-modal__join-button">
                            <MainButton onClick={toggleJoinFarmModal} secondary>
                              {translate('common.joinObject', {
                                object: translate('common.reaction.farm'),
                              })}
                            </MainButton>
                          </div>
                          <MainButton onClick={toggleCreateFarmModal}>
                            {translate('common.createObject', {
                              object: translate('common.reaction.farm'),
                            })}
                          </MainButton>
                        </Fragment>
                      )}
                      {isAdminOrMember && (
                        <Fragment>
                          <div className="edit-profile-modal__leave-farm-button">
                            <LeaveFarmButton />
                          </div>
                          {isAdmin && membersResultsNumber > 1 && (
                            <MainButton onClick={toggleDeleteFarmModal}>
                              {translate('modal.deleteFarm.deleteYourFarm')}
                            </MainButton>
                          )}
                        </Fragment>
                      )}
                    </div>
                  </div>
                  <p className="edit-profile-modal__heading edit-profile-modal__heading--password">
                    {translate('form.authentication.password')}
                  </p>
                  <div className="edit-profile-modal__row">
                    <div className="edit-profile-modal__input edit-profile-modal__input--full-width">
                      <Input
                        value={values.oldPassword}
                        error={errors.oldPassword}
                        touched={touched.oldPassword}
                        name="oldPassword"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={translate(
                          'form.authentication.passwordOld',
                        )}
                        withLabel
                        fullWidth
                      />
                    </div>
                    {!values.oldPassword &&
                      values.password &&
                      values.passwordConfirmation && (
                        <span className="edit-profile-modal__error edit-profile-modal__error--password">
                          This field is required to change password.
                        </span>
                      )}
                  </div>
                  <div className="edit-profile-modal__row">
                    <div className="edit-profile-modal__input edit-profile-modal__input--full-width">
                      <Input
                        value={values.password}
                        error={errors.password}
                        touched={touched.password}
                        name="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={translate(
                          'form.authentication.passwordNew',
                        )}
                        withLabel
                        fullWidth
                      />
                    </div>
                  </div>
                  <div className="edit-profile-modal__row">
                    <div className="edit-profile-modal__input edit-profile-modal__input--full-width">
                      <Input
                        value={values.passwordConfirmation}
                        error={errors.passwordConfirmation}
                        touched={touched.passwordConfirmation}
                        name="passwordConfirmation"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={translate(
                          'form.authentication.passwordRepeat',
                        )}
                        withLabel
                        fullWidth
                      />
                    </div>
                  </div>
                  <p className="edit-profile-modal__heading edit-profile-modal__heading--delete-farm edit-profile-modal__heading--desktop">
                    {translate('common.deleteObject', {
                      object: translate('common.profile'),
                    })}
                  </p>
                  <div className="edit-profile-modal__row">
                    <DeleteAccountButton closeEditProfileModal={closeModal} />
                  </div>
                  <div className="edit-profile-modal__buttons">
                    <MainButton
                      onClick={closeModal}
                      teritary
                      disabled={isSubmitting}
                    >
                      {translate('common.action.cancel')}
                    </MainButton>
                    <div className="edit-profile-modal__submit-button-container">
                      <MainButton
                        type="submit"
                        disabled={
                          (initialValues.firstName === values.firstName &&
                            initialValues.lastName === values.lastName &&
                            initialValues.email === values.email &&
                            initialValues.birthdateDay ===
                              values.birthdateDay &&
                            initialValues.birthdateMonth ===
                              values.birthdateMonth &&
                            initialValues.birthdateYear ===
                              values.birthdateYear &&
                            initialValues.postCode === values.postCode &&
                            initialValues.gender === values.gender &&
                            initialValues.description === values.description &&
                            !profileImageDelete &&
                            (!values.oldPassword ||
                              !values.password ||
                              !values.passwordConfirmation) &&
                            !profileImageToken) ||
                          isSubmitting
                        }
                      >
                        {translate('common.action.save')}
                      </MainButton>
                      {errors.submit && (
                        <span className="edit-profile-modal__error edit-profile-modal__error--submit">
                          {errors.submit}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            )}
          />
        </div>
      </div>
    </div>
  </ModalContainer>
);

EditProfileModalView.defaultProps = defaultProps;

EditProfileModalView.propTypes = propTypes;

export default connect(mapStateToProps)(EditProfileModalView);
