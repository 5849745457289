import React from 'react';
import PropTypes from 'prop-types';
import SearchInput from 'common/components/searchInput';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import './index.scss';

const defaultProps = {
  translate: () => null,
};

const propTypes = {
  searchQuery: PropTypes.string.isRequired,
  onSearchInputChange: PropTypes.func.isRequired,
  translate: PropTypes.func,
};

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
});

const MarketplaceSearchView = ({
  searchQuery,
  onSearchInputChange,
  translate,
}) => (
  <div className="marketplace-search">
    <div className="marketplace-search__search">
      <SearchInput
        value={searchQuery}
        onChange={onSearchInputChange}
        placeholder={translate('common.action.searchArticle')}
      />
    </div>
  </div>
);

MarketplaceSearchView.defaultProps = defaultProps;

MarketplaceSearchView.propTypes = propTypes;

export default connect(mapStateToProps)(MarketplaceSearchView);
