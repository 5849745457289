import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import disableScroll from 'common/utils/disableScroll';
import { fetchLikes, removeLikes } from 'post/redux/actions/likesActions';
import { fetchShares, removeShares } from 'post/redux/actions/sharesActions';
import ModalContainer from 'common/components/modalContainer';
import ActivitiesModalView from 'post/components/activitiesModalView';

const defaultProps = {
  likesNextPageIndex: null,
  likesCount: null,
  sharesNextPageIndex: null,
  sharesCount: null,
};

const propTypes = {
  post: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
      PropTypes.object,
    ]),
  ).isRequired,
  likes: PropTypes.arrayOf(PropTypes.object).isRequired,
  shares: PropTypes.arrayOf(PropTypes.object).isRequired,
  likesCount: PropTypes.number,
  likesNextPageIndex: PropTypes.number,
  sharesCount: PropTypes.number,
  sharesNextPageIndex: PropTypes.number,
  getLikes: PropTypes.func.isRequired,
  getShares: PropTypes.func.isRequired,
  removeLikes: PropTypes.func.isRequired,
  removeShares: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  activeSection: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  likes: state.likes.data,
  likesNextPageIndex: state.likes.nextPageIndex,
  likesCount: state.likes.resultsNumber,
  shares: state.shares.data,
  sharesNextPageIndex: state.shares.nextPageIndex,
  sharesCount: state.shares.resultsNumber,
});

const mapDispatchToProps = dispatch => ({
  getLikes: bindActionCreators(fetchLikes, dispatch),
  removeLikes: bindActionCreators(removeLikes, dispatch),
  getShares: bindActionCreators(fetchShares, dispatch),
  removeShares: bindActionCreators(removeShares, dispatch),
});

const largeBreakpoint = 992;

class ActivitiesModal extends Component {
  state = {
    isLikesLoading: false,
    isSharesLoading: false,
    currentTab: null,
  };

  componentDidMount() {
    const { activeSection, getLikes, getShares, post } = this.props;
    if (activeSection === 'likes') {
      this.toggleInitialLikesLoadIndicator();
      getLikes(post.id).then(() => {
        this.toggleInitialLikesLoadIndicator();
        const { likesNextPageIndex } = this.props;
        // NOTE: In some cases, on larger phones we have to force a call for second page
        if (
          window.innerHeight > 740 &&
          window.innerWidth < largeBreakpoint &&
          likesNextPageIndex
        ) {
          getLikes(post.id, likesNextPageIndex);
        }
      });
    } else if (activeSection === 'shares') {
      this.toggleInitialSharesLoadIndicator();
      getShares(post.id).then(() => {
        this.toggleInitialSharesLoadIndicator();
        const { sharesNextPageIndex } = this.props;
        // NOTE: In some cases, on larger phones we have to force a call for second page
        if (
          window.innerHeight > 740 &&
          window.innerWidth < largeBreakpoint &&
          sharesNextPageIndex
        ) {
          getShares(post.id, sharesNextPageIndex);
        }
      });
    }
  }

  componentWillUnmount() {
    const { removeLikes, removeShares } = this.props;
    removeLikes();
    removeShares();
    disableScroll(false);
  }

  toggleInitialLikesLoadIndicator = () => {
    const { isLikesLoading } = this.state;
    this.setState({ isLikesLoading: !isLikesLoading });
  };

  toggleInitialSharesLoadIndicator = () => {
    const { isSharesLoading } = this.state;
    this.setState({ isSharesLoading: !isSharesLoading });
  };

  getLikesNextPage = () => {
    const { getLikes, likesNextPageIndex, post } = this.props;
    getLikes(post.id, likesNextPageIndex);
  };

  getSharesNextPage = () => {
    const { getShares, sharesNextPageIndex, post } = this.props;
    getShares(post.id, sharesNextPageIndex);
  };

  setCurrentTab = type => {
    const { getShares, post, shares, getLikes, likes } = this.props;
    this.setState({ currentTab: type });
    if (type === 'shares' && shares.length === 0) {
      this.toggleInitialSharesLoadIndicator();
      getShares(post.id).then(() => {
        this.toggleInitialSharesLoadIndicator();
      });
    } else if (type === 'likes' && likes.length === 0) {
      this.toggleInitialLikesLoadIndicator();
      getLikes(post.id).then(() => {
        this.toggleInitialLikesLoadIndicator();
      });
    }
  };

  render() {
    const {
      post,
      closeModal,
      likes,
      likesCount,
      likesNextPageIndex,
      shares,
      sharesNextPageIndex,
      sharesCount,
      activeSection,
    } = this.props;
    const { currentTab, isLikesLoading, isSharesLoading } = this.state;
    return (
      <ModalContainer handleOutsideClick={closeModal}>
        <ActivitiesModalView
          post={post}
          likes={likes}
          shares={shares}
          closeModal={closeModal}
          likesCount={likesCount}
          likesNextPageIndex={likesNextPageIndex}
          getLikesNextPage={this.getLikesNextPage}
          isLikesLoading={isLikesLoading}
          isSharesLoading={isSharesLoading}
          getSharesNextPage={this.getSharesNextPage}
          sharesCount={sharesCount}
          sharesNextPageIndex={sharesNextPageIndex}
          setCurrentTab={this.setCurrentTab}
          currentTab={currentTab}
          activeSection={activeSection}
          commentsCount={post.comments_count}
        />
      </ModalContainer>
    );
  }
}

ActivitiesModal.defaultProps = defaultProps;

ActivitiesModal.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ActivitiesModal);
