import axios from 'axios';

export const loginUser = data =>
  axios.post(`${process.env.REACT_APP_OAUTH_URL}/token`, data);

export const checkEmail = ({ email }) =>
  axios.get(`${process.env.REACT_APP_API_URL}/check`, { params: { email } });

export const verifyEmailAddress = email =>
  axios.get(`${process.env.REACT_APP_API_URL}/verifyEmailAddress`, {
    params: { email },
  });

export const verifyEmailAddressPayed = email => axios.get('http://apilayer.net/api/check', {
    params: {
      email,
      access_key: 'e0e3ad74627df37b5c026fc3b5978e5d',
      smtp: 1,
      format: 1,
    },
  });

export const registerUser = data =>
  axios.post(`${process.env.REACT_APP_API_URL}/register`, data);

export const forgotAccount = data =>
  axios.post(`${process.env.REACT_APP_API_URL}/password/email`, data);

export const resetPassword = data =>
  axios.post(`${process.env.REACT_APP_API_URL}/password/reset`, data);

export const resendEmail = email =>
  axios.get(`${process.env.REACT_APP_API_URL}/email/resend`, {
    params: {
      email,
    },
  });
