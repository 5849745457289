import { createReducer } from 'common/utils/reduxUtils';
import {
  FETCH_USER_NEWSFEED_SUCCESS,
  ADD_CREATED_USER_POST,
  UPDATE_USER_NEWSFEED,
  REMOVE_USER_NEWSFEED,
} from 'profile/redux/types';

const initialState = {
  data: [],
  currentTimestamp: null,
  nextPageIndex: null,
  resultsNumber: null,
};

const userNewsfeedReducer = createReducer(
  { ...initialState },
  {
    [FETCH_USER_NEWSFEED_SUCCESS]: (state, action) => {
      let { data, meta } = action.payload.data;

      // Remove all post which doeesn;t have type hereby fixing this bug https://app.clickup.com/t/24ma4m
      // data = data.filter(c => c.type !== null);

      const { currentTimestamp } = state;
      const hasNextPage = meta.current_page < meta.last_page;

      return {
        ...state,
        data: meta.current_page === 1 ? [...data] : [...state.data, ...data],
        currentTimestamp:
          meta.current_page === 1 ? meta.current_timestamp : currentTimestamp,
        nextPageIndex: hasNextPage ? meta.current_page + 1 : null,
        resultsNumber: meta.total,
      };
    },
    [ADD_CREATED_USER_POST]: (state, action) => ({
      ...state,
      data: [action.payload.data, ...state.data],
    }),
    [UPDATE_USER_NEWSFEED]: (state, action) => ({
      ...state,
      data: [...action.payload.data],
    }),
    [REMOVE_USER_NEWSFEED]: state => ({
      ...state,
      data: [],
      currentTimestamp: null,
      nextPageIndex: null,
      resultsNumber: null,
    }),
  },
);

export default userNewsfeedReducer;
