import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import disableScroll from 'common/utils/disableScroll';
import { getTranslate } from 'react-localize-redux';
import ActivitiesModal from 'post/containers/activitiesModal';
import EditPostModal from 'post/containers/editPostModal';
import PostView from 'post/components/postView';
import ModalContainer from 'common/components/modalContainer';
import PostDetailsModal from 'post/containers/postDetailsModal';
import FarmTourModal from 'farm/containers/farmTourModal';
import TextPostDetailsModal from 'post/containers/textPostDetailsModal';
import ArticleDetailsModal from 'post/containers/articleDetailsModal';
import ImagePreviewModal from 'common/components/imagePreviewModal';
import SellArticleModal from 'post/containers/sellArticleModal';
import GuestModal from 'common/components/guestModal';
import { deletePost } from 'common/api/postsApi';
import { updateNewsfeed } from 'newsfeed/redux/actions/newsfeedActions';
import { updateFarmNewsfeed } from 'farm/redux/actions/farmNewsfeedActions';
import { updateUserNewsfeed } from 'profile/redux/actions/userNewsfeedActions';
import { updateMarketplace } from 'marketplace/redux/actions/marketplaceActions';
import { fetchUserProfile } from 'profile/redux/actions/userProfileActions';
import { fetchFarmDetails } from 'farm/redux/actions/farmActions';
import { addView } from 'post/api/viewsApi';
import { updateHashtagfeed } from 'hashtag/redux/actions/hashtagActions';

const defaultProps = {
  farmId: null,
  userData: {},
  history: {},
  match: {},
  hashtaged: false,
  token: '',
  index: null,
  activeFarms: [],
};

const propTypes = {
  newsfeed: PropTypes.arrayOf(PropTypes.object).isRequired,
  post: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
      PropTypes.object,
    ]),
  ).isRequired,
  farmId: PropTypes.number,
  userData: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
  ),
  getUserProfile: PropTypes.func.isRequired,
  getFarmDetails: PropTypes.func.isRequired,
  feed: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
  updateUserNewsfeed: PropTypes.func.isRequired,
  updateNewsfeed: PropTypes.func.isRequired,
  updateHashtagfeed: PropTypes.func.isRequired,
  updateFarmNewsfeed: PropTypes.func.isRequired,
  history: PropTypes.object,
  updateMarketplace: PropTypes.func.isRequired,
  match: PropTypes.object,
  hashtaged: PropTypes.bool,
  token: PropTypes.string,
  index: PropTypes.number,
  activeFarms: PropTypes.array,
};

const mapStateToProps = state => ({
  userData: state.userData.data,
  token: state.auth.token,
  feed: state.newsfeed.activeFeed,
  translate: getTranslate(state.localize),
  activeFarms: state.farmsList.activeFarms,
});

const mapDispatchToProps = dispatch => ({
  updateNewsfeed: bindActionCreators(updateNewsfeed, dispatch),
  updateFarmNewsfeed: bindActionCreators(updateFarmNewsfeed, dispatch),
  updateUserNewsfeed: bindActionCreators(updateUserNewsfeed, dispatch),
  updateMarketplace: bindActionCreators(updateMarketplace, dispatch),
  getUserProfile: bindActionCreators(fetchUserProfile, dispatch),
  getFarmDetails: bindActionCreators(fetchFarmDetails, dispatch),
  updateHashtagfeed: bindActionCreators(updateHashtagfeed, dispatch),
});

class Post extends Component {
  state = {
    isImagePreviewModalVisible: false,
    isPostDetailsModalVisible: false,
    isTextPostDetailsModalVisible: false,
    isCommentSectionVisible: false,
    isActivitiesModalVisible: false,
    isEditArticleModalVisible: false,
    isEditPostModalVisible: false,
    isArticleDetailsModalVisible: false,
    isFarmTourModalVisible: false,
    isGuestModalVisible: false,
    width: null,
  };

  componentDidMount() {
    this.setWidth();
    window.addEventListener('resize', this.setWidth);
  }

  componentDidUpdate() {
    /* const { userData, history } = this.props;

    if (!userData) {
      history.replace('/auth');
    } */
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setWidth);
  }

  setWidth = () => {
    this.setState({ width: window.innerWidth });
  };

  toggleActivitiesModal = () => {
    const { width } = this.state;
    if (width < 992) {
      const { isActivitiesModalVisible } = this.state;
      this.setState(
        {
          isActivitiesModalVisible: !isActivitiesModalVisible,
        },
        () => disableScroll(!isActivitiesModalVisible, false),
      );
    }
  };

  toggleCommentsSection = () => {
    const { width } = this.state;
    const { userData } = this.props;

    if (userData.id) {
      if (width > 992) {
        this.setState(({ isCommentSectionVisible }) => ({
          isCommentSectionVisible: !isCommentSectionVisible,
        }));
      } else {
        this.toggleActivitiesModal();
      }
    } else {
      this.toggleGuestModal();
    }
  };

  logViewedPost = post => {
    const {
      feed,
      newsfeed,
      updateNewsfeed,
      updateMarketplace,
      token,
      history: {
        location: { pathname },
      },
    } = this.props;

    if (!token) return;

    addView(post.id, token).then(res => {
      if (
        (feed === 'news' || pathname === '/marketplace') &&
        res.data.created !== false
      ) {
        const updated = newsfeed.map(item => {
          if (item.id === post.id) {
            item.views_count += 1;
          }
          return item;
        });
        if (feed === 'news') {
          updateNewsfeed(updated);
        } else {
          updateMarketplace(updated);
        }
      }
    });
  };

  togglePostDetailsModal = () => {
    const { isPostDetailsModalVisible } = this.state;
    this.setState(
      {
        isPostDetailsModalVisible: !isPostDetailsModalVisible,
      },
      () => disableScroll(!isPostDetailsModalVisible, false),
    );
  };

  toggleTextPostDetailsModal = () => {
    const { isTextPostDetailsModalVisible } = this.state;
    this.setState(
      {
        isTextPostDetailsModalVisible: !isTextPostDetailsModalVisible,
      },
      () => disableScroll(!isTextPostDetailsModalVisible, false),
    );
  };

  toggleArticleDetailsModal = () => {
    const { isArticleDetailsModalVisible } = this.state;
    this.setState(
      { isArticleDetailsModalVisible: !isArticleDetailsModalVisible },
      disableScroll(!isArticleDetailsModalVisible, false),
    );
  };

  toggleImagePreviewModal = () => {
    const { isImagePreviewModalVisible } = this.state;
    this.setState(
      {
        isImagePreviewModalVisible: !isImagePreviewModalVisible,
      },
      () => disableScroll(!isImagePreviewModalVisible, false),
    );
  };

  toggleEditArticleModal = () => {
    const { isEditArticleModalVisible } = this.state;
    this.setState(
      { isEditArticleModalVisible: !isEditArticleModalVisible },
      disableScroll(!isEditArticleModalVisible, false),
    );
  };

  toggleFarmTourModal = () => {
    const { isFarmTourModalVisible } = this.state;

    this.setState(
      {
        isFarmTourModalVisible: !isFarmTourModalVisible,
      },
      () => disableScroll(!isFarmTourModalVisible, false),
    );
  };

  toggleEditPostModal = () => {
    const { isEditPostModalVisible } = this.state;
    this.setState(
      { isEditPostModalVisible: !isEditPostModalVisible },
      disableScroll(!isEditPostModalVisible, false),
    );
  };

  toggleDetailsModal = () => {
    const { post } = this.props;
    if (post.shared_content.attachment) {
      this.togglePostDetailsModal();
    } else if (post.shared_content.article) {
      this.toggleArticleDetailsModal();
    } else {
      this.toggleTextPostDetailsModal();
    }
  };

  toggleGuestModal = () => {
    const { isGuestModalVisible } = this.state;
    this.setState(
      { isGuestModalVisible: !isGuestModalVisible },
      disableScroll(!isGuestModalVisible),
    );
  };

  deletePost = () => {
    const {
      newsfeed,
      post,
      updateNewsfeed,
      updateHashtagfeed,
      updateUserNewsfeed,
      updateFarmNewsfeed,
      updateMarketplace,
      match,
      userData,
      getUserProfile,
      getFarmDetails,
    } = this.props;
    const { path } = match;
    deletePost(post.id).then(() => {
      const updated = newsfeed.filter(item => item.id !== post.id);
      if (path === '/') {
        updateNewsfeed(updated);
      }
      if (window.location.pathname === '/hashtag') {
        updateHashtagfeed(updated);
      }
      if (path.indexOf('/profile') > -1) {
        updateUserNewsfeed(updated);
        getUserProfile(userData.id);
      }
      if (path.indexOf('/farms') > -1) {
        const { params } = match;
        updateFarmNewsfeed(updated);
        getFarmDetails(params.farmId);
      }
      if (path.indexOf('/marketplace') > -1) {
        updateMarketplace(updated);
      }
    });
  };

  render() {
    const {
      index,
      newsfeed,
      post,
      farmId,
      userData,
      feed,
      translate,
      hashtaged,
      activeFarms,
      history: {
        location: { pathname },
      },
    } = this.props;

    const {
      isPostDetailsModalVisible,
      isTextPostDetailsModalVisible,
      isImagePreviewModalVisible,
      isCommentSectionVisible,
      isActivitiesModalVisible,
      isEditArticleModalVisible,
      isEditPostModalVisible,
      isArticleDetailsModalVisible,
      isFarmTourModalVisible,
      isGuestModalVisible,
      width,
    } = this.state;
    return (
      <Fragment>
        <PostView
          hashtaged={hashtaged}
          post={post}
          activeFarms={activeFarms}
          index={index}
          newsfeed={newsfeed}
          togglePostDetailsModal={this.togglePostDetailsModal}
          toggleFarmTourModal={this.toggleFarmTourModal}
          toggleImagePreviewModal={this.toggleImagePreviewModal}
          isCommentSectionVisible={isCommentSectionVisible}
          toggleCommentsSection={this.toggleCommentsSection}
          farmId={farmId}
          toggleEditArticleModal={this.toggleEditArticleModal}
          toggleEditPostModal={this.toggleEditPostModal}
          userData={userData}
          toggleArticleDetailsModal={this.toggleArticleDetailsModal}
          toggleDetailsModal={this.toggleDetailsModal}
          deletePost={this.deletePost}
          width={width}
          feed={feed}
          logViewedPost={this.logViewedPost}
          toggleGuestModal={this.toggleGuestModal}
          translate={translate}
          setCurrentTag={this.setCurrentTag}
          path={pathname}
        />
        {isPostDetailsModalVisible && (
          <ModalContainer handleOutsideClick={this.togglePostDetailsModal}>
            <PostDetailsModal
              post={post.shared_content ? post.shared_content : post}
              newsfeed={newsfeed}
              feed={feed}
              togglePostDetailsModal={this.togglePostDetailsModal}
              toggleImagePreviewModal={this.toggleImagePreviewModal}
              farmId={farmId}
            />
          </ModalContainer>
        )}
        {isTextPostDetailsModalVisible && (
          <ModalContainer handleOutsideClick={this.toggleTextPostDetailsModal}>
            <TextPostDetailsModal
              post={post.shared_content ? post.shared_content : post}
              newsfeed={newsfeed}
              togglePostDetailsModal={this.toggleTextPostDetailsModal}
              farmId={farmId}
            />
          </ModalContainer>
        )}
        {isFarmTourModalVisible && (
          <ModalContainer handleOutsideClick={this.toggleFarmTourModal}>
            <FarmTourModal
              post={post}
              toggleFarmTourModal={this.toggleFarmTourModal}
            />
          </ModalContainer>
        )}
        {isArticleDetailsModalVisible && (
          <ModalContainer handleOutsideClick={this.toggleArticleDetailsModal}>
            <ArticleDetailsModal
              newsfeed={newsfeed}
              toggleGuestModal={this.toggleGuestModal}
              post={post.shared_content ? post.shared_content : post}
              toggleArticleDetailsModal={this.toggleArticleDetailsModal}
            />
          </ModalContainer>
        )}
        {isImagePreviewModalVisible && (
          <ImagePreviewModal
            imageObj={
              post.attachment ? post.attachment : post.shared_content.attachment
            }
            onClose={this.toggleImagePreviewModal}
          />
        )}
        {isActivitiesModalVisible && (
          <ActivitiesModal
            post={post}
            closeModal={this.toggleActivitiesModal}
            activeSection="comments"
          />
        )}
        {isEditArticleModalVisible && (
          <SellArticleModal
            closeModal={this.toggleEditArticleModal}
            post={post}
          />
        )}

        {isEditPostModalVisible && (
          <EditPostModal
            newsfeed={newsfeed}
            userData={userData}
            post={post}
            closeModal={this.toggleEditPostModal}
          />
        )}
        {isGuestModalVisible && (
          <GuestModal closeModal={this.toggleGuestModal} />
        )}
      </Fragment>
    );
  }
}

Post.defaultProps = defaultProps;

Post.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Post));
