import React from 'react';
import PropTypes from 'prop-types';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import './index.scss';

const propTypes = {
  url: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
});

const PostReadMore = ({ url, translate }) => (
  <div className="read-more-container">
    <a className="read-more-button" href={url} target="_self">
      {translate('common.action.feeds.readMore')}
    </a>
  </div>
);

PostReadMore.propTypes = propTypes;

export default connect(mapStateToProps)(PostReadMore);
