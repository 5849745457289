import { createReducer } from 'common/utils/reduxUtils';
import {
  FETCH_FARMS_TO_JOIN_LIST_SUCCESS,
  REMOVE_FARMS_TO_JOIN_LIST,
  UPDATE_FARMS_TO_JOIN_LIST_QUERY,
} from 'farms/redux/types';

const initialState = {
  data: [],
  nextPageIndex: null,
  query: '',
};

const FarmsToJoinListReducer = createReducer(
  { ...initialState },
  {
    [FETCH_FARMS_TO_JOIN_LIST_SUCCESS]: (state, action) => {
      const { data, meta } = action.payload.data;
      const hasNextPage = meta.current_page < meta.last_page;

      return {
        ...state,
        data: meta.current_page === 1 ? [...data] : [...state.data, ...data],
        nextPageIndex: hasNextPage ? meta.current_page + 1 : null,
      };
    },
    [UPDATE_FARMS_TO_JOIN_LIST_QUERY]: (state, action) => ({
      ...state,
      query: action.payload.query,
    }),
    [REMOVE_FARMS_TO_JOIN_LIST]: state => ({
      ...state,
      data: [],
      nextPageIndex: null,
      query: '',
    }),
  },
);

export default FarmsToJoinListReducer;
