import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import loadImage from 'blueimp-load-image';
import disableScroll from 'common/utils/disableScroll';
import FarmView from 'farm/components/farmView';
import CreateFarmModal from 'farm/containers/createFarmModal';
import CreateFarmTourModal from 'farm/containers/createFarmTourModal';
import FarmTourModal from 'farm/containers/farmTourModal';
import ModalContainer from 'common/components/modalContainer';

import TitleImageModal from 'farm/components/titleImageModal';
import MembersListModal from 'farm/containers/membersListModal';
import MembershipRequestsModal from 'farm/containers/membershipRequestsModal';
import ImagePreviewModal from 'common/components/imagePreviewModal';
import {
  fetchFarmDetails,
  removeFarmDetails,
} from 'farm/redux/actions/farmActions';
import {
  fetchFarmNewsfeed,
  removeFarmNewsfeed,
} from 'farm/redux/actions/farmNewsfeedActions';
import {
  fetchFarmMembers,
  removeFarmMembers,
} from 'farm/redux/actions/farmMembersActions';
import { fetchFarmMembershipRequests } from 'farm/redux/actions/farmMembershipRequestsActions';
import requestAttachmentToken from 'common/api/attachmentsApi';
import { editFarm } from 'farm/api/farmsApi';
import { removeComments } from 'post/redux/actions/commentsActions';
import { getTranslate } from 'react-localize-redux';

const defaultProps = {
  farmDetails: {},
  farmNewsfeed: [],
  farmMembers: [],
  nextPageIndex: null,
  token: '',
  userData: null,
  membersResultsNumber: 0,
  match: {},
  history: {},
  translate: () => null,
};

const propTypes = {
  farmDetails: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.object,
      PropTypes.array,
      PropTypes.bool,
    ]),
  ),
  farmNewsfeed: PropTypes.arrayOf(PropTypes.object),
  getFarmDetails: PropTypes.func.isRequired,
  getFarmNewsfeed: PropTypes.func.isRequired,
  getFarmMembers: PropTypes.func.isRequired,
  farmMembers: PropTypes.arrayOf(PropTypes.object),
  nextPageIndex: PropTypes.number,
  token: PropTypes.string,
  userData: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
  ),
  membersResultsNumber: PropTypes.number,
  removeComments: PropTypes.func.isRequired,
  removeFarmNewsfeed: PropTypes.func.isRequired,
  removeFarmDetails: PropTypes.func.isRequired,
  removeFarmMembers: PropTypes.func.isRequired,
  getFarmMembershipRequests: PropTypes.func.isRequired,
  match: PropTypes.object,
  history: PropTypes.object,
  translate: PropTypes.func,
};

const mapStateToProps = state => ({
  farmDetails: state.farm.data,
  farmNewsfeed: state.farmNewsfeed.data,
  nextPageIndex: state.farmNewsfeed.nextPageIndex,
  farmMembers: state.farmMembers.data,
  membersResultsNumber: state.farmMembers.resultsNumber,
  token: state.auth.token,
  userData: state.userData.data,
  translate: getTranslate(state.localize),
});

const mapDispatchToProps = dispatch => ({
  getFarmDetails: bindActionCreators(fetchFarmDetails, dispatch),
  getFarmNewsfeed: bindActionCreators(fetchFarmNewsfeed, dispatch),
  getFarmMembers: bindActionCreators(fetchFarmMembers, dispatch),
  removeComments: bindActionCreators(removeComments, dispatch),
  removeFarmDetails: bindActionCreators(removeFarmDetails, dispatch),
  removeFarmNewsfeed: bindActionCreators(removeFarmNewsfeed, dispatch),
  removeFarmMembers: bindActionCreators(removeFarmMembers, dispatch),
  getFarmMembershipRequests: bindActionCreators(
    fetchFarmMembershipRequests,
    dispatch,
  ),
});

class Farm extends Component {
  state = {
    isMembersListModalVisible: false,
    isCreateFarmModalVisible: false,
    isCreateFarmTourModalVisible: false,
    isMembershipRequestsModalVisible: false,
    isTitleImageLoaded: false,
    isInitialPageLoading: false,
    isTitleImageModalVisible: false,
    isTitleImageLoading: false,
    titleImageToken: '',
    isImagePreviewModalVisible: false,
    uploadProgress: 0,
    uploadCounter: 0,
    totalUploadTime: 0,

    isFarmTourModalVisible: false,
  };

  componentDidMount() {
    const {
      token,
      userData,
      getFarmDetails,
      getFarmNewsfeed,
      getFarmMembershipRequests,
      getFarmMembers,
      match,
      history,
    } = this.props;
    const { farmId } = match.params;

    this.toggleInitialPageLoading();
    getFarmDetails(farmId);
    getFarmMembers(farmId);
    getFarmMembershipRequests(farmId);

    if (token && userData) {
      getFarmNewsfeed(farmId).then(() => {
        this.toggleInitialPageLoading();
      });
    } else {
      history.push(`/farms/${farmId}/unauthorized`);
    }
    disableScroll(false);
  }

  componentDidUpdate(prevProps) {
    const { match: prevMatch } = prevProps;
    const { farmId: prevFarmId } = prevMatch.params;
    const { match } = this.props;
    const { farmId } = match.params;
    if (prevFarmId !== farmId) {
      const {
        getFarmDetails,
        getFarmNewsfeed,
        getFarmMembers,
        removeFarmNewsfeed,
        removeFarmDetails,
        removeFarmMembers,
      } = this.props;
      removeFarmDetails();
      removeFarmMembers();
      removeFarmNewsfeed();
      getFarmDetails(farmId);
      getFarmMembers(farmId);
      this.toggleInitialPageLoading();
      getFarmNewsfeed(farmId).then(() => {
        this.toggleInitialPageLoading();
      });
    }
  }

  componentWillUnmount() {
    const {
      removeFarmDetails,
      removeFarmMembers,
      removeFarmNewsfeed,
      removeComments,
    } = this.props;
    removeFarmDetails();
    removeFarmMembers();
    removeFarmNewsfeed();
    removeComments();
  }

  toggleInitialPageLoading = () => {
    const { isInitialPageLoading } = this.state;
    this.setState({ isInitialPageLoading: !isInitialPageLoading });
  };

  toggleMembersListModal = () => {
    const { isMembersListModalVisible } = this.state;
    this.setState(
      { isMembersListModalVisible: !isMembersListModalVisible },
      disableScroll(!isMembersListModalVisible),
    );
  };

  toggleCreateFarmModal = () => {
    const { isCreateFarmModalVisible } = this.state;
    this.setState(
      { isCreateFarmModalVisible: !isCreateFarmModalVisible },
      disableScroll(!isCreateFarmModalVisible),
    );
  };

  toggleCreateFarmTourModal = () => {
    const { isCreateFarmTourModalVisible } = this.state;
    this.setState(
      { isCreateFarmTourModalVisible: !isCreateFarmTourModalVisible },
      disableScroll(!isCreateFarmTourModalVisible),
    );
  };

  toggleFarmTourModal = () => {
    const { isFarmTourModalVisible } = this.state;
    this.setState(
      { isFarmTourModalVisible: !isFarmTourModalVisible },
      disableScroll(!isFarmTourModalVisible),
    );
  };

  handleTitleImageLoad = () => {
    this.setState({ isTitleImageLoaded: true });
  };

  getFarmNewsfeedNextPage = () => {
    const { getFarmNewsfeed, nextPageIndex, match } = this.props;
    const { farmId } = match.params;
    getFarmNewsfeed(farmId, nextPageIndex);
  };

  updateFarmNewsfeed = () => {
    const { getFarmNewsfeed, removeFarmNewsfeed, match } = this.props;
    const { farmId } = match.params;
    removeFarmNewsfeed();
    getFarmNewsfeed(farmId);
  };

  toggleTitleImageModal = () => {
    const { isTitleImageModalVisible } = this.state;
    this.setState(
      { isTitleImageModalVisible: !isTitleImageModalVisible },
      disableScroll(!isTitleImageModalVisible),
    );
  };

  toggleMembershipRequestsModal = () => {
    const {
      isCreateFarmModalVisible,
      isMembershipRequestsModalVisible,
    } = this.state;
    if (isCreateFarmModalVisible) {
      this.toggleCreateFarmModal();
    }
    this.setState(
      { isMembershipRequestsModalVisible: !isMembershipRequestsModalVisible },
      disableScroll(!isMembershipRequestsModalVisible),
    );
  };

  changeTitleImage = e => {
    this.toggleTitleImageModal();
    this.setState({
      isTitleImageLoading: true,
    });
    const imageFile = e.target.files[0];
    const reader = new FileReader();
    reader.onloadstart = this.updateProgress;

    const image = new Image();
    if (imageFile) {
      reader.readAsDataURL(imageFile);
      reader.addEventListener('load', () => {
        image.src = reader.result;
        image.addEventListener('load', () => {
          loadImage.parseMetaData(imageFile, data => {
            let orientation = 0;
            if (data.exif) {
              orientation = data.exif.get('Orientation');
            }
            loadImage(
              imageFile,
              canvas => {
                const base64data = canvas.toDataURL(imageFile.type);
                canvas.toBlob(blob => {
                  this.setState({
                    titleImageUrl:
                      imageFile.type === 'image/gif' ? image.src : base64data,
                    titleImageToken: '',
                    titleImageError: '',
                  });
                  document.getElementById('farm-profile-image-input').value =
                    '';
                  const imgAsForm = new FormData();
                  imgAsForm.append(
                    'file',
                    imageFile.type === 'image/gif' ? imageFile : blob,
                  );
                  requestAttachmentToken(imgAsForm, 'image')
                    .then(res => {
                      this.resetProgressInterval();

                      this.setState({
                        titleImageToken: res.data.token,
                        isTitleImageLoading: false,
                      });
                    })
                    .catch(error => {
                      this.resetProgressInterval();

                      this.setState({
                        isTitleImageLoading: false,
                        titleImageError:
                          error.response.data &&
                          error.response.data.errors &&
                          error.response.data.errors.file.join(' '),
                      });
                    });
                }, imageFile.type);
              },
              {
                canvas: true,
                orientation,
                maxWidth: 1300
              },
            );
          });
        });
      });
    }
  };

  uploadTitleImage = () => {
    const { titleImageToken } = this.state;
    const {
      getFarmDetails,
      getFarmNewsfeed,
      removeFarmNewsfeed,
      farmDetails,
    } = this.props;
    editFarm(farmDetails.id, {
      title_image: titleImageToken,
    }).then(() => {
      this.resetProgressInterval();
      this.toggleTitleImageModal();
      getFarmDetails(farmDetails.id);
      removeFarmNewsfeed();
      getFarmNewsfeed(farmDetails.id);
    });
  };

  updateProgress = evt => {
    this.resetProgressInterval();

    if (evt.lengthComputable) {
      const mb = 1024 * 1024;

      const totalMb = Math.ceil(evt.total / mb);
      const ratio = 2;
      const secs = Math.ceil(totalMb * ratio);

      this.setState({
        totalUploadTime: secs,
      });

      this.interval = setInterval(() => {
        this.updateProgressBar();
      }, 100);
    }
  };

  resetProgressInterval = () => {
    clearInterval(this.interval);

    this.setState({
      uploadCounter: 0,
      totalUploadTime: 0,
      uploadProgress: 0,
    });
  };

  updateProgressBar = () => {
    this.setState({
      uploadCounter: this.state.uploadCounter + 0.1,
    });

    const percentLoaded = Math.round(
      (this.state.uploadCounter / this.state.totalUploadTime) * 100,
    );

    if (percentLoaded <= 95) {
      this.setState({
        uploadProgress: percentLoaded,
      });
    } else {
      this.setState({
        uploadProgress: 95,
      });
    }

    if (this.state.uploadCounter >= this.state.totalUploadTime) {
      clearInterval(this.interval);
    }
  };

  toggleImagePreviewModal = () => {
    const { isImagePreviewModalVisible } = this.state;
    this.setState(
      {
        isImagePreviewModalVisible: !isImagePreviewModalVisible,
      },
      () => disableScroll(!isImagePreviewModalVisible),
    );
  };

  render() {
    const {
      farmDetails,
      farmNewsfeed,
      nextPageIndex,
      farmMembers,
      userData,
      membersResultsNumber,
      token,
      translate,
    } = this.props;
    const {
      isMembersListModalVisible,
      isCreateFarmModalVisible,
      isCreateFarmTourModalVisible,
      isMembershipRequestsModalVisible,
      isTitleImageLoaded,
      isInitialPageLoading,
      isTitleImageModalVisible,
      titleImageUrl,
      isTitleImageLoading,
      titleImageError,
      titleImageToken,
      isImagePreviewModalVisible,
      uploadProgress,
      isFarmTourModalVisible,
    } = this.state;
    return (
      <Fragment>
        <FarmView
          farm={farmDetails}
          toggleCreateFarmModal={this.toggleCreateFarmModal}
          toggleCreateFarmTourModal={this.toggleCreateFarmTourModal}
          toggleMembersListModal={this.toggleMembersListModal}
          handleTitleImageLoad={this.handleTitleImageLoad}
          isTitleImageLoaded={isTitleImageLoaded}
          farmNewsfeed={farmNewsfeed}
          nextPageIndex={nextPageIndex}
          isInitialPageLoading={isInitialPageLoading}
          getFarmNewsfeedNextPage={this.getFarmNewsfeedNextPage}
          changeTitleImage={this.changeTitleImage}
          farmMembers={farmMembers}
          isAdmin={userData ? userData.admin_in === farmDetails.id : false}
          isMember={userData ? userData.member_in === farmDetails.id : false}
          membersResultsNumber={membersResultsNumber}
          toggleMembershipRequestsModal={this.toggleMembershipRequestsModal}
          isAuthorized={!!(token && userData)}
          toggleImagePreviewModal={this.toggleImagePreviewModal}
          toggleFarmTourModal={this.toggleFarmTourModal}
        />
        {isCreateFarmModalVisible && !isMembersListModalVisible && (
          <CreateFarmModal
            farm={farmDetails}
            closeModal={this.toggleCreateFarmModal}
            toggleMembersListModal={this.toggleMembersListModal}
            submitButtonText={translate('common.action.save')}
            membersResultsNumber={membersResultsNumber}
            farmMembers={farmMembers}
            toggleMembershipRequestsModal={this.toggleMembershipRequestsModal}
            uploadProgress={uploadProgress}
            updateProgress={this.updateProgress}
            resetProgressInterval={this.resetProgressInterval}
          />
        )}
        {isCreateFarmTourModalVisible && (
          <CreateFarmTourModal
            farm={farmDetails}
            updateFarmNewsfeed={this.updateFarmNewsfeed}
            closeModal={this.toggleCreateFarmTourModal}
            uploadProgress={uploadProgress}
            updateProgress={this.updateProgress}
            resetProgressInterval={this.resetProgressInterval}
          />
        )}
        {isFarmTourModalVisible && (
          <ModalContainer handleOutsideClick={this.toggleFarmTourModal}>
            <FarmTourModal
              farm={farmDetails}
              toggleFarmTourModal={this.toggleFarmTourModal}
            />
          </ModalContainer>
        )}
        {isTitleImageModalVisible && (
          <TitleImageModal
            image={titleImageUrl}
            closeModal={this.toggleTitleImageModal}
            uploadTitleImage={this.uploadTitleImage}
            isTitleImageLoading={isTitleImageLoading}
            titleImageError={titleImageError}
            titleImageToken={titleImageToken}
            uploadProgress={uploadProgress}
          />
        )}
        {isMembersListModalVisible && (
          <MembersListModal
            farm={farmDetails}
            closeModal={this.toggleMembersListModal}
            isAdmin={userData.admin_in === farmDetails.id}
          />
        )}
        {isMembershipRequestsModalVisible && (
          <MembershipRequestsModal
            closeModal={this.toggleMembershipRequestsModal}
            farmId={farmDetails.id}
            isAdmin={token && userData}
          />
        )}
        {isImagePreviewModalVisible && (
          <ImagePreviewModal
            imageObj={farmDetails.title_image}
            onClose={this.toggleImagePreviewModal}
          />
        )}
      </Fragment>
    );
  }
}

Farm.defaultProps = defaultProps;

Farm.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Farm));
