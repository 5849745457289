import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import NewsfeedView from 'newsfeed/components/newsfeedView';
import GuestModal from 'common/components/guestModal';
import {
  fetchNewsfeed,
  removeNewsfeed,
  checkForUnreadPosts,
} from 'newsfeed/redux/actions/newsfeedActions';
import { removeComments } from 'post/redux/actions/commentsActions';
import disableScroll from 'common/utils/disableScroll';
import { fetchNotifications } from 'notification/redux/actions/notificationActions';
import { fetchActiveFarms } from 'farms/redux/actions/farmsListActions';

const defaultProps = {
  newsfeed: [],
  // token: '',
  // userData: null,
  nextPageIndex: null,
  history: {},
};

const propTypes = {
  newsfeed: PropTypes.arrayOf(PropTypes.object),
  getNewsfeed: PropTypes.func.isRequired,
  // token: PropTypes.string,
  // userData: PropTypes.objectOf(
  //   PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
  // ),
  removeNewsfeed: PropTypes.func.isRequired,
  nextPageIndex: PropTypes.number,
  removeComments: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  history: PropTypes.object,
  notificationModalVisible: PropTypes.bool.isRequired,
  checkForUnreadPosts: PropTypes.func.isRequired,
  fetchNotifications: PropTypes.func.isRequired,
  fetchActiveFarms: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  newsfeed: state.newsfeed.data,
  nextPageIndex: state.newsfeed.nextPageIndex,
  token: state.auth.token,
  userData: state.userData.data,
  isLoading: state.newsfeed.isLoading,
  notificationModalVisible: state.notifications.isModalVisible,
});

const mapDispatchToProps = dispatch => ({
  getNewsfeed: bindActionCreators(fetchNewsfeed, dispatch),
  removeNewsfeed: bindActionCreators(removeNewsfeed, dispatch),
  removeComments: bindActionCreators(removeComments, dispatch),
  checkForUnreadPosts: bindActionCreators(checkForUnreadPosts, dispatch),
  fetchNotifications: bindActionCreators(fetchNotifications, dispatch),
  fetchActiveFarms: bindActionCreators(fetchActiveFarms, dispatch),
});

class Newsfeed extends Component {
  state = {
    fixedToggler: false,
    isGuestModalVisible: false,
  };

  componentDidMount() {
    const {
      // token,
      // userData,
      history,
      getNewsfeed,
      checkForUnreadPosts,
      fetchNotifications,
      fetchActiveFarms,
    } = this.props;
    // if (!token && !userData) {
    //   history.replace('/auth');
    // } else {
    const path = history.location.pathname;

    setTimeout(() => {
      fetchNotifications();
    }, 250);
    fetchActiveFarms();

    setTimeout(() => {
      if (path.includes('post')) {
        const parts = path.split('/');

        getNewsfeed(1, parts[2]);
      } else {
        getNewsfeed();
        checkForUnreadPosts();
      }
    }, 250);
    // }

    disableScroll(false);
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    const { removeNewsfeed, removeComments } = this.props;
    removeNewsfeed();
    removeComments();
    window.removeEventListener('scroll', this.handleScroll);
    clearInterval(this.interval);
  }

  handleScroll = () => {
    this.setState({
      fixedToggler: !!(
        window.innerWidth < 992 ||
        (window.innerWidth > 991 && window.scrollY >= 30)
      ),
    });
  };

  getNewsfeedNextPage = () => {
    const { getNewsfeed, nextPageIndex } = this.props;
    getNewsfeed(nextPageIndex);
  };

  toggleGuestModal = () => {
    const { isGuestModalVisible } = this.state;
    this.setState(
      { isGuestModalVisible: !isGuestModalVisible },
      disableScroll(!isGuestModalVisible),
    );
  };

  render() {
    const {
      newsfeed,
      nextPageIndex,
      isLoading,
      notificationModalVisible,
      history,
    } = this.props;
    const { fixedToggler, isGuestModalVisible } = this.state;
    return (
      <Fragment>
        <NewsfeedView
          newsfeed={newsfeed}
          getNewsfeedNextPage={this.getNewsfeedNextPage}
          nextPageIndex={nextPageIndex}
          isLoading={isLoading}
          fixed={fixedToggler}
          history={history}
          toggleGuestModal={this.toggleGuestModal}
          notificationModalVisible={notificationModalVisible}
        />
        {isGuestModalVisible && (
          <GuestModal closeModal={this.toggleGuestModal} />
        )}
      </Fragment>
    );
  }
}

Newsfeed.defaultProps = defaultProps;

Newsfeed.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Newsfeed);
