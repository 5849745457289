import axios from 'axios';

export const sendFarmInvitations = (id, data) =>
  axios.post(`${process.env.REACT_APP_API_URL}/farms/${id}/invitations`, {
    payload: data,
  });

export const sendAppInvitations = data =>
  axios.post(`${process.env.REACT_APP_API_URL}/invitations`, {
    payload: data,
  });
