import React from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import classNames from 'classnames';
import ActivityItem from 'post/components/activityItem';
import Loader from 'common/components/loader';
import Button from 'common/components/button';
import CloseButton from 'common/components/closeButton';
import PostComments from 'post/containers/postComments';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import './index.scss';

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
});

const defaultProps = {
  currentTab: null,
  likesCount: null,
  likesNextPageIndex: null,
  sharesCount: null,
  sharesNextPageIndex: null,
  commentsCount: null,
};

const propTypes = {
  activeSection: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  currentTab: PropTypes.string,
  getLikesNextPage: PropTypes.func.isRequired,
  getSharesNextPage: PropTypes.func.isRequired,
  isLikesLoading: PropTypes.bool.isRequired,
  isSharesLoading: PropTypes.bool.isRequired,
  likes: PropTypes.arrayOf(PropTypes.object).isRequired,
  likesCount: PropTypes.number,
  likesNextPageIndex: PropTypes.number,
  post: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
      PropTypes.object,
    ]),
  ).isRequired,
  shares: PropTypes.arrayOf(PropTypes.object).isRequired,
  setCurrentTab: PropTypes.func.isRequired,
  sharesCount: PropTypes.number,
  sharesNextPageIndex: PropTypes.number,
  commentsCount: PropTypes.number,
};

const ActivitiesModalView = ({
  activeSection,
  closeModal,
  currentTab,
  getLikesNextPage,
  getSharesNextPage,
  isLikesLoading,
  isSharesLoading,
  likes,
  likesCount,
  likesNextPageIndex,
  post,
  shares,
  setCurrentTab,
  sharesCount,
  sharesNextPageIndex,
  commentsCount,
  translate,
}) => {
  const likesTabClasses = classNames({
    'activities-modal__tab-button': true,
    'activities-modal__tab-button--likes-active':
      (currentTab && currentTab === 'likes') ||
      (!currentTab && activeSection === 'likes'),
  });
  const sharesTabClasses = classNames({
    'activities-modal__tab-button': true,
    'activities-modal__tab-button--shares-active':
      (currentTab && currentTab === 'shares') ||
      (!currentTab && activeSection === 'shares'),
  });
  const commentsTabClasses = classNames({
    'activities-modal__tab-button': true,
    'activities-modal__tab-button--comments': true,
    'activities-modal__tab-button--comments-active':
      (currentTab && currentTab === 'comments') ||
      (!currentTab && activeSection === 'comments'),
  });
  return (
    <div className="activities-modal">
      <div className="activities-modal__header">
        <div>
          <Button
            onClick={() => setCurrentTab('comments')}
            className={commentsTabClasses}
          >
            <span className="activities-modal__tab-button-content activities-modal__tab-button-content--comments">
              {commentsCount || post.comments_count}
              <span className="activities-modal__tab-button-text">
                &nbsp;
                {(commentsCount || post.comments_count) === 1
                  ? translate('common.reaction.comment')
                  : translate('common.reaction.comments')}
              </span>
            </span>
          </Button>
          {post.likes_count > 0 && (
            <Button
              onClick={() => setCurrentTab('likes')}
              className={likesTabClasses}
            >
              <span className="activities-modal__tab-button-content activities-modal__tab-button-content--likes">
                {likesCount || post.likes_count}
                <span className="activities-modal__tab-button-text">
                  &nbsp;
                  {(likesCount || post.likes_count) === 1
                    ? translate('common.reaction.like')
                    : translate('common.reaction.likes')}
                </span>
              </span>
            </Button>
          )}
          {post.shares_count > 0 && (
            <Button
              onClick={() => setCurrentTab('shares')}
              className={sharesTabClasses}
            >
              <span className="activities-modal__tab-button-content activities-modal__tab-button-content--shares">
                {sharesCount || post.shares_count}
                <span className="activities-modal__tab-button-text">
                  &nbsp;
                  {(sharesCount || post.shares_count) === 1
                    ? translate('common.reaction.share')
                    : translate('common.reaction.shares')}
                </span>
              </span>
            </Button>
          )}
        </div>
        <CloseButton onClick={closeModal} />
      </div>
      {((currentTab && currentTab === 'likes') ||
        (!currentTab && activeSection === 'likes')) && (
        <div id="likes-container" className="activities-modal__content">
          {isLikesLoading && (
            <div className="activities-modal__load-container">
              <Loader message={translate('common.loading')} />
            </div>
          )}
          <InfiniteScroll
            dataLength={likes.length}
            scrollThreshold="400px"
            next={likesNextPageIndex ? getLikesNextPage : null}
            hasMore={!!likesNextPageIndex}
            scrollableTarget="likes-container"
            loader={
              likesNextPageIndex ? (
                <Loader message={translate('common.loading')} />
              ) : null
            }
          >
            {likes.map(like => (
              <div key={like.id}>
                <ActivityItem activity={like} />
              </div>
            ))}
          </InfiniteScroll>
        </div>
      )}
      {((currentTab && currentTab === 'shares') ||
        (!currentTab && activeSection === 'shares')) && (
        <div id="shares-container" className="activities-modal__content">
          {isSharesLoading && (
            <div className="activities-modal__load-container">
              <Loader message={translate('common.loading')} />
            </div>
          )}
          <InfiniteScroll
            dataLength={shares.length}
            next={sharesNextPageIndex ? getSharesNextPage : null}
            hasMore={!!sharesNextPageIndex}
            scrollableTarget="shares-container"
            loader={
              sharesNextPageIndex ? (
                <Loader message={translate('common.loading')} />
              ) : null
            }
          >
            {shares.map(share => (
              <div key={share.id}>
                <ActivityItem activity={share} />
              </div>
            ))}
          </InfiniteScroll>
        </div>
      )}
      {((currentTab && currentTab === 'comments') ||
        (!currentTab && activeSection === 'comments')) && (
        <div className="activities-modal__content activities-modal__content--comments">
          <PostComments post={post} />
        </div>
      )}
    </div>
  );
};

ActivitiesModalView.defaultProps = defaultProps;

ActivitiesModalView.propTypes = propTypes;

export default connect(mapStateToProps)(ActivitiesModalView);
