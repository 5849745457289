import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import 'intersection-observer';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import {
  FacebookShareButton,
  WhatsappShareButton,
  FacebookIcon,
  WhatsappIcon,
} from 'react-share';
import Linkify from 'linkifyjs/react';
import renderHTML from 'react-render-html';
import PostComments from 'post/containers/postComments';
import PostHeader from 'post/components/postHeader';
import PostActivities from 'post/containers/postActivities';
import PostActions from 'post/containers/postActions';
import PostReadMore from 'post/components/postReadMore';
import SharedContent from 'post/components/sharedContent';
import ImageWithLoader from 'common/components/imageWithLoader';
import VideoWithLoader from 'common/components/videoWithLoader';
import ActiveFarm from 'farms/components/activeFarm';
import * as linkify from 'linkifyjs';
import hashtag from 'linkifyjs/plugins/hashtag';
import './index.scss';
import FarmlifesImage from 'assets/images/farmlifes.jpg';

hashtag(linkify);

const defaultProps = {
  farmId: null,
  width: null,
  feed: '',
  translate: () => null,
  logViewedPost: () => null,
  hashtaged: false,
  path: '',
  toggleFarmTourModal: () => null,
  toggleGuestModal: () => null,
  index: null,
  activeFarms: [],
};

const propTypes = {
  newsfeed: PropTypes.arrayOf(PropTypes.object).isRequired,
  post: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
      PropTypes.object,
    ]),
  ).isRequired,
  togglePostDetailsModal: PropTypes.func.isRequired,
  toggleFarmTourModal: PropTypes.func,
  toggleImagePreviewModal: PropTypes.func.isRequired,
  isCommentSectionVisible: PropTypes.bool.isRequired,
  toggleCommentsSection: PropTypes.func.isRequired,
  toggleGuestModal: PropTypes.func,
  toggleDetailsModal: PropTypes.func.isRequired,
  farmId: PropTypes.number,
  toggleEditArticleModal: PropTypes.func.isRequired,
  userData: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
  ).isRequired,
  toggleArticleDetailsModal: PropTypes.func.isRequired,
  deletePost: PropTypes.func.isRequired,
  width: PropTypes.number,
  toggleEditPostModal: PropTypes.func.isRequired,
  feed: PropTypes.string,
  translate: PropTypes.func,
  logViewedPost: PropTypes.func,
  hashtaged: PropTypes.bool,
  path: PropTypes.string,
  index: PropTypes.number,
  activeFarms: PropTypes.array,
};

const PostView = ({
  newsfeed,
  post,
  togglePostDetailsModal,
  toggleFarmTourModal,
  toggleImagePreviewModal,
  isCommentSectionVisible,
  toggleCommentsSection,
  toggleGuestModal,
  toggleDetailsModal,
  farmId,
  toggleEditArticleModal,
  toggleEditPostModal,
  userData,
  toggleArticleDetailsModal,
  deletePost,
  width,
  feed,
  logViewedPost,
  translate,
  hashtaged,
  path,
  index,
  activeFarms,
}) => {
  const [ref, inView] = useInView({
    threshold: post.attachment && post.attachment.type === 'video' ? 1 : 0.1,
    // triggerOnce: post.attachment && post.attachment.type !== 'video',
    triggerOnce: false,
  });

  let shouldPlay = false;

  if (inView) {
    logViewedPost(post);
    shouldPlay = true;
  }

  const postItemClasses = classNames({
    'post-item': true,
    'post-item__blurred': index && index > 4 && !userData.id && !post.article,
  });
  const sharedContentClasses = classNames({
    'post-item__shared-content': true,
    'post-item__shared-content--linked':
      post.shared_content && post.shared_content.attachment,
  });
  const desktopContentClasses = classNames({
    'post-item__content': true,
    'post-item__content--linked': post.attachment,
    'post-for-hashtag': !!hashtaged,
  });
  const textClasses = classNames({
    'post-item__text': true,
    'post-item__text--margin':
      (post.attachment && post.attachment.type === 'image') ||
      (post.attachment && post.attachment.type === 'video'),
  });

  let farms = [];
  if (index && index === 2 && activeFarms && activeFarms.length > 0) {
    farms = activeFarms.map((item, i) => <ActiveFarm farm={item} key={i} />);
  }

  const linkOption = {
    className: 'inpost-link',
    defaultProtocol: 'https',
    format(value) {
      return value.replace(/(?:http:\/\/|https:\/\/)/g, '');
    },
    formatHref(href, type) {
      let newHref = href;

      if (type === 'hashtag') {
        newHref = `/hashtag${newHref}`;
      }
      return newHref;
    },
    target: {
      url: '_blank',
    },
    attributes: {
      onClick: () => {
        // window.location.replace('/hashtag#farm')
      },
    },
  };

  let modalToOpen = toggleImagePreviewModal;

  if (post.type === 'farm_farmtour') {
    modalToOpen = toggleFarmTourModal;
  } else if (
    post.shared_content &&
    post.shared_content.type === 'farm_farmtour'
  ) {
    modalToOpen = toggleFarmTourModal;
  }

  if (post.text && post.text.length > 0) {
    const regexp = /(#\S+)/gi;
    const matcheArray = post.text.match(regexp);

    if (matcheArray && matcheArray.length > 0) {
      matcheArray.forEach(item => {
        const newItem = item
          .replace(/ö/g, 'o')
          .replace(/ä/g, 'a')
          .replace(/ü/g, 'u');
        if (newItem !== item) {
          post.text = post.text.replace(item, newItem);
        }
      });
    }
  }

  const urlArray = path.split('/');

  let articleTitle = '';
  let articleDescription = '';
  let articleImage = '';

  if (post.article) {
    articleTitle =
      post.article.title.length < 100
        ? post.article.title
        : post.article.title.substr(0, 100);
    articleTitle +=
      post.article.price_on_request === '0'
        ? ` - ${post.article.price} €`
        : ` - ${translate('farm.priceOnRequest')}`;

    articleImage =
      post.article.attachments.length > 0
        ? post.article.attachments[0].url
        : `${window.location.origin}${FarmlifesImage}`;
    articleDescription = post.text;

    // if(urlArray.length === 3 && post.article.length !== 0){
    //   window.location.hash = `!${post.article.slug}`;
    // }
  }

  return (
    <div className="post-item-wrapper">
      <div className={postItemClasses}>
        {!hashtaged && (
          <PostHeader
            post={post}
            toggleGuestModal={toggleGuestModal}
            toggleEditArticleModal={toggleEditArticleModal}
            toggleEditPostModal={toggleEditPostModal}
            deletePost={deletePost}
            userData={userData}
          />
        )}
        {width < 992 && (
          <div
            className="post-item__content post-item__content--mobile"
            ref={ref}
          >
            {!post.article && post.text && !hashtaged && (
              <div className={textClasses}>
                {post.text && post.text.length > 0 && (
                  <Linkify options={linkOption}>
                    {renderHTML(
                      post.text && post.text.replace(/(?:\r\n|\r|\n)/g, '<br>'),
                    )}
                  </Linkify>
                )}
              </div>
            )}
            {post.type === 'farm_farmtour' && (
              <div className="post-farmtour-title">
                {translate('farm.farmtour')}
              </div>
            )}
            {post.attachment && post.attachment.type === 'image' && (
              <div
                className="post-item__image-container"
                role="button"
                tabIndex="0"
                onClick={modalToOpen}
                onKeyPress={modalToOpen}
              >
                <ImageWithLoader
                  image={post.attachment}
                  parent="post"
                  postType={post.type}
                  withBackground
                  hashtaged={hashtaged}
                />
              </div>
            )}
            {post.attachment && post.attachment.type === 'video' && (
              <VideoWithLoader
                shouldPlay={shouldPlay}
                video={post.attachment}
                withBackground
              />
            )}
          </div>
        )}

        {post.article && (
          <div
            className="post-item__article"
            role="button"
            tabIndex="0"
            onClick={toggleArticleDetailsModal}
            onKeyPress={toggleArticleDetailsModal}
          >
            {post && post.article && post.article.is_sold && (
              <div className="is-sold">{translate('common.sold')}</div>
            )}
            {post.article.attachments && post.article.attachments[0] && (
              <div className="post-item__article-image">
                {post.article.attachments[0] && (
                  <ImageWithLoader
                    image={post.article.attachments[0]}
                    parent="post"
                    withBackground
                  />
                )}
                <p className="post-item__article-price post-item__article-price--white">
                  {(post.article.price_on_request === '0' &&
                    new Intl.NumberFormat('de-DE', { style: 'decimal' }).format(
                      parseFloat(post.article.price),
                    )) ||
                    translate('page.marketplace.onRequest')}
                  {post.article.price_on_request === '0' && (
                    <span>&nbsp;&euro;</span>
                  )}
                </p>
              </div>
            )}
            <div className="post-item__article-details">
              {!post.article.attachments[0] && (
                <p className="post-item__article-price post-item__article-price--grey">
                  {(post.article.price_on_request === '0' &&
                    new Intl.NumberFormat('de-DE', { style: 'decimal' }).format(
                      parseFloat(post.article.price),
                    )) ||
                    translate('page.marketplace.onRequest')}
                  {post.article.price_on_request === '0' && (
                    <span>&nbsp;&euro;</span>
                  )}
                </p>
              )}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <p className="post-item__article-title">{post.article.title}</p>

                <div
                  className="share-buttons__wrapper"
                  onClick={e => {
                    e.stopPropagation();
                  }}
                >
                  <FacebookShareButton
                    url={`${window.location.origin}/marketplace/${
                      post.article.id
                    }`}
                    quote={articleTitle}
                  >
                    <FacebookIcon size={30} round />
                  </FacebookShareButton>

                  <WhatsappShareButton
                    url={`${window.location.origin}/marketplace/${
                      post.article.id
                    }`}
                    title={articleTitle}
                    separator={'\n'}
                  >
                    <WhatsappIcon size={30} round />
                  </WhatsappShareButton>
                </div>
              </div>

              <div className="post-item__text post-item__text--article">
                {post.text && post.text.length > 0 && (
                  <Linkify options={linkOption}>
                    {renderHTML(
                      post.text && post.text.replace(/(?:\r\n|\r|\n)/g, '<br>'),
                    )}
                  </Linkify>
                )}
              </div>
            </div>

            {urlArray.length === 3 && (
              <Helmet>
                <meta charSet="utf-8" />
                <title>{post.article.title}</title>
                <link rel="canonical" href={`${window.location.href}`} />
                <meta name="description" content={articleDescription} />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="article" />
                <meta property="fb:app_id" content="2313889218719652" />
                <meta property="og:image" content={articleImage} />
                <meta property="og:description" content={articleDescription} />
                <meta property="og:title" content={articleTitle} />
              </Helmet>
            )}
          </div>
        )}

        {width >= 992 && (
          <div
            className={desktopContentClasses}
            role="button"
            tabIndex="0"
            ref={ref}
            onClick={event => {
              if (userData.id) {
                if (post.attachment) {
                  if (event.target.className !== 'inpost-link') {
                    if (post.type !== 'farm_farmtour') {
                      togglePostDetailsModal();
                    } else {
                      toggleFarmTourModal();
                    }
                  }
                }
              } else {
                toggleGuestModal();
              }
            }}
            onKeyPress={post.attachment ? togglePostDetailsModal : () => null}
          >
            {!post.article &&
              post.text &&
              post.text.length > 0 &&
              !hashtaged && (
                <div className={textClasses}>
                  {post.text && post.text.length > 0 && (
                    <Linkify options={linkOption}>
                      {renderHTML(
                        post.text &&
                          post.text.replace(/(?:\r\n|\r|\n)/g, '<br>'),
                      )}
                    </Linkify>
                  )}
                </div>
              )}

            {post.type === 'farm_farmtour' && (
              <div className="post-farmtour-title">
                {translate('farm.farmtour')}
              </div>
            )}

            {post.attachment && post.attachment.type === 'image' && (
              <div className="post-item__image-container">
                <ImageWithLoader
                  image={post.attachment}
                  parent="post"
                  postType={post.type}
                  hashtaged={hashtaged}
                  withBackground
                />
              </div>
            )}
            {post.attachment && post.attachment.type === 'video' && (
              <VideoWithLoader
                video={post.attachment}
                shouldPlay={shouldPlay}
              />
            )}
          </div>
        )}
        {post.shared_content && !hashtaged && (
          <div
            className={sharedContentClasses}
            role="button"
            tabIndex="0"
            onClick={event => {
              if (userData.id) {
                if (post.shared_content.attachment) {
                  if (event.target.className !== 'inpost-link') {
                    if (post.shared_content.type === 'farm_farmtour') {
                      toggleFarmTourModal();
                    } else {
                      toggleDetailsModal();
                    }
                  }
                } else {
                  toggleDetailsModal();
                }
              } else {
                toggleGuestModal();
              }
            }}
            onKeyPress={event => {
              if (userData.id) {
                if (post.shared_content.attachment) {
                  if (event.target.className !== 'inpost-link') {
                    if (post.shared_content.type === 'farm_farmtour') {
                      toggleFarmTourModal();
                    } else {
                      toggleDetailsModal();
                    }
                  }
                } else {
                  toggleDetailsModal();
                }
              } else {
                toggleDetailsModal();
              }
            }}
          >
            <SharedContent
              shouldPlay={shouldPlay}
              sharedContent={post.shared_content}
            />
          </div>
        )}
        {!hashtaged && (
          <PostActivities
            feed={feed}
            path={path}
            post={post}
            userData={userData}
            toggleGuestModal={toggleGuestModal}
            onCommentsClick={toggleCommentsSection}
          />
        )}
        {!hashtaged && (
          <PostActions
            post={post}
            newsfeed={newsfeed}
            toggleGuestModal={toggleGuestModal}
            onCommentsClick={toggleCommentsSection}
            farmId={farmId}
            feed={feed}
            isCommentActionHidden={feed === 'news'}
          />
        )}

        {isCommentSectionVisible && (
          <div className="post-item__comments">
            <PostComments post={post} />
          </div>
        )}
        {post.read_more_url && <PostReadMore url={post.read_more_url} />}
      </div>
      {(index && index > 4 && !userData.id && !post.article && (
        <div onClick={toggleGuestModal} className="guest-overlay">
          <div className="guest-overlay__message">
            {translate('guest.errorOnPost')}
          </div>
          <div className="guest-overlay__message">
            {translate('guest.joinNow')}
          </div>
        </div>
      )) ||
        ''}

      {(index && index === 2 && farms.length > 0 && (
        <div className="active-farms">
          <div className="active-farms__header">
            <span className="active-farms__title">
              {translate('farm.farmProposals')}
            </span>
            <Link to="/farms" className="active-farms__subtitle">
              {translate('farm.allFarms')}
            </Link>
          </div>
          <div className="active-farms__farms">{farms}</div>
        </div>
      )) ||
        ''}
    </div>
  );
};

PostView.defaultProps = defaultProps;

PostView.propTypes = propTypes;

export default PostView;
