import React from 'react';
import { Link } from 'react-router-dom';
import Button from 'common/components/button';
import CreatePost from 'common/containers/createPost';
import NotificationsBell from 'notification/containers/notificationsBell';
import Indicator from 'common/components/indicator';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { farmFlowStart } from 'farm/redux/actions/farmActions';
import './index.scss';

const defaultProps = {
  userData: {},
  unreadArticles: false,
  toggleGuestModal: () => null,
};

const propTypes = {
  farmFlowStartAction: PropTypes.func.isRequired,
  userData: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
  ),
  unreadArticles: PropTypes.bool,
  activeFeed: PropTypes.string.isRequired,
  toggleGuestModal: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
  farmFlowStartAction: () => dispatch(farmFlowStart()),
});

const mapStateToProps = state => ({
  userData: state.userData.data,
  activeFeed: state.newsfeed.activeFeed,
  unreadArticles: state.newsfeed.unreadArticles,
});

const BottomNavigationBarView = ({
  farmFlowStartAction,
  userData,
  activeFeed,
  unreadArticles,
  toggleGuestModal,
}) => (
  <div className="bottom-navigation-bar">
    <Link
      to="/"
      className="bottom-navigation-bar__button bottom-navigation-bar__button--newsfeed"
    />
    <Button
      onClick={() => (userData.id ? farmFlowStartAction() : toggleGuestModal())}
      className="bottom-navigation-bar__button bottom-navigation-bar__button--farm"
    />
    {((activeFeed === 'farm' ||
      (activeFeed === 'news' && userData.can_create_news)) && (
      <CreatePost>
        {({ toggleCreatePostModal }) => (
          <div className="camera-button">
            <Button
              onClick={() => {
                if (userData.id) {
                  if (window.innerWidth < 992) {
                    const elem = document.getElementById('image-input');
                    if (elem && document.createEvent) {
                      const evt = document.createEvent('MouseEvents');
                      evt.initEvent('click', true, false);
                      elem.dispatchEvent(evt);
                    }
                  } else {
                    toggleCreatePostModal();
                  }
                } else {
                  toggleGuestModal();
                }
              }}
              className="bottom-navigation-bar__button bottom-navigation-bar__button--post"
            />
          </div>
        )}
      </CreatePost>
    )) ||
      ''}
    <NotificationsBell
      userData={userData}
      toggleGuestModal={toggleGuestModal}
    />
    <Link
      to="/marketplace"
      className="bottom-navigation-bar__button bottom-navigation-bar__button--marketplace"
    >
      {unreadArticles && (
        <span className="indicator-wrapper">
          <Indicator top={13} />
        </span>
      )}
    </Link>

    {/* <Link
     to={`/profile/${userData.id}`}
     className="bottom-navigation-bar__button bottom-navigation-bar__button--invite"
     /> */}
    {/* <InviteModal>
     {({ handleInviteButtonClick }) => (
     <Button
     onClick={handleInviteButtonClick}
     className="bottom-navigation-bar__button bottom-navigation-bar__button--invite"
     />
     )}
     </InviteModal> */}
  </div>
);

BottomNavigationBarView.defaultProps = defaultProps;
BottomNavigationBarView.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BottomNavigationBarView);
