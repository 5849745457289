import { createReducer } from 'common/utils/reduxUtils';
import {
  FETCH_FARM_MEMBERSHIP_REQUESTS_SUCCESS,
  REMOVE_FARM_MEMBERSHIP_REQUESTS,
  UPDATE_FARM_MEMBERSHIP_REQUESTS,
} from 'farm/redux/types';

const initialState = {
  data: [],
  nextPageIndex: null,
  resultsNumber: 0,
  totalRequests: 0,
};

const farmMembershipRequestsReducer = createReducer(
  { ...initialState },
  {
    [FETCH_FARM_MEMBERSHIP_REQUESTS_SUCCESS]: (state, action) => {
      const { data, meta } = action.payload.data;
      const hasNextPage = meta.current_page < meta.last_page;
      return {
        ...state,
        data: meta.current_page === 1 ? [...data] : [...state.data, ...data],
        nextPageIndex: hasNextPage ? meta.current_page + 1 : null,
        resultsNumber: meta.total,
        totalRequests: meta.total,
      };
    },
    [REMOVE_FARM_MEMBERSHIP_REQUESTS]: state => ({
      ...state,
      data: [],
      nextPageIndex: null,
      resultsNumber: 0,
    }),

    [UPDATE_FARM_MEMBERSHIP_REQUESTS]: (state, action) => ({
      ...state,
      data: [...action.payload.data],
    }),
  },
);

export default farmMembershipRequestsReducer;
