import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ModalContainer from 'common/components/modalContainer';
import { Translate } from 'react-localize-redux';

import { Link } from 'react-router-dom';
import './index.scss';

const propTypes = {
  closeModal: PropTypes.func.isRequired,
};

const GuestModal = ({ closeModal }) => {
  const modalClasses = classNames({
    'guest-modal': true,
  });

  return (
    <ModalContainer handleOutsideClick={closeModal} notFullSize>
      <div className={modalClasses}>
        <div className="guest-modal-container">
          <div className="guest-modal-title">
            <Translate id="guest.notLogged" />
          </div>

          <div className="guest-modal-description">
            <Translate id="guest.wantJoin" />
          </div>

          <Link
            onClick={() => closeModal()}
            to="/auth#new"
            className="guest-modal-confirm"
          >
            <Translate id="guest.yes" />
          </Link>

          <button
            type="button"
            onClick={() => closeModal()}
            className="guest-modal-no-button"
          >
            <Translate id="guest.no" />
          </button>

          <div className="guest-modal-registered">
            <Link
              onClick={() => closeModal()}
              to="/auth"
              className="guest-modal-have-account"
            >
              <Translate id="guest.haveAccount" />
            </Link>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

GuestModal.propTypes = propTypes;

export default GuestModal;
