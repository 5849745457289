import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import MembersListModalView from 'farm/components/membersListModalView';
import SetNewAdminModal from 'common/containers/setNewAdminModal';
import DeleteFarmModal from 'common/containers/deleteFarmModal';
import DeleteFarmMemberModal from 'common/containers/deleteFarmMemberModal';
import disableScroll from 'common/utils/disableScroll';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchFarmMembers } from 'farm/redux/actions/farmMembersActions';
import { getTranslate } from 'react-localize-redux';

const defaultProps = {
  farmMembers: [],
  nextPageIndex: null,
  getFarmMembers: null,
};

const propTypes = {
  closeModal: PropTypes.func.isRequired,
  farm: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.object,
      PropTypes.array,
    ]),
  ).isRequired,
  membersResultsNumber: PropTypes.number.isRequired,
  farmMembers: PropTypes.arrayOf(PropTypes.object),
  isAdmin: PropTypes.bool.isRequired,
  getFarmMembers: PropTypes.func,
  nextPageIndex: PropTypes.number,
};

const mapStateToProps = state => ({
  userData: state.userData.data,
  nextPageIndex: state.farmMembers.nextPageIndex,
  farmMembers: state.farmMembers.data,
  membersResultsNumber: state.farmMembers.resultsNumber,
  translate: getTranslate(state.localize),
});

const mapDispatchToProps = dispatch => ({
  getFarmMembers: bindActionCreators(fetchFarmMembers, dispatch),
});

class MembersListModal extends Component {
  state = {
    isVerifyDeleteMemberModalVisible: false,
    isVerifyDeleteFarmModalVisible: false,
    isNewAdminModalVisible: false,
    memberIdToDelete: null,
  };

  componentDidMount() {
    const { getFarmMembers, match } = this.props;
    const { farmId } = match.params;
    getFarmMembers(farmId);
  }

  componentWillUnmount() {
    disableScroll(false);
  }

  handleDeleteClick = (memberId, memberIsAdmin) => {
    const { membersResultsNumber } = this.props;

    this.setState({ memberIdToDelete: memberId });

    if (membersResultsNumber === 1) {
      this.toggleDeleteFarmModal();
    } else if (memberIsAdmin) {
      this.toggleNewAdminModal();
    } else {
      this.toggleDeleteMemberModal();
    }
  };

  getFarmMembersNextPage = () => {
    const { farm, getFarmMembers, nextPageIndex } = this.props;
    const { id: farmId } = farm;
    getFarmMembers(farmId, nextPageIndex);
  };

  toggleDeleteMemberModal = () => {
    const { isVerifyDeleteMemberModalVisible } = this.state;
    this.setState(
      { isVerifyDeleteMemberModalVisible: !isVerifyDeleteMemberModalVisible },
      disableScroll(!isVerifyDeleteMemberModalVisible),
    );
  };

  toggleDeleteFarmModal = () => {
    const { isVerifyDeleteFarmModalVisible } = this.state;
    this.setState(
      { isVerifyDeleteFarmModalVisible: !isVerifyDeleteFarmModalVisible },
      disableScroll(!isVerifyDeleteFarmModalVisible),
    );
  };

  toggleNewAdminModal = () => {
    const { isNewAdminModalVisible } = this.state;
    this.setState(
      { isNewAdminModalVisible: !isNewAdminModalVisible },
      disableScroll(!isNewAdminModalVisible),
    );
  };

  render() {
    const {
      closeModal,
      isAdmin,
      membersResultsNumber,
      farmMembers,
      nextPageIndex,
      farm,
      translate,
    } = this.props;

    const { id: farmId } = farm;

    const {
      isVerifyDeleteMemberModalVisible,
      isVerifyDeleteFarmModalVisible,
      isNewAdminModalVisible,
      memberIdToDelete,
    } = this.state;

    return (
      <Fragment>
        {isVerifyDeleteMemberModalVisible && (
          <DeleteFarmMemberModal
            closeModal={this.toggleDeleteMemberModal}
            memberId={memberIdToDelete}
            heading={translate('modal.deleteFarmMember.heading')}
            message={translate('modal.deleteFarmMember.message')}
          />
        )}
        {isVerifyDeleteFarmModalVisible && (
          <DeleteFarmModal
            closeModal={this.toggleDeleteFarmModal}
            farmId={farmId}
            redirect
            heading={translate('modal.deleteFarm.heading')}
            message={translate('modal.deleteFarm.message')}
          />
        )}
        {isNewAdminModalVisible && (
          <SetNewAdminModal closeModal={this.toggleNewAdminModal} />
        )}
        <MembersListModalView
          closeModal={closeModal}
          isAdmin={isAdmin}
          membersResultsNumber={membersResultsNumber}
          farmMembers={farmMembers}
          handleMemberDelete={this.handleDeleteClick}
          getFarmMembersNextPage={this.getFarmMembersNextPage}
          nextPageIndex={nextPageIndex}
        />
      </Fragment>
    );
  }
}

MembersListModal.defaultProps = defaultProps;

MembersListModal.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(MembersListModal));
