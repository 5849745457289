import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Linkify from 'linkifyjs/react';
import renderHTML from 'react-render-html';
import PostActivities from 'post/containers/postActivities';
import PostActions from 'post/containers/postActions';
import ImageWithLoader from 'common/components/imageWithLoader';
import VideoWithLoader from 'common/components/videoWithLoader';
import UserAvatar from 'common/components/userAvatar';
import FromNow from 'common/components/fromNow';
import CloseButton from 'common/components/closeButton';
import PostComments from 'post/containers/postComments';
import './index.scss';

const defaultProps = {
  height: null,
  width: null,
  farmId: null,
  feed: '',
};

const propTypes = {
  newsfeed: PropTypes.arrayOf(PropTypes.object).isRequired,
  post: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
      PropTypes.object,
    ]),
  ).isRequired,
  togglePostDetailsModal: PropTypes.func.isRequired,
  toggleImagePreviewModal: PropTypes.func.isRequired,
  toggleActivitiesModal: PropTypes.func.isRequired,
  height: PropTypes.number,
  width: PropTypes.number,
  farmId: PropTypes.number,
  feed: PropTypes.string,
};

const PostDetailsModalView = ({
  newsfeed,
  feed,
  post,
  togglePostDetailsModal,
  toggleImagePreviewModal,
  toggleActivitiesModal,
  height,
  width,
  farmId,
}) => {
  const linkOption = {
    className: 'inpost-link',
    defaultProtocol: 'http',
    format(value) {
      return value.replace(/(?:http:\/\/|https:\/\/)/g, '');
    },
    formatHref(href) {
      return href;
    },
    target: {
      url: '_blank',
    },
    validate: true,
  };
  
  if(post.text && post.text.length > 0) {
    var regexp = /(#\S+)/gi;
    var matches_array = post.text.match(regexp);

    if(matches_array && matches_array.length > 0) {
      matches_array.forEach((item, i) => {
        let newItem = item.replace(/ö/g, 'o').replace(/ä/g, 'a').replace(/ü/g, 'u');
        if(newItem !== item) {
          post.text = post.text.replace(item, newItem);
        }
      })
    }
  }

  return (
    <div className="post-details-modal" id="post-details-modal">
      <div className="post-details-modal__media-container--desktop">
        {post.attachment.type === 'image' && (
          <ImageWithLoader image={post.attachment} parent="modal" isAutoSize />
        )}
        {post.attachment.type === 'video' && (
          <VideoWithLoader video={post.attachment} isAutoSize />
        )}
      </div>
      <div className="post-details-modal__details-container">
        <div className="post-details-modal__header">
          <div className="post-details-modal__header-details">
            <div className="post-details-modal__avatar">
              <UserAvatar
                id={post.author.id}
                image={post.author.avatar}
                type={post.author.type}
              />
            </div>
            <div>
              {post.author && (
                <Link
                  to={
                    post.author.type === 'farm'
                      ? `/farms/${post.author.id}`
                      : `/profile/${post.author.id}`
                  }
                >
                  <span className="post-details-modal__author">
                    {post.author.name}
                  </span>
                </Link>
              )}
              <FromNow time={post.created_at} />
            </div>
          </div>
          <div className="post-details-modal__close">
            <CloseButton onClick={togglePostDetailsModal} />
          </div>
        </div>
        <div className="post-details-modal__details-scroll">
          {post.attachment && post.attachment.type === 'image' && (
            <div
              className="post-details-modal__media-container--mobile"
              role="button"
              tabIndex="0"
              onClick={toggleImagePreviewModal}
              onKeyPress={toggleImagePreviewModal}
            >
              <ImageWithLoader image={post.attachment} parent="modal" />
            </div>
          )}
          {post.attachment && post.attachment.type === 'video' && (
            <div className="post-details-modal__media-container--mobile">
              <VideoWithLoader video={post.attachment} withBackground />
            </div>
          )}
          <div className="post-details-modal__text">
            {post.text && post.text.length > 0 && (
              <Linkify options={linkOption}>
                {renderHTML(
                  post.text && post.text.replace(/(?:\r\n|\r|\n)/g, '<br>'),
                )}
              </Linkify>
            )}
          </div>
        </div>
        <div className="post-details-modal__actions">
          <PostActivities
            feed={feed}
            post={post}
            onCommentsClick={toggleActivitiesModal}
          />
          <PostActions
            post={post}
            newsfeed={newsfeed}
            onCommentsClick={toggleActivitiesModal}
            isCommentActionHidden={width > 992}
            farmId={farmId}
          />
        </div>
        <div className="post-details-modal__comments">
          {/* Post comments section height was calculated based on the design */}
          <PostComments post={post} height={height - 312} isFixedHeight />
        </div>
      </div>
    </div>
  );
};

PostDetailsModalView.defaultProps = defaultProps;

PostDetailsModalView.propTypes = propTypes;

export default PostDetailsModalView;
