import React from 'react';
import PropTypes from 'prop-types';
import MainButton from 'common/components/mainButton';
import ModalContainer from 'common/components/modalContainer';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import './index.scss';

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
});

const defaultProps = {
  primaryOptionButton: 'Ok',
  secondaryOptionButton: 'Ok',
  heading: '',
  handleSecondaryOptionButtonClick: () => null,
  isSecondaryOptionButtonVisible: false,
  isCancelButtonVisible: false,
  primaryOptionButtonDisabled: false,
  secondaryOptionButtonDisabled: false,
};

const propTypes = {
  heading: PropTypes.string,
  message: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  primaryOptionButton: PropTypes.string,
  secondaryOptionButton: PropTypes.string,
  handlePrimaryOptionButtonClick: PropTypes.func.isRequired,
  handleSecondaryOptionButtonClick: PropTypes.func,
  isSecondaryOptionButtonVisible: PropTypes.bool,
  isCancelButtonVisible: PropTypes.bool,
  primaryOptionButtonDisabled: PropTypes.bool,
  secondaryOptionButtonDisabled: PropTypes.bool,
};

const InfoModal = ({
  closeModal,
  message,
  heading,
  handleSecondaryOptionButtonClick,
  handlePrimaryOptionButtonClick,
  secondaryOptionButton,
  primaryOptionButton,
  isSecondaryOptionButtonVisible,
  isCancelButtonVisible,
  primaryOptionButtonDisabled,
  secondaryOptionButtonDisabled,
  translate,
}) => (
  <ModalContainer handleOutsideClick={closeModal} notFullSize>
    <div className="info-modal">
      {heading && <h1 className="info-modal__heading">{heading}</h1>}
      <p className="info-modal__message">{message}</p>
      <div className="info-modal__footer">
        <div>
          {isCancelButtonVisible && (
            <MainButton onClick={closeModal} teritary>
              {translate('common.action.cancel')}
            </MainButton>
          )}
        </div>
        <div className="info-modal__option-buttons">
          {isSecondaryOptionButtonVisible && (
            <div className="info-modal__secondary-button">
              <MainButton
                onClick={handleSecondaryOptionButtonClick}
                secondary
                disabled={secondaryOptionButtonDisabled}
              >
                {secondaryOptionButton}
              </MainButton>
            </div>
          )}
          <MainButton
            onClick={handlePrimaryOptionButtonClick}
            disabled={primaryOptionButtonDisabled}
          >
            {primaryOptionButton}
          </MainButton>
        </div>
      </div>
    </div>
  </ModalContainer>
);

InfoModal.defaultProps = defaultProps;

InfoModal.propTypes = propTypes;

export default connect(mapStateToProps)(InfoModal);
