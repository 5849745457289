import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MarketplaceView from 'marketplace/components/marketplaceView';
import {
  fetchMarketplace,
  removeMarketplace,
  updateMarketplaceCategory,
} from 'marketplace/redux/actions/marketplaceActions';
import { removeComments } from 'post/redux/actions/commentsActions';
import disableScroll from 'common/utils/disableScroll';
import debounce from 'lodash/debounce';

import GuestModal from 'common/components/guestModal';
import SellArticleModal from 'post/containers/sellArticleModal';
import fetchCategories from 'common/redux/actions/categoriesActions';
import { checkForUnreadPosts } from 'newsfeed/redux/actions/newsfeedActions';

const defaultProps = {
  marketplace: [],
  token: '',
  userData: null,
  nextPageIndex: null,
  history: {},
  categories: [],
  match: {},
};

const propTypes = {
  marketplace: PropTypes.arrayOf(PropTypes.object),
  getMarketplace: PropTypes.func.isRequired,
  token: PropTypes.string,
  userData: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
  ),
  removeMarketplace: PropTypes.func.isRequired,
  nextPageIndex: PropTypes.number,
  removeComments: PropTypes.func.isRequired,
  updateMarketplaceCategory: PropTypes.func.isRequired,
  getCategories: PropTypes.func.isRequired,
  history: PropTypes.object,
  categories: PropTypes.arrayOf(PropTypes.object),
  checkForUnreadPosts: PropTypes.func.isRequired,
  match: PropTypes.object,
};

const mapStateToProps = state => ({
  marketplace: state.marketplace.data,
  nextPageIndex: state.marketplace.nextPageIndex,
  token: state.auth.token,
  userData: state.userData.data,
  categories: state.categories.list,
});

const mapDispatchToProps = dispatch => ({
  getMarketplace: bindActionCreators(fetchMarketplace, dispatch),
  removeMarketplace: bindActionCreators(removeMarketplace, dispatch),
  removeComments: bindActionCreators(removeComments, dispatch),
  getCategories: bindActionCreators(fetchCategories, dispatch),
  updateMarketplaceCategory: bindActionCreators(
    updateMarketplaceCategory,
    dispatch,
  ),
  checkForUnreadPosts: bindActionCreators(checkForUnreadPosts, dispatch),
});

class Marketplace extends Component {
  state = {
    isInitialPageLoading: false,
    isSellArticleModalVisible: false,
    isGuestModalVisible: false,
    fixedSearch: false,
  };

  debouncedGetMarketplace = debounce(() => {
    const { getMarketplace } = this.props;
    getMarketplace(1);
  }, 250);

  componentDidMount() {
    const {
      token,
      userData,
      history,
      getMarketplace,
      getCategories,
      checkForUnreadPosts,
      categories,
    } = this.props;
    if (!token && !userData) {
      // history.replace('/auth');
    }

    if (!categories.length) {
      getCategories();
    }

    const path = history.location.pathname;

    const parts = path.split('/');

    let articleId = null;

    if (parts.length === 3 && !isNaN(parts[2])) {
      articleId = parts[2];
    }

    this.setState({ isInitialPageLoading: true });

    getMarketplace(1, articleId).then(() => {
      this.setState({ isInitialPageLoading: false });
      setTimeout(() => {
        checkForUnreadPosts();
      }, 500);
    });

    window.addEventListener('scroll', this.handlePageScroll);

    disableScroll(false);
  }

  componentDidUpdate(prevProps) {
    const { match: prevMatch, getMarketplace } = prevProps;
    const { postId: prevPostId } = prevMatch.params;
    const { match } = this.props;
    const { postId } = match.params;

    if (postId && prevPostId !== postId) {
      getMarketplace(1, postId);
    }
  }

  componentWillUnmount() {
    const { removeMarketplace, removeComments } = this.props;
    removeMarketplace();
    removeComments();
    window.removeEventListener('scroll', this.handlePageScroll);
  }

  handlePageScroll = () => {
    this.setState({
      fixedSearch: !!(
        window.innerWidth < 992 ||
        (window.innerWidth > 991 && window.scrollY >= 30)
      ),
    });
  };

  getMarketplaceNextPage = () => {
    const { getMarketplace, nextPageIndex } = this.props;
    getMarketplace(nextPageIndex);
  };

  handleCategoryChange = categoryId => {
    const { updateMarketplaceCategory } = this.props;
    updateMarketplaceCategory(categoryId);
    this.debouncedGetMarketplace();
  };

  toggleSellArticleModal = () => {
    const { isSellArticleModalVisible } = this.state;
    const { token, userData } = this.props;

    if (userData && token) {
      this.setState(
        { isSellArticleModalVisible: !isSellArticleModalVisible },
        disableScroll(!isSellArticleModalVisible),
      );
    } else {
      this.toggleGuestModal();
    }
  };

  toggleGuestModal = () => {
    const { isGuestModalVisible } = this.state;
    this.setState(
      { isGuestModalVisible: !isGuestModalVisible },
      disableScroll(!isGuestModalVisible),
    );
  };

  render() {
    const { marketplace, nextPageIndex, categories } = this.props;
    const {
      isInitialPageLoading,
      isSellArticleModalVisible,
      isGuestModalVisible,
      fixedSearch,
    } = this.state;

    return (
      <Fragment>
        {isSellArticleModalVisible && (
          <SellArticleModal closeModal={this.toggleSellArticleModal} />
        )}
        {isGuestModalVisible && (
          <GuestModal closeModal={this.toggleGuestModal} />
        )}
        <MarketplaceView
          marketplace={marketplace}
          getMarketplaceNextPage={this.getMarketplaceNextPage}
          nextPageIndex={nextPageIndex}
          isInitialPageLoading={isInitialPageLoading}
          toggleSellArticleModal={this.toggleSellArticleModal}
          toggleGuestModal={this.toggleGuestModal}
          handleCategoryChange={this.handleCategoryChange}
          handleMarketplaceClick={this.handleMarketplaceClick}
          categories={categories}
          fixedSearch={fixedSearch}
        />
      </Fragment>
    );
  }
}

Marketplace.defaultProps = defaultProps;

Marketplace.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Marketplace);
