import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import loadImage from 'blueimp-load-image';
import disableScroll from 'common/utils/disableScroll';
import CreateFarmModalView from 'farm/components/createFarmModalView';
import InfoModal from 'common/components/infoModal';
import { createFarm, editFarm, deleteFarm } from 'farm/api/farmsApi';
import requestAttachmentToken from 'common/api/attachmentsApi';
import setFarmErrors from 'farm/utils/farmErrors';
import { fetchFarmDetails } from 'farm/redux/actions/farmActions';
import { fetchUserData } from 'common/redux/actions/userDataActions';
import { getTranslate } from 'react-localize-redux';
import {
  fetchFarmNewsfeed,
  removeFarmNewsfeed,
} from 'farm/redux/actions/farmNewsfeedActions';
import fetchFarmCategories from 'common/redux/actions/farmCategoriesActions';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

const defaultProps = {
  farmMembers: [],
  membersResultsNumber: 0,
  toggleMembersListModal: () => null,
  toggleMembershipRequestsModal: () => null,
  farm: null,
  farmCategories: [],
  uploadProgress: 0,
  translate: () => null,
  history: {},
};

const propTypes = {
  closeModal: PropTypes.func.isRequired,
  farm: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.object,
      PropTypes.array,
    ]),
  ),
  getFarmDetails: PropTypes.func.isRequired,
  membersResultsNumber: PropTypes.number,
  farmMembers: PropTypes.arrayOf(PropTypes.object),
  fetchUserData: PropTypes.func.isRequired,
  toggleMembersListModal: PropTypes.func,
  toggleMembershipRequestsModal: PropTypes.func,
  getFarmCategories: PropTypes.func.isRequired,
  farmCategories: PropTypes.arrayOf(PropTypes.object),
  removeFarmNewsfeed: PropTypes.func.isRequired,
  getFarmNewsfeed: PropTypes.func.isRequired,
  uploadProgress: PropTypes.number,
  translate: PropTypes.func,
  history: PropTypes.object,
};

const mapStateToProps = state => ({
  farmCategories: state.farmCategories.list,
  translate: getTranslate(state.localize),
});

const mapDispatchToProps = dispatch => ({
  getFarmDetails: bindActionCreators(fetchFarmDetails, dispatch),
  fetchUserData: bindActionCreators(fetchUserData, dispatch),
  getFarmCategories: bindActionCreators(fetchFarmCategories, dispatch),
  removeFarmNewsfeed: bindActionCreators(removeFarmNewsfeed, dispatch),
  getFarmNewsfeed: bindActionCreators(fetchFarmNewsfeed, dispatch),
});

class CreateFarmModal extends Component {
  state = {
    profileImageUrl: '',
    profileImageToken: '',
    profileImageError: '',
    profileImageDelete: false,
    profileImageLoading: false,
    titleImageUrl: '',
    titleImageToken: '',
    titleImageError: '',
    titleImageDelete: false,
    titleImageLoading: false,
    initialValues: {
      name: '',
      address: '',
      city: '',
      postCode: '',
      farmArea: '',
      farmCategoryId: '',
      description: '',
      fullAddress: '',
      location: '',
    },
    places: false,
    blob: false,
    isDeleteFarmModalVisible: false,
    isDeleteInProgress: false,
  };

  componentDidMount() {
    const { farm, farmCategories, getFarmCategories } = this.props;
    if (farmCategories.length === 0) {
      getFarmCategories();
    }
    if (farm) {
      this.setState({
        initialValues: {
          name: farm.name,
          address: farm.adress,
          city: farm.city,
          postCode: farm.post_code,
          farmArea: farm.farm_area,
          farmCategoryId: farm.farm_category_id,
          description: farm.description,
          fullAddress: '',
          location: '',
        },
        profileImageUrl: farm.profile_image && farm.profile_image.url,
        titleImageUrl: farm.title_image && farm.title_image.url,
      });
    }
  }

  componentWillUnmount() {
    disableScroll(false);
  }

  handlePlacesChange = address => {
    this.setState({
      initialValues: {
        ...this.state.initialValues,
        address,
      },
    });
  };

  handlePlacesSelect = address => {
    geocodeByAddress(address).then(results => {
      this.parsePlacesResults(results[0]);

      getLatLng(results[0]).then(() => {
        // console.log(lat)
      });
    });
    // .catch(error => {console.error('Error', error)});
  };

  parsePlacesResults(place) {
    const location = `${place.geometry.location.lat()} ${place.geometry.location.lng()}`;
    const address = place.formatted_address;
    let city = '';
    let postCode = '';
    let country = '';
    // let street = '';
    // let streetNumber = '';

    for (let i = 0; i < place.address_components.length; i++) {
      if (place.address_components[i].types[0] === 'locality') {
        city = place.address_components[i].long_name;
      }

      if (place.address_components[i].types[0] === 'postal_code') {
        postCode = place.address_components[i].long_name;
      }

      /* if(place.address_components[i].types[0] === 'route') {
        street = place.address_components[i].long_name;
      }

      if(place.address_components[i].types[0] === 'street_number') {
        streetNumber = place.address_components[i].long_name
      } */

      if (place.address_components[i].types[0] === 'country') {
        country = place.address_components[i].long_name;
      }
    }

    this.setState({
      initialValues: {
        ...this.state.initialValues,
        fullAddress: address,
        address,
        city,
        postCode,
        location,
        country,
      },
      places: true,
    });
  }

  addFarmImage = (e, type) => {
    const { resetProgressInterval, updateProgress } = this.props;
    const imageFile = e.target.files[0];
    const reader = new FileReader();
    const image = new Image();
    if (imageFile) {
      reader.onloadstart = updateProgress;

      this.setState({
        blob: true,
        [`${type}ImageLoading`]: true,
        [`${type}ImageError`]: '',
      });
      reader.readAsDataURL(imageFile);
      reader.addEventListener('load', () => {
        image.src = reader.result;
        image.addEventListener('load', () => {
          loadImage.parseMetaData(imageFile, data => {
            let orientation = 0;
            if (data.exif) {
              orientation = data.exif.get('Orientation');
            }
            loadImage(
              imageFile,
              canvas => {
                const base64data = canvas.toDataURL(imageFile.type);
                canvas.toBlob(blob => {
                  const imgAsForm = new FormData();
                  imgAsForm.append(
                    'file',
                    imageFile.type === 'image/gif' ? imageFile : blob,
                  );
                  requestAttachmentToken(imgAsForm, 'image')
                    .then(res => {
                      if (resetProgressInterval) {
                        resetProgressInterval();
                      }

                      this.setState({
                        [`${type}ImageUrl`]:
                          imageFile.type === 'image/gif'
                            ? image.src
                            : base64data,
                        [`${type}ImageToken`]: res.data.token,
                        [`${type}ImageLoading`]: false,
                      });
                    })
                    .catch(error => {
                      if (resetProgressInterval) {
                        resetProgressInterval();
                      }

                      this.setState({
                        [`${type}ImageError`]:
                          error.response.data &&
                          error.response.data.errors &&
                          error.response.data.errors.file.join(' '),
                        [`${type}ImageLoading`]: false,
                      });
                    });
                }, imageFile.type);
              },
              {
                canvas: true,
                orientation,
                maxWidth: 1300,
              },
            );
          });
        });
      });
    }
  };

  submitFarmForm = (values, actions) => {
    const {
      name,
      address,
      city,
      postCode,
      farmArea,
      farmCategoryId,
      description,
    } = values;
    const {
      farm,
      history,
      closeModal,
      getFarmDetails,
      fetchUserData,
      getFarmNewsfeed,
      removeFarmNewsfeed,
    } = this.props;
    const {
      initialValues,
      profileImageDelete,
      profileImageToken,
      titleImageDelete,
      titleImageToken,
    } = this.state;

    if (initialValues.name) {
      editFarm(farm.id, {
        profile_image: profileImageToken,
        title_image: titleImageToken,
        remove_profile_image: profileImageDelete,
        remove_title_image: titleImageDelete,
        name,
        adress: address,
        city,
        post_code: postCode,
        farm_area: farmArea || null,
        farm_category_id: farmCategoryId || null,
        description,
        full_address: initialValues.fullAddress,
        location: initialValues.location,
      })
        .then(response => {
          closeModal();
          getFarmDetails(farm.id);
          removeFarmNewsfeed();
          getFarmNewsfeed(farm.id);
        })
        .catch(error => {
          actions.setErrors(setFarmErrors(error));
          actions.setSubmitting(false);
        });
    } else {
      createFarm({
        profile_image: profileImageToken,
        title_image: titleImageToken,
        name,
        adress: address,
        city,
        post_code: postCode,
        farm_area: farmArea || null,
        farm_category_id: farmCategoryId || null,
        description,
        full_address: initialValues.fullAddress,
        location: initialValues.location,
      })
        .then(response => {
          closeModal();
          fetchUserData();
          history.push(`/farms/${response.data.id}`);
        })
        .catch(error => {
          actions.setErrors(setFarmErrors(error));
          actions.setSubmitting(false);
        });
    }
  };

  toggleDeleteFarmModal = () => {
    const { isDeleteFarmModalVisible } = this.state;
    this.setState({ isDeleteFarmModalVisible: !isDeleteFarmModalVisible });
  };

  deleteFarmProfile = () => {
    const { farm, history, fetchUserData } = this.props;
    this.setState({ isDeleteInProgress: true });
    deleteFarm(farm.id)
      .then(() => {
        this.setState({ isDeleteInProgress: false });
        fetchUserData();
        history.push('/');
      })
      .catch(() => {
        this.setState({ isDeleteInProgress: false });
      });
  };

  deleteFarmPicture = type => {
    this.setState({
      [`${type}ImageUrl`]: '',
      [`${type}ImageToken`]: '',
      [`${type}ImageError`]: '',
      [`${type}ImageDelete`]: true,
    });
  };

  render() {
    const {
      closeModal,
      membersResultsNumber,
      farmMembers,
      toggleMembersListModal,
      toggleMembershipRequestsModal,
      farm,
      translate,
      farmCategories,
      uploadProgress,
    } = this.props;
    const {
      profileImageUrl,
      titleImageUrl,
      profileImageError,
      titleImageError,
      profileImageToken,
      titleImageToken,
      initialValues,
      isDeleteFarmModalVisible,
      isDeleteInProgress,
      profileImageDelete,
      titleImageDelete,
      profileImageLoading,
      titleImageLoading,
      blob,
      places,
    } = this.state;

    return (
      <Fragment>
        <CreateFarmModalView
          closeModal={closeModal}
          submitFarmForm={this.submitFarmForm}
          addFarmImage={this.addFarmImage}
          profileImageUrl={profileImageUrl}
          titleImageUrl={titleImageUrl}
          profileImageError={profileImageError}
          titleImageError={titleImageError}
          profileImageToken={profileImageToken}
          titleImageToken={titleImageToken}
          initialValues={initialValues}
          submitButtonText={translate('common.action.create')}
          membersResultsNumber={membersResultsNumber}
          farmMembers={farmMembers}
          toggleDeleteFarmModal={this.toggleDeleteFarmModal}
          toggleMembersListModal={toggleMembersListModal}
          toggleMembershipRequestsModal={toggleMembershipRequestsModal}
          deleteFarmPicture={this.deleteFarmPicture}
          profileImageDelete={profileImageDelete}
          titleImageDelete={titleImageDelete}
          farmId={farm && farm.id}
          farmCategories={farmCategories}
          profileImageLoading={profileImageLoading}
          titleImageLoading={titleImageLoading}
          uploadProgress={uploadProgress}
          blob={blob}
          handlePlacesChange={this.handlePlacesChange}
          handlePlacesSelect={this.handlePlacesSelect}
          places={places}
        />
        {isDeleteFarmModalVisible && (
          <InfoModal
            closeModal={this.toggleDeleteFarmModal}
            heading={translate('modal.deleteFarm.heading')}
            message={translate('modal.deleteFarm.message')}
            handlePrimaryOptionButtonClick={this.deleteFarmProfile}
            primaryOptionButton={translate('common.yes')}
            isCancelButtonVisible
            primaryOptionButtonDisabled={isDeleteInProgress}
          />
        )}
      </Fragment>
    );
  }
}

CreateFarmModal.defaultProps = defaultProps;

CreateFarmModal.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(CreateFarmModal));
