import * as Yup from 'yup';
import translateMessage from 'common/utils/translateMessage.js';
import localizeYup from 'common/utils/localizeYupDefaults.js';
import { eMailRegex } from 'common/utils/validationUtils';

localizeYup();

const invitationsValidationSchema = Yup.object().shape({
  email: Yup.string().matches(eMailRegex, {
    message: translateMessage('form.error.invalidFormat'),
    excludeEmptyString: true,
  }),
});

export default invitationsValidationSchema;
