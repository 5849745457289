import axios from 'axios';
import * as types from 'marketplace/redux/types';
import { createActionCreator } from 'common/utils/reduxUtils';

const {
  ADD_CREATED_ARTICLE,
  FETCH_MARKETPLACE_REQUEST,
  FETCH_MARKETPLACE_SUCCESS,
  FETCH_MARKETPLACE_FAILURE,
  UPDATE_MARKETPLACE_QUERY,
  UPDATE_MARKETPLACE_CATEGORY,
  UPDATE_MARKETPLACE_VICINITY,
  UPDATE_MARKETPLACE,
  REMOVE_MARKETPLACE,
  FETCH_ARTICLES_NUMBER_SUCCESS,
} = types;

const fetchMarketplaceRequest = createActionCreator(FETCH_MARKETPLACE_REQUEST);
const fetchMarketplaceSuccess = createActionCreator(
  FETCH_MARKETPLACE_SUCCESS,
  'data',
  'id',
);
const fetchArticlesNumberSuccess = createActionCreator(
  FETCH_ARTICLES_NUMBER_SUCCESS,
  'data',
);
const fetchMarketplaceFailure = createActionCreator(FETCH_MARKETPLACE_FAILURE);

export const fetchMarketplace = (page = 1, articleId) => (
  dispatch,
  getState,
) => {
  const {
    categoryId,
    currentTimestamp,
    search,
    vicinity,
  } = getState().marketplace;
  dispatch(fetchMarketplaceRequest());
  return axios
    .get(`${process.env.REACT_APP_API_URL}/articles`, {
      params: {
        page,
        user_id: getState().userData.data ? getState().userData.data.id : false,
        post_id: articleId,
        to: currentTimestamp,
        search: search.length > 1 ? search : '',
        category_id: categoryId,
        vicinity,
      },
    })
    .then(response => {
      dispatch(fetchMarketplaceSuccess(response.data, articleId));
    })
    .catch(() => {
      dispatch(fetchMarketplaceFailure());
    });
};

export const countArticles = () => dispatch =>
  axios
    .get(`${process.env.REACT_APP_API_URL}/articles/count`)
    .then(response => {
      dispatch(fetchArticlesNumberSuccess(response.data.data));
    })
    .catch(() => {});

export const updateMarketplaceQuery = createActionCreator(
  UPDATE_MARKETPLACE_QUERY,
  'search',
);

export const updateMarketplaceCategory = createActionCreator(
  UPDATE_MARKETPLACE_CATEGORY,
  'categoryId',
);

export const updateMarketplaceVicinity = createActionCreator(
  UPDATE_MARKETPLACE_VICINITY,
  'vicinity',
);

export const addCreatedArticle = createActionCreator(
  ADD_CREATED_ARTICLE,
  'data',
);

export const updateMarketplace = createActionCreator(
  UPDATE_MARKETPLACE,
  'data',
);

export const removeMarketplace = createActionCreator(REMOVE_MARKETPLACE);
