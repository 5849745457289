import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Linkify from 'linkifyjs/react';
import renderHTML from 'react-render-html';
import classNames from 'classnames';
import UserAvatar from 'common/components/userAvatar';
import FromNow from 'common/components/fromNow';
import CloseButton from 'common/components/closeButton';
import ImageWithLoader from 'common/components/imageWithLoader';
import MainButton from 'common/components/mainButton';
import Carousel from 'common/components/carousel';
import ContactSellerForm from 'post/components/contactSellerForm';
import Button from 'common/components/button';
import PostActivities from 'post/containers/postActivities';
import PostActions from 'post/containers/postActions';
import PostComments from 'post/containers/postComments';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import './index.scss';

const defaultProps = {
  width: null,
  userData: {},
  toggleGuestModal: () => null,
  isArticleOwner: false,
};

const propTypes = {
  closeModal: PropTypes.func.isRequired,
  isArticleOwner: PropTypes.bool,
  isContactSellerFormVisible: PropTypes.bool.isRequired,
  isPhoneNumberVisible: PropTypes.bool.isRequired,
  isUpdateInProgress: PropTypes.bool.isRequired,
  markArticleAsSold: PropTypes.func.isRequired,
  newsfeed: PropTypes.arrayOf(PropTypes.object).isRequired,
  post: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
      PropTypes.object,
    ]),
  ).isRequired,
  showPhoneNumber: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  toggleContactSellerForm: PropTypes.func.isRequired,
  toggleContactSellerModal: PropTypes.func.isRequired,
  toggleEditArticleModal: PropTypes.func.isRequired,
  width: PropTypes.number,
  isCommentSectionVisible: PropTypes.bool.isRequired,
  toggleCommentsSection: PropTypes.func.isRequired,
  toggleGuestModal: PropTypes.func,
  translate: PropTypes.func.isRequired,
  userData: PropTypes.object,
};

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
  userData: state.userData.data,
});

const ArticleDetailsModalView = ({
  closeModal,
  isArticleOwner,
  isContactSellerFormVisible,
  isPhoneNumberVisible,
  isUpdateInProgress,
  markArticleAsSold,
  post,
  showPhoneNumber,
  submitForm,
  toggleContactSellerForm,
  toggleContactSellerModal,
  toggleEditArticleModal,
  newsfeed,
  width,
  isCommentSectionVisible,
  toggleCommentsSection,
  translate,
  userData,
  toggleGuestModal,
}) => {
  const modalClasses = classNames({
    'article-details-modal': true,
    'article-details-modal--images':
      post.article.attachments && post.article.attachments.length > 0,
  });
  const detailsContainerClasses = classNames({
    'article-details-modal__details-container': true,
    'article-details-modal__details-container--images':
      post.article.attachments && post.article.attachments.length > 0,
  });
  const detailsScrollClasses = classNames({
    'article-details-modal__details-scroll': true,
    'article-details-modal__details-scroll--small':
      width >= 992 &&
      (!post.article.attachments || !post.article.attachments[0]),
  });

  const linkOption = {
    className: 'inpost-link',
    defaultProtocol: 'http',
    format(value) {
      return value.replace(/(?:http:\/\/|https:\/\/)/g, '');
    },
    formatHref(href) {
      return href;
    },
    target: {
      url: '_blank',
    },
  };

  if (post.text && post.text.length > 0) {
    const regexp = /(#\S+)/gi;
    const matchesArray = post.text.match(regexp);

    if (matchesArray && matchesArray.length > 0) {
      matchesArray.forEach(item => {
        const newItem = item
          .replace(/ö/g, 'o')
          .replace(/ä/g, 'a')
          .replace(/ü/g, 'u');
        if (newItem !== item) {
          post.text = post.text.replace(item, newItem);
        }
      });
    }
  }

  return (
    <div className={modalClasses}>
      {post.article.attachments && post.article.attachments.length > 0 && (
        <div className="article-details-modal__media-container--desktop">
          {post.article.attachments.length === 1 && (
            <ImageWithLoader
              image={post.article.attachments[0]}
              isAutoSize
              parent="modal"
            />
          )}
          {post.article.attachments.length > 1 && (
            <Carousel>
              {post.article.attachments.map(image => (
                <div
                  key={image.url}
                  className="article-details-modal__slider-image"
                >
                  <ImageWithLoader image={image} isAutoSize parent="modal" />
                </div>
              ))}
            </Carousel>
          )}
        </div>
      )}
      <div className={detailsContainerClasses}>
        <div className="article-details-modal__header">
          <div className="article-details-modal__header-details">
            <div className="article-details-modal__avatar">
              {post.author && (
                <UserAvatar
                  id={userData.id && post.author.id}
                  image={post.author.avatar}
                />
              )}
            </div>
            <div>
              {post.author && (
                <div>
                  <span className="article-details-modal__sold">
                    {translate('common.soldBy')}
                    {' '}
                  </span>
                  {userData.id && (
                    <Link to={`/profile/${post.author.id}`}>
                      <span className="post-details-modal__author">
                        {post.author.name}
                      </span>
                    </Link>
                  )}

                  {!userData.id && (
                    <button type="button" onClick={() => toggleGuestModal()}>
                      <span className="post-details-modal__author">
                        {post.author.name}
                      </span>
                    </button>
                  )}
                </div>
              )}
              <div className="article-details-modal__author-details">
                <span className="article-details-modal__address">
                  {post.article.post_code} 
                  {' '}
                  {post.article.city}
                </span>
                <FromNow time={post.created_at} />
              </div>
            </div>
          </div>
          <div className="article-details-modal__close">
            <CloseButton onClick={closeModal} />
          </div>
        </div>
        <div className={detailsScrollClasses}>
          {post.article.attachments && post.article.attachments.length > 0 && (
            <div className="article-details-modal__mobile-gallery">
              {post.article.attachments.length === 1 && (
                <ImageWithLoader
                  parent="modal"
                  image={post.article.attachments[0]}
                  isAutoSize
                />
              )}
              {post.article.attachments.length > 1 && (
                <Carousel>
                  {post.article.attachments.map(image => (
                    <div
                      key={image.url}
                      className="article-details-modal__slider-image"
                    >
                      <ImageWithLoader
                        image={image}
                        isAutoSize
                        parent="modal"
                      />
                    </div>
                  ))}
                </Carousel>
              )}
            </div>
          )}
          <div className="article-details-modal__data">
            <div className="article-details-modal__price-container">
              <p className="article-details-modal__price">
                {(post.article.price_on_request === '0' &&
                  new Intl.NumberFormat('de-DE', { style: 'decimal' }).format(
                    parseFloat(post.article.price),
                  )) ||
                  translate('page.marketplace.onRequest')}
                {post.article.price_on_request === '0' && (
                  <span>&nbsp;&euro;</span>
                )}
              </p>
              {post.article.is_sold && (
                <span className="article-details-modal__sold-mark">
                  {translate('marketplace.articleSold')}
                </span>
              )}
              {isArticleOwner && (
                <Button
                  className="article-details-modal__edit-button"
                  onClick={toggleEditArticleModal}
                />
              )}
            </div>
            <p className="article-details-modal__title">{post.article.title}</p>
            <div className="article-details-modal__phone-container">
              <div className="article-details-modal__phone-inner-container--mobile">
                <a
                  className="article-details-modal__phone-link main-button"
                  href={`tel:${post.article.phone}`}
                >
                  {width > 420
                    ? translate('marketplace.callSeller')
                    : translate('marketplace.callSellerShort')}
                </a>
                {!isArticleOwner && !post.article.is_sold && (
                  <MainButton onClick={toggleContactSellerModal}>
                    {width > 420
                      ? translate('marketplace.contactSeller')
                      : translate('marketplace.contactSellerShort')}
                  </MainButton>
                )}
              </div>
              <div className="article-details-modal__phone-inner-container--desktop">
                {!isPhoneNumberVisible && (
                  <MainButton onClick={showPhoneNumber}>
                    {translate('common.action.showPhoneNumber')}
                  </MainButton>
                )}
                {isPhoneNumberVisible && (
                  <p className="article-details-modal__phone">
                    {post.article.phone}
                  </p>
                )}
              </div>
            </div>
            <div className="article-details-modal__text">
              {post.text && post.text.length > 0 && (
                <Linkify options={linkOption}>
                  {renderHTML(
                    post.text && post.text.replace(/(?:\r\n|\r|\n)/g, '<br>'),
                  )}
                </Linkify>
              )}
            </div>
            {!isArticleOwner && !post.article.is_sold && (
              <div className="article-details-modal__contact-form">
                {isContactSellerFormVisible ? (
                  <ContactSellerForm submitForm={submitForm} />
                ) : (
                  <div className="article-details-modal__success-container">
                    <p className="article-details-modal__success-message">
                      {translate(
                        'marketplace.articlePost.contactSellerConfirmation.heading',
                      )}
                    </p>
                    <MainButton onClick={toggleContactSellerForm}>
                      {translate(
                        'marketplace.articlePost.contactSellerConfirmation.sendAnother',
                      )}
                    </MainButton>
                  </div>
                )}
              </div>
            )}
            {isArticleOwner && !post.article.is_sold && (
              <MainButton
                onClick={markArticleAsSold}
                disabled={isUpdateInProgress}
                fullWidth
              >
                {translate('marketplace.markArticleAsSold')}
              </MainButton>
            )}
          </div>
        </div>
        <div className="post-details-modal__actions">
          {userData && (
            <PostActivities
              post={post}
              userData={userData}
              toggleGuestModal={toggleGuestModal}
              onCommentsClick={toggleCommentsSection}
            />
          )}
          <PostActions
            post={post}
            newsfeed={newsfeed}
            toggleGuestModal={toggleGuestModal}
            onCommentsClick={toggleCommentsSection}
            isCommentActionHidden={
              !!(
                width >= 992 &&
                (post.article.attachments && post.article.attachments[0])
              )
            }
          />
        </div>
        {isCommentSectionVisible &&
          (!post.article.attachments || !post.article.attachments[0]) && (
            <PostComments post={post} />
          )}
        {post.article.attachments &&
          post.article.attachments[0] &&
          width >= 992 && (
            <PostComments
              toggleGuestModal={toggleGuestModal}
              post={post}
              height={200}
              isFixedHeight
            />
          )}
      </div>
    </div>
  );
};

ArticleDetailsModalView.defaultProps = defaultProps;

ArticleDetailsModalView.propTypes = propTypes;

export default connect(mapStateToProps)(ArticleDetailsModalView);
