import axios from 'axios';
import * as types from 'farm/redux/types';
import { createActionCreator } from 'common/utils/reduxUtils';
import { fetchFarmDetails } from './farmActions';

const {
  FETCH_FARMTOUR_DATA_REQUEST,
  FETCH_FARMTOUR_DATA_SUCCESS,
  FETCH_FARMTOUR_DATA_FAILURE,
  REMOVE_FARMTOUR_DATA,

  FETCH_FARMTOUR_POST_REQUEST,
  FETCH_FARMTOUR_POST_SUCCESS,
  FETCH_FARMTOUR_POST_FAILURE,
  REMOVE_FARMTOUR_POST,
} = types;

const fetchFarmTourDataRequest = createActionCreator(
  FETCH_FARMTOUR_DATA_REQUEST,
);

const fetchFarmTourPostRequest = createActionCreator(
  FETCH_FARMTOUR_POST_REQUEST,
);

const fetchFarmTourDataSuccess = createActionCreator(
  FETCH_FARMTOUR_DATA_SUCCESS,
  'data',
);

const fetchFarmTourPostSuccess = createActionCreator(
  FETCH_FARMTOUR_POST_SUCCESS,
  'data',
);

const fetchFarmTourDataFailure = createActionCreator(
  FETCH_FARMTOUR_DATA_FAILURE,
);

const fetchFarmTourPostFailure = createActionCreator(
  FETCH_FARMTOUR_POST_FAILURE,
);

export const getFarmTourImages = id => dispatch => {
  dispatch(fetchFarmTourDataRequest());

  return axios
    .get(`${process.env.REACT_APP_API_URL}/farmtour/${id}`)
    .then(response => {
      dispatch(fetchFarmTourDataSuccess(response.data));
    })
    .catch(() => {
      dispatch(fetchFarmTourDataFailure());
    });
};

export const getFarmTourPost = id => dispatch => {
  dispatch(fetchFarmTourPostRequest());

  return axios
    .get(`${process.env.REACT_APP_API_URL}/farmtour/${id}/post`)
    .then(response => {
      dispatch(fetchFarmTourPostSuccess(response.data));
    })
    .catch(() => {
      dispatch(fetchFarmTourPostFailure());
    });
};

export const swapImages = (fromId, toId, id) => dispatch =>
  axios
    .post(`${process.env.REACT_APP_API_URL}/farmtour/swap`, {
      from_id: fromId,
      to_id: toId,
      farm_id: id,
    })
    .then(() => {
      dispatch(fetchFarmDetails(id));
      dispatch(getFarmTourImages(id));
    })
    .catch(() => {
      dispatch(fetchFarmTourPostFailure());
    });

export const removeFarmTourData = createActionCreator(REMOVE_FARMTOUR_DATA);
export const removeFarmPostData = createActionCreator(REMOVE_FARMTOUR_POST);
