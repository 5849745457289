import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import loadImage from 'blueimp-load-image';
import SellArticleModalView from 'post/components/sellArticleModalView';
import {
  updateMarketplace,
  addCreatedArticle,
} from 'marketplace/redux/actions/marketplaceActions';
import {
  addCreatedPost,
  updateNewsfeed,
} from 'newsfeed/redux/actions/newsfeedActions';
import {
  updateUserNewsfeed,
  addCreatedUserPost,
} from 'profile/redux/actions/userNewsfeedActions';
import fetchCategories from 'common/redux/actions/categoriesActions';
import {
  fetchArticleToEdit,
  removeArticleToEdit,
} from 'post/redux/actions/articleToEditActions';
import { createArticlePost, updateArticlePost } from 'common/api/postsApi';
import requestAttachmentToken from 'common/api/attachmentsApi';
import setArticleErrors from 'post/utils/articleErrors';

const defaultProps = {
  categories: [],
  post: null,
  articleToEdit: null,
  match: {},
};

const propTypes = {
  addCreatedPost: PropTypes.func.isRequired,
  addCreatedUserPost: PropTypes.func.isRequired,
  marketplace: PropTypes.arrayOf(PropTypes.object).isRequired,
  newsfeed: PropTypes.arrayOf(PropTypes.object).isRequired,
  userNewsfeed: PropTypes.arrayOf(PropTypes.object).isRequired,
  post: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
      PropTypes.object,
    ]),
  ),
  updateNewsfeed: PropTypes.func.isRequired,
  updateUserNewsfeed: PropTypes.func.isRequired,
  getCategories: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(PropTypes.object),
  getArticleToEdit: PropTypes.func.isRequired,
  removeArticleToEdit: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  articleToEdit: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
      PropTypes.array,
      PropTypes.object,
    ]),
  ),
  updateMarketplace: PropTypes.func.isRequired,
  addCreatedArticle: PropTypes.func.isRequired,
  match: PropTypes.object,
};

const mapStateToProps = state => ({
  articleToEdit: state.articleToEdit.data,
  categories: state.categories.list,
  marketplace: state.marketplace.data,
  newsfeed: state.newsfeed.data,
  userNewsfeed: state.userNewsfeed.data,
});

const mapDispatchToProps = dispatch => ({
  addCreatedArticle: bindActionCreators(addCreatedArticle, dispatch),
  updateMarketplace: bindActionCreators(updateMarketplace, dispatch),
  addCreatedPost: bindActionCreators(addCreatedPost, dispatch),
  updateNewsfeed: bindActionCreators(updateNewsfeed, dispatch),
  addCreatedUserPost: bindActionCreators(addCreatedUserPost, dispatch),
  updateUserNewsfeed: bindActionCreators(updateUserNewsfeed, dispatch),
  getCategories: bindActionCreators(fetchCategories, dispatch),
  getArticleToEdit: bindActionCreators(fetchArticleToEdit, dispatch),
  removeArticleToEdit: bindActionCreators(removeArticleToEdit, dispatch),
});

class SellArticleModal extends Component {
  state = {
    initialValues: {
      price: '',
      title: '',
      city: '',
      postCode: '',
      categoryId: '',
      phone: '',
      text: '',
      priceOnRequest: '0',
    },
    initialImages: [],
    imagesTokens: [],
    imagesUrls: [],
    deleteAttachments: [],
    isArticleToEditLoading: false,
    articleImageError: '',
    imageLoading: false,
  };

  componentDidMount() {
    const { getArticleToEdit, getCategories, post } = this.props;
    if (post) {
      this.setState({ isArticleToEditLoading: true });
      getArticleToEdit(post.article.id).then(() => {
        const { articleToEdit: article } = this.props;

        this.setState(prevState => ({
          ...prevState,
          initialValues: {
            price: article.price,
            title: article.title,
            city: article.city,
            postCode: article.post_code,
            categoryId: article.category.id,
            phone: article.phone,
            text: article.text || '',
            priceOnRequest: article.price_on_request,
          },
          initialImages:
            article.attachments.length > 0 ? article.attachments : [],
          isArticleToEditLoading: false,
        }));
      });
    }
    getCategories();
  }

  componentWillUnmount() {
    const { removeArticleToEdit } = this.props;
    removeArticleToEdit();
  }

  submitArticleForm = (values, actions) => {
    const {
      closeModal,
      marketplace,
      newsfeed,
      userNewsfeed,
      match,
      post,
      updateMarketplace,
      updateNewsfeed,
      updateUserNewsfeed,
      addCreatedArticle,
      addCreatedPost,
      addCreatedUserPost,
    } = this.props;
    const { path } = match;
    const {
      price,
      title,
      city,
      postCode,
      categoryId,
      phone,
      text,
      priceOnRequest,
    } = values;
    const { deleteAttachments, imagesTokens } = this.state;
    if (post) {
      updateArticlePost(post.article.id, {
        price: price || '0',
        title,
        city,
        price_on_request: priceOnRequest,
        post_code: postCode,
        category_id: categoryId,
        phone,
        text,
        attachments: imagesTokens,
        delete_attachments: deleteAttachments,
      })
        .then(response => {
          const createUpdatedNewsfeed = newsfeed =>
            newsfeed.map(item => {
              if (item.id === post.id) {
                return {
                  ...post,
                  text: response.data.text,
                  article: response.data,
                };
              }
              if (item.shared_content && item.shared_content.id === post.id) {
                return {
                  ...item,
                  shared_content: {
                    ...item.shared_content,
                    text: response.data.text,
                    article: response.data,
                  },
                };
              }
              return item;
            });
          if (path === '/') {
            updateNewsfeed(createUpdatedNewsfeed(newsfeed));
          }
          if (path.indexOf('/profile') > -1) {
            updateUserNewsfeed(createUpdatedNewsfeed(userNewsfeed));
          }
          if (path.indexOf('/marketplace') > -1) {
            updateMarketplace(createUpdatedNewsfeed(marketplace));
          }
          closeModal();
        })
        .catch(error => {
          actions.setErrors(setArticleErrors(error));
          actions.setSubmitting(false);
        });
    } else {
      createArticlePost({
        price: price || '0',
        title,
        city,
        price_on_request: priceOnRequest,
        post_code: postCode,
        category_id: categoryId,
        phone,
        text,
        attachments: imagesTokens,
      })
        .then(response => {
          if (path === '/') {
            addCreatedPost(response.data);
          }
          if (path.indexOf('/profile') > -1) {
            addCreatedUserPost(response.data);
          }
          if (path.indexOf('/marketplace') > -1) {
            addCreatedArticle(response.data);
          }
          closeModal();
        })
        .catch(error => {
          actions.setErrors(setArticleErrors(error));
          actions.setSubmitting(false);
        });
    }
  };

  addArticleImage = e => {
    const imageFile = e.target.files[0];
    const reader = new FileReader();
    const image = new Image();
    if (imageFile) {
      this.setState({
        imageLoading: true,
        articleImageError: '',
      });
      reader.readAsDataURL(imageFile);
      reader.addEventListener('load', () => {
        image.src = reader.result;
        image.addEventListener('load', () => {
          loadImage.parseMetaData(imageFile, data => {
            let orientation = 0;
            if (data.exif) {
              orientation = data.exif.get('Orientation');
            }
            loadImage(
              imageFile,
              canvas => {
                const base64data = canvas.toDataURL(imageFile.type);
                canvas.toBlob(blob => {
                  const { imagesUrls } = this.state;
                  const imgAsForm = new FormData();
                  imgAsForm.append(
                    'file',
                    imageFile.type === 'image/gif' ? imageFile : blob,
                  );
                  requestAttachmentToken(imgAsForm, 'image')
                    .then(res => {
                      const { imagesTokens } = this.state;
                      this.setState({
                        imagesUrls: [
                          ...imagesUrls,
                          imageFile.type === 'image/gif'
                            ? image.src
                            : base64data,
                        ],
                        imagesTokens: [...imagesTokens, res.data.token],
                        imageLoading: false,
                      });
                    })
                    .catch(error => {
                      this.setState({
                        articleImageError:
                          error.response.data &&
                          error.response.data.errors &&
                          error.response.data.errors.file.join(' '),
                        imageLoading: false,
                      });
                    });
                }, imageFile.type);
              },
              {
                canvas: true,
                orientation,
                maxWidth: 1300
              },
            );
          });
        });
      });
    }
  };

  removeImage = index => {
    const { imagesTokens, imagesUrls } = this.state;
    this.setState({
      imagesTokens: imagesTokens.filter((image, i) => index !== i),
      imagesUrls: imagesUrls.filter((image, i) => index !== i),
    });
  };

  removeInitialImage = (id, index) => {
    const { deleteAttachments, initialImages } = this.state;
    this.setState({
      initialImages: initialImages.filter((image, i) => index !== i),
      deleteAttachments: [...deleteAttachments, id],
    });
  };

  render() {
    const { categories, closeModal, post } = this.props;
    const {
      deleteAttachments,
      imagesUrls,
      initialImages,
      initialValues,
      isArticleToEditLoading,
      articleImageError,
      imageLoading,
    } = this.state;
    return (
      <SellArticleModalView
        addArticleImage={this.addArticleImage}
        categories={categories}
        closeModal={closeModal}
        imagesUrls={imagesUrls}
        initialImages={initialImages}
        initialValues={initialValues}
        isArticleToEditLoading={isArticleToEditLoading}
        removeImage={this.removeImage}
        removeInitialImage={this.removeInitialImage}
        submitArticleForm={this.submitArticleForm}
        post={post}
        deleteAttachments={deleteAttachments}
        articleImageError={articleImageError}
        imageLoading={imageLoading}
      />
    );
  }
}

SellArticleModal.defaultProps = defaultProps;

SellArticleModal.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(SellArticleModal));
