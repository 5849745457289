import { createReducer } from 'common/utils/reduxUtils';
import {
  FETCH_COMMENTS_SUCCESS,
  UPDATE_COMMENTS,
  REMOVE_COMMENTS,
  REMOVE_SINGLE_COMMENT,
  REMOVE_SINGLE_COMMENTS_LIST,
  SAVE_OPEN_COMMENT_ID,
  REMOVE_OPEN_COMMENT_ID,
} from 'post/redux/types';

const initialState = {
  data: {},
  ids: [],
};

const commentsReducer = createReducer(
  { ...initialState },
  {
    [FETCH_COMMENTS_SUCCESS]: (state, action) => {
      const { data, meta } = action.payload.data;
      const { id } = action.payload;
      const { currentTimestamp } = state.data[id] ? state.data[id] : {};
      const hasNextPage = meta.current_page < meta.last_page;
      let newData;
      if (!state.data[id]) {
        newData = {
          ...state.data,
          [id]: {
            data: [...data],
            nextPageIndex: hasNextPage ? meta.current_page + 1 : null,
            resultsNumber: meta.total,
            currentTimestamp:
              meta.current_page === 1
                ? meta.current_timestamp
                : currentTimestamp,
          },
        };
      } else {
        newData = {
          ...state.data,
          [id]: {
            data: [...state.data[id].data, ...data],
            nextPageIndex: hasNextPage ? meta.current_page + 1 : null,
            resultsNumber: meta.total,
            currentTimestamp:
              meta.current_page === 1
                ? meta.current_timestamp
                : currentTimestamp,
          },
        };
      }

      return {
        ...state,
        data: newData,
      };
    },
    [UPDATE_COMMENTS]: (state, action) => {
      const { data, id } = action.payload;
      let newData;
      if (!state.data[id]) {
        newData = {
          ...state.data,
          [id]: {
            ...state.data[id],
            data: [data],
          },
        };
      } else {
        newData = {
          ...state.data,
          [id]: {
            ...state.data[id],
            data: [data, ...state.data[id].data],
          },
        };
      }

      return {
        ...state,
        data: newData,
      };
    },
    [REMOVE_COMMENTS]: state => ({
      ...state,
      data: {},
    }),
    [REMOVE_SINGLE_COMMENT]: (state, action) => ({
      ...state,
      data: {
        ...state.data,
        [action.payload.postId]: {
          data: state.data[action.payload.postId].data.filter(
            comment => comment.id !== action.payload.commentId,
          ),
        },
      },
    }),
    [REMOVE_SINGLE_COMMENTS_LIST]: (state, action) => ({
      ...state,
      data: {
        ...state.data,
        [action.payload.id]: {
          data: [],
        },
      },
    }),
    [SAVE_OPEN_COMMENT_ID]: (state, action) => ({
      ...state,
      ids: [action.payload.id, ...state.ids],
    }),
    [REMOVE_OPEN_COMMENT_ID]: (state, action) => {
      let newIds;
      if (state.ids.length === 1) {
        newIds = [];
      } else {
        newIds = state.ids.splice(state.ids.indexOf(action.payload.id), 1);
      }
      return {
        ...state,
        ids: [...newIds],
      };
    },
  },
);

export default commentsReducer;
