import React from 'react';
import PropTypes from 'prop-types';
import ModalContainer from 'common/components/modalContainer';
import CloseButton from 'common/components/closeButton';
import MembersIcon from 'assets/icons/members.svg';
import MemberListItem from 'farm/components/farmMemberListItem';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from 'common/components/loader';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import './index.scss';

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
});

const defaultProps = {
  nextPageIndex: null,
};

const propTypes = {
  closeModal: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  membersResultsNumber: PropTypes.number.isRequired,
  farmMembers: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleMemberDelete: PropTypes.func.isRequired,
  getFarmMembersNextPage: PropTypes.func.isRequired,
  nextPageIndex: PropTypes.number,
};

const MembersListModalView = ({
  closeModal,
  isAdmin,
  membersResultsNumber,
  farmMembers,
  handleMemberDelete,
  nextPageIndex,
  getFarmMembersNextPage,
  translate,
}) => (
  <ModalContainer handleOutsideClick={closeModal} notFullSize>
    <div className="members-list-modal">
      <div className="members-list-modal__header">
        <div className="members-list-modal__header--left">
          <img
            src={MembersIcon}
            alt=""
            className="members-list-modal__header-icon"
          />
          <h1 className="members-list-modal__header-heading">
            {membersResultsNumber}
            {' '}
            {membersResultsNumber === 1
              ? translate('common.reaction.member')
              : translate('common.reaction.members')}
          </h1>
        </div>
        <CloseButton onClick={closeModal} />
      </div>
      <div className="members-list-modal__list" id="members-list-modal-scroll">
        <InfiniteScroll
          dataLength={farmMembers.length}
          scrollThreshold="400px"
          next={nextPageIndex ? getFarmMembersNextPage : null}
          hasMore={!!nextPageIndex}
          scrollableTarget="members-list-modal-scroll"
          loader={
            nextPageIndex === 1 ? (
              <Loader
                message={translate('common.load', {
                  object: translate('common.reaction.members'),
                })}
              />
            ) : (
              <Loader
                message={translate('common.loadMore', {
                  object: translate('common.reaction.members'),
                })}
              />
            )
          }
        >
          {farmMembers.map(member => (
            <div className="members-list-modal__member" key={member.id}>
              <MemberListItem
                member={member}
                isAdmin={isAdmin}
                handleMemberDelete={handleMemberDelete}
              />
            </div>
          ))}
        </InfiniteScroll>
      </div>
    </div>
  </ModalContainer>
);

MembersListModalView.defaultProps = defaultProps;

MembersListModalView.propTypes = propTypes;

export default connect(mapStateToProps)(MembersListModalView);
