import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import bell from 'assets/icons/bell.svg';

import './index.scss';

const defaultProps = {
  isUnread: false,
  toggleGuestModal: () => null,
  userData: {},
};

const propTypes = {
  toggleNotificationsModal: PropTypes.func.isRequired,
  fetchNotifications: PropTypes.func.isRequired,
  isUnread: PropTypes.bool,
  toggleGuestModal: PropTypes.func,
  userData: PropTypes.object,
};

const NotificationsBellView = ({
  isUnread,
  toggleNotificationsModal,
  fetchNotifications,
  userData,
  toggleGuestModal,
}) => {
  const bellClasses = classNames({
    'unread-circle': true,
    'circle-visible': isUnread,
  });

  return (
    <div className="notifications-wrapper">
      <button
        type="button"
        onClick={() => {
          if (userData.id) {
            toggleNotificationsModal();
            fetchNotifications();
          } else {
            toggleGuestModal();
          }
        }}
      >
        <img className="notifications-bell" src={bell} alt="" />
        <i className={bellClasses} />
      </button>
    </div>
  );
};

NotificationsBellView.defaultProps = defaultProps;

NotificationsBellView.propTypes = propTypes;

export default NotificationsBellView;
