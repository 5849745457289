import axios from 'axios';

export const addView = (post_id, token) =>
  axios.post(
    `${process.env.REACT_APP_API_URL}/views`,
    {
      post_id,
    },
    { headers: { Authorization: `Bearer ${token}` } },
  );
