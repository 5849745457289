import axios from 'axios';
import * as types from 'common/redux/types';
import { createActionCreator } from 'common/utils/reduxUtils';

const {
  FETCH_CATEGORIES_REQUEST,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORIES_FAILURE,
} = types;

const fetchCategoriesRequest = createActionCreator(FETCH_CATEGORIES_REQUEST);
const fetchCategoriesSuccess = createActionCreator(
  FETCH_CATEGORIES_SUCCESS,
  'data',
);
const fetchCategoriesFailure = createActionCreator(FETCH_CATEGORIES_FAILURE);

const fetchCategories = () => dispatch => {
  dispatch(fetchCategoriesRequest());
  return axios
    .get(`${process.env.REACT_APP_API_URL}/categories`)
    .then(response => {
      dispatch(fetchCategoriesSuccess(response.data.data));
    })
    .catch(() => {
      dispatch(fetchCategoriesFailure());
    });
};

export default fetchCategories;
