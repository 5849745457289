import React from 'react';
import Icon from 'assets/icons/congrats.svg';
import MainButton from 'common/components/mainButton';
import { Translate } from 'react-localize-redux';
import './index.scss';

const CongratulationsView = ({ history }) => {
  const signIn = () => {
    history.push('/auth');
  };

  return (
    <div className="congratulations">
      <div className="congratulations__wrapper">
        <img className="congratulations__icon" src={Icon} alt="" />
        <h1 className="congratulations__heading">
          <Translate id="page.congratulations.title" />
        </h1>
        <h2 className="congratulations__sub-heading">
          <Translate id="page.congratulations.subHeading" />
        </h2>
        <div className="congratulations__button-sign-in">
          <MainButton fullWidth onClick={signIn}>
            <Translate id="common.action.singIn" />
          </MainButton>
        </div>
      </div>
    </div>
  );
};

export default CongratulationsView;
