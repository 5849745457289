import axios from 'axios';
import * as types from 'profile/redux/types';
import { createActionCreator } from 'common/utils/reduxUtils';

const {
  FETCH_USER_PROFILE_REQUEST,
  FETCH_USER_PROFILE_SUCCESS,
  FETCH_USER_PROFILE_FAILURE,
  REMOVE_USER_PROFILE,
} = types;

const fetchUserProfileRequest = createActionCreator(FETCH_USER_PROFILE_REQUEST);
const fetchUserProfileSuccess = createActionCreator(
  FETCH_USER_PROFILE_SUCCESS,
  'data',
);
const fetchUserProfileFailure = createActionCreator(FETCH_USER_PROFILE_FAILURE);

export const fetchUserProfile = id => dispatch => {
  dispatch(fetchUserProfileRequest());
  return axios
    .get(`${process.env.REACT_APP_API_URL}/users/${id}`)
    .then(response => {
      dispatch(fetchUserProfileSuccess(response.data));
    })
    .catch(() => {
      dispatch(fetchUserProfileFailure());
    });
};

export const removeUserProfile = createActionCreator(REMOVE_USER_PROFILE);
