import axios from 'axios';

export const createComment = (text, id) =>
  axios.post(`${process.env.REACT_APP_API_URL}/comments`, {
    id,
    text,
    type: 'post',
  });

export const getComment = id =>
  axios.get(`${process.env.REACT_APP_API_URL}/comments/${id}`);

export const deleteComment = id =>
  axios.delete(`${process.env.REACT_APP_API_URL}/comments/${id}`);
