import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './index.scss';

const defaultProps = {
  type: 'text',
  onBlur: () => null,
  onChange: () => null,
  onFocus: () => null,
  error: '',
  touched: false,
  value: '',
  name: '',
  placeholder: '',
  disabled: false,
  isIconVisible: false,
  isUsernameIconVisible: false,
  isPasswordIconVisible: false,
  isSearchIconVisible: false,
  withLabel: false,
  fullWidth: false,
  maxLength: null,
  autoFocus: false,
};

const propTypes = {
  value: PropTypes.string,
  type: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.string,
  touched: PropTypes.bool,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  isIconVisible: PropTypes.bool,
  isUsernameIconVisible: PropTypes.bool,
  isPasswordIconVisible: PropTypes.bool,
  isSearchIconVisible: PropTypes.bool,
  withLabel: PropTypes.bool,
  fullWidth: PropTypes.bool,
  autoFocus: PropTypes.bool,
  maxLength: PropTypes.number,
};

class Input extends Component {
  state = {
    isOldPasswordVisible: false,
    isPasswordVisible: false,
    isPasswordConfirmationVisible: false,
    isFocused: false,
  };

  togglePassword = () => {
    const { isPasswordVisible } = this.state;
    this.setState({ isPasswordVisible: !isPasswordVisible });
  };

  togglePasswordConfirmation = () => {
    const { isPasswordConfirmationVisible } = this.state;
    this.setState({
      isPasswordConfirmationVisible: !isPasswordConfirmationVisible,
    });
  };

  toggleOldPassword = () => {
    const { isOldPasswordVisible } = this.state;
    this.setState({
      isOldPasswordVisible: !isOldPasswordVisible,
    });
  };

  setFocus = () => {
    this.setState({ isFocused: true });
  };

  removeFocus = () => {
    this.setState({ isFocused: false });
  };

  render() {
    const {
      value,
      type,
      onBlur,
      onChange,
      name,
      error,
      touched,
      placeholder,
      disabled,
      isIconVisible,
      isUsernameIconVisible,
      isPasswordIconVisible,
      isSearchIconVisible,
      withLabel,
      fullWidth,
      maxLength,
      autoFocus,
      onFocus,
    } = this.props;
    const {
      isOldPasswordVisible,
      isPasswordVisible,
      isPasswordConfirmationVisible,
      isFocused,
    } = this.state;
    const inputClasses = classNames({
      input: true,
      input__icon: isIconVisible,
      'input__icon--username': isUsernameIconVisible,
      'input__icon--password': isPasswordIconVisible,
      'input__icon--search': isSearchIconVisible,
      'input--focused': isFocused || value,
      'input--full-width': fullWidth,
    });
    const inputFieldclasses = classNames({
      input__field: true,
      'input__field--error': touched && error,
      'input__field--icon': isIconVisible,
      'input__field--search': isSearchIconVisible,
      'input__field--full-width': fullWidth,
    });
    const togglePasswordButtonClasses = classNames({
      'input__toggle-password-button': true,
      'input__toggle-password-button--hidden':
        (name === 'password' && !isPasswordVisible) ||
        (name === 'passwordConfirmation' && !isPasswordConfirmationVisible) ||
        (name === 'oldPassword' && !isOldPasswordVisible),
    });
    return (
      <div className={inputClasses}>
        {withLabel && (
          <label className="input__label" htmlFor={name} id={name}>
            {placeholder}
          </label>
        )}
        <input
          type={
            (name === 'password' && !isPasswordVisible) ||
            (name === 'passwordConfirmation' &&
              !isPasswordConfirmationVisible) ||
            (name === 'oldPassword' && !isOldPasswordVisible)
              ? 'password'
              : type
          }
          name={name}
          readOnly={disabled}
          placeholder={withLabel ? '' : placeholder}
          onChange={onChange}
          onBlur={e => {
            onBlur(e);
            this.removeFocus();
          }}
          value={value}
          className={inputFieldclasses}
          onFocus={() => {
            this.setFocus();
            onFocus();
          }}
          autoFocus={autoFocus}
          maxLength={maxLength}
        />
        {name === 'password' && (
          <div
            className={togglePasswordButtonClasses}
            role="button"
            tabIndex="0"
            onClick={this.togglePassword}
            onKeyPress={this.togglePassword}
          />
        )}
        {name === 'passwordConfirmation' && (
          <div
            className={togglePasswordButtonClasses}
            role="button"
            tabIndex="0"
            onClick={this.togglePasswordConfirmation}
            onKeyPress={this.togglePasswordConfirmation}
          />
        )}
        {name === 'oldPassword' && (
          <div
            className={togglePasswordButtonClasses}
            role="button"
            tabIndex="0"
            onClick={this.toggleOldPassword}
            onKeyPress={this.toggleOldPassword}
          />
        )}
        {touched && error && (
          <div className="input__error-message">{error}</div>
        )}
      </div>
    );
  }
}

Input.propTypes = propTypes;

Input.defaultProps = defaultProps;

export default Input;
