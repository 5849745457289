import axios from 'axios';

export const createFarm = farmData =>
  axios.post(`${process.env.REACT_APP_API_URL}/farms`, {
    ...farmData,
  });

export const editFarm = (id, farmData) =>
  axios.put(`${process.env.REACT_APP_API_URL}/farms/${id}`, {
    ...farmData,
  });

export const deleteFarm = id =>
  axios.delete(`${process.env.REACT_APP_API_URL}/farms/${id}`);

export const deleteFarmMember = (farmId, memberId) =>
  axios.delete(
    `${process.env.REACT_APP_API_URL}/farms/${farmId}/members/${memberId}`,
  );

export const setNewAdmin = (id, farmData) =>
  axios.put(`${process.env.REACT_APP_API_URL}/farms/${id}/admin`, {
    ...farmData,
  });

export const createFarmMember = (farmId, requestId) =>
  axios.post(`${process.env.REACT_APP_API_URL}/farms/${farmId}/members`, {
    membership_request_id: requestId,
  });

export const sendFarmMembershipRequest = id =>
  axios.post(
    `${process.env.REACT_APP_API_URL}/farms/${id}/membership-requests`,
  );

export const deleteFarmMembershipRequest = (farmId, requestId) =>
  axios.delete(
    `${process.env.REACT_APP_API_URL}/farms/${farmId}/membership-requests`,
    {
      data: {
        membership_request_id: requestId,
      },
    },
  );
