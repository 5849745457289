import axios from 'axios';
import * as types from 'newsfeed/redux/types';
import { createActionCreator } from 'common/utils/reduxUtils';

const {
  FETCH_NEWSFEED_REQUEST,
  FETCH_NEWSFEED_SUCCESS,
  FETCH_NEWSFEED_FAILURE,
  ADD_CREATED_POST,
  UPDATE_NEWSFEED,
  REMOVE_NEWSFEED,
  SET_ACTIVE_FEED,
  CHECK_UNREAD_POSTS_REQUEST,
  CHECK_UNREAD_POSTS_FAILURE,
  CHECK_UNREAD_POSTS_SUCCESS,
  UPDATE_POST,
} = types;

const fetchNewsfeedRequest = createActionCreator(FETCH_NEWSFEED_REQUEST);
const fetchNewsfeedSuccess = createActionCreator(
  FETCH_NEWSFEED_SUCCESS,
  'data',
);
const fetchNewsfeedFailure = createActionCreator(FETCH_NEWSFEED_FAILURE);

const checkForUnreadPostsRequest = createActionCreator(
  CHECK_UNREAD_POSTS_REQUEST,
);
const checkForUnreadPostsFailure = createActionCreator(
  CHECK_UNREAD_POSTS_FAILURE,
);
const checkForUnreadPostsSuccess = createActionCreator(
  CHECK_UNREAD_POSTS_SUCCESS,
  'data',
);

export const fetchNewsfeed = (page = 1, post_id) => (dispatch, getState) => {
  const { currentTimestamp } = getState().newsfeed;
  dispatch(fetchNewsfeedRequest());
  return axios
    .get(`${process.env.REACT_APP_API_URL}/newsfeed`, {
      headers: {
        Authorization: `Bearer ${getState().auth.token}`,
      },
      params: {
        page,
        post_id,
        user_id: getState().userData.data ? getState().userData.data.id : false,
        to: currentTimestamp,
        type: !post_id ? getState().newsfeed.activeFeed : null,
      },
    })
    .then(response => {
      dispatch(fetchNewsfeedSuccess(response.data));
    })
    .catch(error => {
      dispatch(fetchNewsfeedFailure());
      if (error.response && error.response.status === 401) {
        window.location.reload(true);
      }
    });
};

export const checkForUnreadPosts = () => (dispatch, getState) => {
  dispatch(checkForUnreadPostsRequest());

  return axios
    .get(`${process.env.REACT_APP_API_URL}/newsfeed/unread`, {
      params: {
        user_id: getState().userData.data ? getState().userData.data.id : false,
      },
      headers: {
        Authorization: `Bearer ${getState().auth.token}`,
      },
    })
    .then(response => {
      dispatch(checkForUnreadPostsSuccess(response.data));
    })
    .catch(() => {
      dispatch(checkForUnreadPostsFailure());
    });
};

export const addCreatedPost = createActionCreator(ADD_CREATED_POST, 'data');

export const updateNewsfeed = createActionCreator(UPDATE_NEWSFEED, 'data');
export const updatePost = createActionCreator(UPDATE_POST, 'data');

export const setActiveFeed = type => dispatch => {
  dispatch({ type: SET_ACTIVE_FEED, payload: type });
  dispatch(fetchNewsfeed());
};

export const removeNewsfeed = createActionCreator(REMOVE_NEWSFEED);
