import React from 'react';
import PropTypes from 'prop-types';
import loaderIconLeft from 'assets/icons/loader_left.svg';
import loaderIconRight from 'assets/icons/loader_right.svg';
import './index.scss';

const defaultProps = {
  message: 'Loading...',
};

const propTypes = {
  message: PropTypes.string,
};

const Loader = ({ message }) => (
  <div className="loader">
    <div>
      <img
        className="loader__icon loader__icon--left"
        src={loaderIconLeft}
        alt=""
      />
      <img
        className="loader__icon loader__icon--right"
        src={loaderIconRight}
        alt=""
      />
    </div>
    <p className="loader__load-message">{message}</p>
  </div>
);

Loader.defaultProps = defaultProps;
Loader.propTypes = propTypes;

export default Loader;
