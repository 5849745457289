import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import disableScroll from 'common/utils/disableScroll';
import ActivitiesModal from 'post/containers/activitiesModal';
import TextPostDetailsModalView from 'post/components/textPostDetailsModal';

const defaultProps = {
  farmId: null,
};

const propTypes = {
  newsfeed: PropTypes.arrayOf(PropTypes.object).isRequired,
  post: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
      PropTypes.object,
    ]),
  ).isRequired,
  togglePostDetailsModal: PropTypes.func.isRequired,
  farmId: PropTypes.number,
};

class TextPostDetailsModal extends Component {
  state = {
    isCommentSectionVisible: false,
    isActivitiesModalVisible: false,
  };

  componentWillUnmount() {
    disableScroll(false);
  }

  toggleActivitiesModal = () => {
    const { isActivitiesModalVisible } = this.state;
    this.setState(
      {
        isActivitiesModalVisible: !isActivitiesModalVisible,
      },
      () => disableScroll(!isActivitiesModalVisible),
    );
  };

  toggleCommentsSection = () => {
    if (window.innerWidth > 992) {
      this.setState(({ isCommentSectionVisible }) => ({
        isCommentSectionVisible: !isCommentSectionVisible,
      }));
    } else {
      this.toggleActivitiesModal();
    }
  };

  render() {
    const { newsfeed, post, togglePostDetailsModal, farmId } = this.props;
    const { isCommentSectionVisible, isActivitiesModalVisible } = this.state;
    return (
      <Fragment>
        <TextPostDetailsModalView
          post={post}
          newsfeed={newsfeed}
          isCommentSectionVisible={isCommentSectionVisible}
          toggleCommentsSection={this.toggleCommentsSection}
          togglePostDetailsModal={togglePostDetailsModal}
          farmId={farmId}
        />
        {isActivitiesModalVisible && (
          <ActivitiesModal
            post={post}
            closeModal={this.toggleActivitiesModal}
            activeSection="comments"
          />
        )}
      </Fragment>
    );
  }
}

TextPostDetailsModal.defaultProps = defaultProps;

TextPostDetailsModal.propTypes = propTypes;

export default TextPostDetailsModal;
