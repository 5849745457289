import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import debounce from 'lodash/debounce';
import VicinityInputView from 'marketplace/components/vicinityInputView';
import {
  fetchMarketplace,
  updateMarketplaceVicinity,
} from 'marketplace/redux/actions/marketplaceActions';

const propTypes = {
  getMarketplace: PropTypes.func.isRequired,
  updateMarketplaceVicinity: PropTypes.func.isRequired,
  vicinity: PropTypes.number.isRequired,
};

const mapStateToProps = state => ({
  vicinity: state.marketplace.vicinity,
});

const mapDispatchToProps = dispatch => ({
  getMarketplace: bindActionCreators(fetchMarketplace, dispatch),
  updateMarketplaceVicinity: bindActionCreators(
    updateMarketplaceVicinity,
    dispatch,
  ),
});

class VicinityInput extends Component {
  debouncedGetMarketplace = debounce(() => {
    const { getMarketplace } = this.props;
    getMarketplace();
  }, 250);

  handleVicinityChange = e => {
    const { updateMarketplaceVicinity } = this.props;
    updateMarketplaceVicinity(Number(e.target.value));
    this.debouncedGetMarketplace();
  };

  render() {
    const { vicinity } = this.props;
    return (
      <VicinityInputView
        handleVicinityChange={this.handleVicinityChange}
        vicinity={vicinity}
      />
    );
  }
}

VicinityInput.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VicinityInput);
