import React from 'react';
import PropTypes from 'prop-types';
import Button from 'common/components/button';
import './index.scss';

const defaultProps = {
  text: 'Delete',
};

const propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string,
};

const DeleteButton = ({ onClick, text }) => (
  <Button className="delete-button" onClick={onClick}>
    {text}
  </Button>
);

DeleteButton.defaultProps = defaultProps;

DeleteButton.propTypes = propTypes;

export default DeleteButton;
