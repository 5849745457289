import { createReducer } from 'common/utils/reduxUtils';
import {
  FETCH_ARTICLE_TO_EDIT_SUCCESS,
  REMOVE_ARTICLE_TO_EDIT,
} from 'post/redux/types';

const initialState = {
  data: {},
};

const articleToEditReducer = createReducer(
  { ...initialState },
  {
    [FETCH_ARTICLE_TO_EDIT_SUCCESS]: (state, action) => ({
      ...state,
      data: action.payload.data,
    }),
    [REMOVE_ARTICLE_TO_EDIT]: state => ({
      ...state,
      data: {},
    }),
  },
);

export default articleToEditReducer;
