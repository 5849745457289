import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CloseButton from 'common/components/closeButton';
import ImageWithLoader from 'common/components/imageWithLoader';
import VideoWithLoader from 'common/components/videoWithLoader';
import { Link } from 'react-router-dom';
import Carousel from 'common/components/carousel';
import { connect } from 'react-redux';
import './index.scss';

const defaultProps = {
  width: null,
  onSlideChanged: null,
  currentSlide: 0,
  farm: {},
};

const propTypes = {
  closeModal: PropTypes.func.isRequired,
  farmtour: PropTypes.object.isRequired,
  width: PropTypes.number,
  farm: PropTypes.object,
  translate: PropTypes.func.isRequired,
  onSlideChanged: PropTypes.func,
  currentSlide: PropTypes.number,
};

const FarmTourModalView = ({
  closeModal,
  farmtour,
  farm,
  width,
  onSlideChanged,
  translate,
  currentSlide,
}) => {
  const modalClasses = classNames({
    'farmtour-details-modal': true,
    'farmtour-details-modal--images':
      farmtour.data.attachments && farmtour.data.attachments.length > 0,
  });
  const detailsContainerClasses = classNames({
    'farmtour-details-modal__details-container': true,
    'farmtour-details-modal__details-container--images':
      farmtour.data.attachments && farmtour.data.attachments.length > 0,
  });
  const detailsScrollClasses = classNames({
    'farmtour-details-modal__details-scroll': true,
    'farmtour-details-modal__details-scroll--small':
      width >= 992 &&
      (!farmtour.data.attachments || !farmtour.data.attachments[0]),
  });

  let shouldShowGreenButton = false;

  if (
    farmtour.farmTourPost &&
    farmtour.data.attachments &&
    farmtour.data.attachments.length !== 0
  ) {
    if (currentSlide >= 1) {
      shouldShowGreenButton = true;
    } else if (currentSlide === 0 && farmtour.data.attachments.length === 1) {
      shouldShowGreenButton = true;
    }
  }

  return (
    <div className={modalClasses}>
      <div className="farmtour-header">
        <span className="farmname">{farm.name}</span>
        <span className="farmroundtour">{translate('farm.farmtour')}</span>
      </div>
      <div className="farmtour-details-modal__close_desktop">
        <CloseButton onClick={closeModal} />
      </div>

      {farmtour.data.attachments && farmtour.data.attachments.length > 0 && (
        <div className="farmtour-details-modal__media-container--desktop">
          {farmtour.data.attachments.length === 1 && (
            <ImageWithLoader
              image={farmtour.data.attachments[0]}
              isAutoSize
              parent="modal"
            />
          )}
          {farmtour.data.attachments.length > 1 && (
            <Carousel onSlideChanged={onSlideChanged}>
              {farmtour.data.attachments.map(image => (
                <div
                  key={image.url}
                  className="farmtour-details-modal__slider-image"
                >
                  {(image.type !== 'farmtour_video' && (
                    <ImageWithLoader image={image} isAutoSize parent="modal" />
                  )) || <VideoWithLoader video={image} withBackground />}
                </div>
              ))}
            </Carousel>
          )}
        </div>
      )}
      <div className={detailsContainerClasses}>
        <div className={detailsScrollClasses}>
          {farmtour.data.attachments && farmtour.data.attachments.length > 0 && (
            <div className="farmtour-details-modal__mobile-gallery">
              {farmtour.data.attachments.length === 1 && (
                <ImageWithLoader
                  parent="modal"
                  image={farmtour.data.attachments[0]}
                  isAutoSize
                />
              )}
              {farmtour.data.attachments.length > 1 && (
                <Carousel onSlideChanged={onSlideChanged}>
                  {farmtour.data.attachments.map(image => (
                    <div
                      key={image.url}
                      className="farmtour-details-modal__slider-image"
                    >
                      {(image.type !== 'farmtour_video' && (
                        <ImageWithLoader
                          image={image}
                          isAutoSize
                          parent="modal"
                        />
                      )) || <VideoWithLoader video={image} withBackground />}
                    </div>
                  ))}
                </Carousel>
              )}
            </div>
          )}
        </div>
      </div>
      {shouldShowGreenButton && (
        <div className="like-farmtour">
          <Link
            onClick={() => closeModal()}
            to={`/post/${farmtour.farmTourPost.id}`}
          >
            {translate('farm.likeFarmtour')}
          </Link>
        </div>
      )}
    </div>
  );
};

FarmTourModalView.defaultProps = defaultProps;

FarmTourModalView.propTypes = propTypes;

export default connect(null)(FarmTourModalView);
