import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Translate, getTranslate } from 'react-localize-redux';
import Button from 'common/components/button';
import Logo from 'assets/icons/logo.svg';
import LogoSmall from 'assets/icons/logo-small.svg';
import UserAvatar from 'common/components/userAvatar';
import MainHeaderOptionsMenu from 'common/containers/mainHeaderOptionsMenu';
import SearchFarms from 'common/containers/searchFarms';
import NotificationsBell from 'notification/containers/notificationsBell';
import NotificationsModal from 'notification/containers/notificationsModal';
import './index.scss';
import FarmlifesImage from 'assets/images/farmlifes.jpg';

const defaultProps = {
  userData: {},
  isNotificationsModalVisible: false,
  toggleGuestModal: () => null,
};

const propTypes = {
  isOptionsMenuVisible: PropTypes.bool.isRequired,
  handleLogOutClick: PropTypes.func.isRequired,
  toggleOptionsMenu: PropTypes.func.isRequired,
  userData: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
  ),
  isNotificationsModalVisible: PropTypes.bool,
  toggleGuestModal: PropTypes.func,
  translate: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
});

const MainHeaderView = ({
  isOptionsMenuVisible,
  handleLogOutClick,
  toggleOptionsMenu,
  userData,
  isNotificationsModalVisible,
  toggleGuestModal,
  translate,
}) => {
  const menuButtonClasses = classNames({
    'main-header__menu-button': true,
    'main-header__menu-button--open': isOptionsMenuVisible,
  });

  return (
    <div className="main-header">
      <div className="main-header__container">
        <div className="main-header__content">
          <div className="main-header__content--left">
            <div className="main-header__logo">
              <Link to="/">
                <img
                  className="main-header__logo main-header__logo--mobile"
                  src={LogoSmall}
                  alt="Farmlifes logo"
                />
                <img
                  className="main-header__logo main-header__logo--desktop"
                  src={Logo}
                  alt="Farmlifes logo"
                />
              </Link>
            </div>
            <div className="main-header__search">
              <SearchFarms
                userId={userData.id}
                onFocus={() => {
                  if (!userData.id) {
                    toggleGuestModal();
                  }
                }}
              />
            </div>
          </div>
          <div>
            <div className="main-header__user-content">
              <Fragment>
                {userData.id && (
                  <Link className="main-header__farmfeed" to="/">
                    <Translate id="common.action.farmfeed" />
                  </Link>
                )}
                {!userData.id && (
                  <button
                    type="button"
                    className="main-header__farmfeed"
                    onClick={toggleGuestModal}
                  >
                    <Translate id="common.action.farmfeed" />
                  </button>
                )}

                <div className="main-menu-notification">
                  <NotificationsBell
                    userData={userData}
                    toggleGuestModal={toggleGuestModal}
                  />
                  {isNotificationsModalVisible && <NotificationsModal />}
                </div>
                <div className="main-header__user">
                  {userData.id && (
                    <UserAvatar id={userData.id} image={userData.avatar} />
                  )}
                  {!userData.id && (
                    <button
                      type="button"
                      style={{ marginRight: 20 }}
                      onClick={toggleGuestModal}
                    >
                      <UserAvatar image={userData.avatar} />
                    </button>
                  )}

                  {userData.id && (
                    <Link to={`/profile/${userData.id}`}>
                      <p className="main-header__user-name">{userData.name}</p>
                    </Link>
                  )}
                </div>
                <Button
                  id="menu-button"
                  className={menuButtonClasses}
                  onClick={toggleOptionsMenu}
                />
              </Fragment>
            </div>
            {isOptionsMenuVisible && (
              <MainHeaderOptionsMenu
                handleLogOutClick={handleLogOutClick}
                toggleOptionsMenu={toggleOptionsMenu}
                userData={userData}
              />
            )}
          </div>
        </div>
      </div>

      <Helmet>
        <meta charSet="utf-8" />
        <title>{translate('og.general.title')}</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content="" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <meta property="fb:app_id" content="2313889218719652" />
        <meta
          property="og:image"
          content={`${window.location.origin}${FarmlifesImage}`}
        />
        <meta name="og:description" content="" />
        <meta property="og:title" content={translate('og.general.title')} />
      </Helmet>
    </div>
  );
};

MainHeaderView.defaultProps = defaultProps;

MainHeaderView.propTypes = propTypes;

export default connect(mapStateToProps)(MainHeaderView);
