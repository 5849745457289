import { createReducer } from 'common/utils/reduxUtils';
import { FETCH_FARM_CATEGORIES_SUCCESS } from 'common/redux/types';

const farmCategoriesReducer = createReducer(
  {},
  {
    [FETCH_FARM_CATEGORIES_SUCCESS]: (state, action) => ({
      ...state,
      list: action.payload.data,
    }),
  },
);

export default farmCategoriesReducer;
