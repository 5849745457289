import 'core-js';
import 'react-app-polyfill/ie9';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { LocalizeProvider } from 'react-localize-redux';
import Echo from 'laravel-echo';
import Main from 'common/containers/main';
import TagManager from 'react-gtm-module';
import Routes from './routes';
import store from './storeConfig';
import * as serviceWorker from './serviceWorker';
import 'normalize.css';

import 'styles/index.scss';

window.io = require('socket.io-client');

const bugsnagClient = bugsnag('813df845fda4fba0c8228e16ea15c519');

bugsnagClient.use(bugsnagReact, React);
const ErrorBoundary = bugsnagClient.getPlugin('react');

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <LocalizeProvider store={store}>
        <Main>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </Main>
      </LocalizeProvider>
    </Provider>
  </ErrorBoundary>,
  document.getElementById('root'),
);

window.Echo = new Echo({
  broadcaster: 'socket.io',
  host: process.env.REACT_APP_SOCKET_URL,
});

const tagManagerArgs = {
  gtmId: 'GTM-PV5RWBQ',
};

if (process.env.REACT_APP_ENV === 'PRODUCTION') {
  TagManager.initialize(tagManagerArgs);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
