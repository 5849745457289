import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FarmsListView from 'farms/components/farmsListView';
import {
  fetchFarmsList,
  fetchUsersList,
} from 'farms/redux/actions/farmsListActions';
import disableScroll from 'common/utils/disableScroll';

const defaultProps = {
  farmsList: [],
  usersList: [],
};

const propTypes = {
  farmsList: PropTypes.arrayOf(PropTypes.object),
  usersList: PropTypes.arrayOf(PropTypes.object),
  getFarmsList: PropTypes.func.isRequired,
  getUsersList: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  farmsList: state.farmsList.data,
  usersList: state.farmsList.users,
  nextPageIndex: state.farmsList.nextPageIndex,
  nextUserPageIndex: state.farmsList.nextUserPageIndex,
});

const mapDispatchToProps = dispatch => ({
  getFarmsList: bindActionCreators(fetchFarmsList, dispatch),
  getUsersList: bindActionCreators(fetchUsersList, dispatch),
});

class FarmsList extends Component {
  componentDidMount() {
    const { getFarmsList, getUsersList } = this.props;
    getFarmsList();
    getUsersList();
    disableScroll(false);
  }

  getFarmsListNextPage = () => {
    const { getFarmsList, nextPageIndex } = this.props;
    getFarmsList(nextPageIndex);
  };

  getUsersListNextPage = () => {
    const { getUsersList, nextUserPageIndex } = this.props;
    getUsersList(nextUserPageIndex);
  };

  render() {
    const {
      farmsList,
      usersList,
      nextPageIndex,
      nextUserPageIndex,
    } = this.props;
    return (
      <FarmsListView
        farmsList={farmsList}
        usersList={usersList}
        getFarmsListNextPage={this.getFarmsListNextPage}
        getUsersListNextPage={this.getUsersListNextPage}
        nextPageIndex={nextPageIndex}
        nextUserPageIndex={nextUserPageIndex}
      />
    );
  }
}

FarmsList.defaultProps = defaultProps;

FarmsList.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FarmsList);
