import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import disableScroll from 'common/utils/disableScroll';
import {
  fetchUserProfile,
  removeUserProfile,
} from 'profile/redux/actions/userProfileActions';
import ProfileHeaderView from 'profile/components/profileHeaderView';
import EditProfileModal from 'profile/containers/editProfileModal';
import CreateFarmModal from 'farm/containers/createFarmModal';
import JoinFarmModal from 'farms/containers/joinFarmModal';
import InfoModal from 'common/components/infoModal';

const defaultProps = {
  postsCount: 0,
  userData: null,
  userProfile: null,
};

const propTypes = {
  userData: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
  ),
  userProfile: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ),
  postsCount: PropTypes.number,
  getUserProfile: PropTypes.func.isRequired,
  removeUserProfile: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  userData: state.userData.data,
  userProfile: state.userProfile.data,
  postsCount: state.userNewsfeed.resultsNumber,
});

const mapDispatchToProps = dispatch => ({
  getUserProfile: bindActionCreators(fetchUserProfile, dispatch),
  removeUserProfile: bindActionCreators(removeUserProfile, dispatch),
});

class ProfileHeader extends Component {
  state = {
    isEditProfileModalVisible: false,
    isEmailChangeRequestModalVisible: false,
    isJoinFarmModalVisible: false,
    isCreateFarmModalVisible: false,
  };

  componentDidMount() {
    const { match, getUserProfile } = this.props;
    const { userId } = match.params;
    getUserProfile(userId);
  }

  componentDidUpdate(prevProps) {
    const { match: prevMatch } = prevProps;
    const { userId: prevUserId } = prevMatch.params;
    const { match } = this.props;
    const { userId } = match.params;
    if (prevUserId !== userId) {
      const { getUserProfile, removeUserProfile } = this.props;
      removeUserProfile();
      getUserProfile(userId);
    }
  }

  componentWillUnmount() {
    const { removeUserProfile } = this.props;
    removeUserProfile();
  }

  toggleEditProfileModal = () => {
    const { isEditProfileModalVisible } = this.state;
    this.setState(
      { isEditProfileModalVisible: !isEditProfileModalVisible },
      () => disableScroll(!isEditProfileModalVisible),
    );
  };

  toggleJoinFarmModal = () => {
    const { isJoinFarmModalVisible, isEditProfileModalVisible } = this.state;
    if (isEditProfileModalVisible) {
      this.toggleEditProfileModal();
    }
    this.setState({ isJoinFarmModalVisible: !isJoinFarmModalVisible }, () =>
      disableScroll(!isJoinFarmModalVisible),
    );
  };

  toggleCreateFarmModal = () => {
    const { isCreateFarmModalVisible, isEditProfileModalVisible } = this.state;
    if (isEditProfileModalVisible) {
      this.toggleEditProfileModal();
    }
    this.setState({ isCreateFarmModalVisible: !isCreateFarmModalVisible });
  };

  toggleEmailChangeRequestModal = () => {
    const { isEmailChangeRequestModalVisible } = this.state;
    this.setState(
      { isEmailChangeRequestModalVisible: !isEmailChangeRequestModalVisible },
      () => disableScroll(!isEmailChangeRequestModalVisible),
    );
  };

  render() {
    const { userData, match, userProfile, postsCount } = this.props;
    const { userId } = match.params;
    const {
      isEditProfileModalVisible,
      isEmailChangeRequestModalVisible,
      isCreateFarmModalVisible,
      isJoinFarmModalVisible,
    } = this.state;
    return (
      <Fragment>
        <ProfileHeaderView
          isProfileOwner={!!(userData && userData.id === Number(userId))}
          userProfile={userProfile}
          toggleEditProfileModal={this.toggleEditProfileModal}
          postsCount={postsCount}
        />
        {isEditProfileModalVisible && (
          <EditProfileModal
            closeModal={this.toggleEditProfileModal}
            userProfile={userProfile}
            toggleJoinFarmModal={this.toggleJoinFarmModal}
            toggleCreateFarmModal={this.toggleCreateFarmModal}
            toggleEmailChangeRequestModal={this.toggleEmailChangeRequestModal}
          />
        )}
        {isCreateFarmModalVisible && (
          <CreateFarmModal closeModal={this.toggleCreateFarmModal} />
        )}
        {isJoinFarmModalVisible && (
          <JoinFarmModal closeModal={this.toggleJoinFarmModal} />
        )}
        {isEmailChangeRequestModalVisible && (
          <InfoModal
            message="E-mail notification is send to the old e-mail address
              with information about the e-mail change request. You have to
              confirm the change by clicking on the confirmation link in the
              e-mail notification."
            handlePrimaryOptionButtonClick={this.toggleEmailChangeRequestModal}
            closeModal={this.toggleEmailChangeRequestModal}
          />
        )}
      </Fragment>
    );
  }
}

ProfileHeader.defaultProps = defaultProps;

ProfileHeader.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ProfileHeader));
