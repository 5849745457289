import { createReducer } from 'common/utils/reduxUtils';
import {
  FETCH_FARM_DETAILS_SUCCESS,
  REMOVE_FARM_DETAILS,
  FARM_FLOW_START,
  FARM_FLOW_END,
} from 'farm/redux/types';

const initialState = { farmFlowActive: false };

const FarmReducer = createReducer(
  { ...initialState },
  {
    [FETCH_FARM_DETAILS_SUCCESS]: (state, action) => ({
      ...state,
      data: action.payload.data,
    }),
    [REMOVE_FARM_DETAILS]: state => ({
      ...state,
      data: {},
    }),
    [FARM_FLOW_START]: state => ({
      ...state,
      farmFlowActive: true,
    }),
    [FARM_FLOW_END]: state => ({
      ...state,
      farmFlowActive: false,
    }),
  },
);

export default FarmReducer;
