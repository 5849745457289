export default function setProfileErrors(error) {
  let errors = {};
  if (error.response.data.message) {
    errors = {
      submit: error.response.data.message,
    };
  }
  if (error.response.data.errors) {
    if (error.response.data.errors.first_name) {
      errors = {
        ...errors,
        ...{ firstName: error.response.data.errors.first_name.join(' ') },
      };
    }
    if (error.response.data.errors.last_name) {
      errors = {
        ...errors,
        ...{ lastName: error.response.data.errors.last_name.join(' ') },
      };
    }
    if (error.response.data.errors.email) {
      errors = {
        ...errors,
        ...{
          email: error.response.data.errors.email.join(' '),
        },
      };
    }
    if (error.response.data.errors.post_code) {
      errors = {
        ...errors,
        ...{ postCode: error.response.data.errors.post_code.join(' ') },
      };
    }
    if (error.response.data.errors.birth_date) {
      errors = {
        ...errors,
        ...{ birthdateYear: error.response.data.errors.birth_date.join(' ') },
      };
    }
    if (error.response.data.errors.gender) {
      errors = {
        ...errors,
        ...{ gender: error.response.data.errors.gender.join(' ') },
      };
    }
    if (error.response.data.errors.old_password) {
      errors = {
        ...errors,
        ...{ oldPassword: error.response.data.errors.old_password.join(' ') },
      };
    }
    if (error.response.data.errors.password) {
      errors = {
        ...errors,
        ...{ password: error.response.data.errors.password.join(' ') },
      };
    }
    if (error.response.data.errors.password_confirmation) {
      errors = {
        ...errors,
        ...{
          passwordConfirmation: error.response.data.errors.password_confirmation.join(
            ' ',
          ),
        },
      };
    }
  }

  return errors;
}
