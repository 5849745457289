import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import loadImage from 'blueimp-load-image';
import CreatePostModal from 'common/components/createPostModal';
import InfoModal from 'common/components/infoModal';
import SellArticleModal from 'post/containers/sellArticleModal';
import { createPost, createFarmPost } from 'common/api/postsApi';
import { addCreatedPost } from 'newsfeed/redux/actions/newsfeedActions';
import { addCreatedFarmPost } from 'farm/redux/actions/farmNewsfeedActions';
import { addCreatedUserPost } from 'profile/redux/actions/userNewsfeedActions';
import requestAttachmentToken from 'common/api/attachmentsApi';
import disableScroll from 'common/utils/disableScroll';
import { fetchFarmDetails } from 'farm/redux/actions/farmActions';
import { fetchUserProfile } from 'profile/redux/actions/userProfileActions';
import { getTranslate } from 'react-localize-redux';

const defaultProps = {
  farmId: null,
  userData: {},
  translate: () => null,
  match: {},
  feed: '',
};

const propTypes = {
  addCreatedPost: PropTypes.func.isRequired,
  addCreatedFarmPost: PropTypes.func.isRequired,
  addCreatedUserPost: PropTypes.func.isRequired,
  getFarmDetails: PropTypes.func.isRequired,
  farmId: PropTypes.number,
  userData: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
  ),
  getUserProfile: PropTypes.func.isRequired,
  translate: PropTypes.func,
  match: PropTypes.object,
  feed: PropTypes.string,
};

const mapStateToProps = state => ({
  userData: state.userData.data,
  translate: getTranslate(state.localize),
  feed: state.newsfeed.activeFeed,
});

const mapDispatchToProps = dispatch => ({
  addCreatedPost: bindActionCreators(addCreatedPost, dispatch),
  addCreatedFarmPost: bindActionCreators(addCreatedFarmPost, dispatch),
  addCreatedUserPost: bindActionCreators(addCreatedUserPost, dispatch),
  getFarmDetails: bindActionCreators(fetchFarmDetails, dispatch),
  getUserProfile: bindActionCreators(fetchUserProfile, dispatch),
});

class CreatePost extends Component {
  state = {
    postMessage: '',
    readMoreUrl: '',
    isCreatePostModalVisible: false,
    imageToken: null,
    imageUrl: null,
    uploadError: null,
    videoToken: null,
    isVideoUploadInProgress: false,
    videoDuration: null,
    isProcessVideoModalVisible: false,
    previewUrl: null,
    isSellArticleModalVisible: false,
    uploadProgress: 0,
    uploadCounter: 0,
    totalUploadTime: 0,
    progressType: false,
  };

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  toggleCreatePostModal = () => {
    const { isCreatePostModalVisible } = this.state;
    if (isCreatePostModalVisible) {
      this.removeAttachment();
    }

    this.setState(
      { isCreatePostModalVisible: !isCreatePostModalVisible },
      () => {
        if (window.innerWidth < 992) {
          disableScroll(!isCreatePostModalVisible);
        }
      },
    );
  };

  openFileDialog = () => {
    const elem = document.getElementById('image-input');
    if (elem && document.createEvent) {
      const evt = document.createEvent('MouseEvents');
      evt.initEvent('click', true, false);
      elem.dispatchEvent(evt);
    }
  };

  removeAttachment = () => {
    if (document.getElementById('image-input').value) {
      document.getElementById('image-input').value = null;
    }
    if (document.getElementById('video-input').value) {
      document.getElementById('video-input').value = null;
    }

    this.setState({
      imageUrl: null,
      imageToken: null,
      videoToken: null,
      videoDuration: null,
      uploadError: null,
      previewUrl: null,
    });
  };

  createImagePost = e => {
    const fileType = e.target.files[0] && e.target.files[0].type.split('/')[0];

    if (fileType === 'video') {
      return this.createVideoPost(e);
    }

    const imageFile = e.target.files[0];
    const reader = new FileReader();

    const image = new Image();
    const { isCreatePostModalVisible } = this.state;
    if (!isCreatePostModalVisible) {
      this.toggleCreatePostModal();
    }
    const extension =
      e.target.files[0] &&
      e.target.files[0].name
        .split('.')
        .pop()
        .toLowerCase();

    if (
      extension !== 'jpeg' &&
      extension !== 'jpg' &&
      extension !== 'png' &&
      extension !== 'gif'
    ) {
      this.setState({
        uploadError:
          'File format is not correct. Allowed formats are: jpeg, jpg, png, gif.',
      });
      return;
    }
    if (imageFile) {
      this.setState({
        progressType: 'image',
      });
      reader.onloadstart = this.updateProgress;

      reader.readAsDataURL(imageFile);
      reader.addEventListener('load', () => {
        this.setState({
          imageUrl: 'true',
        });
        image.src = reader.result;
        image.addEventListener('load', () => {
          loadImage.parseMetaData(imageFile, data => {
            let orientation = 0;
            if (data.exif) {
              orientation = data.exif.get('Orientation');
            }
            loadImage(
              imageFile,
              canvas => {
                const base64data = canvas.toDataURL(imageFile.type);
                canvas.toBlob(blob => {
                  if (document.getElementById('video-input')) {
                    document.getElementById('video-input').value = null;
                  }
                  this.setState({
                    imageUrl:
                      imageFile.type === 'image/gif' ? image.src : base64data,
                    imageToken: null,
                    uploadError: null,
                    videoToken: null,
                    videoDuration: null,
                    previewUrl: null,
                  });
                  const imgAsForm = new FormData();
                  imgAsForm.append(
                    'file',
                    imageFile.type === 'image/gif' ? imageFile : blob,
                  );
                  requestAttachmentToken(imgAsForm, 'image')
                    .then(res => {
                      this.setState({
                        uploadCounter: 0,
                        totalUploadTime: 0,
                        uploadProgress: 0,
                        imageToken: res.data.token,
                      });
                    })
                    .catch(error => {
                      this.setState({
                        uploadCounter: 0,
                        totalUploadTime: 0,
                        uploadProgress: 0,
                        uploadError:
                          error.response.data &&
                          error.response.data.errors &&
                          error.response.data.errors.file.join(' '),
                      });
                    });
                }, imageFile.type);
              },
              {
                canvas: true,
                orientation,
                maxWidth: 1300,
              },
            );
          });
        });
      });
    }
  };

  updateProgress = evt => {
    this.resetProgressInterval();

    if (evt.lengthComputable) {
      const mb = 1024 * 1024;

      const totalMb = Math.ceil(evt.total / mb);
      // const ratio = !this.state.progressType || this.state.progressType === 'video' ? 2.5 : 1.5;
      const ratio = 2.5;
      const secs = Math.ceil(totalMb * ratio);

      this.setState({
        totalUploadTime: secs,
      });

      this.interval = setInterval(() => {
        this.updateProgressBar();
      }, 100);
    }
  };

  resetProgressInterval = () => {
    clearInterval(this.interval);

    this.setState({
      uploadCounter: 0,
      totalUploadTime: 0,
      uploadProgress: 0,
    });
  };

  updateProgressBar = () => {
    this.setState({
      uploadCounter: this.state.uploadCounter + 0.1,
    });

    const percentLoaded = Math.round(
      (this.state.uploadCounter / this.state.totalUploadTime) * 100,
    );

    if (percentLoaded <= 95) {
      this.setState({
        uploadProgress: percentLoaded,
      });
    } else {
      this.setState({
        uploadProgress: 95,
      });
    }

    if (this.state.uploadCounter >= this.state.totalUploadTime) {
      clearInterval(this.interval);
    }
  };

  createVideoPost = e => {
    const { isCreatePostModalVisible } = this.state;
    if (!isCreatePostModalVisible) {
      this.toggleCreatePostModal();
    }
    if (e.target.files[0]) {
      this.setState({
        imageToken: null,
        imageUrl: null,
        videoToken: null,
        isVideoUploadInProgress: true,
        uploadError: '',
        videoDuration: null,
        previewUrl: null,
      });
      const reader = new FileReader();
      const video = e.target.files[0];
      document.getElementById('image-input').value = null;
      const videoEl = document.createElement('video');

      this.setState({
        progressType: 'video',
      });

      reader.onloadstart = this.updateProgress;

      reader.onloadend = () => {
        const videoAsForm = new FormData();
        videoEl.addEventListener('loadeddata', () => {
          const canvas = document.createElement('canvas');
          canvas.width = videoEl.videoWidth;
          canvas.height = videoEl.videoHeight;
          canvas.getContext('2d').drawImage(videoEl, 0, 0);
          canvas.toBlob(blob => {
            const previewUrl = URL.createObjectURL(blob);
            this.setState({ previewUrl });
          });
        });
        videoAsForm.append('file', video);

        requestAttachmentToken(videoAsForm, 'video')
          .then(res => {
            this.resetProgressInterval();

            this.setState({
              videoToken: res.data.token,
              isVideoUploadInProgress: false,
              videoDuration: videoEl.duration,
            });
          })
          .catch(error => {
            this.resetProgressInterval();

            this.setState({
              uploadError:
                error.response.data &&
                error.response.data.errors &&
                error.response.data.errors.file.join(' '),
              isVideoUploadInProgress: false,
            });
          });
      };
      reader.readAsDataURL(video);
      videoEl.src = URL.createObjectURL(video);
    }
  };

  updatePostMessage = e => {
    this.setState({
      uploadError: '',
      postMessage: e.target.value,
    });
  };

  onUrlChange = e => {
    this.setState({
      uploadError: '',
      readMoreUrl: e.target.value,
    });
  };

  createPost = () => {
    const {
      addCreatedPost,
      addCreatedFarmPost,
      addCreatedUserPost,
      farmId,
      getFarmDetails,
      match,
      userData,
      getUserProfile,
      feed,
    } = this.props;
    const { path } = match;
    const { imageToken, postMessage, videoToken, readMoreUrl } = this.state;
    if (postMessage || imageToken || videoToken) {
      let token;
      if (imageToken) {
        token = imageToken;
      } else if (videoToken) {
        token = videoToken;
      }
      if (farmId) {
        createFarmPost(postMessage, token, farmId).then(response => {
          this.toggleCreatePostModal();
          // if (!videoToken) {
          addCreatedFarmPost(response.data.created);
          getFarmDetails(farmId);
          // } else {
          //   this.toggleProcessVideoModal();
          // }
          this.setState({
            postMessage: '',
            imageUrl: null,
            readMoreUrl: '',
            imageToken: null,
            videoToken: null,
            videoDuration: null,
            uploadError: null,
            previewUrl: null,
          });
          document.getElementsByClassName('close-button')[0] &&
            document.getElementsByClassName('close-button')[0].click();
        });
      } else {
        createPost(postMessage, token, feed, readMoreUrl)
          .then(response => {
            this.toggleCreatePostModal();
            // if (!videoToken) {
            if (path === '/') {
              addCreatedPost(response.data.created);
            }
            if (path.indexOf('/profile') > -1) {
              const { userId } = match.params;
              if (userData.id === Number(userId)) {
                addCreatedUserPost(response.data.created);
                getUserProfile(userId);
              }
            }
            // } else {
            //   this.toggleProcessVideoModal();
            // }
            this.setState({
              postMessage: '',
              readMoreUrl: '',
              imageUrl: null,
              imageToken: null,
              videoToken: null,
              videoDuration: null,
              uploadError: null,
              previewUrl: null,
            });
            document.getElementsByClassName('close-button')[0] &&
              document.getElementsByClassName('close-button')[0].click();
          })
          .catch(() => {
            // console.log(err);
          });
      }
    }
  };

  toggleProcessVideoModal = () => {
    const { isProcessVideoModalVisible } = this.state;
    this.setState(
      { isProcessVideoModalVisible: !isProcessVideoModalVisible },
      disableScroll(!isProcessVideoModalVisible),
    );
  };

  toggleSellArticleModal = () => {
    const { isSellArticleModalVisible } = this.state;
    this.setState(
      { isSellArticleModalVisible: !isSellArticleModalVisible },
      disableScroll(!isSellArticleModalVisible),
    );
  };

  render() {
    const { children, farmId, translate } = this.props;
    const {
      isCreatePostModalVisible,
      postMessage,
      readMoreUrl,
      imageUrl,
      imageToken,
      uploadError,
      isVideoUploadInProgress,
      videoToken,
      videoDuration,
      isProcessVideoModalVisible,
      previewUrl,
      isSellArticleModalVisible,
      uploadProgress,
    } = this.state;
    return (
      <Fragment>
        {children({
          toggleCreatePostModal: this.toggleCreatePostModal,
          createImagePost: this.createImagePost,
          createVideoPost: this.createVideoPost,
          toggleSellArticleModal: this.toggleSellArticleModal,
          postMessage,
          farmId,
        })}
        {isCreatePostModalVisible && (
          <CreatePostModal
            toggleCreatePostModal={this.toggleCreatePostModal}
            createImagePost={this.createImagePost}
            createVideoPost={this.createVideoPost}
            updatePostMessage={this.updatePostMessage}
            postMessage={postMessage}
            createPost={this.createPost}
            imageUrl={imageUrl}
            removeAttachment={this.removeAttachment}
            imageToken={imageToken}
            uploadError={uploadError}
            isVideoUploadInProgress={isVideoUploadInProgress}
            uploadProgress={uploadProgress}
            videoToken={videoToken}
            videoDuration={videoDuration}
            previewUrl={previewUrl}
            toggleSellArticleModal={this.toggleSellArticleModal}
            farmId={farmId}
            handleChange={this.onUrlChange}
            readMoreUrl={readMoreUrl}
          />
        )}
        {isProcessVideoModalVisible && (
          <InfoModal
            message={translate('modal.videoProcessing.heading')}
            handlePrimaryOptionButtonClick={this.toggleProcessVideoModal}
            closeModal={this.toggleProcessVideoModal}
          />
        )}
        {isSellArticleModalVisible && (
          <SellArticleModal closeModal={this.toggleSellArticleModal} />
        )}
      </Fragment>
    );
  }
}

CreatePost.defaultProps = defaultProps;

CreatePost.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(CreatePost));
