import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './index.scss';

const defaultProps = {
  value: '',
  onBlur: () => null,
  onChange: () => null,
  error: '',
  touched: false,
  fullWidth: false,
  placeholder: '',
  name: '',
};

const propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  error: PropTypes.string,
  touched: PropTypes.bool,
  fullWidth: PropTypes.bool,
  placeholder: PropTypes.string,
  name: PropTypes.string,
};

const Select = ({
  value,
  onBlur,
  onChange,
  name,
  error,
  touched,
  placeholder,
  children,
  fullWidth,
}) => {
  const selectClasses = classNames({
    select: true,
    'select--full-width': fullWidth,
  });
  const selectFieldClasses = classNames({
    select__field: true,
    'select__field--error': touched && error,
    'select__field--full-width': fullWidth,
    'select__field--value': value,
  });
  return (
    <div className={selectClasses}>
      <select
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        className={selectFieldClasses}
      >
        {placeholder && <option value="">{placeholder}</option>}
        {children}
      </select>
      {touched && error && <div className="select__error-message">{error}</div>}
    </div>
  );
};

Select.defaultProps = defaultProps;

Select.propTypes = propTypes;

export default Select;
