export const FETCH_USER_DATA_REQUEST = 'FETCH_USER_DATA_REQUEST';
export const FETCH_USER_DATA_SUCCESS = 'FETCH_USER_DATA_SUCCESS';
export const FETCH_USER_DATA_FAILURE = 'FETCH_USER_DATA_FAILURE';

export const REMOVE_USER_DATA = 'REMOVE_USER_DATA';

export const FETCH_CATEGORIES_REQUEST = 'FETCH_CATEGORIES_REQUEST';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
export const FETCH_CATEGORIES_FAILURE = 'FETCH_CATEGORIES_FAILURE';

export const FETCH_FARM_CATEGORIES_REQUEST = 'FETCH_FARM_CATEGORIES_REQUEST';
export const FETCH_FARM_CATEGORIES_SUCCESS = 'FETCH_FARM_CATEGORIES_SUCCESS';
export const FETCH_FARM_CATEGORIES_FAILURE = 'FETCH_FARM_CATEGORIES_FAILURE';

export const SET_COOKIES_ACCEPT = 'SET_COOKIES_ACCEPT';
