import { createReducer } from 'common/utils/reduxUtils';
import {
  FETCH_HASHTAG_REQUEST,
  FETCH_HASHTAG_SUCCESS,
  FETCH_HASHTAG_FAILURE,
  UPDATE_HASHTAGFEED,
  REMOVE_HASHTAGFEED,
  SET_CURRENT_HASHTAG,
} from 'hashtag/redux/types';

const initialState = {
  data: [],
  currentTimestamp: null,
  nextPageIndex: null,
  isLoading: false,
  currentTag: false,
};

const hashtagReducer = createReducer(
  { ...initialState },
  {
    [FETCH_HASHTAG_SUCCESS]: (state, action) => {
      const { data, meta } = action.payload.data;
      const { currentTimestamp } = state;
      const hasNextPage = meta.current_page < meta.last_page;

      return {
        ...state,
        data: meta.current_page === 1 ? [...data] : [...state.data, ...data],
        currentTimestamp:
          meta.current_page === 1 ? meta.current_timestamp : currentTimestamp,
        nextPageIndex: hasNextPage ? meta.current_page + 1 : null,
        isLoading: false,
      };
    },
    [FETCH_HASHTAG_REQUEST]: state => ({
      ...state,
      isLoading: true,
    }),
    [FETCH_HASHTAG_FAILURE]: state => ({
      ...state,
      isLoading: false,
    }),

    [UPDATE_HASHTAGFEED]: (state, action) => ({
      ...state,
      data: [...action.payload.data],
    }),
    [SET_CURRENT_HASHTAG]: (state, action) => ({
      ...state,
      currentTag: action.payload.data,
    }),
    [REMOVE_HASHTAGFEED]: state => ({
      ...state,
      data: [],
      currentTimestamp: null,
      nextPageIndex: null,
    }),
  },
);

export default hashtagReducer;
