export const FETCH_USER_NEWSFEED_REQUEST = 'FETCH_USER_NEWSFEED_REQUEST';
export const FETCH_USER_NEWSFEED_SUCCESS = 'FETCH_USER_NEWSFEED_SUCCESS';
export const FETCH_USER_NEWSFEED_FAILURE = 'FETCH_USER_NEWSFEED_FAILURE';

export const ADD_CREATED_USER_POST = 'ADD_CREATED_USER_POST';
export const UPDATE_USER_NEWSFEED = 'UPDATE_USER_NEWSFEED';

export const REMOVE_USER_NEWSFEED = 'REMOVE_USER_NEWSFEED';

export const FETCH_USER_PROFILE_REQUEST = 'FETCH_USER_PROFILE_REQUEST';
export const FETCH_USER_PROFILE_SUCCESS = 'FETCH_USER_PROFILE_SUCCESS';
export const FETCH_USER_PROFILE_FAILURE = 'FETCH_USER_PROFILE_FAILURE';
export const REMOVE_USER_PROFILE = 'REMOVE_USER_PROFILE';
