import axios from 'axios';
import { loadToken } from 'stateLoader';

export default function setInterceptors() {
  axios.interceptors.request.use(
    config => {
      if (config.headers.Authorization) {
        return {
          ...config,
          headers: {
            ...config.headers,
          },
        };
      }
      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${loadToken()}`,
        },
      };
    },
    error => error,
  );

  axios.interceptors.response.use(
    response => response,
    error => {
      if (error.response) {
        const { status } = error.response;

        if (status === 418) {
          // window.location.replace('/verify');
        } else if (status === 401) {
          // window.location.reload(true);
        }
      }
      return Promise.reject(error);
    },
  );
}
