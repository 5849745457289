import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

const propTypes = {
  checked: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const RadioInput = ({ checked, name, onChange }) => (
  <label htmlFor={name} className="radio">
    <input
      type="radio"
      id={name}
      name={name}
      onChange={onChange}
      checked={checked}
      className="radio__input"
    />
    <span className="radio__checkmark" />
  </label>
);

RadioInput.propTypes = propTypes;

export default RadioInput;
