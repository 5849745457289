import React, { Component } from 'react';
import UserLogin from 'userAuth/containers/userLogin';
import UserRegister from 'userAuth/containers/userRegister';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import RegisterList from 'userAuth/components/registerList';
import AboutVideo from 'userAuth/components/aboutVideo';
import PromoLogos from 'userAuth/components/promoLogos';
import { Translate } from 'react-localize-redux';
import './index.scss';

const largeBreakpoint = 992;

class UserAuthView extends Component {
  constructor() {
    super();
    this.state = {
      width: null,
    };
  }

  componentDidMount() {
    this.setScreenWidth();
    window.addEventListener('resize', this.setScreenWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setScreenWidth);
  }

  setScreenWidth = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { width } = this.state;
    const {
      location: { hash },
    } = window;

    const mobile = (
      <div className="user-auth">
        <div className="user-auth__about-farmlifes">
          <a href="https://info.farmlifes.com" target="_new">
            <p>
              <Translate
                id={!hash ? 'page.about.title' : 'guest.registrationTitle'}
              />
            </p>
            <Translate
              id={
                !hash
                  ? 'page.about.description'
                  : 'guest.registrationDescription'
              }
            />
          </a>
        </div>
        <div className="user-auth__content">
          <Tabs defaultIndex={hash ? 1 : 0}>
            <TabList>
              <Tab>
                <Translate id="page.signIn.title2" />
              </Tab>
              <Tab>
                <Translate id="page.signUp.titleShort2" />
              </Tab>
            </TabList>
            <TabPanel>
              <UserLogin />
            </TabPanel>
            <TabPanel>
              <UserRegister />
            </TabPanel>
          </Tabs>
        </div>
      </div>
    );

    const desktop = (
      <div className="user-auth">
        <div className="user-auth__content">
          <div className="user-auth__static">
            <RegisterList />
          </div>
          {
            <div className="user-auth__register">
              <UserRegister />
            </div>
          }
          <div className="user-auth__login">
            <UserLogin />
          </div>
        </div>

        <div className="register-bottom">
          <AboutVideo />
        </div>

        <div className="promo-logos-wrapper">
          <PromoLogos />
        </div>
      </div>
    );

    if (width < largeBreakpoint) {
      return mobile;
    }
    return desktop;
  }
}

export default UserAuthView;
