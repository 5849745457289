import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Input from 'common/components/input/index';
import Textarea from 'common/components/textarea';
import Select from 'common/components/select';
import ModalContainer from 'common/components/modalContainer';
import MainButton from 'common/components/mainButton';
import Button from 'common/components/button';
import Loader from 'common/components/loader';
import { articleValidationSchema as validationSchema } from 'post/validators/articleValidators';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import './index.scss';

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
});

const defaultProps = {
  imagesUrls: [],
  initialImages: [],
  deleteAttachments: [],
  post: null,
  translate: () => null,
};

const propTypes = {
  addArticleImage: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  closeModal: PropTypes.func.isRequired,
  imagesUrls: PropTypes.arrayOf(PropTypes.string),
  initialValues: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ).isRequired,
  submitArticleForm: PropTypes.func.isRequired,
  removeImage: PropTypes.func.isRequired,
  initialImages: PropTypes.arrayOf(PropTypes.object),
  removeInitialImage: PropTypes.func.isRequired,
  isArticleToEditLoading: PropTypes.bool.isRequired,
  post: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
      PropTypes.object,
    ]),
  ),
  deleteAttachments: PropTypes.arrayOf(PropTypes.number),
  articleImageError: PropTypes.string.isRequired,
  imageLoading: PropTypes.bool.isRequired,
  translate: PropTypes.func,
};

const SellArticleModalView = ({
  addArticleImage,
  categories,
  closeModal,
  imagesUrls,
  initialImages,
  initialValues,
  isArticleToEditLoading,
  removeImage,
  removeInitialImage,
  submitArticleForm,
  post,
  deleteAttachments,
  articleImageError,
  translate,
  imageLoading,
}) => (
  <ModalContainer handleOutsideClick={closeModal}>
    <div className="sell-article-modal">
      {isArticleToEditLoading && (
        <div className="sell-article-modal__loader">
          <Loader />
        </div>
      )}
      {(!post || (post && !isArticleToEditLoading)) && (
        <div className="sell-article-modal__scroll-container">
          <p className="sell-article-modal__heading">
            {post
              ? translate('common.editObject', {
                  object: translate('common.reaction.article'),
                })
              : translate('common.sellObject', {
                  object: translate('common.reaction.article'),
                })}
          </p>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={(values, { setErrors, setSubmitting }) =>
              submitArticleForm(values, { setErrors, setSubmitting })
            }
            render={({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <form
                onSubmit={handleSubmit}
                noValidate
                className="sell-article-modal__form"
              >
                <div className="sell-article-modal__form-content">
                  <div className="sell-article-modal__row price-row sell-article-modal__row--inline sell-article-modal__row--center">
                    <p className="sell-article-modal__form-label short">
                      {translate('page.marketplace.price')}
                      {' '}
(&euro;)
                    </p>
                    <Input
                      type="text"
                      value={values.price}
                      error={errors.price}
                      touched={touched.price}
                      name="price"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="12000 &euro;"
                      disabled={values.priceOnRequest === '1'}
                    />
                    <label
                      className="on-request-label"
                      htmlFor="price_on_request"
                    >
                      <input
                        type="checkbox"
                        className="on-request"
                        id="price_on_request"
                        value={values.priceOnRequest}
                        name="price_on_request"
                        checked={values.priceOnRequest === '1'}
                        onChange={() => {
                          if (values.priceOnRequest === '0') {
                            setFieldValue('price', '');
                          }
                          setFieldValue(
                            'priceOnRequest',
                            values.priceOnRequest === '0' ? '1' : '0',
                          );
                        }}
                      />
                      {translate('page.marketplace.onRequest')}
                    </label>
                  </div>
                  <div className="sell-article-modal__row">
                    <Input
                      type="text"
                      value={values.title}
                      error={errors.title}
                      touched={touched.title}
                      name="title"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={translate('page.marketplace.productTitle')}
                      fullWidth
                      withLabel
                    />
                  </div>
                  <div className="sell-article-modal__row">
                    <Input
                      type="text"
                      value={values.city}
                      error={errors.city}
                      touched={touched.city}
                      name="city"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={translate('form.common.city')}
                      fullWidth
                      withLabel
                    />
                  </div>
                  <div className="sell-article-modal__row sell-article-modal__row--inline sell-article-modal__row--center">
                    <p className="sell-article-modal__form-label">
                      {translate('form.common.postCode')}
                    </p>
                    <Input
                      type="text"
                      value={values.postCode}
                      error={errors.postCode}
                      touched={touched.postCode}
                      name="postCode"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="_ _ _ _"
                      fullWidth
                      maxLength={10}
                    />
                  </div>
                  <div className="sell-article-modal__row sell-article-modal__row--inline">
                    <p className="sell-article-modal__form-label">
                      {translate('form.common.category')}
                    </p>
                    <Select
                      value={values.categoryId}
                      error={errors.categoryId}
                      touched={touched.categoryId}
                      name="categoryId"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={translate('form.common.undefined')}
                      fullWidth
                    >
                      {categories.map(category => (
                        <option key={category.id} value={category.id}>
                          {translate(`common.action.${category.name}`)}
                        </option>
                      ))}
                    </Select>
                  </div>
                  <div className="sell-article-modal__row">
                    <Input
                      type="text"
                      value={values.phone}
                      error={errors.phone}
                      touched={touched.phone}
                      name="phone"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={translate('form.marketplace.phone')}
                      fullWidth
                      withLabel
                    />
                  </div>
                  <div className="sell-article-modal__row">
                    <Textarea
                      type="text"
                      value={values.text}
                      error={errors.text}
                      touched={touched.text}
                      name="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={translate('form.common.description')}
                      fullWidth
                      withLabel
                      textLength={values.text ? values.text.length : 0}
                    />
                  </div>
                </div>
                <div className="sell-article-modal__images">
                  {initialImages.map((image, index) => (
                    <div
                      className="sell-article-modal__image"
                      style={{ backgroundImage: `url(${image.url})` }}
                      key={image.id}
                    >
                      <Button
                        className="sell-article-modal__delete-image"
                        onClick={() => removeInitialImage(image.id, index)}
                      />
                    </div>
                  ))}
                  {imagesUrls.map((image, index) => (
                    <div
                      className="sell-article-modal__image"
                      style={{ backgroundImage: `url(${image})` }}
                      key={Math.random()}
                    >
                      <Button
                        className="sell-article-modal__delete-image"
                        onClick={() => removeImage(index)}
                      />
                    </div>
                  ))}
                  <div className="sell-article-modal__image-placeholder">
                    {imageLoading && (
                      <div className="sell-article-modal__loader-wrapper">
                        <Loader
                          message={translate('common.load', {
                            object: translate('common.reaction.image'),
                          })}
                        />
                      </div>
                    )}
                    {initialImages.length + imagesUrls.length < 10 && (
                      <label
                        htmlFor="article-image-input"
                        className="sell-article-modal__label"
                      >
                        <input
                          name="article-image-input"
                          id="article-image-input"
                          className="sell-article-modal__input"
                          type="file"
                          accept=".jpeg, .JPEG, .JPG, .jpg, .png, .PNG, .gif, .GIF"
                          onChange={addArticleImage}
                        />
                        <span className="sell-article-modal__label-text">
                          {translate('common.selectObject', {
                            object: translate('common.reaction.image'),
                          })}
                        </span>
                      </label>
                    )}
                  </div>
                  {articleImageError && (
                    <p className="sell-article-modal__error">
                      {articleImageError}
                    </p>
                  )}
                </div>
                <div className="sell-article-modal__buttons">
                  <MainButton
                    onClick={closeModal}
                    teritary
                    disabled={isSubmitting}
                  >
                    {translate('common.action.cancel')}
                  </MainButton>
                  <div className="sell-article-modal__submit-button-container">
                    <MainButton
                      type="submit"
                      disabled={
                        (initialValues.price === values.price &&
                          initialValues.title === values.title &&
                          initialValues.city === values.city &&
                          initialValues.postCode === values.postCode &&
                          initialValues.postCode === values.postCode &&
                          initialValues.categoryId === values.categoryId &&
                          initialValues.phone === values.phone &&
                          initialValues.text === values.text &&
                          initialValues.priceOnRequest ===
                            values.priceOnRequest &&
                          imagesUrls.length === 0 &&
                          deleteAttachments.length === 0) ||
                        isSubmitting
                      }
                    >
                      {translate('common.action.save')}
                    </MainButton>
                    {errors.submit && (
                      <span className="sell-article-modal__error sell-article-modal__error--submit">
                        {errors.submit}
                      </span>
                    )}
                  </div>
                </div>
              </form>
            )}
          />
        </div>
      )}
    </div>
  </ModalContainer>
);

SellArticleModalView.defaultProps = defaultProps;

SellArticleModalView.propTypes = propTypes;

export default connect(mapStateToProps)(SellArticleModalView);
