import axios from 'axios';

export const addLike = id =>
  axios.post(`${process.env.REACT_APP_API_URL}/likes`, {
    type: 'post',
    id,
  });

export const deleteLike = id =>
  axios.delete(`${process.env.REACT_APP_API_URL}/likes`, {
    data: {
      type: 'post',
      id,
    },
  });
