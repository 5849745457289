import axios from 'axios';
import * as types from 'farm/redux/types';
import { createActionCreator } from 'common/utils/reduxUtils';

const {
  FETCH_FARM_DETAILS_REQUEST,
  FETCH_FARM_DETAILS_SUCCESS,
  FETCH_FARM_DETAILS_FAILURE,
  REMOVE_FARM_DETAILS,
  FARM_FLOW_START,
  FARM_FLOW_END,
} = types;

const fetchFarmDetailsRequest = createActionCreator(FETCH_FARM_DETAILS_REQUEST);
const fetchFarmDetailsSuccess = createActionCreator(
  FETCH_FARM_DETAILS_SUCCESS,
  'data',
);
const fetchFarmDetailsFailure = createActionCreator(FETCH_FARM_DETAILS_FAILURE);

export const fetchFarmDetails = id => dispatch => {
  dispatch(fetchFarmDetailsRequest());
  return axios
    .get(`${process.env.REACT_APP_API_URL}/farms/${id}`)
    .then(response => {
      dispatch(fetchFarmDetailsSuccess(response.data));
    })
    .catch(() => {
      dispatch(fetchFarmDetailsFailure());
    });
};

export const removeFarmDetails = createActionCreator(REMOVE_FARM_DETAILS);

export const farmFlowStart = createActionCreator(FARM_FLOW_START);
export const farmFlowEnd = createActionCreator(FARM_FLOW_END);
