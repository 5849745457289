import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Translate } from 'react-localize-redux';
import './index.scss';

const defaultProps = {
  isSidebar: false,
};

const propTypes = {
  isSidebar: PropTypes.bool,
};

const Footer = ({ isSidebar }) => {
  const footerContentClasses = classNames({
    footer__content: true,
    'footer__content--sidebar': isSidebar,
  });
  const footerLinkClasses = classNames({
    footer__link: true,
    'footer__link--sidebar': isSidebar,
  });
  return (
    <div className="footer">
      <div className={footerContentClasses}>
        <a
          className={footerLinkClasses}
          href="https://info.farmlifes.com"
          rel="noopener noreferrer"
          target="_blank"
        >
          <Translate id="page.aboutUs.title" />
        </a>
        <a
          className={footerLinkClasses}
          href="https://info.farmlifes.com/service/impressum"
          rel="noopener noreferrer"
          target="_blank"
        >
          <Translate id="page.legal.title" />
        </a>
        {/*
          TODO: Add proper link to terms
          <a
            className={footerLinkClasses}
            href=""
            rel="noopener noreferrer"
            target="_blank"
          >
            <Translate id="page.terms.title" />
          </a>
          */}
        <a
          className={footerLinkClasses}
          href="https://info.farmlifes.com/datenschutz"
          rel="noopener noreferrer"
          target="_blank"
        >
          <Translate id="page.privacy.title" />
        </a>
        <a
          className={footerLinkClasses}
          href="https://info.farmlifes.com/service"
          rel="noopener noreferrer"
          target="_blank"
        >
          <Translate id="page.services.title" />
        </a>
        <a
          className={footerLinkClasses}
          href="https://info.farmlifes.com/partner"
          rel="noopener noreferrer"
          target="_blank"
        >
          <Translate id="page.partner.title" />
        </a>
        <a
          className={footerLinkClasses}
          href="https://info.farmlifes.com/werbung"
          rel="noopener noreferrer"
          target="_blank"
        >
          <Translate id="page.advertising.title" />
        </a>
      </div>
    </div>
  );
};

Footer.defaultProps = defaultProps;

Footer.propTypes = propTypes;

export default Footer;
