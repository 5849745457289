import translateMessage from 'common/utils/translateMessage';

export default function setSubmitErrors(error) {
  let submitErrors = {
    submit: translateMessage('form.error.submit'),
  };
  if (error.response && error.response.data.error) {
    submitErrors = {
      ...(error.response.data.error === 'invalid_credentials'
        ? { submit: translateMessage('form.error.userCredentials') }
        : {}),
    };
  }
  return submitErrors;
}
