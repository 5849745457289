import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UserAuthView from 'userAuth/components/userAuthView';
import Loader from 'common/components/loader';
import { eMailRegex } from 'common/utils/validationUtils';
import { loginUser } from 'userAuth/api/authApi';
import { setUserAuthToken } from 'userAuth/redux/actions/authActions';
import { fetchUserDataFirstTime } from 'common/redux/actions/userDataActions';

const defaultProps = {
  token: '',
  userData: {},
  history: {},
};

const propTypes = {
  token: PropTypes.string,
  userData: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
  ),
  setUserAuthToken: PropTypes.func.isRequired,
  fetchUserDataFirstTime: PropTypes.func.isRequired,
  history: PropTypes.object,
};

const mapStateToProps = state => ({
  token: state.auth.token,
  userData: state.userData.data,
});

const mapDispatchToProps = dispatch => ({
  setUserAuthToken: bindActionCreators(setUserAuthToken, dispatch),
  fetchUserDataFirstTime: bindActionCreators(fetchUserDataFirstTime, dispatch),
});

class UserAuth extends Component {
  constructor() {
    super();

    this.state = {
      verifying: false,
    };
  }

  componentDidMount() {
    const { token, userData, history } = this.props;

    if (token && userData) {
      history.replace('/');
    } else {
      this.checkIfVerified();
    }
  }

  checkIfVerified = () => {
    const { history } = this.props;
    const { hash } = history.location;

    if (hash) {
      const email = hash.replace(/^#/, '');
      if (
        email.match(eMailRegex) &&
        localStorage.getItem('tempPass') !== null &&
        localStorage.getItem('tempEmail') !== null &&
        email === localStorage.getItem('tempEmail')
      ) {
        this.setState({
          verifying: true,
        });
        this.loginVerifiedUser();
      } else {
        history.replace(`/auth${hash}`);
      }
    }
  };

  loginVerifiedUser = () => {
    const { history, fetchUserDataFirstTime, setUserAuthToken } = this.props;

    loginUser({
      username: localStorage.getItem('tempEmail'),
      password: localStorage.getItem('tempPass'),
      grant_type: 'password',
      client_id: `${process.env.REACT_APP_CLIENT_ID}`,
      client_secret: `${process.env.REACT_APP_CLIENT_SECRET}`,
    })
      .then(response => {
        if (response.data.access_token) {
          const token = response.data.access_token;
          setUserAuthToken(token);

          fetchUserDataFirstTime(token)
            .then(() => {
              this.setState({
                verifying: false,
              });
              history.push('/');
            })
            .catch(() => {
              this.setState({
                verifying: false,
              });
            });
        }
      })
      .catch(() => {
        this.setState({
          verifying: false,
        });
      });

    localStorage.removeItem('tempPass');
    localStorage.removeItem('tempEmail');
  };

  render() {
    const { verifying } = this.state;

    return (
      (!verifying && <UserAuthView />) || (
        <div className="auth-loader">
          <Loader message="" />
        </div>
      )
    );
  }
}

UserAuth.defaultProps = defaultProps;

UserAuth.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(UserAuth));
