import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import UserAvatar from 'common/components/userAvatar';
import Footer from 'common/components/footer';
import Button from 'common/components/button';
import { connect } from 'react-redux';
import { farmFlowStart } from 'farm/redux/actions/farmActions';
import { Translate } from 'react-localize-redux';
import './index.scss';
import WhiteAvatar from '../../../assets/icons/farm-white.svg';
import GreyAvatar from '../../../assets/icons/farm-green.svg';
import UserWhiteAvatar from '../../../assets/icons/user-white.svg';
import UserGreenAvatar from '../../../assets/icons/user-lightgreen.svg';

const propTypes = {
  handleLogOutClick: PropTypes.func.isRequired,
  toggleOptionsMenu: PropTypes.func.isRequired,
  userData: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
  ).isRequired,
  farmFlowStartAction: PropTypes.func.isRequired,
  articles: PropTypes.number.isRequired,
};

const mapStateToProps = state => ({
  articles: state.marketplace.total,
});

const mapDispatchToProps = dispatch => ({
  farmFlowStartAction: () => dispatch(farmFlowStart()),
});

const MainHeaderOptionsMenuView = ({
  handleLogOutClick,
  toggleOptionsMenu,
  userData,
  farmFlowStartAction,
  articles,
}) => {
  const hofProfileAvatarWidth = window.innerWidth > 991 ? 30 : 60;
  const defaultAvatar = window.innerWidth > 991 ? WhiteAvatar : GreyAvatar;
  const defaultUserAvatar =
    window.innerWidth > 991 ? UserWhiteAvatar : UserGreenAvatar;

  return (
    <div className="options-menu">
      <div>
        <div className="options-menu__user-container">
          <div className="options-menu__user">
            <div
              role="button"
              tabIndex="0"
              onClick={toggleOptionsMenu}
              onKeyPress={toggleOptionsMenu}
            >
              <UserAvatar id={userData.id} image={userData.avatar} />
            </div>
            <Link
              to={`/profile/${userData.id}`}
              onClick={toggleOptionsMenu}
              className="options-menu__user-link"
            >
              <p className="options-menu__user-name">{userData.name}</p>
            </Link>
          </div>
        </div>

        <div className="options-menu__links">
          <Link
            to={`/profile/${userData.id}`}
            onClick={toggleOptionsMenu}
            className="options-menu__hofprofile userprofile"
          >
            {(userData.farmAvatar && (
              <img
                alt=""
                src={`${
                  userData.avatar
                }?w=${hofProfileAvatarWidth}&h=${hofProfileAvatarWidth}&fit=crop`}
              />
            )) || <img alt="" src={defaultUserAvatar} />}
            <span>{userData.name}</span>
          </Link>
          <Button
            onClick={() => {
              farmFlowStartAction();
              toggleOptionsMenu();
            }}
            className="options-menu__hofprofile"
          >
            {(userData.farmAvatar && (
              <img
                alt=""
                src={`${
                  userData.farmAvatar
                }?w=${hofProfileAvatarWidth}&h=${hofProfileAvatarWidth}&fit=crop`}
              />
            )) || <img alt="" src={defaultAvatar} />}
            <span>
              <Translate id="page.farmProfile.title" />
            </span>
          </Button>
          <Link
            to="/marketplace"
            onClick={toggleOptionsMenu}
            className="options-menu__marketplace"
          >
            <span>
              <Translate id="page.marketplace.title" />
            </span>
            <span className="articles-count">
(
              {articles}
)
            </span>
          </Link>
          <Link
            to="/hashtag#videos"
            onClick={toggleOptionsMenu}
            className="options-menu__video"
          >
            <span>
              <Translate id="page.video.title" />
            </span>
          </Link>
          <Link
            to="/hashtag#farmlifeswebapp"
            onClick={toggleOptionsMenu}
            className="options-menu__webapp"
          >
            <span>
              <Translate id="page.webApp.title" />
            </span>
          </Link>
          <a
            href="https://info.farmlifes.com"
            rel="noopener noreferrer"
            target="_blank"
            onClick={() => {
              toggleOptionsMenu();
            }}
            className="options-menu__farmlifes"
          >
            <Translate id="common.action.information" />
          </a>
        </div>

        <Button onClick={handleLogOutClick} className="options-menu__log-out">
          <Translate id="common.action.logOut" />
        </Button>
      </div>
      <div className="options-menu__footer">
        <Footer />
      </div>
    </div>
  );
};

MainHeaderOptionsMenuView.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MainHeaderOptionsMenuView);
