import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import disableScroll from 'common/utils/disableScroll';
import FarmTourModalView from 'farm/components/farmTourModalView';
import { getTranslate } from 'react-localize-redux';
import {
  getFarmTourImages,
  getFarmTourPost,
  removeFarmTourData,
} from 'farm/redux/actions/farmtourActions';

const defaultProps = {
  farmtour: {},
  post: {},
  farm: {},
};

const propTypes = {
  getFarmTourImages: PropTypes.func.isRequired,
  getFarmTourPost: PropTypes.func.isRequired,
  removeFarmTourData: PropTypes.func.isRequired,
  post: PropTypes.object,
  farm: PropTypes.object,
  farmtour: PropTypes.object,
  toggleFarmTourModal: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  farmtour: state.farmtour,
  // farm: state.farm.data,
  translate: getTranslate(state.localize),
});

const mapDispatchToProps = dispatch => ({
  getFarmTourImages: bindActionCreators(getFarmTourImages, dispatch),
  getFarmTourPost: bindActionCreators(getFarmTourPost, dispatch),
  removeFarmTourData: bindActionCreators(removeFarmTourData, dispatch),
});

// const breakpoint = 992;

class FarmTourModal extends Component {
  state = {
    width: null,
    isFarmTourLoading: false,
    currentSlide: 0,
  };

  componentDidMount() {
    const { getFarmTourImages, getFarmTourPost, post, farm } = this.props;

    this.setWidth();
    window.addEventListener('resize', this.setWidth);

    this.setState({ isFarmTourLoading: true });

    let farmId = false;

    if (farm && farm.id) {
      farmId = farm.id;
    } else {
      farmId = post.shared_content
        ? post.shared_content.author.id
        : post.farm.id;
    }

    if (farmId) {
      getFarmTourImages(farmId).then(() => {
        getFarmTourPost(farmId);

        this.setState(prevState => ({
          ...prevState,
          isFarmTourLoading: false,
        }));
      });
    }
  }

  componentWillUnmount() {
    const { removeFarmTourData } = this.props;

    window.removeEventListener('resize', this.setWidth);
    disableScroll(false);

    removeFarmTourData();
  }

  setWidth = () => {
    this.setState({ width: window.innerWidth });
  };

  onSlideChanged = index => {
    this.setState({
      currentSlide: index,
    });
  };

  render() {
    const { farm, farmtour, toggleFarmTourModal, translate } = this.props;
    const { width, currentSlide } = this.state;

    return (
      <Fragment>
        <FarmTourModalView
          farm={farm}
          farmtour={farmtour}
          closeModal={toggleFarmTourModal}
          width={width}
          translate={translate}
          currentSlide={currentSlide}
          onSlideChanged={this.onSlideChanged}
        />
      </Fragment>
    );
  }
}

FarmTourModal.defaultProps = defaultProps;

FarmTourModal.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(FarmTourModal));
