import React from 'react';
import PropTypes from 'prop-types';
import Button from 'common/components/button';
import { Link } from 'react-router-dom';
import { Translate } from 'react-localize-redux';
import './index.scss';

const propTypes = {
  resendEmail: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
};

const VerifyAccountView = ({ resendEmail, email }) => (
  <div className="verify-account">
    <div className="verify-account__wrapper">
      <h1 className="verify-account__heading">
        <Translate id="page.verifyAccount.heading2" />
      </h1>
      <h2 className="verify-account__sub-heading">
        <Translate id="page.verifyAccount.subHeading2" />
      </h2>

      <div className="entered-email-header">
        <Translate id="page.verifyAccount.yourEmail" />
      </div>

      <div className="entered-email">
        <span className="entered-email__email">{email}</span>
        <Button className="verify-account__resend-button" onClick={resendEmail}>
          <Translate id="common.action.resendLink" />
        </Button>
      </div>

      <div className="wrong-email-message">
        <Translate id="page.verifyAccount.wrongEmailMessage" />
      </div>

      <Link className="verify-account__resend-button __link" to="/auth">
        <Translate id="common.action.backToRegistrationForm" />
      </Link>
    </div>
  </div>
);

VerifyAccountView.propTypes = propTypes;

export default VerifyAccountView;
