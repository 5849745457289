import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import disableScroll from 'common/utils/disableScroll';
import { resendEmail } from 'userAuth/api/authApi';
import VerifyAccountView from 'userAuth/components/verifyAccountView';
import InfoModal from 'common/components/infoModal';

const defaultProps = {
  userData: {},
  emailAuth: '',
};

const propTypes = {
  userData: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
  ),
  emailAuth: PropTypes.string,
};

const mapStateToProps = state => ({
  userData: state.userData.data,
  emailAuth: state.auth.email,
  translate: getTranslate(state.localize),
});

class VerifyAccount extends Component {
  state = {
    isVerificationModalVisible: false,
  };

  toggleVerificationModal = () => {
    const { isVerificationModalVisible } = this.state;
    this.setState(
      { isVerificationModalVisible: !isVerificationModalVisible },
      () => disableScroll(!isVerificationModalVisible),
    );
  };

  resendEmail = () => {
    const { emailAuth, userData } = this.props;
    const email = userData.email ? userData.email : emailAuth;
    resendEmail(email).then(() => {
      this.toggleVerificationModal();
    });
  };

  render() {
    const { translate, emailAuth } = this.props;
    const { isVerificationModalVisible } = this.state;
    return (
      <Fragment>
        <VerifyAccountView email={emailAuth} resendEmail={this.resendEmail} />
        {isVerificationModalVisible && (
          <InfoModal
            message={translate('modal.verifyAccount.message')}
            handlePrimaryOptionButtonClick={this.toggleVerificationModal}
            closeModal={this.toggleVerificationModal}
          />
        )}
      </Fragment>
    );
  }
}

VerifyAccount.defaultProps = defaultProps;

VerifyAccount.propTypes = propTypes;

export default connect(mapStateToProps)(VerifyAccount);
