export const FETCH_NEWSFEED_REQUEST = 'FETCH_NEWSFEED_REQUEST';
export const FETCH_NEWSFEED_SUCCESS = 'FETCH_NEWSFEED_SUCCESS';
export const FETCH_NEWSFEED_FAILURE = 'FETCH_NEWSFEED_FAILURE';

export const ADD_CREATED_POST = 'ADD_CREATED_POST';
export const UPDATE_NEWSFEED = 'UPDATE_NEWSFEED';
export const UPDATE_POST = 'UPDATE_POST';

export const REMOVE_NEWSFEED = 'REMOVE_NEWSFEED';

export const SET_ACTIVE_FEED = 'SET_ACTIVE_FEED';

export const CHECK_UNREAD_POSTS_REQUEST = 'CHECK_UNREAD_POSTS_REQUEST';
export const CHECK_UNREAD_POSTS_FAILURE = 'CHECK_UNREAD_POSTS_FAILURE';
export const CHECK_UNREAD_POSTS_SUCCESS = 'CHECK_UNREAD_POSTS_SUCCESS';
