import { createReducer } from 'common/utils/reduxUtils';
import {
  FETCH_FARM_MEMBERS_SUCCESS,
  REMOVE_FARM_MEMBERS,
  UPDATE_FARM_MEMBERS,
} from 'farm/redux/types';

const initialState = {
  data: [],
  nextPageIndex: null,
  resultsNumber: 0,
};

const farmNewsfeedReducer = createReducer(
  { ...initialState },
  {
    [FETCH_FARM_MEMBERS_SUCCESS]: (state, action) => {
      const { data, meta } = action.payload.data;
      const hasNextPage = meta.current_page < meta.last_page;
      return {
        ...state,
        data: meta.current_page === 1 ? [...data] : [...state.data, ...data],
        nextPageIndex: hasNextPage ? meta.current_page + 1 : null,
        resultsNumber: meta.total,
      };
    },
    [REMOVE_FARM_MEMBERS]: state => ({
      ...state,
      data: [],
      nextPageIndex: null,
      resultsNumber: 0,
    }),

    [UPDATE_FARM_MEMBERS]: (state, action) => {
      const { data, resultsNumber } = action.payload.data;
      return {
        ...state,
        data,
        resultsNumber,
      };
    },
  },
);

export default farmNewsfeedReducer;
