import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import InfiniteScroll from 'react-infinite-scroll-component';
import Post from 'post/containers/post';
import FarmHeaderMobile from 'farm/components/farmHeaderMobile';
import FarmTourIcon from 'farm/components/farmtourIconView';
import FarmMap from 'farm/components/farmMapView';
import FarmHeaderDesktop from 'farm/components/farmHeaderDesktop';
import CreatePost from 'common/containers/createPost';
import CreatePostView from 'common/components/createPostView';
import Loader from 'common/components/loader';
import EndMessage from 'common/components/endMessage';
import Footer from 'common/components/footer';
import UserLogin from 'userAuth/containers/userLogin';
import UserRegister from 'userAuth/containers/userRegister';
import AppSidebar from 'common/components/appSidebar';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { getTranslate, Translate } from 'react-localize-redux';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import './index.scss';
import {
  fetchFarmsList,
  updateFarmsListQuery,
} from 'farms/redux/actions/farmsListActions';

const defaultProps = {
  farmsList: [],
  nextPageIndex: null,
  farmMembers: [],
  toggleCreateFarmModal: null,
  toggleCreateFarmTourModal: null,
  isInitialPageLoading: false,
  getFarmNewsfeedNextPage: null,
  changeTitleImage: null,
  farmNewsfeed: [],
  isAdmin: false,
  isMember: false,
  toggleMembershipRequestsModal: null,
  isAuthorized: false,
  translate: () => null,
  toggleFarmTourModal: () => null,
  getFarmsList: () => null,
  updateFarmsQuery: () => null,
};

const propTypes = {
  farm: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.object,
      PropTypes.array,
      PropTypes.bool,
    ]),
  ).isRequired,
  toggleCreateFarmModal: PropTypes.func,
  toggleCreateFarmTourModal: PropTypes.func,
  toggleMembersListModal: PropTypes.func.isRequired,
  handleTitleImageLoad: PropTypes.func.isRequired,
  isTitleImageLoaded: PropTypes.bool.isRequired,
  nextPageIndex: PropTypes.number,
  isInitialPageLoading: PropTypes.bool,
  getFarmNewsfeedNextPage: PropTypes.func,
  changeTitleImage: PropTypes.func,
  farmMembers: PropTypes.arrayOf(PropTypes.object),
  farmNewsfeed: PropTypes.arrayOf(PropTypes.object),
  isAdmin: PropTypes.bool,
  isMember: PropTypes.bool,
  membersResultsNumber: PropTypes.number.isRequired,
  toggleMembershipRequestsModal: PropTypes.func,
  isAuthorized: PropTypes.bool,
  toggleImagePreviewModal: PropTypes.func.isRequired,
  toggleFarmTourModal: PropTypes.func,
  translate: PropTypes.func,
  getFarmsList: PropTypes.func,
  updateFarmsQuery: PropTypes.func,
  farmsList: PropTypes.array,
};

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
  farmsList: state.farmsList.data,
});

const mapDispatchToProps = dispatch => ({
  getFarmsList: bindActionCreators(fetchFarmsList, dispatch),
  updateFarmsQuery: bindActionCreators(updateFarmsListQuery, dispatch),
});

const largeBreakpoint = 992;

class FarmView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: null,
    };

    const { getFarmsList, updateFarmsQuery } = this.props;
    updateFarmsQuery('');
    getFarmsList();
  }

  componentDidMount() {
    this.setScreenWidth();
    window.addEventListener('resize', this.setScreenWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setScreenWidth);
  }

  setScreenWidth = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const {
      farm,
      toggleCreateFarmModal,
      toggleCreateFarmTourModal,
      toggleMembersListModal,
      handleTitleImageLoad,
      isTitleImageLoaded,
      farmNewsfeed,
      nextPageIndex,
      isInitialPageLoading,
      getFarmNewsfeedNextPage,
      changeTitleImage,
      farmMembers,
      isAdmin,
      isMember,
      toggleMembershipRequestsModal,
      membersResultsNumber,
      isAuthorized,
      translate,
      toggleImagePreviewModal,
      toggleFarmTourModal,
      farmsList,
    } = this.props;
    const { width } = this.state;
    const titleImageClasses = classNames({
      'farm__title-image': true,
      'farm__title-image--active':
        farm.title_image && farm.title_image.imageUrl,
    });

    let farmTourImage = '';

    if (farm && farm.farmtour_image) {
      if (farm.farmtour_image.thumbnail) {
        farmTourImage = farm.farmtour_image.thumbnail;
      } else if (farm.farmtour_image.imageUrl) {
        farmTourImage = farm.farmtour_image.imageUrl;
      } else {
        farmTourImage = '';
      }
    }

    return (
      <div className="farm">
        {farm.title_image && (
          <img
            src={farm.title_image.imageUrl}
            alt=""
            onLoad={handleTitleImageLoad}
            className="farm__header-image"
          />
        )}
        <div className="farm__header-mobile">
          <FarmHeaderMobile
            farm={farm}
            isTitleImageLoaded={isTitleImageLoaded}
            changeTitleImage={changeTitleImage}
            toggleCreateFarmModal={toggleCreateFarmModal}
            toggleCreateFarmTourModal={toggleCreateFarmTourModal}
            toggleMembersListModal={toggleMembersListModal}
            farmMembers={farmMembers}
            isAdmin={isAdmin}
            isMember={isMember}
            farmsList={farmsList}
            membersResultsNumber={
              isAuthorized ? membersResultsNumber : farm.members_count
            }
            toggleMembershipRequestsModal={toggleMembershipRequestsModal}
            toggleImagePreviewModal={toggleImagePreviewModal}
            toggleFarmTourModal={toggleFarmTourModal}
          />
        </div>
        <div className="farm__sidebar farm__sidebar--left">
          <div className="farm__header-desktop">
            <FarmHeaderDesktop
              farm={farm}
              isTitleImageLoaded={isTitleImageLoaded}
              changeTitleImage={changeTitleImage}
              toggleCreateFarmModal={toggleCreateFarmModal}
              toggleMembersListModal={toggleMembersListModal}
              farmMembers={farmMembers}
              isAdmin={isAdmin}
              isMember={isMember}
              membersResultsNumber={
                isAuthorized ? membersResultsNumber : farm.members_count
              }
              toggleMembershipRequestsModal={toggleMembershipRequestsModal}
            />
          </div>
          <Footer isSidebar />
        </div>

        {isAuthorized && (
          <div className="farm__feed">
            <div className="farm__title-image-container">
              {(isAdmin || isMember) && (
                <label
                  htmlFor="farm-profile-image-input"
                  className="farm__label"
                >
                  <div className="farm__edit-button" />
                  <input
                    name="farm-profile-image-input"
                    id="farm-profile-image-input"
                    className="farm__input"
                    type="file"
                    accept=".jpeg, .JPEG, .JPG, .jpg, .png, .PNG, .gif, .GIF"
                    onChange={changeTitleImage}
                  />
                </label>
              )}
              <div
                className={titleImageClasses}
                style={
                  farm.title_image && isTitleImageLoaded
                    ? {
                        backgroundImage: `url(${farm.title_image.imageUrl})`,
                        backgroundSize: 'cover',
                      }
                    : {}
                }
                role="button"
                tabIndex="0"
                onClick={
                  farm.title_image ? toggleImagePreviewModal : () => null
                }
                onKeyPress={
                  farm.title_image ? toggleImagePreviewModal : () => null
                }
              />
            </div>

            <div className="farm-activities">
              {width > 991 && farm.lat && farm.lng && farmsList.length > 0 && (
                <FarmMap
                  farmsList={farmsList}
                  farm={farm}
                  lat={farm.lat}
                  lng={farm.lng}
                />
              )}

              {width > 991 && (
                <FarmTourIcon
                  toggleCreateFarmTourModal={toggleCreateFarmTourModal}
                  toggleFarmTourModal={toggleFarmTourModal}
                  isAdmin={isAdmin}
                  farmTourImage={farmTourImage}
                />
              )}
            </div>

            {(isAdmin || isMember) && (
              <div className="farm__create-post">
                <CreatePost farmId={farm.id}>
                  {({
                    toggleCreatePostModal,
                    toggleSellArticleModal,
                    createImagePost,
                    createVideoPost,
                    postMessage,
                    farmId,
                  }) => (
                    <CreatePostView
                      toggleCreatePostModal={toggleCreatePostModal}
                      toggleSellArticleModal={toggleSellArticleModal}
                      createImagePost={createImagePost}
                      createVideoPost={createVideoPost}
                      postMessage={postMessage}
                      farmId={farmId}
                    />
                  )}
                </CreatePost>
              </div>
            )}
            {isInitialPageLoading && (
              <Loader
                message={translate('common.load', {
                  object: translate('common.reaction.posts'),
                })}
              />
            )}
            {!isInitialPageLoading && farmNewsfeed.length === 0 && (
              <p className="farm__empty">
                {translate('common.noObjects', {
                  objects: translate('common.reaction.posts'),
                })}
              </p>
            )}
            <InfiniteScroll
              dataLength={farmNewsfeed.length}
              scrollThreshold="400px"
              next={nextPageIndex ? getFarmNewsfeedNextPage : null}
              hasMore={!!nextPageIndex}
              loader={
                nextPageIndex === 1 ? (
                  <Loader
                    message={translate('common.load', {
                      object: translate('common.reaction.posts'),
                    })}
                  />
                ) : (
                  <Loader
                    message={translate('common.loadMore', {
                      object: translate('common.reaction.posts'),
                    })}
                  />
                )
              }
              endMessage={
                farmNewsfeed.length > 0 ? (
                  <EndMessage
                    message={translate('common.noMore', {
                      object: translate('common.reaction.posts'),
                    })}
                  />
                ) : null
              }
            >
              {farmNewsfeed.map(post => (
                <div className="farm__post" key={post.id}>
                  <Post post={post} newsfeed={farmNewsfeed} farmId={farm.id} />
                </div>
              ))}
            </InfiniteScroll>
          </div>
        )}
        {!isAuthorized && (
          <div className="farm__registration">
            <div
              className="farm__title-image"
              style={
                farm.title_image && isTitleImageLoaded
                  ? {
                      backgroundImage: `url(${farm.title_image.imageUrl})`,
                      backgroundSize: 'cover',
                    }
                  : {}
              }
            />
            {width < largeBreakpoint && (
              <div className="farm__register farm__register--mobile">
                <Tabs>
                  <TabList>
                    <Tab>
                      <Translate id="common.action.signIn" />
                    </Tab>
                    <Tab>
                      <Translate id="common.action.signUp" />
                    </Tab>
                  </TabList>
                  <TabPanel>
                    <UserLogin />
                  </TabPanel>
                  <TabPanel>
                    <UserRegister />
                  </TabPanel>
                </Tabs>
              </div>
            )}
            {width >= largeBreakpoint && (
              <div className="farm__register farm__register--desktop">
                <UserRegister />
              </div>
            )}
          </div>
        )}
        <div className="farm__sidebar farm__sidebar--right">
          <AppSidebar />
        </div>
      </div>
    );
  }
}

FarmView.defaultProps = defaultProps;

FarmView.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FarmView);
