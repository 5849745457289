import * as types from 'userAuth/redux/types';
import { createActionCreator } from 'common/utils/reduxUtils';

const {
  SET_USER_AUTH_TOKEN,
  REMOVE_USER_AUTH_TOKEN,
  SET_USER_EMAIL,
  REMOVE_USER_EMAIL,
} = types;

const setUserAuthTokenAction = createActionCreator(
  SET_USER_AUTH_TOKEN,
  'token',
);

const removeUserAuthTokenAction = createActionCreator(REMOVE_USER_AUTH_TOKEN);

export const setUserAuthToken = token => dispatch => {
  dispatch(setUserAuthTokenAction(token));
};

export const removeUserAuthToken = () => dispatch => {
  dispatch(removeUserAuthTokenAction());
};

const setUserEmailAction = createActionCreator(SET_USER_EMAIL, 'email');

const removeUserEmailAction = createActionCreator(REMOVE_USER_EMAIL);

export const setUserEmail = email => dispatch => {
  dispatch(setUserEmailAction(email));
};

export const removeUserEmail = () => dispatch => {
  dispatch(removeUserEmailAction());
};
