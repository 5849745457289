import React from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import CreatePost from 'common/containers/createPost';
import CreatePostView from 'common/components/createPostView';
import Post from 'post/containers/post';
import Loader from 'common/components/loader';
import ProfileHeader from 'profile/containers/profileHeader';
import Footer from 'common/components/footer';
import EndMessage from 'common/components/endMessage';
import AppSidebar from 'common/components/appSidebar';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import './index.scss';

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
});

const defaultProps = {
  nextPageIndex: null,
};

const propTypes = {
  isInitialPageLoading: PropTypes.bool.isRequired,
  newsfeed: PropTypes.arrayOf(PropTypes.object).isRequired,
  nextPageIndex: PropTypes.number,
  getUserNewsfeedNextPage: PropTypes.func.isRequired,
  isProfileOwner: PropTypes.bool.isRequired,
};

const ProfileView = ({
  isInitialPageLoading,
  newsfeed,
  nextPageIndex,
  getUserNewsfeedNextPage,
  isProfileOwner,
  translate,
}) => (
  <div className="profile">
    <div className="profile__header-content">
      <div className="profile__header">
        <ProfileHeader />
      </div>
      <div className="profile__footer">
        <Footer isSidebar />
      </div>
    </div>
    <div className="profile__feed">
      {isProfileOwner && (
        <div className="profile__create-post">
          <CreatePost>
            {({
              toggleCreatePostModal,
              toggleSellArticleModal,
              createImagePost,
              createVideoPost,
              postMessage,
            }) => (
              <CreatePostView
                toggleCreatePostModal={toggleCreatePostModal}
                toggleSellArticleModal={toggleSellArticleModal}
                createImagePost={createImagePost}
                createVideoPost={createVideoPost}
                postMessage={postMessage}
              />
            )}
          </CreatePost>
        </div>
      )}
      {isInitialPageLoading && (
        <Loader
          message={translate('common.load', {
            object: translate('common.reaction.posts'),
          })}
        />
      )}
      {!isInitialPageLoading && newsfeed.length === 0 && (
        <p className="profile__empty">
          {translate('common.noObjects', {
            objects: translate('common.reaction.posts'),
          })}
        </p>
      )}
      <InfiniteScroll
        dataLength={newsfeed.length}
        scrollThreshold="400px"
        next={nextPageIndex ? getUserNewsfeedNextPage : null}
        hasMore={!!nextPageIndex}
        loader={
          nextPageIndex === 1 ? (
            <Loader
              message={translate('common.load', {
                object: translate('common.reaction.posts'),
              })}
            />
          ) : (
            <Loader
              message={translate('common.loadMore', {
                object: translate('common.reaction.posts'),
              })}
            />
          )
        }
        endMessage={
          newsfeed.length > 0 ? (
            <EndMessage
              message={translate('common.noMore', {
                object: translate('common.reaction.posts'),
              })}
            />
          ) : null
        }
      >
        {newsfeed.map(post => (
          <div className="newsfeed-view__post" key={post.id}>
            <Post post={post} newsfeed={newsfeed} />
          </div>
        ))}
      </InfiniteScroll>
    </div>
    <div className="profile__sidebar profile__sidebar--right">
      <AppSidebar />
    </div>
  </div>
);

ProfileView.defaultProps = defaultProps;

ProfileView.propTypes = propTypes;

export default connect(mapStateToProps)(ProfileView);
