import React from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import classNames from 'classnames';
import CreatePost from 'common/containers/createPost';
import CreatePostView from 'common/components/createPostView';
import FeedsToggler from 'common/containers/feedsToggler';
import Post from 'post/containers/post';
import Loader from 'common/components/loader';
import NewsfeedNoMorePosts from 'newsfeed/components/newsfeedNoMorePosts';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import './index.scss';

const defaultProps = {
  newsfeed: [],
  nextPageIndex: null,
  user: {},
  translate: () => null,
  history: {},
  notificationModalVisible: false,
  fixed: false,
  toggleGuestModal: () => null,
};

const propTypes = {
  newsfeed: PropTypes.arrayOf(PropTypes.object),
  getNewsfeedNextPage: PropTypes.func.isRequired,
  nextPageIndex: PropTypes.number,
  isLoading: PropTypes.bool.isRequired,
  user: PropTypes.object,
  activeFeed: PropTypes.string.isRequired,
  translate: PropTypes.func,
  toggleGuestModal: PropTypes.func,
  history: PropTypes.object,
  notificationModalVisible: PropTypes.bool,
  fixed: PropTypes.bool,
};

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
  user: state.userData.data,
  activeFeed: state.newsfeed.activeFeed,
});

const NewsfeedView = ({
  newsfeed,
  getNewsfeedNextPage,
  nextPageIndex,
  isLoading,
  translate,
  user,
  activeFeed,
  fixed,
  notificationModalVisible,
  history,
  toggleGuestModal,
}) => {
  const {
    location: { pathname },
  } = history;

  const postPath = pathname.includes('post/');

  const togglerClasses = classNames({
    'newsfeed-view__toggle-feeds': true,
    'newsfeed-view__fixed-toggler': fixed,
    'newsfeed-view__hidden-toggler': postPath,
  });

  const createPostView = classNames({
    'newsfeed-view__create-post': true,
    'newsfeed-view__create-post-hidden': postPath,
  });

  const width = window.innerWidth;

  const scrollClass = classNames({
    'hidden-scroll': width < 992 && notificationModalVisible,
  });

  return (
    <div>
      <div className={togglerClasses}>
        <FeedsToggler />
      </div>
      <div className="newsfeed-view">
        {activeFeed === 'news' && !postPath && (
          <div className="news-description">
            {translate('common.action.feeds.newsDescription')}
          </div>
        )}
        {((activeFeed === 'farm' ||
          (activeFeed === 'news' && user.can_create_news)) && (
          <div className={createPostView}>
            <CreatePost>
              {({
                toggleCreatePostModal,
                toggleSellArticleModal,
                createImagePost,
                createVideoPost,
                postMessage,
              }) => (
                <CreatePostView
                  toggleCreatePostModal={toggleCreatePostModal}
                  toggleSellArticleModal={toggleSellArticleModal}
                  createImagePost={createImagePost}
                  createVideoPost={createVideoPost}
                  postMessage={postMessage}
                  toggleGuestModal={toggleGuestModal}
                />
              )}
            </CreatePost>
          </div>
        )) ||
          ''}

        {isLoading && newsfeed.length === 0 && (
          <Loader
            message={translate('common.load', {
              object: translate('common.reaction.posts'),
            })}
          />
        )}
        <InfiniteScroll
          dataLength={newsfeed.length}
          next={nextPageIndex ? getNewsfeedNextPage : null}
          hasMore={!!nextPageIndex}
          scrollThreshold="400px"
          className={scrollClass}
          loader={
            nextPageIndex === 1 ? (
              <Loader
                message={translate('common.load', {
                  object: translate('common.reaction.posts'),
                })}
              />
            ) : (
              <Loader
                message={translate('common.loadMore', {
                  object: translate('common.reaction.posts'),
                })}
              />
            )
          }
          endMessage={newsfeed.length > 0 ? <NewsfeedNoMorePosts /> : null}
        >
          {newsfeed.map((post, i) => (
            <div className="newsfeed-view__post" key={post.id}>
              <Post index={i} post={post} newsfeed={newsfeed} />
            </div>
          ))}
        </InfiniteScroll>
      </div>
    </div>
  );
};

NewsfeedView.defaultProps = defaultProps;

NewsfeedView.propTypes = propTypes;

export default connect(mapStateToProps)(NewsfeedView);
