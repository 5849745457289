import axios from 'axios';

export const createPost = (text, attachment, feed, readMoreUrl) =>
  axios.post(`${process.env.REACT_APP_API_URL}/posts`, {
    text: text || '',
    attachment: attachment || '',
    type: feed === 'news' ? feed : '',
    read_more_url: readMoreUrl || '',
  });

export const updatePost = (
  id,
  text,
  delete_previous_attachment = false,
  attachment,
) =>
  axios.put(`${process.env.REACT_APP_API_URL}/posts/${id}`, {
    text: text || '',
    delete_previous_attachment,
    attachment: attachment || '',
  });

export const deletePost = id =>
  axios.delete(`${process.env.REACT_APP_API_URL}/posts/${id}`);

export const createFarmPost = (text, attachment, id) =>
  axios.post(`${process.env.REACT_APP_API_URL}/farms/${id}/posts`, {
    text: text || '',
    attachment: attachment || '',
  });

export const createArticlePost = data =>
  axios.post(`${process.env.REACT_APP_API_URL}/articles`, {
    ...data,
  });

export const updateArticlePost = (id, data) =>
  axios.put(`${process.env.REACT_APP_API_URL}/articles/${id}`, {
    ...data,
  });

export const markArticleAsSold = id =>
  axios.put(`${process.env.REACT_APP_API_URL}/articles/${id}/sold`);

export const sendMessageToSeller = (id, text) =>
  axios.post(`${process.env.REACT_APP_API_URL}/articles/${id}/contact`, {
    text,
  });
