import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from 'common/components/button';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import './index.scss';

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
  feed: state.newsfeed.activeFeed,
});

const defaultProps = {
  toggleGuestModal: () => null,
  translate: () => null,
  userData: {},
};

const propTypes = {
  toggleCreatePostModal: PropTypes.func.isRequired,
  createImagePost: PropTypes.func.isRequired,
  toggleGuestModal: PropTypes.func,
  translate: PropTypes.func,
  createVideoPost: PropTypes.func.isRequired,
  toggleSellArticleModal: PropTypes.func.isRequired,
  isSellArticleButtonVisible: PropTypes.bool.isRequired,
  feed: PropTypes.string.isRequired,
  userData: PropTypes.object,
};

const CreatePostButtons = ({
  createImagePost,
  createVideoPost,
  toggleCreatePostModal,
  toggleGuestModal,
  toggleSellArticleModal,
  isSellArticleButtonVisible,
  translate,
  userData,
  feed,
}) => (
  <Fragment>
    <Button
      className="create-post-button"
      onClick={() =>
        userData.id ? toggleCreatePostModal() : toggleGuestModal()
      }
    >
      <div className="create-post-button__content create-post-button__content--text">
        <span className="create-post-button__text--mobile">
          <Translate id="common.action.mobile.postText" />
        </span>
        <span className="create-post-button__text--desktop">
          <Translate id="common.action.postText" />
        </span>
      </div>
    </Button>
    <div className="create-post-button">
      <label htmlFor="image-input">
        <div className="create-post-button__input-button">
          <div className="create-post-button__content create-post-button__content--image">
            <span className="create-post-button__text--mobile">
              <Translate id="common.action.mobile.postImage" />
            </span>
            <span className="create-post-button__text--desktop">
              <Translate id="common.action.postImage" />
            </span>
          </div>
        </div>
        <input
          name="image-input"
          id="image-input"
          className="create-post-button__input"
          type="file"
          accept=".jpeg, .JPEG, .JPG, .jpg, .png, .PNG, .gif, .GIF"
          onClick={e => {
            if (!userData.id) {
              e.preventDefault();
              toggleGuestModal();
            }
          }}
          onChange={e => createImagePost(e)}
        />
      </label>
    </div>
    <div className="create-post-button">
      <label htmlFor="video-input">
        <div className="create-post-button__input-button">
          <div className="create-post-button__content create-post-button__content--video">
            <span className="create-post-button__text--mobile">
              <Translate id="common.action.mobile.postVideo" />
            </span>
            <span className="create-post-button__text--desktop">
              <Translate id="common.action.postVideo" />
            </span>
          </div>
        </div>
        <input
          name="video-input"
          id="video-input"
          className="create-post-button__input"
          type="file"
          accept=".mp4, .avi, .wmv, .mov"
          onClick={e => {
            if (!userData.id) {
              e.preventDefault();
              toggleGuestModal();
            }
          }}
          onChange={e => createVideoPost(e)}
        />
      </label>
    </div>
    {isSellArticleButtonVisible && feed === 'farm' && (
      <Button
        className="create-post-button"
        onClick={() =>
          userData.id ? toggleSellArticleModal() : toggleGuestModal()
        }
      >
        <div className="create-post-button__content create-post-button__content--article">
          <span className="create-post-button__text--mobile">
            {translate('common.reaction.article')}
          </span>
          <span className="create-post-button__text--desktop">
            {translate('common.sellObject', {
              object: translate('common.reaction.article'),
            })}
          </span>
        </div>
      </Button>
    )}
  </Fragment>
);

CreatePostButtons.propTypes = propTypes;
CreatePostButtons.defaultProps = defaultProps;

export default connect(mapStateToProps)(CreatePostButtons);
