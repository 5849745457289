import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import TextareaAutosize from 'react-autosize-textarea';
import UserAvatar from 'common/components/userAvatar';
import CreatePostButtons from 'common/components/createPostButtons';
import MainButton from 'common/components/mainButton';
import Button from 'common/components/button';
import CloseButton from 'common/components/closeButton';
import Loader from 'common/components/loader';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './index.scss';

const mapStateToProps = state => ({
  userData: state.userData.data,
  farmData: state.farm.data,
  translate: getTranslate(state.localize),
  feed: state.newsfeed.activeFeed,
});

const defaultProps = {
  imageUrl: '',
  imageToken: '',
  videoDuration: null,
  videoToken: '',
  uploadError: '',
  previewUrl: '',
  farmId: null,
  userData: {},
  farmData: {},
  translate: () => null,
  feed: '',
  handleChange: () => null,
  readMoreUrl: '',
  uploadProgress: 0,
};

const propTypes = {
  createPost: PropTypes.func.isRequired,
  postMessage: PropTypes.string.isRequired,
  toggleCreatePostModal: PropTypes.func.isRequired,
  updatePostMessage: PropTypes.func.isRequired,
  createImagePost: PropTypes.func.isRequired,
  createVideoPost: PropTypes.func.isRequired,
  removeAttachment: PropTypes.func.isRequired,
  imageUrl: PropTypes.string,
  imageToken: PropTypes.string,
  videoDuration: PropTypes.number,
  videoToken: PropTypes.string,
  isVideoUploadInProgress: PropTypes.bool.isRequired,
  uploadError: PropTypes.string,
  previewUrl: PropTypes.string,
  toggleSellArticleModal: PropTypes.func.isRequired,
  farmId: PropTypes.number,
  userData: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
  ),
  farmData: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.array,
      PropTypes.object,
    ]),
  ),
  translate: PropTypes.func,
  feed: PropTypes.string,
  handleChange: PropTypes.func,
  readMoreUrl: PropTypes.string,
  uploadProgress: PropTypes.number,
};

const CreatePostModal = ({
  createPost,
  postMessage,
  toggleCreatePostModal,
  updatePostMessage,
  createImagePost,
  createVideoPost,
  imageUrl,
  removeAttachment,
  imageToken,
  uploadError,
  isVideoUploadInProgress,
  videoToken,
  videoDuration,
  previewUrl,
  toggleSellArticleModal,
  userData,
  farmData,
  farmId,
  translate,
  feed,
  handleChange,
  readMoreUrl,
  uploadProgress,
}) => {
  const textContainerClasses = classNames({
    'create-post-modal__text-container': true,
    'create-post-modal__text-container--small':
      (imageUrl && imageToken) || videoToken,
  });
  const headerCreateClasses = classNames({
    'create-post-modal__header-create': true,
    'create-post-modal__header-create--image': imageUrl && imageToken,
    'create-post-modal__header-create--video': videoToken,
  });
  const mobileTextareaClasses = classNames({
    'create-post-modal__textarea': true,
    'create-post-modal__textarea--small':
      (imageUrl && imageToken) || videoToken,
  });
  const videoContainerClasses = classNames({
    'create-post-modal__video-container': true,
    'create-post-modal__video-container--fixed': !previewUrl,
  });
  return (
    <div className="create-post-modal">
      <div
        className="create-post-modal__outer-container"
        role="button"
        tabIndex="0"
        onClick={toggleCreatePostModal}
        onKeyPress={toggleCreatePostModal}
      />
      <div className="create-post-modal__container">
        <div className="create-post-modal__header create-post-modal__header--mobile">
          <span className={headerCreateClasses}>
            {imageUrl && imageToken && translate('common.action.postImage')}
            {videoToken && translate('common.action.postVideo')}
            {!imageUrl &&
              !imageToken &&
              !videoToken &&
              translate('common.action.postText')}
          </span>
          <CloseButton onClick={toggleCreatePostModal} />
        </div>
        <div className="create-post-modal__header create-post-modal__header--desktop">
          <CreatePostButtons
            toggleCreatePostModal={toggleCreatePostModal}
            createImagePost={createImagePost}
            createVideoPost={createVideoPost}
            toggleSellArticleModal={() => {
              toggleCreatePostModal();
              setTimeout(() => toggleSellArticleModal());
            }}
            userData={userData}
            isSellArticleButtonVisible={!farmId}
          />
          <CloseButton onClick={toggleCreatePostModal} />
        </div>
        <div className="create-post-modal__scroll-container">
          <div className={textContainerClasses}>
            <div className="create-post-modal__avatar">
              {farmId ? (
                <UserAvatar
                  image={farmData.profile_image && farmData.profile_image.url}
                  isFarmProfile
                />
              ) : (
                <UserAvatar image={userData.avatar} />
              )}
            </div>
            <div className="create-post-modal__textarea-container create-post-modal__textarea-container--desktop">
              <TextareaAutosize
                className="create-post-modal__textarea"
                placeholder={translate('common.createPostPlaceholder')}
                autoFocus
                onChange={updatePostMessage}
                value={postMessage}
                rows={(imageUrl && imageToken) || videoToken ? 1 : 6}
                maxRows={(imageUrl && imageToken) || videoToken ? 5 : 6}
              />
            </div>
            <div className="create-post-modal__textarea-container create-post-modal__textarea-container--mobile">
              <TextareaAutosize
                className={mobileTextareaClasses}
                placeholder={translate('common.createPostPlaceholder')}
                onChange={updatePostMessage}
                value={postMessage}
                rows={1}
                maxRows={(imageUrl && imageToken) || videoToken ? 5 : 350}
              />
            </div>
          </div>

          {uploadError && (
            <p className="create-post-modal__error">{uploadError}</p>
          )}

          {feed === 'news' && (
            <div className="news-remote-url">
              <input
                className="input__field"
                type="text"
                name="remote_url"
                value={readMoreUrl}
                onChange={handleChange}
                placeholder={translate('common.addNewsUrlPlaceholder')}
              />
            </div>
          )}

          {imageUrl && !imageToken && !uploadError && (
            <div className="circle-loader-wrapper">
              <CircularProgressbar
                value={uploadProgress}
                strokeWidth={5}
                styles={buildStyles({
                  pathTransitionDuration: 0.5,
                  pathColor: '#707070',
                  trailColor: '#E1E1E1',
                })}
              />
              <Loader
                message={translate('common.load', {
                  object: '',
                })}
              />
            </div>
          )}
          {isVideoUploadInProgress && !videoToken && (
            <div className="circle-loader-wrapper">
              <CircularProgressbar
                value={uploadProgress}
                strokeWidth={5}
                styles={buildStyles({
                  pathTransitionDuration: 0.5,
                  pathColor: '#707070',
                  trailColor: '#E1E1E1',
                })}
              />
              <Loader
                message={translate('common.load', {
                  object: '',
                })}
              />
            </div>
          )}
          {imageUrl && imageToken && (
            <div className="create-post-modal__image-container">
              <Button
                className="create-post-modal__remove-button"
                onClick={removeAttachment}
              />
              <img className="create-post-modal__image" src={imageUrl} alt="" />
            </div>
          )}
          {!isVideoUploadInProgress && videoToken && (
            <div className={videoContainerClasses}>
              <Button
                className="create-post-modal__remove-button"
                onClick={removeAttachment}
              />
              <img
                className="create-post-modal__preview"
                src={previewUrl}
                alt=""
              />
              {!Number.isNaN(videoDuration) && videoDuration && (
                <div className="create-post-modal__duration">
                  {moment.utc(videoDuration * 1000).format('HH:mm:ss')}
                </div>
              )}
            </div>
          )}
        </div>
        <div className="create-post-modal__publish-container create-post-modal__publish-container--desktop">
          <MainButton
            onClick={createPost}
            disabled={
              !postMessage.length && !imageUrl && !imageToken && !videoToken
            }
            large
            fullWidth
          >
            {translate('common.action.publish')}
          </MainButton>
        </div>
        <div className="create-post-modal__footer-container">
          <div className="create-post-modal__publish-container create-post-modal__publish-container--mobile">
            <MainButton
              onClick={createPost}
              disabled={
                !postMessage.length && !imageUrl && !imageToken && !videoToken
              }
              large
              fullWidth
            >
              {translate('common.action.publish')}
            </MainButton>
          </div>
          <div className="create-post-modal__footer">
            <CreatePostButtons
              toggleCreatePostModal={toggleCreatePostModal}
              toggleSellArticleModal={() => {
                toggleCreatePostModal();
                setTimeout(() => toggleSellArticleModal());
              }}
              createImagePost={createImagePost}
              createVideoPost={createVideoPost}
              isSellArticleButtonVisible={!farmId}
              userData={userData}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

CreatePostModal.defaultProps = defaultProps;

CreatePostModal.propTypes = propTypes;

export default connect(mapStateToProps)(CreatePostModal);
