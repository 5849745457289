import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Linkify from 'linkifyjs/react';
import renderHTML from 'react-render-html';
import Input from 'common/components/input/index';
import MainButton from 'common/components/mainButton';
import Button from 'common/components/button';
import ModalContainer from 'common/components/modalContainer';
import validationSchema from 'farm/validators/invitationsValidators';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import './index.scss';

const propTypes = {
  addEmail: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  invitations: PropTypes.arrayOf(PropTypes.object).isRequired,
  removeEmail: PropTypes.func.isRequired,
  submitInvitations: PropTypes.func.isRequired,
  emailError: PropTypes.string.isRequired,
  resetEmailError: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
});

const InviteModalView = ({
  addEmail,
  closeModal,
  invitations,
  submitInvitations,
  removeEmail,
  translate,
  emailError,
  resetEmailError,
}) => {
  const linkOption = {
    className: 'inpost-link',
    defaultProtocol: 'http',
    format(value) {
      return value.replace(/(?:http:\/\/|https:\/\/)/g, '');
    },
    formatHref(href) {
      return href;
    },
    target: {
      url: '_blank',
    },
  };

  return (
    <ModalContainer handleOutsideClick={closeModal}>
      <div className="invite-modal">
        <p className="invite-modal__heading">
          {translate('modal.inviteFriends.heading')}
        </p>
        <p className="invite-modal__sub-heading">
          {translate('modal.inviteFriends.message')}
        </p>
        <div
          id="invitations-scroll-container"
          className="invite-modal__scroll-container"
        >
          {invitations.map((item, index) => (
            <div key={Math.random()} className="invite-modal__invitation">
              <div>
                <p className="invite-modal__email">{item.email}</p>
                <div className="invite-modal__message">
                  {item.text && item.text.length > 0 && (
                    <Linkify options={linkOption}>
                      {renderHTML(
                        item.text &&
                          item.text.replace(/(?:\r\n|\r|\n)/g, '<br>'),
                      )}
                    </Linkify>
                  )}
                </div>
              </div>
              <Button
                onClick={() => removeEmail(index)}
                className="invite-modal__remove-button"
              />
            </div>
          ))}
          <Formik
            initialValues={{ email: '', text: '' }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) =>
              submitInvitations(values, { setSubmitting })
            }
            render={({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit} noValidate>
                <div className="invite-modal__row">
                  <Input
                    type="text"
                    value={values.email}
                    error={errors.email || emailError}
                    touched={touched.email}
                    name="email"
                    onChange={e => {
                      handleChange(e);
                      resetEmailError();
                    }}
                    onBlur={handleBlur}
                    placeholder={translate('form.common.eMail')}
                    fullWidth
                    autoFocus
                    withLabel
                  />
                </div>

                <div className="invite-modal__buttons">
                  <div
                    role="button"
                    className={
                      values.email
                        ? 'invite-modal__add-email-button'
                        : 'invite-modal__add-email-button invite-modal__add-email-button--disabled'
                    }
                    onClick={() => addEmail(values, errors)}
                    onKeyPress={() => addEmail(values, errors)}
                    tabIndex="0"
                  >
                    {translate('common.action.addAnotherEmail')}
                  </div>
                  <div className="invite-modal__form-buttons">
                    <MainButton onClick={closeModal} teritary>
                      {translate('common.action.cancel')}
                    </MainButton>
                    <MainButton
                      type="submit"
                      disabled={
                        isSubmitting ||
                        (!values.email && invitations.length === 0)
                      }
                    >
                      {translate('common.action.send')}
                    </MainButton>
                  </div>
                </div>
              </form>
            )}
          />
        </div>
      </div>
    </ModalContainer>
  );
};

InviteModalView.propTypes = propTypes;

export default connect(mapStateToProps)(InviteModalView);
