import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './index.scss';

const propTypes = {
  farm: PropTypes.object.isRequired,
};

const ActiveFarm = ({ farm }) => {
  const { id, name, profile_image: profileImage } = farm;

  return (
    <div className="active-farm-item">
      <div className="active-farm-item__wrapper">
        <Link to={`/farms/${id}`}>
          <div className="active-farm-item__image-wrapper">
            <div
              className="active-farm-item__image"
              style={
                profileImage && profileImage.url
                  ? {
                      backgroundImage: `url(${
                        profileImage.imageUrl
                      }?w=220&h=220&fit=crop)`,
                      backgroundSize: 'cover',
                    }
                  : {}
              }
            />
          </div>
        </Link>
        <div className="active-farm-item__details">
          <Link to={`/farms/${id}`} className="active-farm-item__name">
            {name}
          </Link>
        </div>
      </div>
    </div>
  );
};

ActiveFarm.propTypes = propTypes;

export default ActiveFarm;
