import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import PlacesAutocomplete from 'react-places-autocomplete';
import Input from 'common/components/input/index';
import Textarea from 'common/components/textarea';
import Select from 'common/components/select';
import ModalContainer from 'common/components/modalContainer';
import MainButton from 'common/components/mainButton';
import Button from 'common/components/button';
import DeleteButton from 'common/components/deleteButton';
import FarmMembers from 'farm/components/farmMembers';
import InviteModal from 'common/containers/inviteModal';
import Loader from 'common/components/loader';
import { farmValidationSchema as validationSchema } from 'farm/validators/farmValidators';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './index.scss';

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
});

const defaultProps = {
  profileImageUrl: '',
  titleImageUrl: '',
  profileImageToken: '',
  titleImageToken: '',
  profileImageError: '',
  titleImageError: '',
  toggleMembersListModal: () => null,
  toggleMembershipRequestsModal: () => null,
  farmId: null,
  uploadProgress: 0,
  translate: () => null,
  handlePlacesSelect: () => null,
  handlePlacesChange: () => null,
  blob: false,
  places: false,
};

const propTypes = {
  closeModal: PropTypes.func.isRequired,
  submitFarmForm: PropTypes.func.isRequired,
  addFarmImage: PropTypes.func.isRequired,
  profileImageUrl: PropTypes.string,
  titleImageUrl: PropTypes.string,
  profileImageError: PropTypes.string,
  titleImageError: PropTypes.string,
  profileImageToken: PropTypes.string,
  titleImageToken: PropTypes.string,
  initialValues: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ).isRequired,
  submitButtonText: PropTypes.string.isRequired,
  farmMembers: PropTypes.arrayOf(PropTypes.object).isRequired,
  membersResultsNumber: PropTypes.number.isRequired,
  toggleDeleteFarmModal: PropTypes.func.isRequired,
  toggleMembersListModal: PropTypes.func,
  toggleMembershipRequestsModal: PropTypes.func,
  deleteFarmPicture: PropTypes.func.isRequired,
  profileImageDelete: PropTypes.bool.isRequired,
  titleImageDelete: PropTypes.bool.isRequired,
  farmId: PropTypes.number,
  farmCategories: PropTypes.arrayOf(PropTypes.object).isRequired,
  profileImageLoading: PropTypes.bool.isRequired,
  titleImageLoading: PropTypes.bool.isRequired,
  uploadProgress: PropTypes.number,
  translate: PropTypes.func,
  handlePlacesSelect: PropTypes.func,
  handlePlacesChange: PropTypes.func,
  blob: PropTypes.bool,
  places: PropTypes.bool,
};

const CreateFarmModalView = ({
  addFarmImage,
  closeModal,
  submitFarmForm,
  profileImageUrl,
  titleImageUrl,
  profileImageError,
  titleImageError,
  profileImageToken,
  titleImageToken,
  initialValues,
  submitButtonText,
  farmMembers,
  membersResultsNumber,
  toggleDeleteFarmModal,
  toggleMembersListModal,
  toggleMembershipRequestsModal,
  deleteFarmPicture,
  profileImageDelete,
  titleImageDelete,
  translate,
  farmId,
  farmCategories,
  profileImageLoading,
  titleImageLoading,
  uploadProgress,
  blob,
  handlePlacesChange,
  handlePlacesSelect,
  places,
}) => (
  <ModalContainer handleOutsideClick={closeModal}>
    <div className="create-farm-modal">
      <div className="create-farm-modal__scroll-container">
        <div className="create-farm-modal__farm-data">
          <p className="create-farm-modal__heading create-farm-modal__heading--mobile">
            {translate('page.farmProfile.createFarm.farmData')}
          </p>
          <div className="create-farm-modal__labels">
            <div className="create-farm-modal__label-container">
              <label
                htmlFor="profile-image-input"
                className="create-farm-modal__label"
              >
                <div className="create-farm-modal__profile-image-container">
                  {profileImageLoading ? (
                    <div className="circle-loader-wrapper small-circle">
                      <CircularProgressbar
                        value={uploadProgress}
                        strokeWidth={5}
                        styles={buildStyles({
                          pathTransitionDuration: 0.5,
                          pathColor: '#707070',
                          trailColor: '#E1E1E1',
                        })}
                      />
                      <Loader message="" />
                    </div>
                  ) : (
                    <div
                      className="create-farm-modal__profile-image"
                      style={
                        profileImageUrl
                          ? {
                              backgroundImage: `url(${profileImageUrl}${
                                !blob ? '?w=220&h=220&fit=crop' : ''
                              })`,
                              backgroundSize: 'cover',
                            }
                          : {}
                      }
                    />
                  )}
                </div>
                <input
                  name="profile-image-input"
                  id="profile-image-input"
                  className="create-farm-modal__input"
                  type="file"
                  accept=".jpeg, .JPEG, .JPG, .jpg, .png, .PNG, .gif, .GIF"
                  onChange={e => addFarmImage(e, 'profile')}
                />
                <div className="create-farm-modal__label-inner-container">
                  <span className="create-farm-modal__label-text">
                    {profileImageUrl
                      ? translate('common.changeObject', {
                          object: translate('common.reaction.profileImage'),
                        })
                      : translate('common.addObject', {
                          object: translate('common.reaction.profileImage'),
                        })}
                  </span>
                  {profileImageUrl && (
                    <div className="create-farm-modal__delete-picture-button">
                      <DeleteButton
                        onClick={e => {
                          deleteFarmPicture('profile');
                          e.preventDefault();
                        }}
                        text={translate('common.deleteObject', {
                          object: translate('common.reaction.image'),
                        })}
                      />
                    </div>
                  )}
                </div>
              </label>
              {profileImageError && (
                <p className="create-farm-modal__error">{profileImageError}</p>
              )}
            </div>
            <div className="create-farm-modal__label-container create-farm-modal__label-container--mobile">
              <label
                htmlFor="title-image-input"
                className="create-farm-modal__label"
              >
                {titleImageLoading ? (
                  <div className="circle-loader-wrapper small-circle">
                    <CircularProgressbar
                      value={uploadProgress}
                      strokeWidth={5}
                      styles={buildStyles({
                        pathTransitionDuration: 0.5,
                        pathColor: '#707070',
                        trailColor: '#E1E1E1',
                      })}
                    />
                    <Loader message="" />
                  </div>
                ) : (
                  <div
                    className="create-farm-modal__title-image"
                    style={
                      titleImageUrl
                        ? {
                            backgroundImage: `url(${titleImageUrl}${
                              !blob ? '?w=220' : ''
                            })`,
                            backgroundSize: 'cover',
                          }
                        : {}
                    }
                  />
                )}
                <input
                  name="title-image-input"
                  id="title-image-input"
                  className="create-farm-modal__input"
                  type="file"
                  accept=".jpeg, .JPEG, .JPG, .jpg, .png, .PNG, .gif, .GIF"
                  onChange={e => addFarmImage(e, 'title')}
                />
                <div className="create-farm-modal__label-inner-container">
                  <span className="create-farm-modal__label-text">
                    {titleImageUrl
                      ? translate('common.changeObject', {
                          object: translate('common.reaction.titleImage'),
                        })
                      : translate('common.addObject', {
                          object: translate('common.reaction.titleImage'),
                        })}
                  </span>
                  {titleImageUrl && (
                    <div className="create-farm-modal__delete-picture-button">
                      <DeleteButton
                        onClick={e => {
                          deleteFarmPicture('title');
                          e.preventDefault();
                        }}
                        text={translate('common.deleteObject', {
                          object: translate('common.reaction.image'),
                        })}
                      />
                    </div>
                  )}
                </div>
              </label>
              {titleImageError && (
                <p className="create-farm-modal__error">{titleImageError}</p>
              )}
            </div>
            <div className="create-farm-modal__label-container create-farm-modal__label-container--desktop">
              <label
                htmlFor="title-image-input"
                className="create-farm-modal__label"
              >
                {titleImageLoading ? (
                  <div className="circle-loader-wrapper small-circle">
                    <CircularProgressbar
                      value={uploadProgress}
                      strokeWidth={5}
                      styles={buildStyles({
                        pathTransitionDuration: 0.5,
                        pathColor: '#707070',
                        trailColor: '#E1E1E1',
                      })}
                    />
                    <Loader message="" />
                  </div>
                ) : (
                  <div
                    className="create-farm-modal__title-image"
                    style={
                      titleImageUrl
                        ? {
                            backgroundImage: `url(${titleImageUrl}${
                              !blob ? '?w=220' : ''
                            })`,
                            backgroundSize: 'cover',
                          }
                        : {}
                    }
                  />
                )}

                <input
                  name="title-image-input"
                  id="title-image-input"
                  className="create-farm-modal__input"
                  type="file"
                  accept=".jpeg, .JPEG, .JPG, .jpg, .png, .PNG, .gif, .GIF"
                  onChange={e => addFarmImage(e, 'title')}
                />
                <div className="create-farm-modal__label-inner-container">
                  <span className="create-farm-modal__label-text">
                    {titleImageUrl
                      ? translate('common.changeObject', {
                          object: translate('common.reaction.titleImage'),
                        })
                      : translate('common.addObject', {
                          object: translate('common.reaction.titleImage'),
                        })}
                  </span>
                  {titleImageUrl && (
                    <div className="create-farm-modal__delete-picture-button">
                      <DeleteButton
                        onClick={e => {
                          deleteFarmPicture('title');
                          e.preventDefault();
                        }}
                        text={translate('common.deleteObject', {
                          object: translate('common.reaction.image'),
                        })}
                      />
                    </div>
                  )}
                </div>
              </label>
              {titleImageError && (
                <p className="create-farm-modal__error">{titleImageError}</p>
              )}
            </div>
          </div>

          <div className="farm-members-wrapper-mobile">
            <div className="create-farm-modal__farm-members--mobile">
              {membersResultsNumber > 0 && (
                <Fragment>
                  <Button
                    className="create-farm-modal__heading create-farm-modal__heading--members"
                    onClick={toggleMembersListModal}
                  >
                    {translate('common.reaction.farmMembers')}
                  </Button>
                  <FarmMembers
                    membersCount={membersResultsNumber}
                    farmMembers={farmMembers}
                    toggleMembersListModal={toggleMembersListModal}
                    isAdmin
                    toggleMembershipRequestsModal={
                      toggleMembershipRequestsModal
                    }
                  />
                </Fragment>
              )}
            </div>
          </div>

          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={(values, { setErrors, setSubmitting }) =>
              submitFarmForm(values, { setErrors, setSubmitting })
            }
            render={({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form
                onSubmit={handleSubmit}
                noValidate
                className="create-farm-modal__form"
              >
                <p className="create-farm-modal__heading create-farm-modal__heading--desktop">
                  {translate('page.farmProfile.createFarm.farmData')}
                </p>

                <div className="create-farm-modal__inputs">
                  <div className="create-farm-modal__row">
                    <Input
                      type="text"
                      value={values.name}
                      error={errors.name}
                      touched={touched.name}
                      name="name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={translate(
                          'form.farmProfile.createFarm.farmName',
                        )}
                      fullWidth
                      withLabel
                    />
                  </div>

                  <div className="create-farm-modal__row create-farm-modal__row--inline">
                    <p className="create-farm-modal__form-label">
                      {translate('page.farmProfile.createFarm.farmSize')}
                      {' (ha)'}
                    </p>
                    <Input
                      type="text"
                      value={values.farmArea}
                      error={errors.farmArea}
                      touched={touched.farmArea}
                      name="farmArea"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="100 ha"
                      fullWidth
                    />
                  </div>
                  <div className="create-farm-modal__row create-farm-modal__row--inline">
                    <p className="create-farm-modal__form-label">
                      {translate('common.reaction.category')}
                    </p>
                    <Select
                      value={values.farmCategoryId}
                      error={errors.farmCategoryId}
                      touched={touched.farmCategoryId}
                      name="farmCategoryId"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={translate('farm.categories.noInfo')}
                      fullWidth
                    >
                      {farmCategories.map(category => (
                        <option key={category.id} value={category.id}>
                          {translate(`farm.categories.${category.name}`)}
                        </option>
                        ))}
                    </Select>
                  </div>
                  <div className="create-farm-modal__row">
                    <Textarea
                      type="text"
                      value={values.description}
                      error={errors.description}
                      touched={touched.description}
                      name="description"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={translate('form.farmProfile.description')}
                      fullWidth
                      withLabel={false}
                      textLength={
                          values.description ? values.description.length : 0
                        }
                    />
                  </div>

                  <div className="create-farm-modal__row">
                    <PlacesAutocomplete
                      value={values.address}
                      onChange={handlePlacesChange}
                      onSelect={handlePlacesSelect}
                    >
                      {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                        }) => (
                          <div className="autocomplete-wrapper">
                            <label
                              className="input__label"
                              htmlFor="address"
                              id="address"
                            >
                              {translate('form.common.adress')}
                            </label>
                            <input
                              {...getInputProps({
                                name: 'address',
                                placeholder: translate('form.common.adress'),
                                className:
                                  'input__field input__field--full-width location-search-input',
                              })}
                              required
                            />
                            <div className="autocomplete-dropdown-container">
                              {suggestions.map(suggestion => {
                                const className = suggestion.active
                                  ? 'suggestion-item active'
                                  : 'suggestion-item';

                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                    })}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                    </PlacesAutocomplete>
                    {touched.address && errors.address && (
                    <div className="input__error-message-location">
                      {translate(errors.address)}
                    </div>
                      )}
                    {touched.location && errors.location && (
                    <div className="input__error-message-location">
                      {translate(errors.location)}
                    </div>
                      )}
                  </div>
                  <div className="create-farm-modal__row">
                    <Input
                      type="text"
                      value={values.city}
                      error={errors.city}
                      touched={touched.city}
                      name="city"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={translate('form.common.city')}
                      fullWidth
                      withLabel
                    />
                  </div>
                  <div className="create-farm-modal__row create-farm-modal__row--inline">
                    <p className="create-farm-modal__form-label">
                      {translate('form.common.postCode')}
                    </p>
                    <Input
                      type="text"
                      value={values.postCode}
                      error={errors.postCode}
                      touched={touched.postCode}
                      name="postCode"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="_ _ _ _"
                      fullWidth
                      maxLength={10}
                    />
                  </div>
                </div>

                <div className="create-farm-modal__buttons">
                  <MainButton
                    onClick={closeModal}
                    teritary
                    disabled={isSubmitting}
                  >
                    {translate('common.action.cancel')}
                  </MainButton>
                  <div className="create-farm-modal__submit-button-container">
                    <MainButton
                      type="submit"
                      disabled={
                          !!(
                            initialValues.name &&
                            (initialValues.name === values.name &&
                              initialValues.farmArea === values.farmArea &&
                              initialValues.farmCategoryId ===
                                values.farmCategoryId &&
                              initialValues.description ===
                                values.description &&
                              !titleImageToken &&
                              !profileImageToken &&
                              !titleImageDelete &&
                              !profileImageDelete)
                          ) && !places
                        }
                    >
                      {submitButtonText}
                    </MainButton>
                    {errors.submit && (
                    <span className="create-farm-modal__error create-farm-modal__error--submit">
                      {errors.submit}
                    </span>
                      )}
                  </div>
                </div>
              </form>
              )}
          />
        </div>

        <div className="create-farm-modal__farm-members--desktop">
          {membersResultsNumber > 0 && (
            <Fragment>
              <Button
                className="create-farm-modal__heading create-farm-modal__heading--members"
                onClick={toggleMembersListModal}
              >
                {translate('common.reaction.farmMembers')}
              </Button>
              <FarmMembers
                membersCount={membersResultsNumber}
                farmMembers={farmMembers}
                toggleMembersListModal={toggleMembersListModal}
                isAdmin
                toggleMembershipRequestsModal={toggleMembershipRequestsModal}
              />
              <div className="create-farm-modal__invite">
                <InviteModal farmId={farmId}>
                  {({ handleInviteButtonClick }) => (
                    <MainButton onClick={handleInviteButtonClick}>
                      {translate('common.action.inviteFriends')}
                    </MainButton>
                  )}
                </InviteModal>
              </div>
            </Fragment>
          )}
          {initialValues.name && (
            <div>
              <p className="create-farm-modal__heading create-farm-modal__heading--members">
                {translate('common.deleteObject', {
                  object: translate('common.profile'),
                })}
              </p>
              <DeleteButton
                onClick={toggleDeleteFarmModal}
                text={translate('common.deleteObject', {
                  object: translate('common.farmProfile'),
                })}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  </ModalContainer>
);

CreateFarmModalView.defaultProps = defaultProps;

CreateFarmModalView.propTypes = propTypes;

export default connect(mapStateToProps)(CreateFarmModalView);
