import * as Yup from 'yup';
import { equalTo, eMailRegex } from 'common/utils/validationUtils';
import translateMessage from 'common/utils/translateMessage.js';
import localizeYup from 'common/utils/localizeYupDefaults.js';

localizeYup();

Yup.addMethod(Yup.string, 'equalTo', equalTo);

const registrationValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2)
    .max(64)
    .matches(/^([a-zA-ZÀ-ÖØ-öø-ÿ0-9_-\s]*)$/, {
      message: translateMessage('form.error.invalidFormat'),
      excludeEmptyString: true,
    })
    .required(),
  lastName: Yup.string()
    .min(2)
    .max(64)
    .matches(/^([a-zA-ZÀ-ÖØ-öø-ÿ0-9_-\s]*)$/, {
      message: translateMessage('form.error.invalidFormat'),
      excludeEmptyString: true,
    })
    .required(),
  email: Yup.string()
    .matches(eMailRegex, {
      message: translateMessage('form.error.invalidEmail'),
      excludeEmptyString: true,
    })
    .required(),
  password: Yup.string()
    // .matches(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/, {
    .matches(/\S{8,}/, {
      message: translateMessage('form.error.passwordFormat'),
      excludeEmptyString: true,
    })
    .required(),
  passwordConfirmation: Yup.string()
    .equalTo(
      Yup.ref('password'),
      translateMessage('form.error.passwordMissmatch'),
    )
    .required(),
  birthdateDay: Yup.string()
    .required()
    // .matches(/^(3[01]|[12][0-9]|[1-9]|0[1-9])$/, {
    .matches(/^(3[01]|[12][0-9]|[1-9]|0[1-9])$/, {
      message: translateMessage('form.error.invalidFormat'),
      excludeEmptyString: true,
    })
    .min(1)
    .max(2),
  birthdateMonth: Yup.string()
    .required()
    .matches(/^([1-9]|0[1-9]|1[0-2]?)$/, {
      message: translateMessage('form.error.invalidFormat'),
      excludeEmptyString: true,
    })
    .min(1)
    .max(2),
  birthdateYear: Yup.string()
    .required()
    // 1900-2099
    .matches(/^(19|20)\d{2}$/, {
      message: translateMessage('form.error.invalidFormat'),
      excludeEmptyString: true,
    })
    .min(4)
    .max(4),
  postCode: Yup.string()
    .required()
    // 1000 - 9999
    .matches(/^[1-9][0-9]{3,10}$/, {
      message: translateMessage('form.error.invalidFormat'),
      excludeEmptyString: true,
    })
    .min(4)
    .max(10),
  gdpr: Yup.boolean()
    .test(
      'consent',
      translateMessage('form.yup.default.required'),
      value => value === true,
    )
    .required(),
  terms: Yup.boolean()
    .test(
      'consent',
      translateMessage('form.yup.default.required'),
      value => value === true,
    )
    .required(),
});

export default registrationValidationSchema;
