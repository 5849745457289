import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Input from 'common/components/input/index';
import Button from 'common/components/button';
import MainButton from 'common/components/mainButton';
import resetPasswordValidationSchema from 'userAuth/validators/resetPasswordValidators';
import { Translate, getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import './index.scss';

const propTypes = {
  submitResetPasswordForm: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
});

const ResetPasswordView = ({ submitResetPasswordForm, cancel, translate }) => (
  <div className="password-reset">
    <div className="password-reset__wrapper">
      <h1 className="password-reset__heading">
        <Translate id="page.resetPasswordForm.heading" />
      </h1>
      <h2 className="password-reset__sub-heading">
        <Translate id="page.resetPasswordForm.subHeading" />
      </h2>
      <Formik
        initialValues={{
          password: '',
          passwordConfirmation: '',
        }}
        validationSchema={resetPasswordValidationSchema}
        onSubmit={(values, { setErrors, setSubmitting }) =>
          submitResetPasswordForm(values, {
            setErrors,
            setSubmitting,
          })
        }
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form
            onSubmit={handleSubmit}
            noValidate
            className="password-reset__form"
          >
            <div className="password-reset__row password-reset__row">
              <div className="password-reset__input password-reset__input--full-width">
                <Input
                  value={values.password}
                  error={errors.password}
                  touched={touched.password}
                  name="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder={translate('form.authentication.password')}
                  withLabel
                  fullWidth
                />
              </div>
            </div>
            <div className="password-reset__row">
              <div className="password-reset__input password-reset__input--full-width">
                <Input
                  value={values.passwordConfirmation}
                  error={errors.passwordConfirmation}
                  touched={touched.passwordConfirmation}
                  name="passwordConfirmation"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder={translate('form.authentication.passwordRepeat')}
                  withLabel
                  fullWidth
                />
              </div>
            </div>
            <div className="password-reset__row password-reset__row--buttons">
              <Button
                onClick={cancel}
                className="password-reset__cancel-button"
              >
                <Translate id="common.action.cancel" />
              </Button>
              <MainButton
                type="submit"
                disabled={isSubmitting}
                className="password-reset__save-button"
              >
                <Translate id="common.action.save" />
              </MainButton>
            </div>
            <div className="password-reset__row">
              {errors.submit && (
                <p className="password-reset__error--submit">{errors.submit}</p>
              )}
            </div>
            <div className="password-reset__row">
              {errors.token && (
                <p className="password-reset__error--submit">{errors.token}</p>
              )}
            </div>
          </form>
        )}
      />
    </div>
  </div>
);

ResetPasswordView.propTypes = propTypes;

export default connect(mapStateToProps)(ResetPasswordView);
