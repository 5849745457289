import React from 'react';
import { Link } from 'react-router-dom';
import { Translate } from 'react-localize-redux';
import PropTypes from 'prop-types';
import './index.scss';

const propTypes = {
  farm: PropTypes.object.isRequired,
};

const FarmItem = ({ farm }) => {
  const {
    id,
    name,
    profile_image: profileImage,
    members_count: membersCount,
    post_code: postCode,
    city,
  } = farm;
  const singularOrPlural =
    membersCount === 1 ? (
      <Translate id="common.reaction.member" />
    ) : (
      <Translate id="common.reaction.members" />
    );
  return (
    <div className="farm-item">
      <div className="farm-item__wrapper">
        <Link to={`/farms/${id}`}>
          <div
            className="farm-item__image"
            style={
              profileImage && profileImage.url
                ? {
                    backgroundImage: `url(${profileImage.url})`,
                    backgroundSize: 'cover',
                  }
                : {}
            }
          />
        </Link>
        <div className="farm-item__details">
          <Link to={`/farms/${id}`} className="farm-item__name">
            {name}
          </Link>
          <p className="farm-item__members">
            {membersCount} 
            {' '}
            {singularOrPlural}
          </p>
          <p className="farm-item__adress">{`${postCode} ${city}`}</p>
        </div>
      </div>
    </div>
  );
};

FarmItem.propTypes = propTypes;

export default FarmItem;
