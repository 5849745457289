import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

const defaultProps = {
  message: 'You reached the end',
};

const propTypes = {
  message: PropTypes.string,
};

const EndMessage = ({ message }) => (
  <div className="end-message">
    <p className="end-message__text">{message}</p>
  </div>
);

EndMessage.propTypes = propTypes;

EndMessage.defaultProps = defaultProps;

export default EndMessage;
