import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './index.scss';

const modal = document.getElementById('modal');

const defaultProps = {
  notFullSize: false,
  fullWidth: false,
};

const propTypes = {
  children: PropTypes.node.isRequired,
  fullWidth: PropTypes.bool,
  handleOutsideClick: PropTypes.func.isRequired,
  notFullSize: PropTypes.bool,
};

const Modal = ({ children, fullWidth, handleOutsideClick, notFullSize }) => {
  const modalContainerClasses = classNames({
    'modal-container__content': true,
    'modal-container__content--auto': notFullSize,
    'modal-container__content--full-width': fullWidth,
  });
  return (
    <div
      className="modal-container"
      role="button"
      onClick={handleOutsideClick}
      onKeyPress={handleOutsideClick}
      tabIndex="0"
    >
      <div
        className={modalContainerClasses}
        onClick={e => {
          e.stopPropagation();
        }}
        role="button"
        onKeyPress={e => {
          e.stopPropagation();
        }}
        tabIndex="0"
      >
        {children}
      </div>
    </div>
  );
};

const ModalContainer = props =>
  ReactDOM.createPortal(<Modal {...props} />, modal);

ModalContainer.defaultProps = defaultProps;

ModalContainer.propTypes = propTypes;

export default ModalContainer;
