import React from 'react';
import PropTypes from 'prop-types';
import ModalContainer from 'common/components/modalContainer';
import MainButton from 'common/components/mainButton';
import Loader from 'common/components/loader';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './index.scss';

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
});

const defaultProps = {
  image: '',
  titleImageError: '',
  titleImageToken: '',
  uploadProgress: 0,
};

const propTypes = {
  closeModal: PropTypes.func.isRequired,
  image: PropTypes.string,
  uploadTitleImage: PropTypes.func.isRequired,
  isTitleImageLoading: PropTypes.bool.isRequired,
  titleImageError: PropTypes.string,
  titleImageToken: PropTypes.string,
  uploadProgress: PropTypes.number,
};

const TitleImageModal = ({
  closeModal,
  image,
  uploadTitleImage,
  isTitleImageLoading,
  titleImageError,
  titleImageToken,
  translate,
  uploadProgress,
}) => (
  <ModalContainer handleOutsideClick={closeModal} notFullSize>
    <div className="title-image-modal">
      <div className="title-image-modal__image-container">
        {isTitleImageLoading && (
          <div className="circle-loader-wrapper">
            <CircularProgressbar
              value={uploadProgress}
              strokeWidth={5}
              styles={buildStyles({
                pathTransitionDuration: 0.5,
                pathColor: '#707070',
                trailColor: '#E1E1E1',
              })}
            />
            <Loader
              message={translate('common.load', {
                object: '',
              })}
            />
          </div>
        )}
        {image && titleImageToken && !isTitleImageLoading && (
          <div
            className="title-image-modal__image"
            style={{
              backgroundImage: `url(${image})`,
              backgroundSize: 'cover',
            }}
          />
        )}
        {titleImageError && (
          <p className="title-image-modal__error">{titleImageError}</p>
        )}
      </div>
      <div className="title-image-modal__buttons">
        <MainButton onClick={closeModal} teritary>
          {translate('common.action.close')}
        </MainButton>
        <MainButton onClick={uploadTitleImage}>
          {translate('common.action.create')}
        </MainButton>
      </div>
    </div>
  </ModalContainer>
);

TitleImageModal.defaultProps = defaultProps;

TitleImageModal.propTypes = propTypes;

export default connect(mapStateToProps)(TitleImageModal);
