import React from 'react';
import PropTypes from 'prop-types';
import ModalContainer from 'common/components/modalContainer';
import MemberListItem from 'farm/components/farmMemberListItem';
import Button from 'common/components/button';
import MainButton from 'common/components/mainButton';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from 'common/components/loader';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import './index.scss';

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
});

const defaultProps = {
  newAdminId: null,
  nextPageIndex: null,
};

const propTypes = {
  closeModal: PropTypes.func.isRequired,
  setNewAdmin: PropTypes.func.isRequired,
  handleNewAdminRadioClick: PropTypes.func.isRequired,
  farmMembers: PropTypes.arrayOf(PropTypes.object).isRequired,
  getFarmMembersNextPage: PropTypes.func.isRequired,
  newAdminId: PropTypes.number,
  nextPageIndex: PropTypes.number,
};

const NewAdminModalView = ({
  closeModal,
  setNewAdmin,
  handleNewAdminRadioClick,
  farmMembers,
  getFarmMembersNextPage,
  newAdminId,
  nextPageIndex,
  translate,
}) => (
  <ModalContainer handleOutsideClick={closeModal} notFullSize>
    <div className="new-admin-modal">
      <div className="new-admin-modal__wrapper">
        <h1 className="new-admin-modal__heading">
          {translate('modal.setNewAdmin.heading')}
        </h1>
        <h2 className="new-admin-modal__sub-heading">
          {translate('modal.setNewAdmin.message')}
        </h2>
        <div
          className="new-admin-modal__members-list"
          id="new-admin-modal-scroll"
        >
          <InfiniteScroll
            dataLength={farmMembers.length}
            next={nextPageIndex ? getFarmMembersNextPage : null}
            hasMore={!!nextPageIndex}
            scrollableTarget="new-admin-modal-scroll"
            scrollThreshold="400px"
            loader={
              nextPageIndex === 1 ? (
                <Loader
                  message={translate('common.load', {
                    object: translate('common.reaction.members'),
                  })}
                />
              ) : (
                <Loader
                  message={translate('common.loadMore', {
                    object: translate('common.reaction.requests'),
                  })}
                />
              )
            }
          >
            {farmMembers.map(member => {
              if (!member.is_admin) {
                return (
                  <div className="members-list-modal__member" key={member.id}>
                    <MemberListItem
                      member={member}
                      isAdminRadioInputVisible
                      handleNewAdminRadioClick={handleNewAdminRadioClick}
                      newAdminId={newAdminId}
                    />
                  </div>
                );
              }
              return null;
            })}
          </InfiniteScroll>
        </div>
        <div className="new-admin-modal__buttons">
          <Button
            onClick={closeModal}
            className="new-admin-modal__button-cancel"
          >
            {translate('common.action.cancel')}
          </Button>
          <MainButton onClick={setNewAdmin}>
            {translate('common.leaveObject', {
              object: translate('common.reaction.farm'),
            })}
          </MainButton>
        </div>
      </div>
    </div>
  </ModalContainer>
);

NewAdminModalView.defaultProps = defaultProps;

NewAdminModalView.propTypes = propTypes;

export default connect(mapStateToProps)(NewAdminModalView);
