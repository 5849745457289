import * as Yup from 'yup';
import translateMessage from 'common/utils/translateMessage.js';

export const contactSellerValidationSchema = Yup.object().shape({
  text: Yup.string()
    .max(400, translateMessage('form.error.maxMessageLength'))
    .nullable(),
});

export default contactSellerValidationSchema;
