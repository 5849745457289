import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './index.scss';

const defaultProps = {
  icon: null,
  text: '',
  heading: '',
};

const propTypes = {
  icon: PropTypes.string,
  text: PropTypes.string,
  heading: PropTypes.string,
};

const RegisterListItem = ({ icon, text, heading }) => {
  const iconClasses = classNames({
    'register-list-item__icon': true,
    'register-list-item__icon--placeholder': !icon,
  });

  return (
    <div className="register-list-item">
      <div className={iconClasses}>{icon && <img src={icon} alt="" />}</div>
      <div className="register-list-item__text-wrapper">
        <h1 className="register-list-item__heading">{heading}</h1>
        <div className="register-list-item__text">{text}</div>
      </div>
    </div>
  );
};

RegisterListItem.propTypes = propTypes;

RegisterListItem.defaultProps = defaultProps;

export default RegisterListItem;
