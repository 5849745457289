import * as Yup from 'yup';
import translateMessage from 'common/utils/translateMessage.js';
import localizeYup from 'common/utils/localizeYupDefaults.js';
import { eMailRegex } from 'common/utils/validationUtils';

localizeYup();

export const forgotAccountValidationSchema = Yup.object().shape({
  email: Yup.string()
    .matches(eMailRegex, {
      message: translateMessage('form.error.invalidFormat'),
      excludeEmptyString: true,
    })
    .required(),
});

export default forgotAccountValidationSchema;
