import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';

import './index.scss';

const propTypes = {
  handleVicinityChange: PropTypes.func.isRequired,
  vicinity: PropTypes.number.isRequired,
};

const VicinityInputView = ({ handleVicinityChange, vicinity }) => (
  <div className="vicinity-input">
    <p className="vicinity-input__description">
      <Translate id="marketplace.filter.vicinityDescription" />
    </p>
    <input
      type="range"
      min="1"
      max="20"
      value={vicinity || 20}
      id="range"
      onChange={handleVicinityChange}
      className="vicinity-input__input"
    />
    <div className="vicinity-input__distance-container">
      <span className="vicinity-input__distance">
        <Translate id="marketplace.filter.near" />
      </span>
      <span className="vicinity-input__distance">
        <Translate id="marketplace.filter.far" />
      </span>
    </div>
  </div>
);

VicinityInputView.propTypes = propTypes;

export default VicinityInputView;
