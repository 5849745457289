import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import debounce from 'lodash/debounce';
import MarketplaceSearchView from 'marketplace/components/marketplaceSearchView';
import {
  fetchMarketplace,
  updateMarketplaceQuery,
} from 'marketplace/redux/actions/marketplaceActions';

const propTypes = {
  getMarketplace: PropTypes.func.isRequired,
  searchQuery: PropTypes.string.isRequired,
  updateMarketplaceSearchQuery: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  searchQuery: state.marketplace.search,
});

const mapDispatchToProps = dispatch => ({
  getMarketplace: bindActionCreators(fetchMarketplace, dispatch),
  updateMarketplaceSearchQuery: bindActionCreators(
    updateMarketplaceQuery,
    dispatch,
  ),
});

class MarketplaceSearch extends Component {
  debouncedGetMarketplace = debounce(() => {
    const { getMarketplace } = this.props;
    getMarketplace();
  }, 250);

  onSearchInputChange = e => {
    const { updateMarketplaceSearchQuery } = this.props;
    updateMarketplaceSearchQuery(e.target.value);
    this.debouncedGetMarketplace();
  };

  render() {
    const { searchQuery } = this.props;

    return (
      <MarketplaceSearchView
        searchQuery={searchQuery}
        onSearchInputChange={this.onSearchInputChange}
      />
    );
  }
}

MarketplaceSearch.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MarketplaceSearch);
