import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import DeleteIcon from 'assets/icons/delete-red.svg';
import Button from 'common/components/button';
import RadioInput from 'common/components/radioInput';
import UserAvatar from 'common/components/userAvatar';
import { Translate } from 'react-localize-redux';
import './index.scss';

const defaultProps = {
  isAdmin: false,
  handleMemberDelete: null,
  isAdminRadioInputVisible: null,
  newAdminId: null,
  handleNewAdminRadioClick: null,
};

const propTypes = {
  member: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.object,
      PropTypes.bool,
    ]),
  ).isRequired,
  isAdmin: PropTypes.bool,
  handleMemberDelete: PropTypes.func,
  handleNewAdminRadioClick: PropTypes.func,
  isAdminRadioInputVisible: PropTypes.bool,
  newAdminId: PropTypes.number,
};

const MemberListItem = ({
  member,
  isAdmin,
  handleMemberDelete,
  isAdminRadioInputVisible,
  handleNewAdminRadioClick,
  newAdminId,
}) => {
  const {
    id: memberId,
    first_name: firstName,
    last_name: lastName,
    is_admin: isFarmAdmin,
  } = member;

  return (
    <div className="farm-member">
      <div className="farm-member__wrapper">
        <div className="farm-member__member">
          <UserAvatar id={member.id} image={member.avatar} />
          <div className="farm-member__details">
            <Link className="farm-member__link" to={`/profile/${member.id}`}>
              <p className="farm-member__name">
                {firstName} 
                {' '}
                {lastName}
              </p>
            </Link>
            {isFarmAdmin && (
              <p className="farm-member__admin">
                <Translate id="common.admin" />
              </p>
            )}
          </div>
        </div>
        <div className="farm-member__action">
          {isAdmin && (
            <div className="farm-member__action--delete">
              <Button
                className="farm-member__delete-button"
                onClick={() => handleMemberDelete(memberId, isFarmAdmin)}
              >
                <img
                  src={DeleteIcon}
                  alt=""
                  className="farm-member__delete-icon"
                />
                <Translate id="common.action.delete" />
              </Button>
            </div>
          )}
          {isAdminRadioInputVisible && (
            <div className="farm-member__action--select">
              <RadioInput
                name={`${memberId}`}
                onChange={handleNewAdminRadioClick}
                checked={newAdminId === memberId}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

MemberListItem.defaultProps = defaultProps;

MemberListItem.propTypes = propTypes;

export default MemberListItem;
