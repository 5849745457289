import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import LazyLoad from 'react-lazyload';
import './index.scss';

const defaultProps = {
  id: null,
  image: '',
  type: '',
  isFarmProfile: false,
  overflow: false,
  offset: 0,
};

const propTypes = {
  id: PropTypes.number,
  image: PropTypes.string,
  type: PropTypes.string,
  isFarmProfile: PropTypes.bool,
  overflow: PropTypes.bool,
  offset: PropTypes.number,
};

const UserAvatar = ({ image, id, isFarmProfile, type, overflow, offset }) => {
  const userAvatarClasses = classNames({
    'user-avatar': true,
    'user-avatar__placeholder': !image,
    'user-avatar__placeholder--farm':
      !image && (isFarmProfile || type === 'farm'),
    'user-avatar__image': image,
  });

  return (
    <Fragment>
      {id ? (
        <Link to={type === 'farm' ? `/farms/${id}` : `/profile/${id}`}>
          <LazyLoad overflow={overflow} offset={offset}>
            <div
              className={userAvatarClasses}
              style={
                image
                  ? { backgroundImage: `url(${image}?w=60&h=60&fit=crop)` }
                  : {}
              }
            />
          </LazyLoad>
        </Link>
      ) : (
        <LazyLoad overflow={overflow} offset={offset}>
          <div
            className={userAvatarClasses}
            style={
              image
                ? { backgroundImage: `url(${image}?w=60&h=60&fit=crop)` }
                : {}
            }
          />
        </LazyLoad>
      )}
    </Fragment>
  );
};

UserAvatar.defaultProps = defaultProps;

UserAvatar.propTypes = propTypes;

export default UserAvatar;
