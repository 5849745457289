import get from 'lodash/get';

const translations = require('../../translation.json');

function translateMessage(id) {
  let localizedId = id;

  switch (process.env.REACT_APP_LANGUAGE) {
    case 'de':
      localizedId = `${localizedId}.0`;
      break;
    case 'en':
      localizedId = `${localizedId}.1`;
      break;
    default:
      localizedId = `${localizedId}.1`;
      break;
  }

  return get(translations, localizedId);
}

export default translateMessage;
