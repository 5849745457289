import { setLocale } from 'yup';
import translateMessage from 'common/utils/translateMessage.js';

const localizeYup = () => {
  setLocale({
    mixed: {
      required: translateMessage('form.yup.default.required'),
    },
  });
};

export default localizeYup;
