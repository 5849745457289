import { createReducer } from 'common/utils/reduxUtils';
import {
  ADD_CREATED_ARTICLE,
  FETCH_MARKETPLACE_SUCCESS,
  UPDATE_MARKETPLACE_QUERY,
  UPDATE_MARKETPLACE_CATEGORY,
  UPDATE_MARKETPLACE_VICINITY,
  REMOVE_MARKETPLACE,
  UPDATE_MARKETPLACE,
  FETCH_ARTICLES_NUMBER_SUCCESS,
} from 'marketplace/redux/types';

const initialState = {
  data: [],
  currentTimestamp: null,
  nextPageIndex: null,
  search: '',
  categoryId: '',
  vicinity: 20,
  total: 0,
};

const marketplaceReducer = createReducer(
  { ...initialState },
  {
    [FETCH_MARKETPLACE_SUCCESS]: (state, action) => {
      const { data, meta } = action.payload.data;
      const { id } = action.payload;
      const { currentTimestamp } = state;
      const hasNextPage = meta.current_page < meta.last_page;
      return {
        ...state,
        data: meta.current_page === 1 ? [...data] : [...state.data, ...data],
        currentTimestamp:
          meta.current_page === 1 ? meta.current_timestamp : currentTimestamp,
        nextPageIndex: id ? null : hasNextPage ? meta.current_page + 1 : null,
      };
    },
    [UPDATE_MARKETPLACE_QUERY]: (state, action) => ({
      ...state,
      search: action.payload.search,
    }),
    [FETCH_ARTICLES_NUMBER_SUCCESS]: (state, action) => ({
      ...state,
      total: action.payload.data,
    }),
    [UPDATE_MARKETPLACE_CATEGORY]: (state, action) => ({
      ...state,
      categoryId: action.payload.categoryId,
    }),
    [UPDATE_MARKETPLACE_VICINITY]: (state, action) => ({
      ...state,
      vicinity: action.payload.vicinity,
    }),
    [ADD_CREATED_ARTICLE]: (state, action) => ({
      ...state,
      data: [action.payload.data, ...state.data],
    }),
    [UPDATE_MARKETPLACE]: (state, action) => ({
      ...state,
      data: [...action.payload.data],
    }),
    [REMOVE_MARKETPLACE]: state => ({
      ...state,
      data: [],
      currentTimestamp: null,
      nextPageIndex: null,
      search: '',
      categoryId: '',
      vicinity: 20,
    }),
  },
);

export default marketplaceReducer;
