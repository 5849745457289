import React from 'react';
import PropTypes from 'prop-types';
import RegisterListItem from 'userAuth/components/registerListItem';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import Accept from 'assets/icons/accept.svg';
import RegistrationImage from 'assets/images/registration-image-m.jpg';
import './index.scss';

const propTypes = {
  translate: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
});

const RegisterList = ({ translate }) => (
  <div className="register-list">
    <h1 className="register-list__heading">
      {translate('page.signUp.signUpList.heading')}
    </h1>
    <h2 className="register-list__sub-heading">
      {translate('page.signUp.signUpList.subHeading')}
    </h2>
    <div className="register-list__list">
      <RegisterListItem
        icon={Accept}
        heading={translate('page.signUp.signUpList.bullet1Heading')}
        text={translate('page.signUp.signUpList.bullet1')}
      />
      <RegisterListItem
        icon={Accept}
        heading={translate('page.signUp.signUpList.bullet2Heading')}
        text={translate('page.signUp.signUpList.bullet2')}
      />
      <RegisterListItem
        icon={Accept}
        heading={translate('page.signUp.signUpList.bullet3Heading')}
        text={translate('page.signUp.signUpList.bullet3')}
      />
      <div className="left-image">
        <img src={RegistrationImage} alt="" />
      </div>
    </div>
  </div>
);

RegisterList.propTypes = propTypes;

export default connect(mapStateToProps)(RegisterList);
