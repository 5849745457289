import React from 'react';
import PropTypes from 'prop-types';
import MainButton from 'common/components/mainButton';
import Button from 'common/components/button';
import Footer from 'common/components/footer';
import VicinityInput from 'marketplace/containers/vicinityInput';
import { Translate } from 'react-localize-redux';
import './index.scss';

const defaultProps = {
  categories: [],
};

const propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object),
  handleCategoryChange: PropTypes.func.isRequired,
  toggleSellArticleModal: PropTypes.func.isRequired,
};

const MarketplaceSidebarView = ({
  categories,
  handleCategoryChange,
  toggleSellArticleModal,
}) => (
  <div className="marketplace-sidebar">
    <div className="marketplace-sidebar__sell-button">
      <MainButton onClick={toggleSellArticleModal} fullWidth>
        <Translate id="common.action.sellArticle" />
      </MainButton>
    </div>
    <div className="marketplace-sidebar__filter-buttons">
      {categories.map(category => (
        <Button
          key={category.id}
          onClick={() => handleCategoryChange(category.id)}
          className={`marketplace-sidebar__button marketplace-sidebar__button--${
            category.id
          }`}
        >
          <Translate id={`common.action.${category.name}`} />
        </Button>
      ))}
    </div>
    <div className="marketplace-sidebar__vicinity-input">
      <VicinityInput />
    </div>
    <Footer isSidebar />
  </div>
);

MarketplaceSidebarView.defaultProps = defaultProps;

MarketplaceSidebarView.propTypes = propTypes;

export default MarketplaceSidebarView;
