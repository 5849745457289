import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import disableScroll from 'common/utils/disableScroll';
import InfoModal from 'common/components/infoModal';
import CreateFarmModal from 'farm/containers/createFarmModal';
import JoinFarmModal from 'farms/containers/joinFarmModal';
import { getTranslate } from 'react-localize-redux';
import { farmFlowEnd } from 'farm/redux/actions/farmActions';

const defaultProps = {
  userData: {},
};

const propTypes = {
  userData: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
  ),
  translate: PropTypes.func.isRequired,
  farmFlowEndAction: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  userData: state.userData.data,
  translate: getTranslate(state.localize),
});

const mapDispatchToProps = dispatch => ({
  farmFlowEndAction: () => dispatch(farmFlowEnd()),
});

class UserFarmFlow extends Component {
  state = {
    isCreateFarmModalVisible: false,
    isJoinFarmModalVisible: false,
    isNoFarmModalVisible: false,
  };

  componentDidUpdate(prevProps) {
    const { farmFlowActive } = this.props;

    if (farmFlowActive && prevProps.farmFlowActive !== farmFlowActive) {
      this.toggleNoFarmModal();
    }
  }

  toggleNoFarmModal = () => {
    const { history, userData, farmFlowEndAction } = this.props;

    if (userData.admin_in || userData.member_in) {
      farmFlowEndAction();
      history.replace(`/farms/${userData.admin_in || userData.member_in}`);
    } else {
      const { isNoFarmModalVisible } = this.state;
      this.setState({ isNoFarmModalVisible: !isNoFarmModalVisible }, () =>
        disableScroll(!isNoFarmModalVisible),
      );
    }
  };

  toggleJoinFarmModal = () => {
    const { isJoinFarmModalVisible } = this.state;
    this.setState({ isJoinFarmModalVisible: !isJoinFarmModalVisible }, () =>
      disableScroll(!isJoinFarmModalVisible),
    );
  };

  toggleCreateFarmModal = () => {
    const { isCreateFarmModalVisible } = this.state;
    this.setState({ isCreateFarmModalVisible: !isCreateFarmModalVisible }, () =>
      disableScroll(!isCreateFarmModalVisible),
    );
  };

  createFarm = () => {
    this.toggleNoFarmModal();
    this.toggleCreateFarmModal();
  };

  joinFarm = () => {
    this.toggleNoFarmModal();
    this.toggleJoinFarmModal();
  };

  render() {
    const { translate, farmFlowEndAction } = this.props;

    const {
      isCreateFarmModalVisible,
      isNoFarmModalVisible,
      isJoinFarmModalVisible,
    } = this.state;
    return (
      <Fragment>
        {isCreateFarmModalVisible && (
          <CreateFarmModal
            closeModal={() => {
              this.toggleCreateFarmModal();
              farmFlowEndAction();
            }}
          />
        )}
        {isNoFarmModalVisible && (
          <InfoModal
            closeModal={() => {
              this.toggleNoFarmModal();
              farmFlowEndAction();
            }}
            heading={translate('modal.noFarmYet.heading')}
            message={translate('modal.noFarmYet.message')}
            handleSecondaryOptionButtonClick={this.joinFarm}
            handlePrimaryOptionButtonClick={this.createFarm}
            secondaryOptionButton={translate('common.joinObject', {
              object: translate('common.reaction.farm'),
            })}
            primaryOptionButton={translate('common.createObject', {
              object: translate('common.reaction.farm'),
            })}
            isCancelButtonVisible
            isSecondaryOptionButtonVisible
          />
        )}
        {isJoinFarmModalVisible && (
          <JoinFarmModal
            closeModal={() => {
              this.toggleJoinFarmModal();
              farmFlowEndAction();
            }}
          />
        )}
      </Fragment>
    );
  }
}

UserFarmFlow.defaultProps = defaultProps;

UserFarmFlow.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(UserFarmFlow));
