import axios from 'axios';
import * as types from 'profile/redux/types';
import { createActionCreator } from 'common/utils/reduxUtils';

const {
  FETCH_USER_NEWSFEED_REQUEST,
  FETCH_USER_NEWSFEED_SUCCESS,
  FETCH_USER_NEWSFEED_FAILURE,
  ADD_CREATED_USER_POST,
  UPDATE_USER_NEWSFEED,
  REMOVE_USER_NEWSFEED,
} = types;

const fetchUserNewsfeedRequest = createActionCreator(
  FETCH_USER_NEWSFEED_REQUEST,
);
const fetchUserNewsfeedSuccess = createActionCreator(
  FETCH_USER_NEWSFEED_SUCCESS,
  'data',
);
const fetchUserNewsfeedFailure = createActionCreator(
  FETCH_USER_NEWSFEED_FAILURE,
);

export const fetchUserNewsfeed = (id, page = 1) => (dispatch, getState) => {
  const { currentTimestamp } = getState().userNewsfeed;
  dispatch(fetchUserNewsfeedRequest());
  return axios
    .get(`${process.env.REACT_APP_API_URL}/users/${id}/newsfeed`, {
      params: {
        page,
        to: currentTimestamp,
      },
    })
    .then(response => {
      dispatch(fetchUserNewsfeedSuccess(response.data));
    })
    .catch(err => {
      dispatch(fetchUserNewsfeedFailure());
    });
};

export const addCreatedUserPost = createActionCreator(
  ADD_CREATED_USER_POST,
  'data',
);

export const updateUserNewsfeed = createActionCreator(
  UPDATE_USER_NEWSFEED,
  'data',
);

export const removeUserNewsfeed = createActionCreator(REMOVE_USER_NEWSFEED);
