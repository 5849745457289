import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import InviteModalView from 'common/components/inviteModalView';
import InvitePopUpView from 'common/components/invitePopUpView';
import {
  sendAppInvitations,
  sendFarmInvitations,
} from 'common/api/invitationsApi';
import disableScroll from 'common/utils/disableScroll';

const defaultProps = {
    farmId: null,
};

const propTypes = {
  farmId: PropTypes.number,
};

class InviteModal extends Component {
  state = {
    invitations: [],
    isInviteModalVisible: false,
    isInvitePopUpVisible: false,
    emailError: '',
  };

  resetEmailError = () => this.setState({ emailError: '' });

  addEmail = (values, errors) => {
    if (!errors.email && values.email) {
      const { invitations } = this.state;
      const isEmailDuplicated = invitations.find(
        item => item.email === values.email,
      );
      if (isEmailDuplicated) {
        this.setState({
          emailError: 'This email address has already been added.',
        });
        return;
      }
      this.setState({
        invitations: [
          ...invitations,
          {
            email: values.email,
            text: values.text,
          },
        ],
      });
      values.email = ''; // eslint-disable-line no-param-reassign
      values.text = ''; // eslint-disable-line no-param-reassign
      const scrollContainer = document.getElementById(
        'invitations-scroll-container',
      );
      scrollContainer.scrollTop = scrollContainer.scrollHeight;
    }
  };

  removeEmail = index => {
    const { invitations } = this.state;
    const newInvitations = [...invitations];
    newInvitations.splice(index, 1);
    this.setState({
      invitations: newInvitations,
    });
  };

  submitInvitations = (values, actions) => {
    const { farmId } = this.props;
    const { invitations } = this.state;
    let data;
    if (values.email) {
      data = [
        ...invitations,
        {
          email: values.email,
          text: values.text,
        },
      ];
    } else {
      data = [...invitations];
    }
    const isEmailDuplicated = invitations.find(
      item => item.email === values.email,
    );
    if (isEmailDuplicated) {
      this.setState({
        emailError: 'This email address has already been added.',
      });
      actions.setSubmitting(false);
      return;
    }
    this.toggleInviteModal();
    if (farmId) {
      sendFarmInvitations(farmId, data);
    } else {
      sendAppInvitations(data);
    }
  };

  toggleInviteModal = () => {
    const { isInviteModalVisible } = this.state;
    this.setState(
      { isInviteModalVisible: !isInviteModalVisible },
      disableScroll(!isInviteModalVisible),
    );
  };

  toggleEmailModal = () => {
    this.setState(
      {
        isInvitePopUpVisible: false,
        isInviteModalVisible: true,
      },
      disableScroll(true),
    );
  };

  toggleInvitePopUp = () => {
    const { isInvitePopUpVisible } = this.state;
    this.setState(
      { isInvitePopUpVisible: !isInvitePopUpVisible },
      disableScroll(!isInvitePopUpVisible),
    );
  };

  render() {
    const { children, farmId } = this.props;
    const {
      emailError,
      invitations,
      isInviteModalVisible,
      isInvitePopUpVisible,
    } = this.state;
    return (
      <Fragment>
        {children({
          handleInviteButtonClick: this.toggleInvitePopUp,
        })}
        {isInviteModalVisible && (
          <InviteModalView
            addEmail={this.addEmail}
            closeModal={this.toggleInviteModal}
            invitations={invitations}
            removeEmail={this.removeEmail}
            submitInvitations={this.submitInvitations}
            farmId={farmId}
            emailError={emailError}
            resetEmailError={this.resetEmailError}
          />
        )}
        {isInvitePopUpVisible && (
          <InvitePopUpView
            closeModal={this.toggleInvitePopUp}
            toggleEmailModal={this.toggleEmailModal}
          />
        )}
      </Fragment>
    );
  }
}

InviteModal.defaultProps = defaultProps;

InviteModal.propTypes = propTypes;

export default InviteModal;
