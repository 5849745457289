import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addLike, deleteLike } from 'post/api/likesApi';
import { updateMarketplace } from 'marketplace/redux/actions/marketplaceActions';
import {
  addCreatedPost,
  updateNewsfeed,
  updatePost,
} from 'newsfeed/redux/actions/newsfeedActions';
import { updateHashtagfeed } from 'hashtag/redux/actions/hashtagActions';
import { updateFarmNewsfeed } from 'farm/redux/actions/farmNewsfeedActions';
import {
  updateUserNewsfeed,
  addCreatedUserPost,
} from 'profile/redux/actions/userNewsfeedActions';
import { createShare } from 'post/api/sharesApi';
import disableScroll from 'common/utils/disableScroll';
import PostActionsView from 'post/components/postActions';
import ModalContainer from 'common/components/modalContainer';
import ShareModal from 'post/components/shareModal';

import { addNotification } from 'notification/api/notificationsApi';

const defaultProps = {
  userData: {},
  isCommentActionHidden: false,
  farmId: null,
  toggleGuestModal: () => null,
  feed: '',
  match: {},
};

const propTypes = {
  addCreatedPost: PropTypes.func.isRequired,
  newsfeed: PropTypes.arrayOf(PropTypes.object).isRequired,
  post: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
      PropTypes.object,
    ]),
  ).isRequired,
  updateNewsfeed: PropTypes.func.isRequired,
  updateHashtagfeed: PropTypes.func.isRequired,
  updatePost: PropTypes.func.isRequired,
  userData: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
  ),
  onCommentsClick: PropTypes.func.isRequired,
  toggleGuestModal: PropTypes.func,
  isCommentActionHidden: PropTypes.bool,
  updateFarmNewsfeed: PropTypes.func.isRequired,
  farmId: PropTypes.number,
  updateUserNewsfeed: PropTypes.func.isRequired,
  addCreatedUserPost: PropTypes.func.isRequired,
  updateMarketplace: PropTypes.func.isRequired,
  feed: PropTypes.string,
  match: PropTypes.object,
};

const mapStateToProps = state => ({
  userData: state.userData.data,
});

const mapDispatchToProps = dispatch => ({
  addCreatedPost: bindActionCreators(addCreatedPost, dispatch),
  updateNewsfeed: bindActionCreators(updateNewsfeed, dispatch),
  updateHashtagfeed: bindActionCreators(updateHashtagfeed, dispatch),
  updatePost: bindActionCreators(updatePost, dispatch),
  updateFarmNewsfeed: bindActionCreators(updateFarmNewsfeed, dispatch),
  updateUserNewsfeed: bindActionCreators(updateUserNewsfeed, dispatch),
  addCreatedUserPost: bindActionCreators(addCreatedUserPost, dispatch),
  updateMarketplace: bindActionCreators(updateMarketplace, dispatch),
});

class PostActions extends Component {
  state = {
    isShareModalVisible: false,
    shareMessage: '',
    isShareInProgress: false,
  };

  handleLikeClick = () => {
    const {
      post,
      newsfeed,
      updateNewsfeed,
      updateHashtagfeed,
      updateFarmNewsfeed,
      updateUserNewsfeed,
      updateMarketplace,
      farmId,
      match,
      userData,
      toggleGuestModal,
    } = this.props;
    const { path } = match;

    if (!userData.id) {
      return toggleGuestModal();
    }

    if (!post.liked) {
      addLike(post.id).then(() => {
        let notificationType = false;

        if (
          post.type === 'farm_cover_image' ||
          post.type === 'farm_title_image'
        ) {
          notificationType = `${post.type}_liked`;
        } else if (post.type === 'farm_farmtour') {
          notificationType = 'farmtour_liked';
        } else if (post.article) {
          notificationType = 'article_liked';
        } else if (post.author.type === 'user') {
          notificationType = 'user_post_liked';
        } else {
          notificationType = 'farm_post_liked';
        }

        addNotification({
          authorId:
            post.author.type === 'user' ? post.author.id : post.user_author.id,
          typeAction: notificationType,
          entityId: post.article ? post.article.id : post.id,
        });

        const updated = newsfeed.map(item => {
          if (item.id === post.id) {
            return {
              ...post,
              liked: true,
              likes_count: post.likes_count + 1,
            };
          }
          if (item.shared_content && item.shared_content.id === post.id) {
            return {
              ...item,
              shared_content: {
                ...item.shared_content,
                liked: true,
                likes_count: item.shared_content.likes_count + 1,
              },
            };
          }
          return item;
        });

        if (farmId) {
          updateFarmNewsfeed(updated);
        } else {
          if (path === '/' || window.location.pathname.includes('post')) {
            updateNewsfeed(updated);
          }
          if (window.location.pathname === '/hashtag') {
            updateHashtagfeed(updated);
          }
          if (path.indexOf('/profile') > -1) {
            updateUserNewsfeed(updated);
          }
          if (path.indexOf('/marketplace') > -1) {
            updateMarketplace(updated);
          }
        }
      });
    } else {
      deleteLike(post.id).then(() => {
        const updated = newsfeed.map(item => {
          if (item.id === post.id) {
            return {
              ...post,
              liked: false,
              likes_count: post.likes_count - 1,
            };
          }
          if (item.shared_content && item.shared_content.id === post.id) {
            return {
              ...item,
              shared_content: {
                ...item.shared_content,
                liked: false,
                likes_count: item.shared_content.likes_count - 1,
              },
            };
          }
          return item;
        });
        if (farmId) {
          updateFarmNewsfeed(updated);
        } else {
          if (path === '/' || window.location.pathname.includes('post')) {
            updateNewsfeed(updated);
          }
          if (window.location.pathname === '/hashtag') {
            updateHashtagfeed(updated);
          }
          if (path.indexOf('/profile') > -1) {
            updateUserNewsfeed(updated);
          }
          if (path.indexOf('/marketplace') > -1) {
            updateMarketplace(updated);
          }
        }
      });
    }
  };

  toggleShareModal = () => {
    const { isShareModalVisible } = this.state;
    const { userData, toggleGuestModal } = this.props;

    if (userData.id) {
      this.setState(
        {
          isShareModalVisible: !isShareModalVisible,
          shareMessage: '',
        },
        () => disableScroll(!isShareModalVisible),
      );
    } else {
      toggleGuestModal();
    }
  };

  handleShareClick = () => {
    this.toggleShareModal();
  };

  createShare = (id, text) => {
    const {
      addCreatedPost,
      updateNewsfeed,
      updatePost,
      newsfeed,
      post,
      farmId,
      updateFarmNewsfeed,
      updateUserNewsfeed,
      updateMarketplace,
      match,
      userData,
      addCreatedUserPost,
    } = this.props;
    const { path } = match;
    const { isShareModalVisible } = this.state;
    const sharedId = post.shared_content ? post.shared_content.id : post.id;
    this.setState({ isShareInProgress: true });
    createShare(id, text)
      .then(response => {
        let notificationType = false;

        if (
          post.type === 'farm_cover_image' ||
          post.type === 'farm_title_image'
        ) {
          notificationType = `${post.type}_shared`;
        } else if (post.type === 'farm_farmtour') {
          notificationType = 'farmtour_shared';
        } else if (post.article) {
          notificationType = 'article_shared';
        } else if (post.author.type === 'user') {
          notificationType = 'user_post_shared';
        } else {
          notificationType = 'farm_post_shared';
        }

        addNotification({
          authorId:
            post.author.type === 'user' ? post.author.id : post.user_author.id,
          typeAction: notificationType,
          entityId: post.article ? post.article.id : post.id,
        });

        const singlePost = window.location.pathname.includes('post');

        const updated = newsfeed.map(item => {
          if (item.id === sharedId) {
            return {
              ...post,
              // id: farmId ? item.id : singlePost ? sharedId : sharedId + 10000,
              shares_count: post.shares_count + 1,
            };
          }
          if (item.shared_content && item.shared_content.id === sharedId) {
            return {
              ...item,
              shared_content: {
                ...item.shared_content,
                shares_count: item.shared_content.shares_count + 1,
              },
            };
          }
          return item;
        });

        if (farmId) {
          updateFarmNewsfeed(updated);
        } else {
          if (path === '/') {
            updateNewsfeed(updated);
            addCreatedPost(response.data.created);
          }
          if (singlePost) {
            updatePost(updated);
          }
          if (path.indexOf('/profile') > -1) {
            updateUserNewsfeed(updated);
            const { userId } = match.params;
            if (userData.id === Number(userId)) {
              addCreatedUserPost(response.data.created);
            }
          }
          if (path.indexOf('/marketplace') > -1) {
            updateMarketplace(updated);
          }
        }
        if (isShareModalVisible) {
          setTimeout(
            () => {
              this.setState({ isShareInProgress: false });

              this.toggleShareModal();
            },
            window.innerWidth > 991 ? 1250 : 2500,
          );
        }
      })
      .catch(() => {
        this.setState({ isShareInProgress: false });
      });
  };

  updateShareMessage = e => {
    this.setState({ shareMessage: e.target.value });
  };

  isShareActionAvailable = () => {
    const { post, userData } = this.props;
    if (post && post.author && userData) {
      if (post.shared_content && post.shared_content.author) {
        return (
          !post.shared_content.author ||
          (post.shared_content.author &&
            (post.shared_content.author.id !== userData.id ||
              (post.shared_content.author.id === userData.id &&
                post.shared_content.author.type === 'farm')))
        );
      }
      if (!post.shared_content) {
        return (
          !post.author ||
          (post.author &&
            (post.author.id !== userData.id ||
              (post.author.id === userData.id && post.author.type === 'farm')))
        );
      }
    }
    return true;
  };

  render() {
    const {
      post,
      onCommentsClick,
      isCommentActionHidden,
      userData,
      feed,
    } = this.props;
    const { isShareModalVisible, shareMessage, isShareInProgress } = this.state;
    return (
      <Fragment>
        <PostActionsView
          post={post}
          handleLikeClick={this.handleLikeClick}
          handleShareClick={this.handleShareClick}
          isShareModalVisible={isShareModalVisible}
          toggleShareModal={this.toggleShareModal}
          createShare={this.createShare}
          updateShareMessage={this.updateShareMessage}
          shareMessage={shareMessage}
          isShareActionAvailable={this.isShareActionAvailable}
          onCommentsClick={onCommentsClick}
          isCommentActionHidden={isCommentActionHidden}
          feed={feed}
        />
        {isShareModalVisible && (
          <ModalContainer handleOutsideClick={this.toggleShareModal}>
            <ShareModal
              post={post}
              closeModal={this.toggleShareModal}
              createShare={this.createShare}
              shareMessage={shareMessage}
              updateShareMessage={this.updateShareMessage}
              isShareInProgress={isShareInProgress}
              userData={userData}
            />
          </ModalContainer>
        )}
      </Fragment>
    );
  }
}

PostActions.defaultProps = defaultProps;

PostActions.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(PostActions));
