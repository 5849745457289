import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import './index.scss';

const propTypes = {
  category: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ).isRequired,
};

const CategoryItem = ({ category }) => (
  <p className={`category-item category-item--${category.id}`}>
    <Translate id={`common.action.${category.name}`} />
  </p>
);

CategoryItem.propTypes = propTypes;

export default CategoryItem;
