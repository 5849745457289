import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './index.scss';

const propTypes = {
  user: PropTypes.object.isRequired,
};

const UserItem = ({ user }) => {
  const { id, name, avatar } = user;

  return (
    <div className="farm-item">
      <div className="farm-item__wrapper">
        <Link to={`/profile/${id}`}>
          <div
            className="farm-item__image"
            style={
              avatar
                ? {
                    backgroundImage: `url(${avatar})`,
                    backgroundSize: 'cover',
                  }
                : {}
            }
          />
        </Link>
        <div className="farm-item__details">
          <Link to={`/profile/${id}`} className="farm-item__name">
            {name}
          </Link>
        </div>
      </div>
    </div>
  );
};

UserItem.propTypes = propTypes;

export default UserItem;
