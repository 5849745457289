import { combineReducers } from 'redux';
import { localizeReducer } from 'react-localize-redux';
import articleToEditReducer from 'post/redux/reducers/articleToEditReducer';
import authReducer from 'userAuth/redux/reducers/authReducer';
import categoriesReducer from 'common/redux/reducers/categoriesReducer';
import commentsReducer from 'post/redux/reducers/commentsReducer';
import cookiesReducer from 'common/redux/reducers/cookiesReducer';
import farmCategoriesReducer from 'common/redux/reducers/farmCategoriesReducer';
import farmReducer from 'farm/redux/reducers/farmReducer';
import farmtourReducer from 'farm/redux/reducers/farmtourReducer';
import farmMembershipRequestsReducer from 'farm/redux/reducers/farmMembershipRequestsReducer';
import farmMembersReducer from 'farm/redux/reducers/farmMembersReducer';
import farmNewsfeedReducer from 'farm/redux/reducers/farmNewsfeedReducer';
import farmsListReducer from 'farms/redux/reducers/farmsListReducer';
import farmsToJoinListReducer from 'farms/redux/reducers/farmsToJoinListReducer';
import likesReducer from 'post/redux/reducers/likesReducer';
import marketplaceReducer from 'marketplace/redux/reducers/marketplaceReducer';
import newsfeedReducer from 'newsfeed/redux/reducers/newsfeedReducer';
import sharesReducer from 'post/redux/reducers/sharesReducer';
import userDataReducer from 'common/redux/reducers/userDataReducer';
import userNewsfeedReducer from 'profile/redux/reducers/userNewsfeedReducer';
import userProfileReducer from 'profile/redux/reducers/userProfileReducer';
import hashtagReducer from 'hashtag/redux/reducers/hashtagReducer';

import notificationReducer from 'notification/redux/reducers/notificationReducer';

const rootReducer = combineReducers({
  articleToEdit: articleToEditReducer,
  auth: authReducer,
  categories: categoriesReducer,
  comments: commentsReducer,
  cookies: cookiesReducer,
  farm: farmReducer,
  farmtour: farmtourReducer,
  farmCategories: farmCategoriesReducer,
  farmMembers: farmMembersReducer,
  farmMembershipRequests: farmMembershipRequestsReducer,
  farmNewsfeed: farmNewsfeedReducer,
  farmsList: farmsListReducer,
  farmsToJoinList: farmsToJoinListReducer,
  likes: likesReducer,
  localize: localizeReducer,
  marketplace: marketplaceReducer,
  newsfeed: newsfeedReducer,
  shares: sharesReducer,
  userData: userDataReducer,
  userNewsfeed: userNewsfeedReducer,
  userProfile: userProfileReducer,
  notifications: notificationReducer,
  hashtagfeed: hashtagReducer,
});

export default rootReducer;
