import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Loader from 'common/components/loader';
import ReactPlayer from 'react-player';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import './index.scss';

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
});

const defaultProps = {
  isAutoSize: false,
  withBackground: false,
  translate: PropTypes.func,
  shouldPlay: false,
};

const propTypes = {
  video: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ).isRequired,
  isAutoSize: PropTypes.bool,
  withBackground: PropTypes.bool,
  shouldPlay: PropTypes.bool,
  translate: PropTypes.func,
};

class VideoWithLoader extends Component {
  constructor(props) {
    super(props);
    this.timer = setTimeout(this.enableLoader, 250);
    if (!props.isAutoSize) {
      this.container = React.createRef();
    }
  }

  state = {
    isThumbnailLoaded: false,
    displayLoader: false,
    height: null,
  };

  componentDidMount() {
    const { isAutoSize } = this.props;
    if (!isAutoSize) {
      this.setHeight();
      window.addEventListener('resize', this.setHeight);
    }
  }

  componentWillUnmount() {
    const { isAutoSize } = this.props;
    if (!isAutoSize) {
      clearTimeout(this.timer);
      window.removeEventListener('resize', this.setHeight);
    }
  }

  enableLoader = () => {
    this.setState({ displayLoader: true });
  };

  handleThumbnailLoad = () => {
    this.setState({
      isThumbnailLoaded: true,
    });
  };

  setHeight = () => {
    const { video } = this.props;
    this.setState({
      height: video.height
        ? (video.height / video.width) * this.container.current.offsetWidth
        : 650,
    });
  };

  handleVideoLoad = () => {
    this.setState({ isVideoLoaded: true });
  };

  render() {
    const {
      video,
      shouldPlay,
      isAutoSize,
      withBackground,
      translate,
    } = this.props;
    const {
      isThumbnailLoaded,
      displayLoader,
      height,
      isVideoLoaded,
    } = this.state;

    const mainContainerClasses = classNames({
      'video-with-loader': true,
      'video-with-loader--limited': !isAutoSize,
      'video-with-loader--loaded': isThumbnailLoaded && isVideoLoaded,
    });
    const videoClasses = classNames({
      'video-with-loader__video': true,
      'video-with-loader__video--hidden': !isThumbnailLoaded || !isVideoLoaded,
      'video-with-loader__video--auto': isAutoSize,
    });
    const loaderClasses = classNames({
      'video-with-loader__loader': true,
      'video-with-loader__loader--background': withBackground,
    });

    return (
      <div
        className={mainContainerClasses}
        ref={this.container}
        style={{ height: isAutoSize ? '100%' : height }}
      >
        <LazyLoadImage
          src={`${video.thumbnail}?h=650`}
          alt=""
          onLoad={this.handleThumbnailLoad}
          className="video-with-loader__thumbnail"
        />

        <ReactPlayer
          className={videoClasses}
          playsinline
          playing={shouldPlay}
          url={video.url}
          controls
          onReady={this.handleVideoLoad}
          loop
          muted
          volume={0.3}
        />

        {(!isThumbnailLoaded || !isVideoLoaded) && displayLoader && (
          <div className={loaderClasses}>
            <Loader
              message={translate('common.load', {
                object: translate('common.reaction.video'),
              })}
            />
          </div>
        )}
      </div>
    );
  }
}

VideoWithLoader.defaultProps = defaultProps;

VideoWithLoader.propTypes = propTypes;

export default connect(mapStateToProps)(VideoWithLoader);
