import * as Yup from 'yup';

export function equalTo(ref: any, msg: any) {
  return Yup.mixed().test({
    name: 'equalTo',
    exclusive: false,
    message: msg || 'Not equal',
    params: {
      reference: ref.path,
    },
    test(value: any) {
      return value === this.resolve(ref);
    },
  });
}

export const eMailRegex = /^([\w-.+]+)@((\[([0-9]{1,3}.){3}[0-9]{1,3}])|(([\w-]+\.)+)([a-zA-Z]{2,63}))$/;
