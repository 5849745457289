import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import UserAvatar from 'common/components/userAvatar';
import FromNow from 'common/components/fromNow';
import Button from 'common/components/button';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import './index.scss';

const mapStateToProps = state => ({
  userData: state.userData.data,
  translate: getTranslate(state.localize),
});

const defaultProps = {
  userData: {},
  toggleEditPostModal: () => null,
  translate: () => null,
  toggleGuestModal: () => null,
};

const propTypes = {
  post: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
      PropTypes.object,
    ]),
  ).isRequired,
  toggleEditArticleModal: PropTypes.func.isRequired,
  toggleEditPostModal: PropTypes.func,
  translate: PropTypes.func,
  userData: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
  ),
  deletePost: PropTypes.func.isRequired,
  toggleGuestModal: PropTypes.func,
};

class PostHeader extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
  }

  state = {
    isPostMenuVisible: false,
  };

  componentWillMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  handleClick = e => {
    if (this.node.current) {
      const { post } = this.props;
      if (
        this.node.current.contains(e.target) ||
        e.target.id === `post-menu-button-${post.id}`
      ) {
        return;
      }
      this.handleClickOutside();
    }
  };

  handleClickOutside = () => {
    this.togglePostMenu();
  };

  togglePostMenu = () => {
    const { isPostMenuVisible } = this.state;
    this.setState({ isPostMenuVisible: !isPostMenuVisible });
  };

  render() {
    const {
      deletePost,
      post,
      toggleEditArticleModal,
      toggleEditPostModal,
      toggleGuestModal,
      userData,
      translate,
    } = this.props;

    const { farm } = post;

    const { isPostMenuVisible } = this.state;

    return (
      <div className="post-header">
        <div className="post-header__post-details">
          <div className="post-header__avatar">
            {post.author && (
              <UserAvatar
                id={post.author && post.author.id && userData.id}
                image={`${post.author.avatar}?w=60&h=60&fit=crop`}
                type={post.author.type}
              />
            )}
          </div>
          <div>
            {post.author && userData.id && (
              <Link
                to={
                  post.author.type === 'farm'
                    ? `/farms/${post.author.id}`
                    : `/profile/${post.author.id}`
                }
              >
                <span className="post-header__author">
                  {post.article && (
                    <span className="post-header__sold">
                      {translate('common.soldBy')}
                      {' '}
                    </span>
                  )}
                  {post.author.name}
                </span>
              </Link>
            )}
            {post.author && !userData.id && (
              <button type="button" onClick={() => toggleGuestModal()}>
                <span className="post-header__author">
                  {post.article && (
                    <span className="post-header__sold">
                      {translate('common.soldBy')}
                      {' '}
                    </span>
                  )}
                  {post.author.name}
                </span>
              </button>
            )}
            {post.author && post.author.type === 'user' && farm && userData.id && (
              <Link to={`/farms/${farm.id}`}>
                <span className="farm-profile-wrapper">
                  <span className="post-header__author">{farm.name}</span>
                </span>
              </Link>
            )}

            {post.author &&
              post.author.type === 'user' &&
              farm &&
              !userData.id && (
                <button type="button" onClick={() => toggleGuestModal()}>
                  <span className="farm-profile-wrapper">
                    <span className="post-header__author">{farm.name}</span>
                  </span>
                </button>
              )}
            <div className="post-header__author-details">
              {post.article && (
                <span className="post-header__address">
                  {post.article.post_code} 
                  {' '}
                  {post.article.city}
                </span>
              )}
              <FromNow time={post.created_at} />
            </div>
          </div>
        </div>
        {post.author &&
          ((post.author.type !== 'farm' && post.author.id === userData.id) ||
            (post.author.type === 'farm' && post.can_edit)) && (
            <Button
              className="post-header__more-button"
              id={`post-menu-button-${post.id}`}
              onClick={this.togglePostMenu}
            />
          )}
        {isPostMenuVisible && (
          <div className="post-header__menu" ref={this.node}>
            {post.article && (
              <Button
                className="post-header__menu-item post-header__menu-item--edit"
                onClick={toggleEditArticleModal}
              >
                {translate('common.action.edit')}
              </Button>
            )}
            {!post.article && (
              <Button
                className="post-header__menu-item post-header__menu-item--edit"
                onClick={toggleEditPostModal}
              >
                {translate('common.action.edit')}
              </Button>
            )}
            <Button
              className="post-header__menu-item post-header__menu-item--delete"
              onClick={deletePost}
            >
              {translate('common.deleteObject', {
                object: translate('common.reaction.post'),
              })}
            </Button>
          </div>
        )}
      </div>
    );
  }
}

PostHeader.propTypes = propTypes;
PostHeader.defaultProps = defaultProps;

export default connect(mapStateToProps)(PostHeader);
