import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import { Translate } from 'react-localize-redux';

const defaultProps = {
  farmTourImage: '',
  toggleCreateFarmTourModal: () => null,
  toggleFarmTourModal: () => null,
};

const propTypes = {
  farmTourImage: PropTypes.string,
  isAdmin: PropTypes.bool.isRequired,
  toggleCreateFarmTourModal: PropTypes.func,
  toggleFarmTourModal: PropTypes.func,
};

const FarmTourIcon = ({
  farmTourImage,
  isAdmin,
  toggleCreateFarmTourModal,
  toggleFarmTourModal,
}) => (
  <div className="farmtour-wrapper">
    <span className="farmtour-title">
      <Translate id="farm.farmtour" />
    </span>
    <div className="farmtour-image-wrapper">
      {isAdmin && (
        <button
          type="button"
          className="farm-tour-edit-button"
          onClick={toggleCreateFarmTourModal}
        />
      )}

      {farmTourImage && (
        <div
          className="farmtour-image"
          style={
            farmTourImage
              ? {
                  backgroundImage: `url(${farmTourImage}?w=500&h=500&fit=crop)`,
                  backgroundSize: 'cover',
                }
              : {}
          }
          role="button"
          tabIndex="0"
          onClick={farmTourImage ? toggleFarmTourModal : () => null}
          onKeyPress={farmTourImage ? toggleFarmTourModal : () => null}
        />
      )}
    </div>
  </div>
);

FarmTourIcon.defaultProps = defaultProps;

FarmTourIcon.propTypes = propTypes;

export default FarmTourIcon;
