import { createReducer } from 'common/utils/reduxUtils';
import {
  SET_USER_AUTH_TOKEN,
  REMOVE_USER_AUTH_TOKEN,
  SET_USER_EMAIL,
  REMOVE_USER_EMAIL,
} from 'userAuth/redux/types';

const initialState = {
  token: null,
  email: null,
};

const authReducer = createReducer(
  { ...initialState },
  {
    [SET_USER_AUTH_TOKEN]: (state, action) => ({
      ...state,
      token: action.payload.token,
    }),
    [REMOVE_USER_AUTH_TOKEN]: state => ({
      ...state,
      token: null,
    }),
    [SET_USER_EMAIL]: (state, action) => ({
      ...state,
      email: action.payload.email,
    }),
    [REMOVE_USER_EMAIL]: state => ({
      ...state,
      email: null,
    }),
  },
);

export default authReducer;
