import React from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import Textarea from 'common/components/textarea';
import MainButton from 'common/components/mainButton';
import contactSellerValidationSchema from 'post/validators/contactSellerValidators';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import './index.scss';

const propTypes = {
  submitForm: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
});

const ContactSellerForm = ({ submitForm, translate }) => (
  <div className="contact-seller-form">
    <Formik
      initialValues={{ text: '' }}
      validationSchema={contactSellerValidationSchema}
      onSubmit={values => submitForm(values)}
      render={({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className="contact-seller-form__row">
            <Textarea
              type="text"
              value={values.text}
              error={errors.text}
              touched={touched.text}
              name="text"
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={translate('marketplace.contactSeller')}
              fullWidth
              maxLength={400}
              textLength={values.text ? values.text.length : 0}
            />
          </div>
          <div className="contact-seller-form__button">
            <MainButton
              type="submit"
              disabled={!!(isSubmitting || !values.text)}
            >
              {translate('common.action.send')}
            </MainButton>
          </div>
        </form>
      )}
    />
  </div>
);

ContactSellerForm.propTypes = propTypes;

export default connect(mapStateToProps)(ContactSellerForm);
