import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BottomNavigationBarView from 'common/components/bottomNavigationBarView';
import GuestModal from 'common/components/guestModal';

const defaultProps = {
  token: '',
};

const propTypes = {
  token: PropTypes.string,
};

const mapStateToProps = state => ({
  token: state.auth.token,
});

const largeBreakpoint = 992;

class BottomNavigationBar extends Component {
  state = {
    width: null,
    isGuestModalVisible: false,
  };

  componentDidMount() {
    this.setScreenWidth();
    window.addEventListener('resize', this.setScreenWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setScreenWidth);
  }

  toggleGuestModal = () => {
    const { isGuestModalVisible } = this.state;
    this.setState({ isGuestModalVisible: !isGuestModalVisible });
  };

  setScreenWidth = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { token } = this.props;
    const { width, isGuestModalVisible } = this.state;
    return (
      <Fragment>
        {width < largeBreakpoint && (
          <BottomNavigationBarView
            toggleGuestModal={this.toggleGuestModal}
            token={token}
          />
        )}

        {isGuestModalVisible && (
          <GuestModal closeModal={this.toggleGuestModal} />
        )}
      </Fragment>
    );
  }
}

BottomNavigationBar.defaultProps = defaultProps;

BottomNavigationBar.propTypes = propTypes;

export default connect(mapStateToProps)(BottomNavigationBar);
