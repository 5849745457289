import React from 'react';
import { Translate } from 'react-localize-redux';
import FirstImage from 'assets/images/hands.jpg';
import SecondImage from 'assets/images/google.jpg';
import ThirdImage from 'assets/images/sheep.jpg';
import './index.scss';

const AppSidebar = () => (
  <div className="app-sidebar">
    <div className="app-sidebar__section">
      <p className="app-sidebar__title">
        <Translate id="sidebar.first.title" />
      </p>

      <img className="app-sidebar__image" src={FirstImage} alt="" />
      <p className="app-sidebar__description">
        <Translate id="sidebar.first.description" />
      </p>
      <a
        className="app-sidebar__link"
        href="https://info.farmlifes.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        info.farmlifes.com
      </a>
    </div>

    <div className="app-sidebar__section">
      <p className="app-sidebar__title">
        <Translate id="sidebar.second.title" />
      </p>
      <img className="app-sidebar__image" src={SecondImage} alt="" />
      <p className="app-sidebar__description">
        <Translate id="sidebar.second.description" />
      </p>
      <a
        className="app-sidebar__link"
        href="https://info.farmlifes.com/was-ist-farmlifes/hof-datenbank"
        target="_blank"
        rel="noopener noreferrer"
      >
        info.farmlifes.com
      </a>
    </div>

    <div className="app-sidebar__section">
      <p className="app-sidebar__title">
        <Translate id="sidebar.third.title" />
      </p>
      <img className="app-sidebar__image" src={ThirdImage} alt="" />

      <p className="app-sidebar__description">
        <Translate id="sidebar.third.description" />
      </p>
      <a
        className="app-sidebar__link"
        href="https://info.farmlifes.com/was-ist-farmlifes/hof-datenbank"
        target="_blank"
        rel="noopener noreferrer"
      >
        info.farmlifes.com
      </a>
    </div>
  </div>
);

export default AppSidebar;
