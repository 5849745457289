import { createReducer } from 'common/utils/reduxUtils';
import {
  ADD_NEW_NOTIFICATION,
  REMOVE_NOTIFICATIONS,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_REQUEST,
  FETCH_NOTIFICATIONS_FAILURE,
  UPDATE_NOTIFICATIONS,
  SET_NOTIFICATIONS_UNREAD,
  TOGGLE_NOTIFICATIONS_MODAL,
} from 'notification/redux/types';

const initialState = {
  data: [],
  isUnread: false,
  unreadNum: 0,
  isModalVisible: false,
};

const notificationReducer = createReducer(
  { ...initialState },
  {
    [ADD_NEW_NOTIFICATION]: (state, action) => ({
      ...state,
      data: [action.payload.data, ...state.data],
    }),
    [FETCH_NOTIFICATIONS_SUCCESS]: (state, action) => {
      const { data } = action.payload.data;

      let unread = 0;

      data.forEach(item => {
        if (!item.read_at) {
          unread += 1;
        }
      });

      return {
        ...state,
        data: [...data],
        isUnread: unread !== 0,
        unreadNum: unread,
      };
    },
    [FETCH_NOTIFICATIONS_REQUEST]: state => ({
      ...state,
    }),
    [FETCH_NOTIFICATIONS_FAILURE]: state => ({
      ...state,
    }),

    [UPDATE_NOTIFICATIONS]: (state, action) => ({
      ...state,
      data: [...action.payload.data],
    }),
    [TOGGLE_NOTIFICATIONS_MODAL]: state => ({
      ...state,
      isModalVisible: !state.isModalVisible,
    }),

    [REMOVE_NOTIFICATIONS]: state => ({
      ...state,
      data: [],
    }),
    [SET_NOTIFICATIONS_UNREAD]: state => ({
      ...state,
      isUnread: false,
    }),
  },
);

export default notificationReducer;
