import React from 'react';
import {
  FacebookShareButton,
  WhatsappShareButton,
  FacebookIcon,
  WhatsappIcon,
} from 'react-share';

import PropTypes from 'prop-types';
import ModalContainer from 'common/components/modalContainer';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import './index.scss';

const propTypes = {
  closeModal: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  toggleEmailModal: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
});

const InvitePopUpView = ({ closeModal, translate, toggleEmailModal }) => (
  <ModalContainer notFullSize handleOutsideClick={closeModal}>
    <div className="invite-modal-options">
      <h2 className="invite-title">{translate('invite.title')}</h2>
      <div className="invite-modal-options__wrapper">
        <FacebookShareButton
          url="https://farmlifes.com"
          quote={translate('invite.facebook')}
          beforeOnClick={closeModal}
        >
          <FacebookIcon size={40} round />
        </FacebookShareButton>

        <WhatsappShareButton
          url="https://farmlifes.com"
          title={`${translate('invite.whatsapp')}`}
          separator={'\n'}
          beforeOnClick={closeModal}
        >
          <WhatsappIcon size={40} round />
        </WhatsappShareButton>

        <button
          type="button"
          onClick={() => {
            closeModal();
            toggleEmailModal();
          }}
          className="email-button"
        />
      </div>
    </div>
  </ModalContainer>
);

InvitePopUpView.propTypes = propTypes;

export default connect(mapStateToProps)(InvitePopUpView);
