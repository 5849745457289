export default function setForgotAccountErrors(error) {
  let errors = {
    submit: 'There was an error submitting the form.',
    email: '',
  };

  if (error.response.data.errors) {
    if (error.response.data.errors.email) {
      errors = {
        ...errors,
        ...{ email: error.response.data.errors.email.join(' ') },
      };
    }
  }

  return errors;
}
