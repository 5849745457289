import SecureLS from 'secure-ls';

const ls = new SecureLS({
  encodingType: 'des',
  isCompression: true,
  encryptionSecret: 'farmlifes',
});

export const initializeState = () => ({
  auth: {},
});

export const loadState = () => {
  try {
    const serializedState = ls.get('farmlifes');

    if (serializedState === null) return initializeState();

    const object = JSON.parse(serializedState);
    if (object.expireTime >= new Date().getTime()) return object.state;

    return initializeState();
  } catch (err) {
    return initializeState();
  }
};

export const loadToken = () => {
  try {
    const serializedState = ls.get('farmlifes');

    if (serializedState === null) return undefined;

    const object = JSON.parse(serializedState);
    if (object.expireTime >= new Date().getTime())
      return object.state.auth.token;

    return undefined;
  } catch (err) {
    return undefined;
  }
};

export const saveState = state => {
  try {
    const serializedState = JSON.stringify(state);
    ls.set('farmlifes', serializedState);
  } catch (err) {
    /* */
  }
};
