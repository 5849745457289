import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from 'common/components/button';
import UserAvatar from 'common/components/userAvatar';
import { Translate } from 'react-localize-redux';
import './index.scss';

const defaultProps = {
  isAdmin: false,
  toggleMembershipRequestsModal: null,
  token: '',
  userData: null,
  totalRequests: 0,
};

const propTypes = {
  toggleMembersListModal: PropTypes.func.isRequired,
  membersCount: PropTypes.number.isRequired,
  farmMembers: PropTypes.arrayOf(PropTypes.object).isRequired,
  isAdmin: PropTypes.bool,
  toggleMembershipRequestsModal: PropTypes.func,
  token: PropTypes.string,
  userData: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
  ),
  totalRequests: PropTypes.number,
};

const mapStateToProps = state => ({
  token: state.auth.token,
  userData: state.userData.data,
  totalRequests: state.farmMembershipRequests.totalRequests,
});

const FarmMembers = ({
  toggleMembersListModal,
  membersCount,
  farmMembers,
  isAdmin,
  toggleMembershipRequestsModal,
  token,
  userData,
  totalRequests,
}) => (
  <div className="farm-members">
    {membersCount > 0 && (
      <div className="farm-members__container">
        {token && userData ? (
          <Button
            className="farm-members__members"
            onClick={toggleMembersListModal}
          >
            {membersCount}
            {' '}
            {membersCount === 1 ? (
              <Translate id="common.reaction.member" />
            ) : (
              <Translate id="common.reaction.members" />
            )}
          </Button>
        ) : (
          <div className="farm-members__members">
            {membersCount}
            {' '}
            {membersCount === 1 ? (
              <Translate id="common.reaction.member" />
            ) : (
              <Translate id="common.reaction.members" />
            )}
          </div>
        )}
        <div className="farm-members__avatars">
          {farmMembers.slice(0, 4).map(member => (
            <div className="farm-members__avatar" key={member.id}>
              <UserAvatar id={member.id} image={member.avatar} />
            </div>
          ))}
        </div>
      </div>
    )}
    {isAdmin && (
      <Button
        className="farm-members__requests"
        onClick={toggleMembershipRequestsModal}
      >
        <Translate id="common.seeMembershipReqeusts" />
        {totalRequests > 0 && <span className="indicator" />}
      </Button>
    )}
  </div>
);

FarmMembers.defaultProps = defaultProps;

FarmMembers.propTypes = propTypes;

export default connect(mapStateToProps)(FarmMembers);
