import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import UserRegisterView from 'userAuth/components/userRegisterView';
import setRegistrationErrors from 'userAuth/utils/registrationErrors';
import { registerUser } from 'userAuth/api/authApi';
import { setUserEmail } from 'userAuth/redux/actions/authActions';

const defaultProps = {
  history: {},
};

const propTypes = {
  setUserEmail: PropTypes.func.isRequired,
  history: PropTypes.object,
};

const mapDispatchToProps = dispatch => ({
  setUserEmail: bindActionCreators(setUserEmail, dispatch),
});

class UserRegister extends Component {
  state = {
    loader: false,
  };

  submitRegisterForm = (values, actions) => {
    const {
      firstName,
      lastName,
      birthdateDay,
      birthdateMonth,
      birthdateYear,
      email,
      password,
      passwordConfirmation,
      postCode,
      terms,
      gdpr,
    } = values;

    const { history, setUserEmail } = this.props;

    localStorage.setItem('tempPass', password);
    localStorage.setItem('tempEmail', email);

    const bDay =
      parseInt(birthdateDay, 10) > 9
        ? birthdateDay
        : `0${parseInt(birthdateDay, 10)}`;
    const bMonth =
      parseInt(birthdateMonth, 10) > 9
        ? birthdateMonth
        : `0${parseInt(birthdateMonth, 10)}`;

    registerUser({
      first_name: firstName,
      last_name: lastName,
      birth_date: `${birthdateYear}-${bMonth}-${bDay}`,
      email,
      password,
      password_confirmation: passwordConfirmation,
      post_code: postCode,
      accepts_terms: terms && gdpr,
    })
      .then(() => {
        setUserEmail(email);
        history.push('/verify');
      })
      .catch(error => {
        actions.setErrors(setRegistrationErrors(error));
        actions.setSubmitting(false);
      });
  };

  render() {
    const { loader } = this.state;
    return (
      <UserRegisterView
        loader={loader}
        submitRegisterForm={this.submitRegisterForm}
      />
    );
  }
}

UserRegister.propTypes = propTypes;
UserRegister.defaultProps = defaultProps;

export default connect(
  null,
  mapDispatchToProps,
)(withRouter(UserRegister));
