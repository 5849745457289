import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import classNames from 'classnames';
import { GoogleApiWrapper, Map, InfoWindow, Marker } from 'google-maps-react';
import Pin from 'assets/icons/pin-sm.png';
import { Translate } from 'react-localize-redux';

const defaultProps = {
  farmsList: [],
};

const propTypes = {
  farmsList: PropTypes.arrayOf(PropTypes.object),
};

export class FarmsListMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showingInfoWindow: false,
      activeMarker: {},
      farmName: '',
      farmAddress: '',
      farmId: '',
      mapFullWidth: false,
    };
  }

  onMarkerClick = (props, marker) => {
    this.setState({
      activeMarker: marker,
      showingInfoWindow: true,
      farmName: props.name,
      farmAddress: props.address,
      farmId: props.id,
    });
  };

  onMapClick = () => {
    if (!this.state.mapFullWidth) {
      this.setState({
        mapFullWidth: true,
        showingInfoWindow: false,
      });
    }
  };

  onInfoWindowClose = () => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
        farmName: '',
        farmAddress: '',
        farmId: '',
      });
    }
  };

  renderMarkers = () => {
    const { farmsList } = this.props;

    const markers = [];

    farmsList.forEach((item, key) => {
      if (item.lat && item.lng) {
        markers.push(
          <Marker
            key={key}
            onClick={this.onMarkerClick}
            name={item.name}
            address={item.adress}
            id={item.id}
            position={{ lat: item.lat, lng: item.lng }}
            icon={{
              url: Pin,
              anchor: new window.google.maps.Point(12.5, 40.5),
              scaledSize: new window.google.maps.Size(25, 40.5),
            }}
          />,
        );
      }
    });

    return markers;
  };

  toggleFarmClick = () => {
    this.setState({
      showingInfoWindow: false,
      activeMarker: null,
    });
  };

  closeMap = () => {
    this.setState({
      mapFullWidth: false,
      showingInfoWindow: false,
      activeMarker: null,
    });
  };

  render() {
    const { farmsList } = this.props;
    const {
      showingInfoWindow,
      activeMarker,
      farmName,
      farmAddress,
      farmId,
      mapFullWidth,
    } = this.state;

    const points = [];

    farmsList.forEach(item => {
      if (item.lat && item.lng) {
        points.push({ lat: parseFloat(item.lat), lng: parseFloat(item.lng) });
      }
    });

    const markers = this.renderMarkers();

    const wrapperClasses = classNames({
      'farms-list-map-wrapper': true,
      'farms-list-map-wrapper__full': mapFullWidth,
    });

    const imageWrapperClasses = classNames({
      'farms-list-map-image-wrapper': true,
      'farms-list-map-image-wrapper__full': mapFullWidth,
    });

    return (
      <div className={wrapperClasses}>
        <div className={imageWrapperClasses}>
          {mapFullWidth && (
            <button onClick={this.closeMap} className="close-map-button">
              <Translate id="common.action.closeFarm" />
            </button>
          )}

          {points.length > 0 && (
            <Map
              key={mapFullWidth}
              google={this.props.google}
              onClick={this.onMapClick}
              initialCenter={points[0]}
              style={{ width: '100%', height: '100%', position: 'relative' }}
              disableDefaultUI={!mapFullWidth}
              streetViewControl={false}
              fullscreenControl={false}
              zoom={5}
            >
              {markers}

              <InfoWindow
                marker={activeMarker}
                visible={showingInfoWindow}
                onClose={this.onInfoWindowClose}
              >
                <div className="map-marker">
                  <span className="farmlifes-icon" />
                  <a
                    href={`/farms/${farmId}`}
                    onClick={() => {
                      this.toggleFarmClick();
                    }}
                  >
                    <span className="map-farm-name">{farmName}</span>
                  </a>
                  <span className="map-farm-address">{farmAddress}</span>
                </div>
              </InfoWindow>
            </Map>
          )}
        </div>
      </div>
    );
  }
}

FarmsListMap.propTypes = propTypes;
FarmsListMap.defaultProps = defaultProps;

export default GoogleApiWrapper({
  apiKey: 'AIzaSyCyEKa9DEjWyxKUpMfEIuPw7s-8G4VIjyo',
  language: process.env.REACT_APP_LANGUAGE,
})(FarmsListMap);
