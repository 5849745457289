import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import NotificationsBellView from '../components/bell/index';
import {
  toggleNotificationsModal,
  fetchNotifications,
} from '../redux/actions/notificationActions';

const defaultProps = {
  isUnread: false,
  toggleNotificationsModal: () => null,
  fetchNotifications: () => null,
  toggleGuestModal: () => null,
  userData: {},
};

const propTypes = {
  isUnread: PropTypes.bool,
  toggleNotificationsModal: PropTypes.func,
  fetchNotifications: PropTypes.func,
  toggleGuestModal: PropTypes.func,
  userData: PropTypes.object,
};

const mapStateToProps = state => ({
  isUnread: state.notifications.isUnread,
});

const mapDispatchToProps = dispatch => ({
  toggleNotificationsModal: bindActionCreators(
    toggleNotificationsModal,
    dispatch,
  ),
  fetchNotifications: bindActionCreators(fetchNotifications, dispatch),
});

class NotificationsBell extends Component {
  state = {};

  render() {
    const {
      isUnread,
      toggleNotificationsModal,
      fetchNotifications,
      userData,
      toggleGuestModal,
    } = this.props;

    return (
      <NotificationsBellView
        isUnread={isUnread}
        toggleNotificationsModal={toggleNotificationsModal}
        fetchNotifications={fetchNotifications}
        userData={userData}
        toggleGuestModal={toggleGuestModal}
      />
    );
  }
}

NotificationsBell.defaultProps = defaultProps;

NotificationsBell.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationsBell);
