import axios from 'axios';
import * as types from 'common/redux/types';
import { createActionCreator } from 'common/utils/reduxUtils';

const {
  FETCH_USER_DATA_REQUEST,
  FETCH_USER_DATA_SUCCESS,
  FETCH_USER_DATA_FAILURE,
  REMOVE_USER_DATA,
} = types;

const fetchUserDataRequest = createActionCreator(FETCH_USER_DATA_REQUEST);
const fetchUserDataSuccess = createActionCreator(
  FETCH_USER_DATA_SUCCESS,
  'data',
);
const fetchUserDataFailure = createActionCreator(FETCH_USER_DATA_FAILURE);

const removeUserDataAction = createActionCreator(REMOVE_USER_DATA);

export const fetchUserData = () => dispatch => {
  dispatch(fetchUserDataRequest());
  return axios
    .get(`${process.env.REACT_APP_API_URL}/user`)
    .then(response => {
      dispatch(fetchUserDataSuccess(response.data.data));
    })
    .catch(() => {
      dispatch(fetchUserDataFailure());
    });
};

export const fetchUserDataFirstTime = token => dispatch => {
  dispatch(fetchUserDataRequest());

  return axios
    .get(`${process.env.REACT_APP_API_URL}/user`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(response => {
      if (response.data.data && response.data.data.email_verified_at) {
        dispatch(fetchUserDataSuccess(response.data.data));
        return true;
      }
      dispatch(fetchUserDataFailure());
      return false;
    })
    .catch(() => {
      dispatch(fetchUserDataFailure());
      return false;
    });
};

export const removeUserData = () => dispatch => {
  dispatch(removeUserDataAction({}));
};
