import React from 'react';
import PropTypes from 'prop-types';
import Button from 'common/components/button';
import MainButton from 'common/components/mainButton';
import ProfileImage from 'common/components/profileImage';
import FarmMembers from 'farm/components/farmMembers';
import InviteModal from 'common/containers/inviteModal';
import { Translate } from 'react-localize-redux';
import renderHTML from 'react-render-html';
import './index.scss';

const defaultProps = {
  toggleCreateFarmModal: null,
  isAdmin: false,
  isMember: false,
  toggleMembershipRequestsModal: null,
  membersResultsNumber: 0,
};

const propTypes = {
  farm: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.object,
      PropTypes.array,
      PropTypes.bool,
    ]),
  ).isRequired,
  farmMembers: PropTypes.arrayOf(PropTypes.object).isRequired,
  toggleCreateFarmModal: PropTypes.func,
  isAdmin: PropTypes.bool,
  isMember: PropTypes.bool,
  membersResultsNumber: PropTypes.number,
  toggleMembersListModal: PropTypes.func.isRequired,
  toggleMembershipRequestsModal: PropTypes.func,
};

const FarmHeaderDesktop = ({
  farm,
  farmMembers,
  toggleCreateFarmModal,
  toggleMembersListModal,
  isAdmin,
  isMember,
  membersResultsNumber,
  toggleMembershipRequestsModal,
}) => (
  <div className="farm-header-desktop">
    {(isAdmin || isMember) && (
      <Button
        className="farm-header-desktop__edit-button"
        onClick={toggleCreateFarmModal}
      />
    )}
    <div className="farm-header-desktop__image">
      <ProfileImage
        profileImage={farm.profile_image && farm.profile_image.imageUrl}
      />
    </div>
    <div className="farm-header-desktop__top-container">
      <p className="farm-header-desktop__name">{farm.name}</p>
      {farm.category && (
        <p className="farm-header-desktop__category">
          <Translate id={`farm.categories.${farm.category.name}`} />
        </p>
      )}

      {farm.farm_area && (
        <p className="farm-header-desktop__farm-area-label">
          <Translate id="page.farmProfile.createFarm.size" />
          <span className="farm-header-desktop__farm-area">
            {' '}
            {parseInt(farm.farm_area, 10)}
            {' '}
ha
          </span>
        </p>
      )}

      {farm.posts_count > 0 && (
        <p className="farm-header-desktop__posts">
          {farm.posts_count}
          {' '}
          {farm.posts_count === 1 ? (
            <Translate id="common.reaction.post" />
          ) : (
            <Translate id="common.reaction.posts" />
          )}
        </p>
      )}
    </div>
    <FarmMembers
      toggleMembersListModal={toggleMembersListModal}
      membersCount={membersResultsNumber}
      farmMembers={farmMembers}
      isAdmin={isAdmin}
      toggleMembershipRequestsModal={toggleMembershipRequestsModal}
    />
    <div className="farm-header-desktop__address-container">
      {farm.adress && (
        <p className="farm-header-desktop__address">{farm.adress}</p>
      )}
      {/* farm.city && <p className="farm-header-desktop__address">{farm.city}</p> */}
    </div>

    {farm.description && (
      <p className="farm-header-desktop__description">
        {renderHTML(farm.description.replace(/(?:\r\n|\r|\n)/g, '<br>'))}
      </p>
    )}
    {(isAdmin || isMember) && (
      <div className="farm-header-desktop__invite">
        <InviteModal farmId={farm.id}>
          {({ handleInviteButtonClick }) => (
            <MainButton onClick={handleInviteButtonClick}>
              <Translate id="common.action.inviteFriends" />
            </MainButton>
          )}
        </InviteModal>
      </div>
    )}
  </div>
);

FarmHeaderDesktop.defaultProps = defaultProps;

FarmHeaderDesktop.propTypes = propTypes;

export default FarmHeaderDesktop;
