import React from 'react';
import PropTypes from 'prop-types';
import Button from 'common/components/button';
import { Translate } from 'react-localize-redux';
import './index.scss';

const defaultProps = {
  feed: '',
  path: '',
};

const propTypes = {
  post: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
      PropTypes.object,
    ]),
  ).isRequired,
  handleShowLikesClick: PropTypes.func.isRequired,
  handleShowSharesClick: PropTypes.func.isRequired,
  onCommentsClick: PropTypes.func.isRequired,
  feed: PropTypes.string,
  path: PropTypes.string,
};

const PostActivitiesView = ({
  handleShowLikesClick,
  handleShowSharesClick,
  post,
  onCommentsClick,
  feed,
  path,
}) => (
  <div className="post-activities">
    <div className="post-activities__left-container">
      {(path === '/marketplace' || feed === 'news') && post.views_count > 0 && (
        <Button onClick={() => null}>
          <span className="post-activities__button-content post-activities__button-content--views">
            {post.views_count}
          </span>
        </Button>
      )}
      {post.likes_count > 0 && (
        <Button onClick={handleShowLikesClick}>
          <span className="post-activities__button-content post-activities__button-content--likes">
            {post.likes_count}
            &nbsp;
            {post.likes_count === 1 ? (
              <Translate id="common.reaction.like" />
            ) : (
              <Translate id="common.reaction.likes" />
            )}
          </span>
        </Button>
      )}
    </div>
    <div className="post-activities__right-container">
      {post.comments_count > 0 && (
        <Button onClick={onCommentsClick}>
          <span className="post-activities__button-content post-activities__button-content--comments">
            {post.comments_count}
            &nbsp;
            {post.comments_count === 1 ? (
              <Translate id="common.reaction.comment" />
            ) : (
              <Translate id="common.reaction.comments" />
            )}
          </span>
        </Button>
      )}
      {post.shares_count > 0 && (
        <Button onClick={handleShowSharesClick}>
          <span className="post-activities__button-content post-activities__button-content--shares">
            {post.shares_count}
            &nbsp;
            {post.shares_count === 1 ? (
              <Translate id="common.reaction.share" />
            ) : (
              <Translate id="common.reaction.shares" />
            )}
          </span>
        </Button>
      )}
    </div>
  </div>
);

PostActivitiesView.propTypes = propTypes;
PostActivitiesView.defaultProps = defaultProps;

export default PostActivitiesView;
