import React from 'react';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import './index.scss';

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
});

const NoNotifications = ({ translate }) => (
  <div className="notifications-no-more">
    <p className="notifications-no-more-message">
      {translate('notification.noMore')}
    </p>
  </div>
);

export default connect(mapStateToProps)(NoNotifications);
