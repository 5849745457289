import axios from 'axios';
import * as types from 'farms/redux/types';
import { createActionCreator } from 'common/utils/reduxUtils';

const {
  FETCH_FARMS_TO_JOIN_LIST_REQUEST,
  FETCH_FARMS_TO_JOIN_LIST_SUCCESS,
  FETCH_FARMS_TO_JOIN_LIST_FAILURE,
  REMOVE_FARMS_TO_JOIN_LIST,
  UPDATE_FARMS_TO_JOIN_LIST_QUERY,
} = types;

const fetchFarmsToJoinListRequest = createActionCreator(
  FETCH_FARMS_TO_JOIN_LIST_REQUEST,
);
const fetchFarmsToJoinListSuccess = createActionCreator(
  FETCH_FARMS_TO_JOIN_LIST_SUCCESS,
  'data',
);
const fetchFarmsToJoinListFailure = createActionCreator(
  FETCH_FARMS_TO_JOIN_LIST_FAILURE,
);

export const fetchFarmsToJoinList = (page = 1) => (dispatch, getState) => {
  const { query } = getState().farmsToJoinList;
  dispatch(fetchFarmsToJoinListRequest());
  return axios
    .get(`${process.env.REACT_APP_API_URL}/farms`, {
      params: {
        page,
        query,
      },
    })
    .then(response => {
      dispatch(fetchFarmsToJoinListSuccess(response.data));
    })
    .catch(() => {
      dispatch(fetchFarmsToJoinListFailure());
    });
};

export const updateFarmsToJoinListQuery = createActionCreator(
  UPDATE_FARMS_TO_JOIN_LIST_QUERY,
  'query',
);

export const removeFarmsToJoinList = createActionCreator(
  REMOVE_FARMS_TO_JOIN_LIST,
);
