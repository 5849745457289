import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

const defaultProps = {
  top: null,
  right: null,
};

const propTypes = {
  top: PropTypes.number,
  right: PropTypes.number,
};

const Indicator = ({ right, top }) => (
  <span className="indicator" style={{ top, right }} />
);

Indicator.defaultProps = defaultProps;
Indicator.propTypes = propTypes;

export default Indicator;
