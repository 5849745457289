import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import renderHTML from 'react-render-html';
import { Link } from 'react-router-dom';
import ProfileImage from 'common/components/profileImage';
import Button from 'common/components/button';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import MainButton from 'common/components/mainButton';
import InviteModal from 'common/containers/inviteModal';
import './index.scss';

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
});

const defaultProps = {
  postsCount: 0,
};

const propTypes = {
  userProfile: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ).isRequired,
  toggleEditProfileModal: PropTypes.func.isRequired,
  isProfileOwner: PropTypes.bool.isRequired,
  postsCount: PropTypes.number,
};

const ProfileHeaderView = ({
  userProfile,
  toggleEditProfileModal,
  isProfileOwner,
  postsCount,
  translate,
}) => {
  const articlesCountClasses = classNames({
    'profile-header__articles-count': true,
    'profile-header__articles-count--divider':
      postsCount > 0 && userProfile.articles_count > 0,
  });
  return (
    <div className="profile-header">
      <div className="profile-header__main-content">
        <div className="profile-header__image">
          <ProfileImage profileImage={userProfile.avatar} isUserProfile />
        </div>
        <div className="profile-header__data">
          <div className="profile-header__name-container">
            <p className="profile-header__name">{userProfile.name}</p>
            {isProfileOwner && (
              <Button
                className="profile-header__edit-button"
                onClick={toggleEditProfileModal}
              />
            )}
          </div>
          <Link
            className="profile-header__farm-name"
            to={`/farms/${userProfile.farm_id}`}
          >
            {userProfile.farm_name}
          </Link>
          <div className="profile-header__counters">
            {postsCount > 0 && (
              <p className="profile-header__posts-count">
                {userProfile.posts_count}
                {' '}
                {userProfile.posts_count === 1
                  ? translate('common.reaction.post')
                  : translate('common.reaction.posts')}
              </p>
            )}
            {userProfile.articles_count > 0 && (
              <p className={articlesCountClasses}>
                {userProfile.articles_count}
                {' '}
                {userProfile.articles_count === 1
                  ? translate('common.reaction.article')
                  : translate('common.reaction.articles')}
              </p>
            )}
          </div>
        </div>
      </div>
      {userProfile.description && (
        <p className="profile-header__description">
          {userProfile.description &&
            renderHTML(
              userProfile.description.replace(/(?:\r\n|\r|\n)/g, '<br>'),
            )}
        </p>
      )}
      <div className="farm-header-desktop__invite">
        <InviteModal>
          {({ handleInviteButtonClick }) => (
            <MainButton onClick={handleInviteButtonClick}>
              <Translate id="common.action.inviteFriends" />
            </MainButton>
          )}
        </InviteModal>
      </div>
    </div>
  );
};

ProfileHeaderView.defaultProps = defaultProps;

ProfileHeaderView.propTypes = propTypes;

export default connect(mapStateToProps)(ProfileHeaderView);
