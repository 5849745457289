import React from 'react';
import PropTypes from 'prop-types';
import ImageWithLoader from 'common/components/imageWithLoader';
import CloseButton from 'common/components/closeButton';
import ModalContainer from 'common/components/modalContainer';
import './index.scss';

const defaultProps = {
  parent: '',
};

const propTypes = {
  imageObj: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ).isRequired,
  onClose: PropTypes.func.isRequired,
  parent: PropTypes.string,
};

const ImagePreviewModal = ({ imageObj, onClose, parent }) => (
  <ModalContainer handleOutsideClick={onClose}>
    <div className="image-preview-modal">
      <div className="image-preview-modal__close">
        <CloseButton onClick={onClose} />
      </div>
      <ImageWithLoader image={imageObj} isAutoSize parent={parent || 'modal'} />
    </div>
  </ModalContainer>
);

ImagePreviewModal.propTypes = propTypes;
ImagePreviewModal.defaultProps = defaultProps;

export default ImagePreviewModal;
