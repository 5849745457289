import React from 'react';
import { Link } from 'react-router-dom';
import UserLogin from 'userAuth/containers/userLogin';
import Logo from 'assets/icons/logo.svg';
import PropTypes from 'prop-types';
import './index.scss';

const defaultProps = {
  login: false,
};

const propTypes = {
  login: PropTypes.bool,
};

const AuthHeader = ({ login }) => (
  <div className="auth-header">
    <div className="auth-header__container">
      <div className="auth-header__content">
        <Link to="/auth">
          <img className="auth-header__logo" src={Logo} alt="Farmlifes logo" />
        </Link>
        {login && (
          <div className="auth-header__login">
            <UserLogin />
          </div>
        )}
      </div>
    </div>
  </div>
);

AuthHeader.defaultProps = defaultProps;

AuthHeader.propTypes = propTypes;

export default AuthHeader;
