import React, { Component } from 'react';
import Carousel from 'nuka-carousel';
import './index.scss';

class PagingDots extends Component {
  getIndexes = (count, inc) => {
    const arr = [];
    for (let i = 0; i < count; i += inc) {
      arr.push(i);
    }
    return arr;
  };

  getListStyles = () => ({
    position: 'relative',
    margin: 0,
    padding: 0,
    height: 30,
  });

  getListItemStyles = () => ({
    listStyleType: 'none',
    display: 'inline-block',
    height: 12,
    position: 'relative',
    padding: 5,
  });

  getButtonStyles = active => ({
    background: active ? '#88bd23' : 'transparent',
    cursor: 'pointer',
    outline: 0,
    border: '2px solid #88bd23',
    borderRadius: '100%',
    width: 12,
    height: 12,
  });

  render() {
    const { currentSlide, goToSlide, slideCount, slidesToScroll } = this.props;
    const indexes = this.getIndexes(slideCount, slidesToScroll);
    return (
      <ul style={this.getListStyles()}>
        {indexes.map(index => (
          <li style={this.getListItemStyles()} key={index}>
            <button
              type="button"
              style={this.getButtonStyles(currentSlide === index)}
              onClick={goToSlide.bind(null, index)}
            />
          </li>
        ))}
      </ul>
    );
  }
}

const CustomCarousel = ({ children, onSlideChanged }) => (
  <Carousel
    afterSlide={slideIndex =>
      onSlideChanged ? onSlideChanged(slideIndex) : null
    }
    renderBottomCenterControls={props => <PagingDots {...props} />}
    renderCenterLeftControls={({ previousSlide, currentSlide }) => (
      <button
        type="button"
        className={`${
          currentSlide !== 0
            ? 'carousel__prev-slide carousel__prev-slide--active'
            : 'carousel__prev-slide'
        }`}
        onClick={previousSlide}
      />
    )}
    renderCenterRightControls={({ nextSlide, currentSlide, slideCount }) => (
      <button
        type="button"
        className={`${
          currentSlide !== slideCount - 1
            ? 'carousel__next-slide carousel__next-slide--active'
            : 'carousel__next-slide'
        }`}
        onClick={nextSlide}
      />
    )}
  >
    {children}
  </Carousel>
);

export default CustomCarousel;
