import axios from 'axios';
import * as types from 'common/redux/types';
import { createActionCreator } from 'common/utils/reduxUtils';

const {
  FETCH_FARM_CATEGORIES_REQUEST,
  FETCH_FARM_CATEGORIES_SUCCESS,
  FETCH_FARM_CATEGORIES_FAILURE,
} = types;

const fetchFarmCategoriesRequest = createActionCreator(
  FETCH_FARM_CATEGORIES_REQUEST,
);
const fetchFarmCategoriesSuccess = createActionCreator(
  FETCH_FARM_CATEGORIES_SUCCESS,
  'data',
);
const fetchFarmCategoriesFailure = createActionCreator(
  FETCH_FARM_CATEGORIES_FAILURE,
);

const fetchFarmCategories = () => dispatch => {
  dispatch(fetchFarmCategoriesRequest());
  return axios
    .get(`${process.env.REACT_APP_API_URL}/farm_categories`)
    .then(response => {
      dispatch(fetchFarmCategoriesSuccess(response.data.data));
    })
    .catch(() => {
      dispatch(fetchFarmCategoriesFailure());
    });
};

export default fetchFarmCategories;
