import axios from 'axios';
import * as types from 'notification/redux/types';
import { createActionCreator } from 'common/utils/reduxUtils';

const {
  FETCH_NOTIFICATIONS_REQUEST,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_FAILURE,
  REMOVE_NOTIFICATIONS,
  SET_NOTIFICATIONS_UNREAD,
  TOGGLE_NOTIFICATIONS_MODAL,
} = types;

const fetchNotificationsRequest = createActionCreator(
  FETCH_NOTIFICATIONS_REQUEST,
);
const fetchNotificationsSuccess = createActionCreator(
  FETCH_NOTIFICATIONS_SUCCESS,
  'data',
);
const fetchNotificationsFailure = createActionCreator(
  FETCH_NOTIFICATIONS_FAILURE,
);

export const fetchNotifications = () => (dispatch, getState) => {
  dispatch(fetchNotificationsRequest());
  if (getState().userData.data) {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/notifications`, {
        headers: {
          Authorization: `Bearer ${getState().auth.token}`,
        },
      })
      .then(response => {
        dispatch(fetchNotificationsSuccess(response.data));
      })
      .catch(error => {
        dispatch(fetchNotificationsFailure());
        if (error.response && error.response.status === 401) {
          window.location.reload(true);
        }
      });
  }
  return false;
};

export const setUnreadNotifications = createActionCreator(
  SET_NOTIFICATIONS_UNREAD,
);

export const updateNotifications = () => dispatch =>
  axios.put(`${process.env.REACT_APP_API_URL}/notifications`).then(() => {
    dispatch(setUnreadNotifications());
  });

export const setNotificationToRead = id => dispatch =>
  axios
    .put(`${process.env.REACT_APP_API_URL}/notification`, {
      id,
    })
    .then(() => {
      dispatch(fetchNotifications());
    });

export const removeNotifications = createActionCreator(REMOVE_NOTIFICATIONS);
export const toggleNotificationsModal = createActionCreator(
  TOGGLE_NOTIFICATIONS_MODAL,
);
