import axios from 'axios';
import * as types from 'post/redux/types';
import { createActionCreator } from 'common/utils/reduxUtils';

const {
  FETCH_SHARES_REQUEST,
  FETCH_SHARES_SUCCESS,
  FETCH_SHARES_FAILURE,
  REMOVE_SHARES,
} = types;

const fetchSharesRequest = createActionCreator(FETCH_SHARES_REQUEST);
const fetchSharesSuccess = createActionCreator(FETCH_SHARES_SUCCESS, 'data');
const fetchSharesFailure = createActionCreator(FETCH_SHARES_FAILURE);

export const fetchShares = (id, page = 1) => (dispatch, getState) => {
  const { currentTimestamp } = getState().shares;
  dispatch(fetchSharesRequest());
  return axios
    .get(`${process.env.REACT_APP_API_URL}/shares`, {
      params: {
        id,
        page,
        type: 'post',
        to: currentTimestamp,
      },
    })
    .then(response => {
      dispatch(fetchSharesSuccess(response.data));
    })
    .catch(() => {
      dispatch(fetchSharesFailure());
    });
};

export const removeShares = createActionCreator(REMOVE_SHARES);
