import axios from 'axios';
import * as types from 'post/redux/types';
import { createActionCreator } from 'common/utils/reduxUtils';

const {
  FETCH_COMMENTS_REQUEST,
  FETCH_COMMENTS_SUCCESS,
  FETCH_COMMENTS_FAILURE,
  UPDATE_COMMENTS,
  REMOVE_COMMENTS,
  REMOVE_SINGLE_COMMENT,
  REMOVE_SINGLE_COMMENTS_LIST,
  SAVE_OPEN_COMMENT_ID,
  REMOVE_OPEN_COMMENT_ID,
} = types;

const fetchCommentsRequest = createActionCreator(FETCH_COMMENTS_REQUEST);
const fetchCommentsSuccess = createActionCreator(
  FETCH_COMMENTS_SUCCESS,
  'data',
  'id',
);
const fetchCommentsFailure = createActionCreator(FETCH_COMMENTS_FAILURE);

export const fetchComments = (id, page = 1) => (dispatch, getState) => {
  const { currentTimestamp } = getState().comments.data[id]
    ? getState().comments.data[id]
    : {};
  dispatch(fetchCommentsRequest());
  return axios
    .get(`${process.env.REACT_APP_API_URL}/comments`, {
      params: {
        id,
        page,
        type: 'post',
        to: currentTimestamp,
      },
    })
    .then(response => {
      dispatch(fetchCommentsSuccess(response.data, id));
    })
    .catch(() => {
      dispatch(fetchCommentsFailure());
    });
};

export const updateCommentsList = createActionCreator(
  UPDATE_COMMENTS,
  'data',
  'id',
);

export const removeComments = createActionCreator(REMOVE_COMMENTS);

export const removeSingleComment = createActionCreator(
  REMOVE_SINGLE_COMMENT,
  'postId',
  'commentId',
);

export const removeSingleCommentsList = createActionCreator(
  REMOVE_SINGLE_COMMENTS_LIST,
  'id',
);

export const saveOpenCommentId = createActionCreator(
  SAVE_OPEN_COMMENT_ID,
  'id',
);

export const removeOpenCommentId = createActionCreator(
  REMOVE_OPEN_COMMENT_ID,
  'id',
);
