import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  setActiveFeed,
  checkForUnreadPosts,
} from 'newsfeed/redux/actions/newsfeedActions';
import FeedsTogglerView from '../components/feedsTooglerView/index';

const defaultProps = {
  activeFeed: 'farm',
};

const propTypes = {
  activeFeed: PropTypes.string,
  setActiveFeed: PropTypes.func.isRequired,
  unreadNews: PropTypes.bool.isRequired,
  checkForUnreadPosts: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  activeFeed: state.newsfeed.activeFeed,
  unreadNews: state.newsfeed.unreadNews,
});

const mapDispatchToProps = dispatch => ({
  setActiveFeed: bindActionCreators(setActiveFeed, dispatch),
  checkForUnreadPosts: bindActionCreators(checkForUnreadPosts, dispatch),
});

class FeedsToggler extends Component {
  state = {};

  toggleFeeds = type => {
    const { setActiveFeed, activeFeed, checkForUnreadPosts } = this.props;

    if (activeFeed !== type) {
      setActiveFeed(type);

      setTimeout(() => {
        checkForUnreadPosts();
      }, 500);
    }
  };

  render() {
    const { activeFeed, unreadNews } = this.props;

    return (
      <FeedsTogglerView
        toggleFeeds={this.toggleFeeds}
        activeFeed={activeFeed}
        unreadNews={unreadNews}
      />
    );
  }
}

FeedsToggler.defaultProps = defaultProps;

FeedsToggler.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FeedsToggler);
