import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import disableScroll from 'common/utils/disableScroll';
import ActivitiesModal from 'post/containers/activitiesModal';
import PostDetailsModalView from 'post/components/postDetailsModalView';

const defaultProps = {
  farmId: null,
  feed: ''
};

const propTypes = {
  newsfeed: PropTypes.arrayOf(PropTypes.object).isRequired,
  post: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
      PropTypes.object,
    ]),
  ).isRequired,
  togglePostDetailsModal: PropTypes.func.isRequired,
  toggleImagePreviewModal: PropTypes.func.isRequired,
  farmId: PropTypes.number,
  feed: PropTypes.string
};

class PostDetailsModal extends Component {
  state = {
    height: null,
    width: null,
    isActivitiesModalVisible: false,
  };

  componentDidMount() {
    this.setHeight();
    window.addEventListener('resize', this.setHeight);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setHeight);
    disableScroll(false);
  }

  setHeight = () => {
    this.setState({
      height: document.getElementById('post-details-modal').offsetHeight,
      width: window.innerWidth,
    });
  };

  toggleActivitiesModal = () => {
    if (window.innerWidth < 992) {
      const { isActivitiesModalVisible } = this.state;
      this.setState(
        {
          isActivitiesModalVisible: !isActivitiesModalVisible,
        },
        () => disableScroll(!isActivitiesModalVisible),
      );
    }
  };

  render() {
    const {
      newsfeed,
      post,
      togglePostDetailsModal,
      toggleImagePreviewModal,
      farmId,
      feed
    } = this.props;
    const { height, width, isActivitiesModalVisible } = this.state;
    return (
      <Fragment>
        <PostDetailsModalView
          newsfeed={newsfeed}
          feed={feed}
          post={post}
          togglePostDetailsModal={togglePostDetailsModal}
          toggleImagePreviewModal={toggleImagePreviewModal}
          toggleActivitiesModal={this.toggleActivitiesModal}
          height={height}
          width={width}
          farmId={farmId}
        />
        {isActivitiesModalVisible && (
          <ActivitiesModal
            post={post.shared_content ? post.shared_content : post}
            closeModal={this.toggleActivitiesModal}
            activeSection="comments"
          />
        )}
      </Fragment>
    );
  }
}

PostDetailsModal.defaultProps = defaultProps;

PostDetailsModal.propTypes = propTypes;

export default PostDetailsModal;
