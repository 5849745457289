import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Linkify from 'linkifyjs/react';
import renderHTML from 'react-render-html';
import PostActivities from 'post/containers/postActivities';
import PostActions from 'post/containers/postActions';
import UserAvatar from 'common/components/userAvatar';
import FromNow from 'common/components/fromNow';
import CloseButton from 'common/components/closeButton';
import PostComments from 'post/containers/postComments';
import './index.scss';

const defaultProps = {
  farmId: null,
};

const propTypes = {
  newsfeed: PropTypes.arrayOf(PropTypes.object).isRequired,
  post: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
      PropTypes.object,
    ]),
  ).isRequired,
  togglePostDetailsModal: PropTypes.func.isRequired,
  isCommentSectionVisible: PropTypes.bool.isRequired,
  toggleCommentsSection: PropTypes.func.isRequired,
  farmId: PropTypes.number,
};

const TextPostDetailsModal = ({
  newsfeed,
  post,
  togglePostDetailsModal,
  isCommentSectionVisible,
  toggleCommentsSection,
  farmId,
}) => {
  const linkOption = {
    className: 'inpost-link',
    defaultProtocol: 'http',
    format(value) {
      return value.replace(/(?:http:\/\/|https:\/\/)/g, '');
    },
    formatHref(href) {
      return href;
    },
    target: {
      url: '_blank',
    },
  };

  if(post.text && post.text.length > 0) {
    var regexp = /(#\S+)/gi;
    var matches_array = post.text.match(regexp);

    if(matches_array && matches_array.length > 0) {
      matches_array.forEach((item, i) => {
        let newItem = item.replace(/ö/g, 'o').replace(/ä/g, 'a').replace(/ü/g, 'u');
        if(newItem !== item) {
          post.text = post.text.replace(item, newItem);
        }
      })
    }
  }

  return (
    <div className="text-post-details-modal">
      <div className="text-post-details-modal__details-container">
        <div className="text-post-details-modal__header">
          <div className="text-post-details-modal__header-details">
            <div className="text-post-details-modal__avatar">
              <UserAvatar
                id={post.author.id}
                image={post.author.avatar}
                type={post.author.type}
              />
            </div>
            <div>
              {post.author && (
                <Link
                  to={
                    post.author.type === 'farm'
                      ? `/farms/${post.author.id}`
                      : `/profile/${post.author.id}`
                  }
                >
                  <span className="text-post-details-modal__author">
                    {post.author.name}
                  </span>
                </Link>
              )}
              <FromNow time={post.created_at} />
            </div>
          </div>
          <div className="text-post-details-modal__close">
            <CloseButton onClick={togglePostDetailsModal} />
          </div>
        </div>
        <div className="text-post-details-modal__details-scroll">
          <div className="text-post-details-modal__text">
            {post.text && post.text.length > 0 && (
              <Linkify options={linkOption}>
                {renderHTML(
                  post.text && post.text.replace(/(?:\r\n|\r|\n)/g, '<br>'),
                )}
              </Linkify>
            )}
          </div>
        </div>
        <div className="text-post-details-modal__actions">
          <PostActivities post={post} onCommentsClick={toggleCommentsSection} />
          <PostActions
            post={post}
            newsfeed={newsfeed}
            onCommentsClick={toggleCommentsSection}
            farmId={farmId}
          />
        </div>
        {isCommentSectionVisible && (
          <div className="text-post-details-modal__comments">
            <PostComments post={post} />
          </div>
        )}
      </div>
    </div>
  );
};

TextPostDetailsModal.defaultProps = defaultProps;

TextPostDetailsModal.propTypes = propTypes;

export default TextPostDetailsModal;
