import React from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import Post from 'post/containers/post';
import Loader from 'common/components/loader';
import HashtagNoMorePosts from 'hashtag/components/hashtagNoMorePosts';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import './index.scss';

const defaultProps = {
  hashtagfeed: [],
  nextPageIndex: null,
  translate: () => null,
};

const propTypes = {
  hashtagfeed: PropTypes.arrayOf(PropTypes.object),
  getHashtagfeedNextPage: PropTypes.func.isRequired,
  nextPageIndex: PropTypes.number,
  isLoading: PropTypes.bool.isRequired,
  translate: PropTypes.func,
  hashtag: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
});

const HashtagView = ({
  hashtagfeed,
  getHashtagfeedNextPage,
  nextPageIndex,
  isLoading,
  translate,
  hashtag,
}) => (
  <div className="newsfeed-view">
    <h2 className="hashtag">{hashtag}</h2>
    <p>{translate('hashtag.hashtagDescription')}</p>

    {isLoading && hashtagfeed.length === 0 && (
      <Loader
        message={translate('common.load', {
          object: translate('common.reaction.posts'),
        })}
      />
    )}

    <InfiniteScroll
      dataLength={hashtagfeed.length}
      next={nextPageIndex ? getHashtagfeedNextPage : null}
      hasMore={!!nextPageIndex}
      scrollThreshold="400px"
      loader={
        nextPageIndex === 1 ? (
          <Loader
            message={translate('common.load', {
              object: translate('common.reaction.posts'),
            })}
          />
        ) : (
          <Loader
            message={translate('common.loadMore', {
              object: translate('common.reaction.posts'),
            })}
          />
        )
      }
      endMessage={hashtagfeed.length > 0 ? <HashtagNoMorePosts /> : null}
    >
      {hashtagfeed.map(post => (
        <div className="newsfeed-view__post" key={post.id}>
          <Post post={post} newsfeed={hashtagfeed} />
        </div>
      ))}
    </InfiniteScroll>
  </div>
);

HashtagView.defaultProps = defaultProps;

HashtagView.propTypes = propTypes;

export default connect(mapStateToProps)(HashtagView);
