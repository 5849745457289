import React from 'react';
import Input from 'common/components/input';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import './index.scss';

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
});

const SearchInput = ({
  value,
  onChange,
  placeholder,
  translate,
  toggleGuestModal,
  userData,
  onFocus,
}) => (
  <div className="search-input">
    <Input
      name="search"
      placeholder={placeholder || translate('common.action.searchHof')}
      isSearchIconVisible
      isIconVisible
      fullWidth
      value={value}
      userData={userData}
      onFocus={onFocus}
      toggleGuestModal={toggleGuestModal}
      onChange={onChange}
    />
  </div>
);

export default connect(mapStateToProps)(SearchInput);
