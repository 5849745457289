import axios from 'axios';
import * as types from 'farms/redux/types';
import { createActionCreator } from 'common/utils/reduxUtils';

const {
  FETCH_FARMS_LIST_REQUEST,
  FETCH_FARMS_LIST_SUCCESS,
  FETCH_FARMS_LIST_FAILURE,
  REMOVE_FARMS_LIST,
  UPDATE_FARMS_LIST_QUERY,
  FETCH_ACTIVE_FARMS_SUCCESS,
  FETCH_FOUND_USERS_SUCCESS,
  FETCH_USERS_LIST_REQUEST,
  FETCH_USERS_LIST_FAILURE,
} = types;

const fetchFarmsListRequest = createActionCreator(FETCH_FARMS_LIST_REQUEST);

const fetchFarmsListSuccess = createActionCreator(
  FETCH_FARMS_LIST_SUCCESS,
  'data',
);
const fetchActiveFarmsSuccess = createActionCreator(
  FETCH_ACTIVE_FARMS_SUCCESS,
  'data',
);
const fetchFarmsListFailure = createActionCreator(FETCH_FARMS_LIST_FAILURE);

const fetchUsersListRequest = createActionCreator(FETCH_USERS_LIST_REQUEST);
const fetchUsersListSuccess = createActionCreator(
  FETCH_FOUND_USERS_SUCCESS,
  'data',
);

const fetchUsersListFailure = createActionCreator(FETCH_USERS_LIST_FAILURE);

export const fetchFarmsList = (page = 1) => (dispatch, getState) => {
  const { query } = getState().farmsList;

  dispatch(fetchFarmsListRequest());
  return axios
    .get(`${process.env.REACT_APP_API_URL}/farms`, {
      params: {
        page,
        query,
      },
    })
    .then(response => {
      dispatch(fetchFarmsListSuccess(response.data));
    })
    .catch(() => {
      dispatch(fetchFarmsListFailure());
    });
};

export const fetchUsersList = (page = 1) => (dispatch, getState) => {
  const { query } = getState().farmsList;

  dispatch(fetchUsersListRequest());
  return axios
    .get(`${process.env.REACT_APP_API_URL}/users`, {
      params: {
        page,
        query,
      },
    })
    .then(response => {
      dispatch(fetchUsersListSuccess(response.data.data));
    })
    .catch(() => {
      dispatch(fetchUsersListFailure());
    });
};

export const fetchActiveFarms = () => dispatch =>
  axios
    .get(`${process.env.REACT_APP_API_URL}/farm/active`)
    .then(response => {
      dispatch(fetchActiveFarmsSuccess(response.data.data));
    })
    .catch(() => {});

export const updateFarmsListQuery = createActionCreator(
  UPDATE_FARMS_LIST_QUERY,
  'query',
);

export const removeFarmsList = createActionCreator(REMOVE_FARMS_LIST);
