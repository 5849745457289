import * as Yup from 'yup';
import { equalTo } from 'common/utils/validationUtils';
import localizeYup from 'common/utils/localizeYupDefaults.js';
import translateMessage from 'common/utils/translateMessage.js';

localizeYup();

Yup.addMethod(Yup.string, 'equalTo', equalTo);

export const registrationValidationSchema = Yup.object().shape({
  password: Yup.string()
    // .matches(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/, {
    .matches(/\S{8,}/, {
      message: translateMessage('form.error.passwordFormat'),
      excludeEmptyString: true,
    })
    .required(),
  passwordConfirmation: Yup.string()
    .equalTo(
      Yup.ref('password'),
      translateMessage('form.error.passwordMissmatch'),
    )
    .required(),
});

export default registrationValidationSchema;
