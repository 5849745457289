import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import InfoModal from 'common/components/infoModal';
import MembershipRequestsModalView from 'farm/components/membershipRequestsModalView';
import {
  fetchFarmMembershipRequests,
  removeFarmMembershipRequests,
  updateFarmMembershipRequests,
} from 'farm/redux/actions/farmMembershipRequestsActions';
import {
  createFarmMember,
  deleteFarmMembershipRequest,
} from 'farm/api/farmsApi';
import { getTranslate } from 'react-localize-redux';
import { fetchFarmMembers } from 'farm/redux/actions/farmMembersActions';

const defaultProps = {
  nextPageIndex: null,
  resultsNumber: 0,
  membershipRequests: [],
  translate: () => null,
};

const propTypes = {
  closeModal: PropTypes.func.isRequired,
  farmId: PropTypes.number.isRequired,
  nextPageIndex: PropTypes.number,
  resultsNumber: PropTypes.number,
  removeFarmMembershipRequests: PropTypes.func.isRequired,
  getFarmMembershipRequests: PropTypes.func.isRequired,
  updateFarmMembershipRequests: PropTypes.func.isRequired,
  membershipRequests: PropTypes.arrayOf(PropTypes.object),
  getFarmMembers: PropTypes.func.isRequired,
  translate: PropTypes.func,
};

const mapStateToProps = state => ({
  nextPageIndex: state.farmMembershipRequests.nextPageIndex,
  membershipRequests: state.farmMembershipRequests.data,
  resultsNumber: state.farmMembershipRequests.resultsNumber,
  translate: getTranslate(state.localize),
});

const mapDispatchToProps = dispatch => ({
  getFarmMembershipRequests: bindActionCreators(
    fetchFarmMembershipRequests,
    dispatch,
  ),
  removeFarmMembershipRequests: bindActionCreators(
    removeFarmMembershipRequests,
    dispatch,
  ),
  updateFarmMembershipRequests: bindActionCreators(
    updateFarmMembershipRequests,
    dispatch,
  ),
  getFarmMembers: bindActionCreators(fetchFarmMembers, dispatch),
});

const largeBreakpoint = 992;

class MembershipRequestsModal extends Component {
  state = {
    isInitialPageLoading: false,
    isVerifyAcceptModalVisible: false,
    isVerifyDismissModalVisible: false,
    chosenRequest: null,
    updatedResultsNumber: null,
    isRequestInProgress: false,
  };

  componentDidMount() {
    const { farmId, getFarmMembershipRequests } = this.props;
    this.toggleInitialPageLoading();
    getFarmMembershipRequests(farmId).then(() => {
      const { nextPageIndex, resultsNumber } = this.props;
      this.toggleInitialPageLoading();
      this.setState({ updatedResultsNumber: resultsNumber });
      // NOTE: In some cases, on larger phones we have to force a call for second page
      if (
        window.innerHeight > 740 &&
        window.innerWidth < largeBreakpoint &&
        nextPageIndex
      ) {
        getFarmMembershipRequests(farmId, nextPageIndex);
      }
    });
  }

  componentWillUnmount() {
    const { removeFarmMembershipRequests } = this.props;
    removeFarmMembershipRequests();
  }

  toggleInitialPageLoading = () => {
    const { isInitialPageLoading } = this.state;
    this.setState({ isInitialPageLoading: !isInitialPageLoading });
  };

  getFarmMembershipRequestsNextPage = () => {
    const { farmId, getFarmMembershipRequests, nextPageIndex } = this.props;
    getFarmMembershipRequests(farmId, nextPageIndex);
  };

  toggleVerifyAcceptModal = request => {
    if (request) {
      this.setChosenRequest(request);
    }
    const { isVerifyAcceptModalVisible } = this.state;
    this.setState({ isVerifyAcceptModalVisible: !isVerifyAcceptModalVisible });
  };

  toggleVerifyDismissModal = request => {
    if (request) {
      this.setChosenRequest(request);
    }
    const { isVerifyDismissModalVisible } = this.state;
    this.setState({
      isVerifyDismissModalVisible: !isVerifyDismissModalVisible,
    });
  };

  setChosenRequest = request => {
    this.setState({ chosenRequest: request });
  };

  setCurrentRequest = (request, type) => {
    this.setState({ chosenRequest: request });

    if (type === 'delete') {
      setTimeout(() => {
        this.dismissRequest();
      }, 250);
    }
  };

  dismissRequest = () => {
    const {
      farmId,
      membershipRequests,
      updateFarmMembershipRequests,
    } = this.props;
    const { chosenRequest, updatedResultsNumber } = this.state;
    this.setState({ isRequestInProgress: true });
    deleteFarmMembershipRequest(farmId, chosenRequest.membership_request_id)
      .then(() => {
        this.setState({
          updatedResultsNumber: updatedResultsNumber - 1,
          isRequestInProgress: false,
        });
        updateFarmMembershipRequests(
          membershipRequests.filter(
            request =>
              request.membership_request_id !==
              chosenRequest.membership_request_id,
          ),
        );
      })
      .catch(() => {
        this.setState({ isRequestInProgress: false });
      });
  };

  acceptRequest = () => {
    const {
      farmId,
      getFarmMembers,
      membershipRequests,
      updateFarmMembershipRequests,
    } = this.props;
    const { chosenRequest, updatedResultsNumber } = this.state;
    this.setState({ isRequestInProgress: true });
    createFarmMember(farmId, chosenRequest.membership_request_id)
      .then(() => {
        this.toggleVerifyAcceptModal();
        this.setState({
          updatedResultsNumber: updatedResultsNumber - 1,
          isRequestInProgress: false,
        });
        updateFarmMembershipRequests(
          membershipRequests.filter(
            request =>
              request.membership_request_id !==
              chosenRequest.membership_request_id,
          ),
        );
        getFarmMembers(farmId);
      })
      .catch(() => {
        this.setState({ isRequestInProgress: false });
      });
  };

  render() {
    const {
      closeModal,
      membershipRequests,
      nextPageIndex,
      translate,
    } = this.props;
    const {
      chosenRequest,
      isInitialPageLoading,
      isVerifyAcceptModalVisible,
      isVerifyDismissModalVisible,
      updatedResultsNumber,
      isRequestInProgress,
    } = this.state;
    return (
      <Fragment>
        {isVerifyAcceptModalVisible && (
          <InfoModal
            closeModal={this.toggleVerifyAcceptModal}
            handlePrimaryOptionButtonClick={this.acceptRequest}
            primaryOptionButton={translate('common.yes')}
            isCancelButtonVisible
            heading={translate('modal.acceptMember.heading')}
            message={`${chosenRequest.name} ${translate(
              'modal.acceptMember.message',
            )}`}
            primaryOptionButtonDisabled={isRequestInProgress}
          />
        )}
        {isVerifyDismissModalVisible && (
          <InfoModal
            closeModal={this.toggleVerifyDismissModal}
            handlePrimaryOptionButtonClick={this.dismissRequest}
            primaryOptionButton={translate('common.yes')}
            isCancelButtonVisible
            heading={translate('modal.dissmissMember.heading')}
            message={`${chosenRequest.name} ${translate(
              'modal.dissmissMember.message',
            )}`}
            primaryOptionButtonDisabled={isRequestInProgress}
          />
        )}
        <MembershipRequestsModalView
          closeModal={closeModal}
          membershipRequests={membershipRequests}
          getFarmMembershipRequestsNextPage={
            this.getFarmMembershipRequestsNextPage
          }
          nextPageIndex={nextPageIndex}
          toggleVerifyAcceptModal={this.toggleVerifyAcceptModal}
          toggleVerifyDismissModal={this.toggleVerifyDismissModal}
          setCurrentRequest={this.setCurrentRequest}
          isInitialPageLoading={isInitialPageLoading}
          updatedResultsNumber={updatedResultsNumber}
        />
      </Fragment>
    );
  }
}

MembershipRequestsModal.defaultProps = defaultProps;

MembershipRequestsModal.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MembershipRequestsModal);
