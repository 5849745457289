export const FETCH_MARKETPLACE_REQUEST = 'FETCH_MARKETPLACE_REQUEST';
export const FETCH_MARKETPLACE_SUCCESS = 'FETCH_MARKETPLACE_SUCCESS';
export const FETCH_MARKETPLACE_FAILURE = 'FETCH_MARKETPLACE_FAILURE';
export const FETCH_ARTICLES_NUMBER_SUCCESS = 'FETCH_ARTICLES_NUMBER_SUCCESS';

export const UPDATE_MARKETPLACE_QUERY = 'UPDATE_MARKETPLACE_QUERY';
export const UPDATE_MARKETPLACE_CATEGORY = 'UPDATE_MARKETPLACE_CATEGORY';
export const UPDATE_MARKETPLACE_VICINITY = 'UPDATE_MARKETPLACE_VICINITY';

export const ADD_CREATED_ARTICLE = 'ADD_CREATED_ARTICLE';
export const UPDATE_MARKETPLACE = 'UPDATE_MARKETPLACE';
export const REMOVE_MARKETPLACE = 'REMOVE_MARKETPLACE';
