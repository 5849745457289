import { createReducer } from 'common/utils/reduxUtils';
import { checkCookies } from 'common/utils/cookiesUtils';
import { SET_COOKIES_ACCEPT } from 'common/redux/types';

const cookiesReducer = createReducer(
  {
    areSet: checkCookies(),
  },
  {
    [SET_COOKIES_ACCEPT]: (state, action) => ({
      ...state,
      areSet: action.payload.areSet,
    }),
  },
);

export default cookiesReducer;
