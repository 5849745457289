import React from 'react';
import PropTypes from 'prop-types';
import Button from 'common/components/button';
import MainButton from 'common/components/mainButton';
import { Formik } from 'formik';
import Input from 'common/components/input/index';
import forgotAccountValidationSchema from 'userAuth/validators/forgotAccountValidators';
import { Translate } from 'react-localize-redux';
import './index.scss';

const propTypes = {
  forgotAccountForm: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
};

const ForgotAccountView = ({ forgotAccountForm, cancel }) => (
  <div className="forgot-account">
    <div className="forgot-account__wrapper">
      <h1 className="forgot-account__heading">
        <Translate id="page.forgotAccount.heading" />
      </h1>
      <h2 className="forgot-account__sub-heading">
        <Translate id="page.forgotAccount.subHeading" />
      </h2>
      <Formik
        initialValues={{
          email: '',
        }}
        validationSchema={forgotAccountValidationSchema}
        onSubmit={(values, { setErrors, setSubmitting }) =>
          forgotAccountForm(values, { setErrors, setSubmitting })
        }
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit} noValidate>
            <div className="forgot-account__input">
              <Input
                type="text"
                value={values.email}
                error={errors.email}
                touched={touched.email}
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="E-Mail"
                withLabel
                fullWidth
              />
            </div>
            <div className="forgot-account__row">
              <Button
                className="forgot-account__cancel-button"
                onClick={cancel}
              >
                <Translate id="common.action.cancel" />
              </Button>
              <MainButton
                className="forgot-account__send-button"
                type="submit"
                disabled={isSubmitting}
              >
                <Translate id="common.action.send" />
              </MainButton>
            </div>
            {errors.submit && (
              <p className="forgot-account__error--submit">{errors.submit}</p>
            )}
          </form>
        )}
      />
    </div>
  </div>
);

ForgotAccountView.propTypes = propTypes;

export default ForgotAccountView;
