import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Button from 'common/components/button';
import Indicator from 'common/components/indicator';
import InviteModal from 'common/containers/inviteModal';
import { Translate } from 'react-localize-redux';
import Footer from 'common/components/footer';
import GuestModal from 'common/components/guestModal';
import {
  fetchNewsfeed,
  removeNewsfeed,
  setActiveFeed,
} from 'newsfeed/redux/actions/newsfeedActions';
import { removeComments } from 'post/redux/actions/commentsActions';
import { farmFlowStart } from 'farm/redux/actions/farmActions';
import './index.scss';

const defaultProps = {
  history: {},
  match: {},
  userData: {},
};

const propTypes = {
  getNewsfeed: PropTypes.func.isRequired,
  removeNewsfeed: PropTypes.func.isRequired,
  removeComments: PropTypes.func.isRequired,
  farmFlowStartAction: PropTypes.func.isRequired,
  unreadArticles: PropTypes.bool.isRequired,
  history: PropTypes.object,
  userData: PropTypes.object,
  match: PropTypes.object,
};

const mapStateToProps = state => ({
  unreadArticles: state.newsfeed.unreadArticles,
  userData: state.userData.data,
});

const mapDispatchToProps = dispatch => ({
  getNewsfeed: bindActionCreators(fetchNewsfeed, dispatch),
  farmFlowStartAction: () => dispatch(farmFlowStart()),
  setActiveFeed: type => dispatch(setActiveFeed(type)),
  removeNewsfeed: bindActionCreators(removeNewsfeed, dispatch),
  removeComments: bindActionCreators(removeComments, dispatch),
});

class SidebarNavigation extends Component {
  state = {
    isGuestModalVisible: false,
  };

  handleNewsfeedClick = () => {
    const { history, match } = this.props;
    const { path } = match;
    if (path === '/') {
      const { getNewsfeed, removeNewsfeed, removeComments } = this.props;
      removeNewsfeed();
      removeComments();
      getNewsfeed();
      window.scrollTo(0, 0);
    } else {
      history.push('/');
    }
  };

  toggleGuestModal = () => {
    const { isGuestModalVisible } = this.state;
    this.setState({ isGuestModalVisible: !isGuestModalVisible });
  };

  render() {
    const { farmFlowStartAction, unreadArticles, userData } = this.props;
    const { isGuestModalVisible } = this.state;

    return (
      <div className="sidebar-navigation">
        {/* <div className="sidebar-navigation__button-container">
          <Button
            className="sidebar-navigation__button sidebar-navigation__button--newsfeed"
            onClick={this.handleNewsfeedClick}
          >
            <Translate id="page.newsfeed.button" />
          </Button>
        </div> */}
        {userData.id && false && (
          <Link
            to={`/profile/${userData.id}`}
            className="sidebar-navigation__button sidebar-navigation__button--userprofile"
          >
            <Translate id="page.userProfile.title" />
          </Link>
        )}

        <div className="sidebar-navigation__button-container">
          <Button
            onClick={() =>
              userData.id ? farmFlowStartAction() : this.toggleGuestModal()
            }
            className="sidebar-navigation__button sidebar-navigation__button--farm"
          >
            <Translate id="page.farmProfile.title" />
          </Button>
        </div>
        <Link
          to="/marketplace"
          className="sidebar-navigation__button sidebar-navigation__button--marketplace"
        >
          {unreadArticles && <Indicator />}
          <Translate id="page.marketplace.title" />
        </Link>
        <div className="sidebar-navigation__button-container">
          <InviteModal>
            {({ handleInviteButtonClick }) => (
              <Button
                onClick={handleInviteButtonClick}
                className="sidebar-navigation__button sidebar-navigation__button--invite"
              >
                <Translate id="common.action.inviteFriends" />
              </Button>
            )}
          </InviteModal>
        </div>
        <Link
          to="/hashtag#videos"
          className="sidebar-navigation__button sidebar-navigation__button--video"
        >
          <Translate id="page.video.title" />
        </Link>
        <Link
          to="/hashtag#farmlifeswebapp"
          className="sidebar-navigation__button sidebar-navigation__button--webapp"
        >
          <Translate id="page.webApp.title" />
        </Link>
        <div className="sidebar-navigation__footer">
          <Footer isSidebar />
        </div>
        {isGuestModalVisible && (
          <GuestModal closeModal={this.toggleGuestModal} />
        )}
      </div>
    );
  }
}

SidebarNavigation.propTypes = propTypes;
SidebarNavigation.defaultProps = defaultProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(SidebarNavigation));
