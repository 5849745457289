import { createReducer } from 'common/utils/reduxUtils';
import {
  FETCH_NEWSFEED_REQUEST,
  FETCH_NEWSFEED_SUCCESS,
  FETCH_NEWSFEED_FAILURE,
  ADD_CREATED_POST,
  UPDATE_NEWSFEED,
  REMOVE_NEWSFEED,
  SET_ACTIVE_FEED,
  CHECK_UNREAD_POSTS_SUCCESS,
  UPDATE_POST,
} from 'newsfeed/redux/types';

const initialState = {
  data: [],
  currentTimestamp: null,
  nextPageIndex: null,
  isLoading: false,
  activeFeed: 'farm',
  unreadNews: false,
  unreadArticles: false,
};

const newsfeedReducer = createReducer(
  { ...initialState },
  {
    [FETCH_NEWSFEED_SUCCESS]: (state, action) => {
      const { data, meta } = action.payload.data;
      const { currentTimestamp } = state;
      const hasNextPage = meta.current_page < meta.last_page;

      return {
        ...state,
        data: meta.current_page === 1 ? [...data] : [...state.data, ...data],
        currentTimestamp:
          meta.current_page === 1 ? meta.current_timestamp : currentTimestamp,
        nextPageIndex: hasNextPage ? meta.current_page + 1 : null,
        isLoading: false,
      };
    },
    [FETCH_NEWSFEED_REQUEST]: state => ({
      ...state,
      isLoading: true,
    }),
    [FETCH_NEWSFEED_FAILURE]: state => ({
      ...state,
      isLoading: false,
    }),
    [ADD_CREATED_POST]: (state, action) => ({
      ...state,
      data: [action.payload.data, ...state.data],
    }),
    [UPDATE_NEWSFEED]: (state, action) => ({
      ...state,
      data: [...action.payload.data],
    }),
    [UPDATE_POST]: (state, action) => ({
      ...state,
      data: action.payload.data,
    }),
    [REMOVE_NEWSFEED]: state => ({
      ...state,
      data: [],
      currentTimestamp: null,
      nextPageIndex: null,
    }),
    [SET_ACTIVE_FEED]: (state, action) => ({
      ...state,
      activeFeed: action.payload,
    }),
    [CHECK_UNREAD_POSTS_SUCCESS]: (state, action) => {
      const { news, articles } = action.payload.data;

      return {
        ...state,
        unreadNews: news,
        unreadArticles: articles,
      };
    },
  },
);

export default newsfeedReducer;
