import React from 'react';
import PropTypes from 'prop-types';
import Button from 'common/components/button';
import './index.scss';

const propTypes = {
  onClick: PropTypes.func.isRequired,
};

const CloseButton = ({ onClick }) => (
  <Button className="close-button" onClick={onClick} />
);

CloseButton.propTypes = propTypes;

export default CloseButton;
