import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ModalContainer from 'common/components/modalContainer';
import Loader from 'common/components/loader';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import Button from 'common/components/button';
import MainButton from 'common/components/mainButton';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import 'react-circular-progressbar/dist/styles.css';

import './index.scss';

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
});

const defaultProps = {
  translate: () => null,
  uploadProgress: 0,
  imagesUrls: [],
  initialImages: [],
  tourImageError: '',
  deleteAttachments: [],
};

const propTypes = {
  closeModal: PropTypes.func.isRequired,
  translate: PropTypes.func,
  uploadProgress: PropTypes.number,
  imagesUrls: PropTypes.arrayOf(PropTypes.string),
  initialImages: PropTypes.arrayOf(PropTypes.object),
  removeInitialImage: PropTypes.func.isRequired,
  imageLoading: PropTypes.bool.isRequired,
  imageLoading2: PropTypes.bool.isRequired,
  submitForm: PropTypes.func.isRequired,
  removeImage: PropTypes.func.isRequired,
  addFarmTourImage: PropTypes.func.isRequired,
  isFarmTourLoading: PropTypes.bool.isRequired,
  tourImageError: PropTypes.string,
  deleteAttachments: PropTypes.array,
  onDragEnd: PropTypes.func.isRequired,
  imagesMoved: PropTypes.bool.isRequired,
};

const getItemStyle = (isDragging, draggableStyle, image) => ({
  userSelect: 'none',
  backgroundColor: isDragging ? 'lightgreen' : 'grey',
  backgroundImage: image,
  ...draggableStyle,
});

const getListStyle = () => ({
  display: 'flex',
  overflow: 'auto',
});

class CreateFarmTourModalView extends Component {
  render() {
    const {
      closeModal,
      translate,

      imageLoading,
      imageLoading2,
      initialImages,
      imagesUrls,
      tourImageError,
      uploadProgress,

      isFarmTourLoading,
      deleteAttachments,
      removeInitialImage,
      removeImage,
      addFarmTourImage,
      submitForm,
      onDragEnd,
      imagesMoved,
    } = this.props;

    return (
      <ModalContainer handleOutsideClick={closeModal}>
        <div className="create-farm-tour-modal">
          <div className="modal-close-button">
            <button
              type="button"
              className="close-button"
              onClick={closeModal}
            />
          </div>

          <div className="modal-data">
            <div className="title-wrapper">
              <p className="title">{translate('farm.makeFarmTour')}</p>
              <p className="subtitle">
                {translate('farm.makeFarmTourSubtitle')}
              </p>
              <p className="cover-title">{translate('farm.coverPicture')}</p>
            </div>
            <div className="create-farm-tour-modal__scroll-container">
              <div className="create-farm-tour-modal__images">
                <DragDropContext onDragEnd={onDragEnd}>
                  {isFarmTourLoading && (
                    <div
                      className="circle-loader-wrapper"
                      style={{ marginTop: 200 }}
                    >
                      <div className="create-farm-tour-modal__loader">
                        <CircularProgressbar
                          value={uploadProgress}
                          strokeWidth={5}
                          styles={buildStyles({
                            pathTransitionDuration: 0.5,
                            pathColor: '#707070',
                            trailColor: '#E1E1E1',
                          })}
                        />
                        <Loader message="" />
                      </div>
                    </div>
                  )}
                  <Droppable droppableId="droppable" direction="horizontal">
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                        {...provided.droppableProps}
                      >
                        {initialImages.map((image, index) => (
                          <Draggable
                            key={image.id}
                            draggableId={image.id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="create-farm-tour-modal__image"
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style,
                                  `url(${
                                    image.type === 'farmtour_image'
                                      ? `${image.imageUrl}?w=300`
                                      : `${image.thumbnail}?w=300`
                                  })`,
                                )}
                                key={`${image.id}${image.imageUrl}`}
                              >
                                <Button
                                  className="create-farm-tour-modal__delete-image"
                                  onClick={() =>
                                    removeInitialImage(image.id, index)
                                  }
                                />
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                  {imagesUrls.map((image, index) => (
                    <div
                      className="create-farm-tour-modal__image"
                      style={{ backgroundImage: `url(${image})` }}
                      key={Math.random()}
                    >
                      <Button
                        className="create-farm-tour-modal__delete-image"
                        onClick={() => removeImage(index)}
                      />
                    </div>
                  ))}
                  <div className="create-farm-tour-modal__image-placeholder first">
                    {imageLoading && (
                      <div className="create-farm-tour-modal__circle-loader-wrapper">
                        <CircularProgressbar
                          value={uploadProgress}
                          strokeWidth={5}
                          styles={buildStyles({
                            pathTransitionDuration: 0.5,
                            pathColor: '#707070',
                            trailColor: '#E1E1E1',
                          })}
                        />
                        <Loader message="" />
                      </div>
                    )}
                    {initialImages.length + imagesUrls.length < 50 &&
                      !isFarmTourLoading && (
                        <label
                          htmlFor="farmtour-image-input"
                          className="create-farm-tour-modal__label"
                        >
                          <input
                            name="farmtour-image-input"
                            id="farmtour-image-input"
                            className="create-farm-tour-modal__input"
                            type="file"
                            accept=".jpeg, .JPEG, .JPG, .jpg, .png, .PNG, .gif, .GIF .mp4, .mov, .qt, .m4p, .mkv"
                            onChange={addFarmTourImage}
                          />
                          <span className="create-farm-tour-modal__label-text">
                            {translate('common.selectObject', {
                              object: translate('common.reaction.image'),
                            })}
                          </span>
                        </label>
                      )}
                  </div>
                </DragDropContext>

                <div className="create-farm-tour-modal__image-placeholder">
                  {imageLoading2 && (
                    <div className="create-farm-tour-modal__circle-loader-wrapper">
                      <CircularProgressbar
                        value={uploadProgress}
                        strokeWidth={5}
                        styles={buildStyles({
                          pathTransitionDuration: 0.5,
                          pathColor: '#707070',
                          trailColor: '#E1E1E1',
                        })}
                      />
                      <Loader message="" />
                    </div>
                  )}
                  {initialImages.length + imagesUrls.length < 50 &&
                    !isFarmTourLoading && (
                      <label
                        htmlFor="farmtour-image-input-2"
                        className="create-farm-tour-modal__label"
                      >
                        <input
                          name="farmtour-image-input-2"
                          id="farmtour-image-input-2"
                          className="create-farm-tour-modal__input"
                          type="file"
                          accept=".jpeg, .JPEG, .JPG, .jpg, .png, .PNG, .gif, .GIF .mp4, .mov, .qt, .m4p, .mkv"
                          onChange={addFarmTourImage}
                        />
                        <span className="create-farm-tour-modal__label-text">
                          {translate('common.selectObject', {
                            object: translate('common.reaction.image'),
                          })}
                        </span>
                      </label>
                    )}
                </div>
                {tourImageError && (
                  <p className="create-farm-tour-modal__error">
                    {tourImageError}
                  </p>
                )}
                {!isFarmTourLoading && (
                  <div className="create-farm-tour-modal__plus-one">+</div>
                )}
              </div>
            </div>
          </div>

          <div className="publish-button-wrapper">
            <MainButton
              onClick={submitForm}
              disabled={
                imagesUrls.length === 0 &&
                deleteAttachments.length === 0 &&
                !imagesMoved
              }
              large
              fullWidth
            >
              {translate('common.action.save')}
            </MainButton>
          </div>
        </div>
      </ModalContainer>
    );
  }
}

CreateFarmTourModalView.defaultProps = defaultProps;

CreateFarmTourModalView.propTypes = propTypes;

export default connect(mapStateToProps)(CreateFarmTourModalView);
