import * as Yup from 'yup';
import localizeYup from 'common/utils/localizeYupDefaults.js';
import translateMessage from 'common/utils/translateMessage.js';

localizeYup();

export const articleValidationSchema = Yup.object().shape({
  price: Yup.string()
    .matches(/^\d+([,.]\d{1,2})?$/, {
      message: translateMessage('form.error.invalidFormat'),
    })
    .nullable(), // TODO add conditional checking
  title: Yup.string()
    .min(2)
    .max(120)
    .required(),
  city: Yup.string()
    .min(2)
    .max(64)
    .required(),
  postCode: Yup.string()
    .required()
    // 1000 - 9999
    .matches(/^[0-9]{4,10}$/, {
      message: translateMessage('form.error.invalidFormat'),
      excludeEmptyString: true,
    })
    .min(4)
    .max(10),
  categoryId: Yup.string().required(),
  phone: Yup.string()
    .matches(/^\+?\d{5,15}$/, translateMessage('form.error.phoneFormat'))
    .required(),
  text: Yup.string()
    .max(35000, translateMessage('form.error.descriptionMax'))
    .nullable(),
});

export default articleValidationSchema;
