import React from 'react';
import ModalContainer from 'common/components/modalContainer';
import Button from 'common/components/button';
import MainButton from 'common/components/mainButton';
import Loader from 'common/components/loader';
import TextareaAutosize from 'react-autosize-textarea';
import UserAvatar from 'common/components/userAvatar';
import './index.scss';
import { connect } from 'react-redux';
import { getTranslate, Translate } from 'react-localize-redux';
import classNames from 'classnames';
import moment from 'moment';

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
});

const EditPostModalView = ({
  closeModal,
  translate,
  userData,
  text,
  imageUrl,
  videoUrl,
  videoDuration,
  imageLoading,
  videoLoading,
  onTextChange,
  onSubmit,
  onRemoveAttachment,
  onUploadImage,
  onUploadVideo,
  errors,
}) => {
  const textContainerClasses = classNames({
    'edit-post-modal__text-container': true,
    'edit-post-modal__text-container--small': !!imageUrl || !!videoUrl,
  });

  const videoContainerClasses = classNames({
    'edit-post-modal__video-container': true,
    'edit-post-modal__video-container--fixed': !!videoUrl,
  });

  return (
    <ModalContainer handleOutsideClick={closeModal}>
      <div className="edit-post-modal">
        <div className="edit-post-modal__header edit-post-modal__header--desktop">
          <div className="edit-post-button">
            <label htmlFor="edit-post-button__image">
              <div className="edit-post-button__input-button">
                <div className="edit-post-button__content edit-post-button__content--image">
                  <span className="edit-post-button__text--mobile">
                    <Translate id="common.action.mobile.postImage" />
                  </span>
                  <span className="edit-post-button__text--desktop">
                    <Translate id="common.action.postImage" />
                  </span>
                </div>
              </div>
              <input
                name="edit-post-button__image"
                id="edit-post-button__image"
                className="edit-post-button__input"
                type="file"
                accept=".jpeg, .JPEG, .JPG, .jpg, .png, .PNG, .gif, .GIF"
                onChange={onUploadImage}
              />
            </label>
          </div>
          <div className="edit-post-button">
            <label htmlFor="edit-post-button__video">
              <div className="edit-post-button__input-button">
                <div className="edit-post-button__content edit-post-button__content--video">
                  <span className="edit-post-button__text--mobile">
                    <Translate id="common.action.mobile.postVideo" />
                  </span>
                  <span className="edit-post-button__text--desktop">
                    <Translate id="common.action.postVideo" />
                  </span>
                </div>
              </div>
              <input
                name="edit-post-button__video"
                id="edit-post-button__video"
                className="edit-post-button__input"
                type="file"
                accept=".mp4, .avi, .wmv, .mov"
                onChange={onUploadVideo}
              />
            </label>
          </div>
        </div>
        <div className="edit-post-modal__scroll-container">
          <div className={textContainerClasses}>
            <div className="create-post-modal__avatar">
              <UserAvatar image={userData.avatar} />
            </div>
            <div className="edit-post-modal__textarea-container edit-post-modal__textarea-container--desktop">
              <TextareaAutosize
                className="edit-post-modal__textarea"
                placeholder={translate('common.createPostPlaceholder')}
                autoFocus
                onChange={onTextChange}
                value={text}
                rows={imageUrl || videoUrl ? 1 : 6}
                maxRows={imageUrl || videoUrl ? 5 : 6}
              />
            </div>
            <div className="edit-post-modal__textarea-container edit-post-modal__textarea-container--mobile">
              <TextareaAutosize
                className="edit-post-modal__textarea"
                placeholder={translate('common.createPostPlaceholder')}
                autoFocus
                onChange={onTextChange}
                value={text}
                rows={1}
                maxRows={imageUrl || videoUrl ? 5 : 350}
              />
            </div>
          </div>
          {imageUrl && (
            <div className="edit-post-modal__image-container">
              <Button
                className="edit-post-modal__remove-button"
                onClick={onRemoveAttachment}
              />
              <img className="create-post-modal__image" src={imageUrl} alt="" />
            </div>
          )}
          {videoUrl && (
            <div className={videoContainerClasses}>
              <Button
                className="edit-post-modal__remove-button"
                onClick={onRemoveAttachment}
              />
              <img className="edit-post-modal__preview" src={videoUrl} alt="" />

              {!Number.isNaN(videoDuration) && videoDuration && (
                <div className="create-post-modal__duration">
                  {moment.utc(videoDuration * 1000).format('HH:mm:ss')}
                </div>
              )}
            </div>
          )}
          {imageLoading && (
            <Loader
              message={translate('common.load', {
                object: translate('common.reaction.image'),
              })}
            />
          )}
          {videoLoading && (
            <Loader
              message={translate('common.load', {
                object: translate('common.reaction.video'),
              })}
            />
          )}
          <div className="edit-post-modal__submit-container edit-post-modal__submit-container--desktop">
            <div className="edit-post-modal__buttons">
              <MainButton onClick={closeModal} teritary disabled={false}>
                {translate('common.action.cancel')}
              </MainButton>

              <div className="edit-post-modal__submit-button-container">
                <MainButton type="submit" disabled={false} onClick={onSubmit}>
                  {translate('common.action.save')}
                </MainButton>
              </div>
            </div>
          </div>
          {errors && <p className="edit-post-modal__error">{errors}</p>}
        </div>
        <div className="edit-post-modal__footer-container">
          <div className="edit-post-modal__submit-container edit-post-modal__submit-container--mobile">
            <MainButton onClick={closeModal} teritary disabled={false}>
              {translate('common.action.cancel')}
            </MainButton>

            <div className="edit-post-modal__submit-button-container">
              <MainButton type="submit" disabled={false} onClick={onSubmit}>
                {translate('common.action.save')}
              </MainButton>
            </div>
          </div>
          <div className="edit-post-modal__footer">
            <div className="edit-post-button">
              <label htmlFor="edit-post-button__image">
                <div className="edit-post-button__input-button">
                  <div className="edit-post-button__content edit-post-button__content--image">
                    <span className="edit-post-button__text--mobile">
                      <Translate id="common.action.mobile.postImage" />
                    </span>
                    <span className="edit-post-button__text--desktop">
                      <Translate id="common.action.postImage" />
                    </span>
                  </div>
                </div>
                <input
                  name="edit-post-button__image"
                  id="edit-post-button__image"
                  className="edit-post-button__input"
                  type="file"
                  accept=".jpeg, .JPEG, .JPG, .jpg, .png, .PNG, .gif, .GIF"
                  onChange={onUploadImage}
                />
              </label>
            </div>
            <div className="edit-post-button">
              <label htmlFor="edit-post-button__video">
                <div className="edit-post-button__input-button">
                  <div className="edit-post-button__content edit-post-button__content--video">
                    <span className="edit-post-button__text--mobile">
                      <Translate id="common.action.mobile.postVideo" />
                    </span>
                    <span className="edit-post-button__text--desktop">
                      <Translate id="common.action.postVideo" />
                    </span>
                  </div>
                </div>
                <input
                  name="edit-post-button__video"
                  id="edit-post-button__video"
                  className="edit-post-button__input"
                  type="file"
                  accept=".mp4, .avi, .wmv, .mov"
                  onChange={onUploadVideo}
                />
              </label>
            </div>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default connect(mapStateToProps)(EditPostModalView);
