import React from 'react';
import PropTypes from 'prop-types';
import ModalContainer from 'common/components/modalContainer';
import CloseButton from 'common/components/closeButton';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from 'common/components/loader';
import EndMessage from 'common/components/endMessage';
import Button from 'common/components/button';
import UserAvatar from 'common/components/userAvatar';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import './index.scss';

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
});

const defaultProps = {
  nextPageIndex: null,
  updatedResultsNumber: 0,
};

const propTypes = {
  closeModal: PropTypes.func.isRequired,
  membershipRequests: PropTypes.arrayOf(PropTypes.object).isRequired,
  nextPageIndex: PropTypes.number,
  getFarmMembershipRequestsNextPage: PropTypes.func.isRequired,
  toggleVerifyAcceptModal: PropTypes.func.isRequired,
  setCurrentRequest: PropTypes.func.isRequired,
  isInitialPageLoading: PropTypes.bool.isRequired,
  updatedResultsNumber: PropTypes.number,
};

const MembershipRequestsModalView = ({
  closeModal,
  membershipRequests,
  nextPageIndex,
  getFarmMembershipRequestsNextPage,
  toggleVerifyAcceptModal,
  isInitialPageLoading,
  updatedResultsNumber,
  setCurrentRequest,
  translate,
}) => (
  <ModalContainer handleOutsideClick={closeModal} notFullSize>
    <div className="membership-requests-modal">
      <div className="membership-requests-modal__header">
        <div className="membership-requests-modal__requests-number">
          {updatedResultsNumber}
          {' '}
          {updatedResultsNumber === 1
            ? translate('common.reaction.membershipRequest')
            : translate('common.reaction.membershipRequests')}
        </div>
        <CloseButton onClick={closeModal} />
      </div>
      {isInitialPageLoading && (
        <Loader
          message={translate('common.load', {
            object: translate('common.reaction.requests'),
          })}
        />
      )}
      <div
        className="membership-requests-modal__list"
        id="membership-requests-modal-scroll"
      >
        <InfiniteScroll
          dataLength={membershipRequests.length}
          scrollThreshold="400px"
          next={nextPageIndex ? getFarmMembershipRequestsNextPage : null}
          hasMore={!!nextPageIndex}
          scrollableTarget="membership-requests-modal-scroll"
          loader={
            nextPageIndex === 1 ? (
              <Loader
                message={translate('common.load', {
                  object: translate('common.reaction.requests'),
                })}
              />
            ) : (
              <Loader
                message={translate('common.loadMore', {
                  object: translate('common.reaction.requests'),
                })}
              />
            )
          }
          endMessage={
            membershipRequests.length > 0 ? (
              <EndMessage
                message={translate('common.noMore', {
                  object: translate('common.reaction.requests'),
                })}
              />
            ) : null
          }
        >
          {membershipRequests.map(request => (
            <div
              className="membership-requests-modal__request"
              key={request.membership_request_id}
            >
              <div className="membership-requests-modal__name-container">
                <div className="membership-requests-modal__avatar">
                  <UserAvatar image={request.avatar} />
                </div>
                <div className="membership-requests-modal__name">
                  {request.name}
                </div>
              </div>
              <div className="membership-requests-modal__buttons">
                <Button
                  className="membership-requests-modal__button membership-requests-modal__button--dismiss"
                  onClick={() => setCurrentRequest(request, 'delete')}
                >
                  {translate('common.action.deleteC')}
                </Button>
                <Button
                  className="membership-requests-modal__button membership-requests-modal__button--accept"
                  onClick={() => toggleVerifyAcceptModal(request)}
                >
                  {translate('common.action.accept')}
                </Button>
              </div>
            </div>
          ))}
        </InfiniteScroll>
      </div>
    </div>
  </ModalContainer>
);

MembershipRequestsModalView.defaultProps = defaultProps;

MembershipRequestsModalView.propTypes = propTypes;

export default connect(mapStateToProps)(MembershipRequestsModalView);
