import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { updatePost } from 'common/api/postsApi';
import { updateNewsfeed } from 'newsfeed/redux/actions/newsfeedActions';
import { updateUserNewsfeed } from 'profile/redux/actions/userNewsfeedActions';
import { updateFarmNewsfeed } from 'farm/redux/actions/farmNewsfeedActions';
import { updateHashtagfeed } from 'hashtag/redux/actions/hashtagActions';
import { bindActionCreators } from 'redux';
import loadImage from 'blueimp-load-image';
import requestAttachmentToken from 'common/api/attachmentsApi';
import EditPostModalView from '../components/editPostModalView';

const mapDispatchToProps = dispatch => ({
  updateNewsfeed: bindActionCreators(updateNewsfeed, dispatch),
  updateUserNewsfeed: bindActionCreators(updateUserNewsfeed, dispatch),
  updateFarmNewsfeed: bindActionCreators(updateFarmNewsfeed, dispatch),
  updateHashtagfeed: bindActionCreators(updateHashtagfeed, dispatch),
});

const mapStateToProps = state => ({
  userNewsfeed: state.userNewsfeed.data,
  farmNewsfeed: state.farmNewsfeed.data,
});

class EditPostModal extends Component {
  constructor(props) {
    super(props);

    const { post } = this.props;

    this.state = {
      text: post.text,
      removeAttachment: false,
      imageUrl:
        post.attachment && post.attachment.type === 'image'
          ? post.attachment.url
          : '',
      videoUrl:
        post.attachment && post.attachment.type === 'video'
          ? post.attachment.thumbnail
          : '',
      imageLoading: false,
      videoLoading: false,
      errors: '',
      attachmentToken: '',
    };
  }

  handleTextChange = e => {
    this.setState({ text: e.target.value });
  };

  removeAttachment = () => {
    this.setState({
      removeAttachment: true,
      imageUrl: '',
      videoUrl: '',
      attachmentToken: '',
    });
  };

  uploadImage = e => {
    const imageFile = e.target.files[0];
    const reader = new FileReader();
    const image = new Image();
    if (imageFile) {
      document.getElementById('edit-post-button__video').value = null;
      this.setState({
        imageLoading: true,
        errors: '',
      });
      reader.readAsDataURL(imageFile);
      reader.addEventListener('load', () => {
        image.src = reader.result;
        image.addEventListener('load', () => {
          loadImage.parseMetaData(imageFile, data => {
            let orientation = 0;
            if (data.exif) {
              orientation = data.exif.get('Orientation');
            }
            loadImage(
              imageFile,
              canvas => {
                const base64data = canvas.toDataURL(imageFile.type);
                canvas.toBlob(blob => {
                  const imgAsForm = new FormData();
                  imgAsForm.append(
                    'file',
                    imageFile.type === 'image/gif' ? imageFile : blob,
                  );
                  requestAttachmentToken(imgAsForm, 'image')
                    .then(res => {
                      this.setState({
                        imageUrl:
                          imageFile.type === 'image/gif'
                            ? image.src
                            : base64data,
                        videoUrl: '',
                        attachmentToken: res.data.token,
                        removeAttachment: false,
                        imageLoading: false,
                      });
                    })
                    .catch(error => {
                      this.setState({
                        errors:
                          error.response.data &&
                          error.response.data.errors &&
                          error.response.data.errors.file.join(' '),
                        imageLoading: false,
                      });
                    });
                }, imageFile.type);
              },
              {
                canvas: true,
                orientation,
                maxWidth: 1300
              },
            );
          });
        });
      });
    }
  };

  uploadVideo = e => {
    if (e.target.files[0]) {
      document.getElementById('edit-post-button__image').value = null;
      this.setState({
        videoLoading: true,
        errors: '',
        videoDuration: null,
      });
      const reader = new FileReader();
      const video = e.target.files[0];
      const videoEl = document.createElement('video');
      reader.onloadend = () => {
        const videoAsForm = new FormData();
        videoEl.addEventListener('loadeddata', () => {
          const canvas = document.createElement('canvas');
          canvas.width = videoEl.videoWidth;
          canvas.height = videoEl.videoHeight;
          canvas.getContext('2d').drawImage(videoEl, 0, 0);
          canvas.toBlob(blob => {
            const videoUrl = URL.createObjectURL(blob);

            videoAsForm.append('file', video);
            requestAttachmentToken(videoAsForm, 'video')
              .then(res => {
                this.setState({
                  attachmentToken: res.data.token,
                  videoUrl,
                  imageUrl: '',
                  videoLoading: false,
                  videoDuration: videoEl.duration,
                  removeAttachment: false,
                });
              })
              .catch(error => {
                this.setState({
                  errors:
                    error.response.data &&
                    error.response.data.errors &&
                    error.response.data.errors.file.join(' '),
                  videoLoading: false,
                });
              });
          });
        });
      };
      reader.readAsDataURL(video);
      videoEl.src = URL.createObjectURL(video);
    }
  };

  handleSubmit = () => {
    const {
      post,
      closeModal,
      match,
      newsfeed,
      userNewsfeed,
      farmNewsfeed,
      updateNewsfeed,
      updateUserNewsfeed,
      updateFarmNewsfeed,
      updateHashtagfeed
    } = this.props;
    const { text, removeAttachment, attachmentToken } = this.state;
    const { path } = match;

    updatePost(post.id, text, removeAttachment, attachmentToken)
      .then(response => {
        const createUpdatedNewsfeed = newsfeed =>
          newsfeed.map(item => {
            if (item.id === post.id) {
              return {
                ...response.data,
                can_edit: true,
                comments_count: post.comments_count,
                likes_count: post.likes_count,
                shares_count: post.shares_count,
                shared_content: post.shared_content
                  ? { ...post.shared_content }
                  : null,
                liked: post.liked,
              };
            }

            return item;
          });
        if (path === '/') {
          updateNewsfeed(createUpdatedNewsfeed(newsfeed));
        }
        if(window.location.pathname === '/hashtag') {
          updateHashtagfeed(createUpdatedNewsfeed(newsfeed));
        }
        if (path.indexOf('/profile') > -1) {
          updateUserNewsfeed(createUpdatedNewsfeed(userNewsfeed));
        }
        if (path.indexOf('/farms') > -1) {
          updateFarmNewsfeed(createUpdatedNewsfeed(farmNewsfeed));
        }
        closeModal();
      })
      .catch(error => {
        this.setState({
          errors:
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors.file.join(' '),
        });
      });
  };

  render() {
    const { closeModal, post, userData } = this.props;
    const {
      text,
      imageUrl,
      videoUrl,
      imageLoading,
      videoLoading,
      videoDuration,
      errors,
    } = this.state;

    return (
      <EditPostModalView
        onRemoveAttachment={this.removeAttachment}
        onSubmit={this.handleSubmit}
        onTextChange={this.handleTextChange}
        onUploadImage={this.uploadImage}
        onUploadVideo={this.uploadVideo}
        imageLoading={imageLoading}
        videoLoading={videoLoading}
        text={text}
        imageUrl={imageUrl}
        videoUrl={videoUrl}
        videoDuration={videoDuration}
        userData={userData}
        post={post}
        errors={errors}
        closeModal={closeModal}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(EditPostModal));
