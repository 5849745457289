import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withLocalize } from 'react-localize-redux';
import setInterceptors from 'common/utils/interceptorUtils';
import { fetchUserData } from 'common/redux/actions/userDataActions';
import { renderToStaticMarkup } from 'react-dom/server';
import translation from 'translation.json';

import { fetchNotifications } from 'notification/redux/actions/notificationActions';
import { checkForUnreadPosts } from 'newsfeed/redux/actions/newsfeedActions';
import { countArticles } from 'marketplace/redux/actions/marketplaceActions';

const defaultProps = {
  token: '',
};

const propTypes = {
  children: PropTypes.node.isRequired,
  initialize: PropTypes.func.isRequired,
  fetchUserData: PropTypes.func.isRequired,
  fetchNotifications: PropTypes.func.isRequired,
  checkForUnreadPosts: PropTypes.func.isRequired,
  countArticles: PropTypes.func.isRequired,
  token: PropTypes.string,
};

const mapDispatchToProps = dispatch => ({
  fetchUserData: bindActionCreators(fetchUserData, dispatch),
  fetchNotifications: bindActionCreators(fetchNotifications, dispatch),
  checkForUnreadPosts: bindActionCreators(checkForUnreadPosts, dispatch),
  countArticles: bindActionCreators(countArticles, dispatch),
});

const mapStateToProps = state => ({
  userData: state.userData.data,
  token: state.auth.token,
});

class Main extends Component {
  constructor(props) {
    super(props);
    const { initialize } = props;
    initialize({
      languages: [
        { name: 'German', code: 'de' },
        { name: 'English', code: 'en' },
      ],
      translation,
      options: {
        renderToStaticMarkup,
        defaultLanguage: process.env.REACT_APP_LANGUAGE,
      },
    });
    setInterceptors();
  }

  componentDidMount() {
    const {
      fetchUserData,
      fetchNotifications,
      checkForUnreadPosts,
      countArticles,
      token,
    } = this.props;

    if (token) {
      setTimeout(() => {
        fetchUserData();
        fetchNotifications();

        this.interval = setInterval(() => {
          fetchNotifications();
        }, 1000);
      }, 250);

      setTimeout(() => {
        checkForUnreadPosts();
      }, 1500);
    }
    countArticles();

    this.articlesInterval = setInterval(() => {
      countArticles();
    }, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    clearInterval(this.articlesInterval);
  }

  render() {
    const { children } = this.props;
    return <Fragment>{children}</Fragment>;
  }
}

Main.defaultProps = defaultProps;

Main.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLocalize(Main));
