import React from 'react';
import Button from 'common/components/button';
import { Translate } from 'react-localize-redux';
import './index.scss';

const ResendForgotAccountView = ({ resendEmail, wrongEmail, email }) => (
  <div className="resend-forgot-account">
    <div className="resend-forgot-account__wrapper">
      <h1 className="resend-forgot-account__heading">
        <Translate id="page.resetPassword.heading" />
      </h1>
      <h2 className="resend-forgot-account__sub-heading">
        <Translate id="page.resetPassword.subHeading" />
      </h2>
      <div className="resend-forgot-account__row">
        <p className="resend-forgot-account__user-mail">{email}</p>
        <Button
          className="resend-forgot-account__wrong-email-button"
          onClick={wrongEmail}
        >
          <Translate id="page.resetPassword.wrongEmail" />
        </Button>
      </div>
      <Button
        className="resend-forgot-account__resend-button"
        onClick={resendEmail}
      >
        <Translate id="common.action.resendLink" />
      </Button>
    </div>
  </div>
);

export default ResendForgotAccountView;
