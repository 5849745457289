import React, { Component } from 'react';
import queryString from 'query-string';
import ResetPasswordView from 'userAuth/components/resetPasswordView';
import { resetPassword } from 'userAuth/api/authApi';
import setResetPasswordErrors from 'userAuth/utils/resetPasswordErrors';

class ResetPassword extends Component {
  submitResetPasswordForm = (values, actions) => {
    const { password, passwordConfirmation } = values;
    const { location, history } = this.props;
    const token = queryString.parse(location.search);

    resetPassword({
      token,
      password,
      password_confirmation: passwordConfirmation,
    })
      .then(() => {
        history.push('/congratulations');
      })
      .catch(error => {
        actions.setErrors(setResetPasswordErrors(error));
        actions.setSubmitting(false);
      });
  };

  cancel = () => {
    const { history } = this.props;
    history.push('/auth');
  };

  render() {
    return (
      <ResetPasswordView
        submitResetPasswordForm={this.submitResetPasswordForm}
        cancel={this.cancel}
      />
    );
  }
}

export default ResetPassword;
