import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from 'common/components/button';
import Indicator from 'common/components/indicator';
import { Translate } from 'react-localize-redux';
import './index.scss';

const defaultProps = {
  unreadNews: false,
};

const propTypes = {
  toggleFeeds: PropTypes.func.isRequired,
  activeFeed: PropTypes.string.isRequired,
  unreadNews: PropTypes.bool,
};

const FeedsTogglerView = ({ toggleFeeds, activeFeed, unreadNews }) => {
  const farmButtonClass = classNames({
    'toggle-feed-button': true,
    'is-active': activeFeed === 'farm',
  });

  const newsButtonClass = classNames({
    'toggle-feed-button news-button': true,
    'is-active': activeFeed === 'news',
  });

  const indicatorClasses = classNames({
    'indicator-wrapper': true,
    'no-sizes': true,
  });

  return (
    <Fragment>
      <Button className={farmButtonClass} onClick={() => toggleFeeds('farm')}>
        <Translate id="common.action.feeds.farmfeed" />
      </Button>
      <Button className={newsButtonClass} onClick={() => toggleFeeds('news')}>
        <span className={indicatorClasses}>
          {unreadNews && <Indicator top={-3} right={-9} />}
          <Translate id="common.action.feeds.newsfeed" />
        </span>
      </Button>
    </Fragment>
  );
};

FeedsTogglerView.defaultProps = defaultProps;
FeedsTogglerView.propTypes = propTypes;

export default FeedsTogglerView;
