import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import CreateFarmTourModalView from 'farm/components/createFarmTourModalView';
import loadImage from 'blueimp-load-image';
import {
  getFarmTourImages,
  removeFarmTourData,
  swapImages
} from 'farm/redux/actions/farmtourActions';
import { fetchFarmDetails } from 'farm/redux/actions/farmActions';
import requestAttachmentToken from 'common/api/attachmentsApi';

const defaultProps = {
  farmtour: {},
  farm: {},
  updateFarmNewsfeed: () => null,
};

const propTypes = {
  closeModal: PropTypes.func.isRequired,
  getFarmTourImages: PropTypes.func.isRequired,
  fetchFarmDetails: PropTypes.func.isRequired,
  removeFarmTourData: PropTypes.func.isRequired,
  farm: PropTypes.object,
  farmtour: PropTypes.object,
  updateFarmNewsfeed: PropTypes.func,
};

const mapStateToProps = state => ({
  farmtour: state.farmtour,
  farm: state.farm.data,
});

const mapDispatchToProps = dispatch => ({
  getFarmTourImages: bindActionCreators(getFarmTourImages, dispatch),
  fetchFarmDetails: bindActionCreators(fetchFarmDetails, dispatch),
  removeFarmTourData: bindActionCreators(removeFarmTourData, dispatch),
  swapImages: bindActionCreators(swapImages, dispatch),
});

class CreateFarmTourModal extends Component {
  state = {
    imageLoading: false,
    imageLoading2: false,
    initialImages: [],
    imagesTokens: [],
    deleteAttachments: [],
    imagesUrls: [],
    isFarmTourLoading: false,
    tourImageError: '',
    uploadCounter: 0,
    totalUploadTime: 0,
    uploadProgress: 0,
    videoToken: null,
    isVideoUploadInProgress: false,
    videoDuration: null,
    imagesMoved: false
  };

  componentDidMount() {
    const { getFarmTourImages, farm } = this.props;

    this.setState({ isFarmTourLoading: true });

    if (farm) {
      getFarmTourImages(farm.id).then(() => {
        const { data } = this.props.farmtour;

        this.setState(prevState => ({
          ...prevState,
          initialImages: data.attachments.length > 0 ? data.attachments : [],
          isFarmTourLoading: false,
        }));
      });
    }
  }

  componentWillUnmount() {
    const { removeFarmTourData } = this.props;
    removeFarmTourData();
  }
  
  _reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    this.props.swapImages(result.draggableId, this.state.initialImages[result.destination.index].id,this.props.farm.id)

    const items = this._reorder(
      this.state.initialImages,
      result.source.index,
      result.destination.index
    );

    this.setState({
      imagesMoved: true,
      initialImages: items
     });
  };

  updateProgress = evt => {
    this.resetProgressInterval();

    if (evt.lengthComputable) {
      const mb = 1024 * 1024;

      const totalMb = Math.ceil(evt.total / mb);
      const ratio = 2;
      const secs = Math.ceil(totalMb * ratio);

      this.setState({
        totalUploadTime: secs,
      });

      this.interval = setInterval(() => {
        this.updateProgressBar();
      }, 100);
    }
  };

  resetProgressInterval = () => {
    clearInterval(this.interval);

    this.setState({
      uploadCounter: 0,
      totalUploadTime: 0,
      uploadProgress: 0,
    });
  };

  updateProgressBar = () => {
    this.setState({
      uploadCounter: this.state.uploadCounter + 0.1,
    });

    const percentLoaded = Math.round(
      (this.state.uploadCounter / this.state.totalUploadTime) * 100,
    );

    if (percentLoaded <= 95) {
      this.setState({
        uploadProgress: percentLoaded,
      });
    } else {
      this.setState({
        uploadProgress: 95,
      });
    }

    if (this.state.uploadCounter >= this.state.totalUploadTime) {
      clearInterval(this.interval);
    }
  };

  createVideoPost = e => {
    if (e.target.files[0]) {
      this.setState({
        imageToken: null,
        imageUrl: null,
        uploadError: '',
        videoDuration: null,
        previewUrl: null,
        imageLoading: true,
      });
      const reader = new FileReader();
      const video = e.target.files[0];
      document.getElementById('image-input').value = null;
      const videoEl = document.createElement('video');

      reader.onloadstart = this.updateProgress;

      reader.onloadend = () => {
        const videoAsForm = new FormData();
        videoEl.addEventListener('loadeddata', () => {
          const canvas = document.createElement('canvas');
          canvas.width = videoEl.videoWidth;
          canvas.height = videoEl.videoHeight;
          canvas.getContext('2d').drawImage(videoEl, 0, 0);
          canvas.toBlob(blob => {
            // const previewUrl = URL.createObjectURL(blob);
          });
        });
        videoAsForm.append('file', video);

        requestAttachmentToken(videoAsForm, 'video')
          .then(res => {
            const { farm } = this.props;

            axios
              .get(
                `${process.env.REACT_APP_API_URL}/farmtour/${
                  farm.id
                }/thumbnail`,
                {
                  params: {
                    token: res.data.token,
                  },
                },
              )
              .then(res => {
                if (res.data && res.data.attachment) {
                  const { imagesUrls } = this.state;

                  this.setState({
                    imagesUrls: [...imagesUrls, res.data.attachment.thumbnail],
                  });
                }
              });

            this.resetProgressInterval();
            const { imagesTokens } = this.state;

            this.setState({
              imagesTokens: [...imagesTokens, res.data.token],
              imageLoading: false,
              uploadProgress: 0,
            });
          })
          .catch(error => {
            this.resetProgressInterval();

            this.setState({
              imageLoading: false,
              uploadError:
                error.response.data &&
                error.response.data.errors &&
                error.response.data.errors.file.join(' '),
              uploadProgress: 0,
            });
          });
      };
      reader.readAsDataURL(video);
      videoEl.src = URL.createObjectURL(video);
    }
  };

  addFarmTourImage = e => {
    const fileType = e.target.files[0] && e.target.files[0].type.split('/')[0];

    const first = e.target.name === 'farmtour-image-input';
    if (fileType === 'video') {
      return this.createVideoPost(e);
    }

    const imageFile = e.target.files[0];
    const reader = new FileReader();
    const image = new Image();

    if (imageFile) {
      reader.onloadstart = this.updateProgress;

      this.setState({
        imageLoading: first,
        imageLoading2: !first,
        tourImageError: '',
      });
      reader.readAsDataURL(imageFile);

      reader.addEventListener('load', () => {
        image.src = reader.result;
        image.addEventListener('load', () => {
          loadImage.parseMetaData(imageFile, data => {
            let orientation = 0;
            if (data.exif) {
              orientation = data.exif.get('Orientation');
            }
            loadImage(
              imageFile,
              canvas => {
                const base64data = canvas.toDataURL(imageFile.type);

                canvas.toBlob(blob => {
                  const { imagesUrls } = this.state;
                  const imgAsForm = new FormData();
                  imgAsForm.append(
                    'file',
                    imageFile.type === 'image/gif' ? imageFile : blob,
                  );

                  requestAttachmentToken(imgAsForm, 'image')
                    .then(res => {
                      this.resetProgressInterval();

                      const { imagesTokens } = this.state;

                      this.setState({
                        imagesUrls: [
                          ...imagesUrls,
                          imageFile.type === 'image/gif'
                            ? image.src
                            : base64data,
                        ],
                        imagesTokens: [...imagesTokens, res.data.token],
                        imageLoading: false,
                        imageLoading2: false,
                      });
                    })
                    .catch(error => {
                      this.resetProgressInterval();

                      this.setState({
                        tourImageError:
                          error.response.data &&
                          error.response.data.errors &&
                          error.response.data.errors.file.join(' '),
                        imageLoading: false,
                        imageLoading2: false,
                      });
                    });
                }, imageFile.type);
              },
              {
                canvas: true,
                orientation,
                maxWidth: 1300,
              },
            );
          });
        });
      });
    }
  };

  removeImage = index => {
    const { imagesTokens, imagesUrls } = this.state;
    this.setState({
      imagesTokens: imagesTokens.filter((image, i) => index !== i),
      imagesUrls: imagesUrls.filter((image, i) => index !== i),
    });
  };

  removeInitialImage = (id, index) => {
    const { deleteAttachments, initialImages } = this.state;
    this.setState({
      initialImages: initialImages.filter((image, i) => index !== i),
      deleteAttachments: [...deleteAttachments, id],
    });
  };

  submitForm = () => {
    const {
      closeModal,
      farm,
      fetchFarmDetails,
      updateFarmNewsfeed,
    } = this.props;

    const { deleteAttachments, imagesTokens } = this.state;

    axios
      .post(`${process.env.REACT_APP_API_URL}/farmtour`, {
        farm_id: farm.id,
        attachments: imagesTokens,
        delete_attachments: deleteAttachments,
      })
      .then(() => {
        fetchFarmDetails(farm.id);
        closeModal();
        updateFarmNewsfeed(farm.id);
      })
      .catch(error => {
        this.setState({
          tourImageError:
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : 'Error',
        });
      });
  };

  render() {
    const { closeModal, farm } = this.props;

    const {
      deleteAttachments,
      imagesUrls,
      initialImages,
      imageLoading,
      imageLoading2,
      isFarmTourLoading,
      tourImageError,
      uploadProgress,
      imagesMoved
    } = this.state;

    return (
      !isFarmTourLoading &&
      <CreateFarmTourModalView
        closeModal={closeModal}
        farmId={farm && farm.id}
        uploadProgress={uploadProgress}
        imageLoading={imageLoading}
        imageLoading2={imageLoading2}
        initialImages={initialImages}
        imagesUrls={imagesUrls}
        tourImageError={tourImageError}
        isFarmTourLoading={isFarmTourLoading}
        removeInitialImage={this.removeInitialImage}
        removeImage={this.removeImage}
        addFarmTourImage={this.addFarmTourImage}
        deleteAttachments={deleteAttachments}
        submitForm={this.submitForm}
        onDragEnd={this.onDragEnd}
        imagesMoved={imagesMoved}
      />
    );
  }
}

CreateFarmTourModal.defaultProps = defaultProps;

CreateFarmTourModal.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateFarmTourModal);
