import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from 'common/components/button';
import { Translate } from 'react-localize-redux';
import './index.scss';

const defaultProps = {
  isCommentActionHidden: false,
  feed: '',
};

const propTypes = {
  handleLikeClick: PropTypes.func.isRequired,
  handleShareClick: PropTypes.func.isRequired,
  isShareActionAvailable: PropTypes.func.isRequired,
  isCommentActionHidden: PropTypes.bool,
  feed: PropTypes.string,
  onCommentsClick: PropTypes.func.isRequired,
  post: PropTypes.object.isRequired
};

const PostActionsView = ({
  handleLikeClick,
  handleShareClick,
  isShareActionAvailable,
  onCommentsClick,
  isCommentActionHidden,
  post,
  feed,
}) => {
  const buttonClasses = classNames({
    'post-actions__button': true,
    'post-actions__button--wide': !isShareActionAvailable() || feed === 'news',
  });

  const spanClasses = classNames({
    'post-actions__button-content': true,
    'post-actions__button-content--like': !post.liked,
    'post-activities__button-content--likes': post.liked,
  });

  return (
    <div className="post-actions">
      <Button className={buttonClasses} onClick={handleLikeClick}>
        <span className={spanClasses}>
          <Translate id="common.action.like" />
        </span>
      </Button>
      {!isCommentActionHidden && (
        <Button className={buttonClasses} onClick={onCommentsClick}>
          <span className="post-actions__button-content post-actions__button-content--comment">
            <Translate id="common.action.comment" />
          </span>
        </Button>
      )}
      {isShareActionAvailable() && (
        <Button className={buttonClasses} onClick={handleShareClick}>
          <span className="post-actions__button-content post-actions__button-content--share">
            <Translate id="common.action.share" />
          </span>
        </Button>
      )}
    </div>
  );
};

PostActionsView.defaultProps = defaultProps;

PostActionsView.propTypes = propTypes;

export default PostActionsView;
