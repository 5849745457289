import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './index.scss';

const defaultProps = {
  disabled: false,
  fullWidth: false,
  large: false,
  onClick: () => null,
  type: 'button',
  secondary: false,
  teritary: false,
};

const propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  large: PropTypes.bool,
  onClick: PropTypes.func,
  type: PropTypes.string,
  secondary: PropTypes.bool,
  teritary: PropTypes.bool,
};

const MainButton = ({
  children,
  disabled,
  onClick,
  type,
  large,
  fullWidth,
  secondary,
  teritary,
}) => {
  const buttonClasses = classNames({
    'main-button': true,
    'main-button--large': large,
    'main-button--full-width': fullWidth,
    'main-button--disabled': disabled,
    'main-button--secondary': secondary,
    'main-button--teritary': teritary,
  });
  /* eslint-disable react/button-has-type */
  return (
    <button
      className={buttonClasses}
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

MainButton.defaultProps = defaultProps;

MainButton.propTypes = propTypes;

export default MainButton;
