import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import { Translate } from 'react-localize-redux';
import classNames from 'classnames';
import { GoogleApiWrapper, Map, InfoWindow, Marker } from 'google-maps-react';
import Pin from 'assets/icons/pin-sm.png';

const defaultProps = {
  farmsList: [],
};

const propTypes = {
  lat: PropTypes.string.isRequired,
  lng: PropTypes.string.isRequired,
  farmsList: PropTypes.array,
};

export class FarmMap extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();

    this.state = {
      showingInfoWindow: false,
      mapFullWidth: false,
      activeMarker: {},
      farmName: '',
      farmAddress: '',
      farmId: '',
      zoom: 12,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        zoom: 11,
      });
    }, 1500);
  }

  onMarkerClick = (props, marker) => {
    this.setState({
      activeMarker: marker,
      mapFullWidth: true,
      farmName: props.name,
      farmAddress: props.address,
      farmId: props.id,
    });

    setTimeout(() => {
      this.setState({
        showingInfoWindow: true,
      });
    }, 500);
  };

  onMapClick = () => {
    if (!this.state.mapFullWidth) {
      this.setState({
        mapFullWidth: true,
        activeMarker: this.myRef.current.marker,
        farmName: this.myRef.current.props.name,
        farmAddress: this.myRef.current.props.address,
        farmId: this.myRef.current.props.id,
      });

      setTimeout(() => {
        this.setState({
          showingInfoWindow: true,
        });
      }, 500);
    }
  };

  onInfoWindowClose = () => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
        farmName: '',
        farmAddress: '',
        farmId: '',
      });
    }
  };

  closeMap = () => {
    this.setState({
      mapFullWidth: false,
      showingInfoWindow: false,
      activeMarker: null,
    });
  };

  toggleFarmClick = () => {
    this.setState({
      showingInfoWindow: false,
      activeMarker: null,
    });
  };

  renderMarkers = () => {
    const { farm, farmsList, google } = this.props;
    const { mapFullWidth } = this.state;

    const markers = [];

    if (mapFullWidth) {
      farmsList.forEach((item, key) => {
        if (item.lat && item.lng) {
          markers.push(
            <Marker
              key={key}
              onClick={this.onMarkerClick}
              name={item.name}
              address={item.adress}
              id={item.id}
              position={{ lat: item.lat, lng: item.lng }}
              icon={{
                url: Pin,
                anchor: new window.google.maps.Point(12.5, 40.5),
                scaledSize: new window.google.maps.Size(25, 40.5),
              }}
            />,
          );
        }
      });
    } else {
      markers.push(
        <Marker
          onClick={this.onMarkerClick}
          name={farm.name}
          address={farm.adress}
          id={farm.id}
          ref={this.myRef}
          key={farm.id}
          icon={{
            url: Pin,
            anchor: new window.google.maps.Point(12.5, 40.5),
            scaledSize: new google.maps.Size(25, 40.5),
          }}
        />,
      );
    }

    return markers;
  };

  getBounds = () => {
    const { lat, lng, farmsList } = this.props;

    const { mapFullWidth } = this.state;

    const points = [];

    farmsList.forEach(item => {
      if (item.lat && item.lng) {
        points.push({ lat: parseFloat(item.lat), lng: parseFloat(item.lng) });
      }
    });

    const farmPoints = { lat: parseFloat(lat), lng: parseFloat(lng) };
    const bounds = new this.props.google.maps.LatLngBounds();

    if (mapFullWidth) {
      for (let i = 0; i < points.length; i++) {
        bounds.extend(points[i]);
      }
    } else {
      bounds.extend(farmPoints);
    }

    return bounds;
  };

  render() {
    const { lat, lng, farmsList, google } = this.props;
    const {
      showingInfoWindow,
      activeMarker,
      mapFullWidth,
      farmName,
      farmAddress,
      farmId,
      zoom,
    } = this.state;

    const wrapperClasses = classNames({
      'farmmap-wrapper': true,
      'farmmap-wrapper__full': mapFullWidth,
    });

    const imageWrapperClasses = classNames({
      'farmmap-image-wrapper': true,
      'farmmap-image-wrapper__full': mapFullWidth,
    });

    const points = [];

    farmsList.forEach(item => {
      if (item.lat && item.lng) {
        points.push({ lat: parseFloat(item.lat), lng: parseFloat(item.lng) });
      }
    });

    const markers = this.renderMarkers();

    return (
      <div className={wrapperClasses}>
        <span className="farmmap-title">
          <Translate id="farm.map" />
        </span>
        <div className={imageWrapperClasses}>
          {mapFullWidth && (
            <button onClick={this.closeMap} className="close-map-button">
              <Translate id="common.action.closeFarm" />
            </button>
          )}

          <Map
            key={mapFullWidth + zoom}
            google={google}
            initialCenter={{ lat, lng }}
            onClick={this.onMapClick}
            disableDefaultUI={!mapFullWidth}
            streetViewControl={false}
            gestureHandling={mapFullWidth ? 'auto' : 'none'}
            fullscreenControl={false}
            style={{ width: '100%', height: '100%', position: 'relative' }}
            zoom={mapFullWidth ? 5 : 10}
          >
            {markers}

            <InfoWindow
              marker={activeMarker}
              visible={showingInfoWindow}
              onClose={this.onInfoWindowClose}
            >
              <div className="map-marker">
                <span className="farmlifes-icon" />
                <a
                  href={`/farms/${farmId}`}
                  onClick={() => {
                    this.toggleFarmClick();
                  }}
                >
                  <span className="map-farm-name">{farmName}</span>
                </a>
                <span className="map-farm-address">{farmAddress}</span>
              </div>
            </InfoWindow>
          </Map>
        </div>
      </div>
    );
  }
}

FarmMap.propTypes = propTypes;
FarmMap.defaultProps = defaultProps;

export default GoogleApiWrapper({
  apiKey: 'AIzaSyCyEKa9DEjWyxKUpMfEIuPw7s-8G4VIjyo',
  language: process.env.REACT_APP_LANGUAGE,
})(FarmMap);
