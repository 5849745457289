import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import disableScroll from 'common/utils/disableScroll';
import { removeUserData } from 'common/redux/actions/userDataActions';
import {
  removeUserAuthToken,
  removeUserEmail,
} from 'userAuth/redux/actions/authActions';
import { deleteUserProfile } from 'common/api/userApi';
import { fetchFarmMembers } from 'farm/redux/actions/farmMembersActions';
import DeleteButton from 'common/components/deleteButton';
import InfoModal from 'common/components/infoModal';
import SetNewAdminModal from 'common/containers/setNewAdminModal';
import DeleteFarmModal from 'common/containers/deleteFarmModal';

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
  userData: state.userData.data,
  membersResultsNumber: state.farmMembers.resultsNumber,
});

const mapDispatchToProps = dispatch => ({
  removeUserData: bindActionCreators(removeUserData, dispatch),
  removeUserAuthToken: bindActionCreators(removeUserAuthToken, dispatch),
  removeUserEmail: bindActionCreators(removeUserEmail, dispatch),
  getFarmMembers: bindActionCreators(fetchFarmMembers, dispatch),
});

const propTypes = {
  removeUserData: PropTypes.func.isRequired,
  removeUserAuthToken: PropTypes.func.isRequired,
  removeUserEmail: PropTypes.func.isRequired,
  userData: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
  ).isRequired,
  membersResultsNumber: PropTypes.number.isRequired,
  closeEditProfileModal: PropTypes.func.isRequired,
};

class DeleteAccount extends Component {
  state = {
    isDeleteAccountModalVisible: false,
    isSetNewAdminModalVisible: false,
    isDeleteFarmModalVisible: false,
  };

  componentDidMount() {
    const { getFarmMembers, userData } = this.props;
    const { admin_in: adminIn } = userData;
    getFarmMembers(adminIn);
  }

  handleDeleteOnClick = () => {
    const { userData, membersResultsNumber } = this.props;

    if (!userData.admin_in) {
      this.deleteAccount();
    } else if (userData.admin_in && membersResultsNumber > 1) {
      this.toggleNewAdminModal();
    } else if (userData.admin_in && membersResultsNumber === 1) {
      this.toggleDeleteFarmModal();
    }
  };

  deleteAccount = () => {
    deleteUserProfile()
      .then(() => {
        const {
          closeEditProfileModal,
          removeUserAuthToken,
          removeUserEmail,
          removeUserData,
          history,
        } = this.props;

        closeEditProfileModal();
        removeUserEmail();
        removeUserAuthToken();
        removeUserData();
        history.push('/auth');
      })
      .catch(() => {
        throw new Error('Could not delete this user profile');
      });
  };

  toggleDeleteAccountModal = () => {
    const { isDeleteAccountModalVisible } = this.state;
    this.setState(
      { isDeleteAccountModalVisible: !isDeleteAccountModalVisible },
      disableScroll(!isDeleteAccountModalVisible),
    );
  };

  toggleNewAdminModal = () => {
    const { isSetNewAdminModalVisible } = this.state;
    this.setState(
      { isSetNewAdminModalVisible: !isSetNewAdminModalVisible },
      disableScroll(!isSetNewAdminModalVisible),
    );
  };

  toggleDeleteFarmModal = () => {
    const { isDeleteFarmModalVisible } = this.state;
    this.setState(
      { isDeleteFarmModalVisible: !isDeleteFarmModalVisible },
      disableScroll(!isDeleteFarmModalVisible),
    );
  };

  render() {
    const { translate, userData } = this.props;
    const {
      isDeleteAccountModalVisible,
      isSetNewAdminModalVisible,
      isDeleteFarmModalVisible,
    } = this.state;

    return (
      <Fragment>
        <DeleteButton
          text={translate('common.deleteObject', {
            object: translate('common.profile'),
          })}
          onClick={this.toggleDeleteAccountModal}
        />
        {isDeleteAccountModalVisible && (
          <InfoModal
            heading={translate('modal.deleteAccount.heading')}
            message={translate('modal.deleteAccount.message')}
            primaryOptionButton={translate('common.action.delete')}
            handlePrimaryOptionButtonClick={this.handleDeleteOnClick}
            closeModal={this.toggleDeleteAccountModal}
            isCancelButtonVisible
          />
        )}
        {isSetNewAdminModalVisible && (
          <SetNewAdminModal
            closeModal={this.toggleNewAdminModal}
            onSuccess={this.deleteAccount}
          />
        )}
        {isDeleteFarmModalVisible && (
          <DeleteFarmModal
            closeModal={this.toggleDeleteFarmModal}
            farmId={userData.admin_in}
            onSuccess={this.deleteAccount}
            heading={translate('modal.deleteFarmOnDeleteAccount.heading')}
            message={translate('modal.deleteFarmOnDeleteAccount.message')}
          />
        )}
      </Fragment>
    );
  }
}

DeleteAccount.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(DeleteAccount));
