import React from 'react';
import PropTypes from 'prop-types';
import UserAvatar from 'common/components/userAvatar';
import CreatePostButtons from 'common/components/createPostButtons';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import './index.scss';

const defaultProps = {
  farmId: null,
  userData: {},
  farmData: {},
  toggleGuestModal: () => null,
};

const mapStateToProps = state => ({
  userData: state.userData.data,
  farmData: state.farm.data,
  translate: getTranslate(state.localize),
});

const propTypes = {
  toggleCreatePostModal: PropTypes.func.isRequired,
  createImagePost: PropTypes.func.isRequired,
  createVideoPost: PropTypes.func.isRequired,
  postMessage: PropTypes.string.isRequired,
  toggleSellArticleModal: PropTypes.func.isRequired,
  userData: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
  ),
  farmData: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.array,
      PropTypes.object,
      PropTypes.bool,
    ]),
  ),
  farmId: PropTypes.number,
  translate: PropTypes.func.isRequired,
  toggleGuestModal: PropTypes.func,
};

const CreatePostView = ({
  createImagePost,
  createVideoPost,
  postMessage,
  toggleCreatePostModal,
  toggleSellArticleModal,
  userData,
  farmData,
  farmId,
  translate,
  toggleGuestModal,
}) => (
  <div className="create-post">
    <div className="create-post__header">
      <CreatePostButtons
        toggleCreatePostModal={toggleCreatePostModal}
        createImagePost={createImagePost}
        toggleGuestModal={toggleGuestModal}
        userData={userData}
        createVideoPost={createVideoPost}
        toggleSellArticleModal={toggleSellArticleModal}
        isSellArticleButtonVisible={!farmId}
      />
    </div>
    <div className="create-post__text-container">
      <div className="create-post__avatar">
        {farmId ? (
          <UserAvatar
            image={farmData.profile_image && farmData.profile_image.imageUrl}
            isFarmProfile
          />
        ) : (
          <UserAvatar image={userData.avatar} />
        )}
      </div>
      <textarea
        className="create-post__textarea"
        placeholder={translate('common.createPostPlaceholder')}
        onFocus={() => {
          if (userData.id) {
            toggleCreatePostModal();
          } else {
            toggleGuestModal();
          }
        }}
        value={postMessage}
        readOnly
      />
    </div>
  </div>
);

CreatePostView.defaultProps = defaultProps;

CreatePostView.propTypes = propTypes;

export default connect(mapStateToProps)(CreatePostView);
