import * as Yup from 'yup';
import localizeYup from 'common/utils/localizeYupDefaults.js';

localizeYup();

const loginValidationSchema = Yup.object().shape({
  username: Yup.string().required(),
  password: Yup.string().required(),
});

export default loginValidationSchema;
