import React, { Component } from 'react';
import PropTypes from 'prop-types';
import disableScroll from 'common/utils/disableScroll';
import NewAdminModalView from 'common/components/setNewAdminModalView';
import { deleteFarmMember, setNewAdmin } from 'farm/api/farmsApi';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchUserData } from 'common/redux/actions/userDataActions';
import { fetchFarmMembers } from 'farm/redux/actions/farmMembersActions';

const defaultProps = {
  nextPageIndex: null,
  onSuccess: () => null,
};

const propTypes = {
  farmMembers: PropTypes.arrayOf(PropTypes.object).isRequired,
  userData: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
  ).isRequired,
  getFarmMembers: PropTypes.func.isRequired,
  getUserData: PropTypes.func.isRequired,
  nextPageIndex: PropTypes.number,
  closeModal: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
};

const mapStateToProps = state => ({
  userData: state.userData.data,
  nextPageIndex: state.farmMembers.nextPageIndex,
  farmMembers: state.farmMembers.data,
});

const mapDispatchToProps = dispatch => ({
  getUserData: bindActionCreators(fetchUserData, dispatch),
  getFarmMembers: bindActionCreators(fetchFarmMembers, dispatch),
});

class SetNewAdminModal extends Component {
  state = {
    newAdminId: null,
  };

  componentDidMount() {
    const { getFarmMembers, userData } = this.props;
    const { admin_in: adminIn } = userData;
    getFarmMembers(adminIn);
  }

  componentWillUnmount() {
    disableScroll(false);
  }

  handleNewAdminRadioClick = e => {
    this.setState({ newAdminId: Number(e.target.name) });
  };

  setNewAdmin = () => {
    const { userData, onSuccess } = this.props;
    const { admin_in: farmId } = userData;
    const { newAdminId } = this.state;

    setNewAdmin(farmId, { admin_id: newAdminId })
      .then(() => {
        const { userData } = this.props;
        const { admin_in: farmId } = userData;
        const { id: memberId } = userData;

        deleteFarmMember(farmId, memberId)
          .then(() => {
            const { closeModal, getUserData } = this.props;
            getUserData();
            onSuccess();
            closeModal();
          })
          .catch(() => {
            throw new Error('Not able to delete old farm Admin');
          });
      })
      .catch(() => {
        throw new Error('Not able to set new farm Admin');
      });
  };

  getFarmMembersNextPage = () => {
    const { userData, getFarmMembers, nextPageIndex } = this.props;
    const { admin_in: farmId } = userData;
    getFarmMembers(farmId, nextPageIndex);
  };

  render() {
    const { closeModal, farmMembers, nextPageIndex } = this.props;
    const { newAdminId } = this.state;

    return (
      <NewAdminModalView
        closeModal={closeModal}
        setNewAdmin={this.setNewAdmin}
        handleNewAdminRadioClick={this.handleNewAdminRadioClick}
        farmMembers={farmMembers}
        getFarmMembersNextPage={this.getFarmMembersNextPage}
        nextPageIndex={nextPageIndex}
        newAdminId={newAdminId}
      />
    );
  }
}

SetNewAdminModal.defaultProps = defaultProps;

SetNewAdminModal.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SetNewAdminModal);
