import { createReducer } from 'common/utils/reduxUtils';
import {
  FETCH_FARMTOUR_DATA_SUCCESS,
  REMOVE_FARMTOUR_DATA,
  FETCH_FARMTOUR_POST_SUCCESS,
} from 'farm/redux/types';

const initialState = { data: [] };

const FarmtourReducer = createReducer(
  { ...initialState },
  {
    [FETCH_FARMTOUR_DATA_SUCCESS]: (state, action) => ({
      ...state,
      data: action.payload.data,
    }),
    [FETCH_FARMTOUR_POST_SUCCESS]: (state, action) => {
      if (action.payload.data.data && action.payload.data.data.info) {
        // state.data.attachments.push(action.payload.data.data.info);
      }
      return {
        ...state,
        farmTourPost: action.payload.data.data,
      };
    },
    [REMOVE_FARMTOUR_DATA]: state => ({
      ...state,
      data: [],
    }),
  },
);

export default FarmtourReducer;
