import React from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import Post from 'post/containers/post';
import Loader from 'common/components/loader';
import classNames from 'classnames';
import MarketplaceSearch from 'marketplace/containers/marketplaceSearch';
import EndMessage from 'common/components/endMessage';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import MarketplaceMobileBarView from 'marketplace/components/marketplaceMobileBarView';

import './index.scss';

const defaultProps = {
  marketplace: [],
  nextPageIndex: null,
  categories: [],
  translate: () => null,
  toggleSellArticleModal: () => null,
  handleCategoryChange: () => null,
};

const propTypes = {
  marketplace: PropTypes.arrayOf(PropTypes.object),
  getMarketplaceNextPage: PropTypes.func.isRequired,
  nextPageIndex: PropTypes.number,
  isInitialPageLoading: PropTypes.bool.isRequired,
  categories: PropTypes.arrayOf(PropTypes.object),
  translate: PropTypes.func,
  toggleSellArticleModal: PropTypes.func,
  handleCategoryChange: PropTypes.func,
  fixedSearch: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
  categoryId: state.marketplace.categoryId,
});

const MarketplaceView = ({
  marketplace,
  getMarketplaceNextPage,
  nextPageIndex,
  isInitialPageLoading,
  translate,
  categories,
  handleCategoryChange,
  toggleSellArticleModal,
  categoryId,
  fixedSearch,
}) => {
  const searchClasses = classNames({
    marketplace__search: true,
    'fixed-marketplace-search': fixedSearch,
  });
  
  const marketplaceClasses = classNames({
    marketplace: true,
    'fixed-state': fixedSearch,
  });

  return (
    <div className={marketplaceClasses}>
      <div className={searchClasses}>
        <MarketplaceSearch />
      </div>
      <MarketplaceMobileBarView
        categories={categories}
        categoryId={categoryId}
        handleCategoryChange={handleCategoryChange}
        toggleSellArticleModal={toggleSellArticleModal}
      />
      {isInitialPageLoading && (
        <Loader
          message={translate('common.load', {
            object: translate('common.reaction.posts'),
          })}
        />
      )}
      {!isInitialPageLoading && marketplace.length === 0 && (
        <p className="marketplace__empty">
          {translate('common.noObjects', {
            objects: translate('common.reaction.posts'),
          })}
        </p>
      )}
      <InfiniteScroll
        dataLength={marketplace.length}
        scrollThreshold="400px"
        next={nextPageIndex ? getMarketplaceNextPage : null}
        hasMore={!!nextPageIndex}
        loader={
          nextPageIndex === 1 ? (
            <Loader
              message={translate('common.load', {
                object: translate('common.reaction.posts'),
              })}
            />
          ) : (
            <Loader
              message={translate('common.loadMore', {
                object: translate('common.reaction.posts'),
              })}
            />
          )
        }
        endMessage={
          marketplace.length > 0 ? (
            <EndMessage
              message={translate('common.noMore', {
                object: translate('common.reaction.posts'),
              })}
            />
          ) : null
        }
      >
        {marketplace.map(post => (
          <div className="marketplace__post" key={post.id}>
            <Post post={post} newsfeed={marketplace} />
          </div>
        ))}
      </InfiniteScroll>
    </div>
  );
};

MarketplaceView.defaultProps = defaultProps;

MarketplaceView.propTypes = propTypes;

export default connect(mapStateToProps)(MarketplaceView);
