import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import HashtagView from 'hashtag/components/hashtagView';
import {
  fetchHashtagfeed,
  removeHashtagfeed,
} from 'hashtag/redux/actions/hashtagActions';
import disableScroll from 'common/utils/disableScroll';

const defaultProps = {
  hashtagfeed: [],
  // token: '',
  // userData: null,
  nextPageIndex: null,
  history: {},
};

const propTypes = {
  hashtagfeed: PropTypes.arrayOf(PropTypes.object),
  getHashtagfeed: PropTypes.func.isRequired,
  // token: PropTypes.string,
  // userData: PropTypes.objectOf(
  //   PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
  // ),
  removeHashtagfeed: PropTypes.func.isRequired,
  nextPageIndex: PropTypes.number,
  isLoading: PropTypes.bool.isRequired,
  history: PropTypes.object,
};

const mapStateToProps = state => ({
  hashtagfeed: state.hashtagfeed.data,
  nextPageIndex: state.hashtagfeed.nextPageIndex,
  // token: state.auth.token,
  // userData: state.userData.data,
  isLoading: state.newsfeed.isLoading,
});

const mapDispatchToProps = dispatch => ({
  getHashtagfeed: bindActionCreators(fetchHashtagfeed, dispatch),
  removeHashtagfeed: bindActionCreators(removeHashtagfeed, dispatch),
});

class Hashtagfeed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hashtag: null,
    };
  }

  componentWillMount() {
    const { history } = this.props;

    if (history.location.hash.length !== 0) {
      this.setState({
        hashtag: history.location.hash,
      });
    }
  }

  componentDidMount() {
    const { getHashtagfeed } = this.props;
    const { hashtag } = this.state;

    getHashtagfeed(1, hashtag);

    disableScroll(false);
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillReceiveProps() {
    const { history, getHashtagfeed } = this.props;
    const { hashtag } = this.state;

    if (history.location.hash !== hashtag) {
      this.setState({
        hashtag: history.location.hash,
      });
      getHashtagfeed(1, history.location.hash);
    }
  }

  componentWillUnmount() {
    const { removeHashtagfeed } = this.props;
    removeHashtagfeed();
  }

  getHashtagfeedNextPage = () => {
    const { getHashtagfeed, nextPageIndex } = this.props;
    const { hashtag } = this.state;

    getHashtagfeed(nextPageIndex, hashtag);
  };

  render() {
    const { hashtagfeed, nextPageIndex, isLoading } = this.props;
    const { hashtag } = this.state;

    return (
      <HashtagView
        hashtagfeed={hashtagfeed}
        getHashtagfeedNextPage={this.getHashtagfeedNextPage}
        nextPageIndex={nextPageIndex}
        isLoading={isLoading}
        hashtag={hashtag}
      />
    );
  }
}

Hashtagfeed.defaultProps = defaultProps;

Hashtagfeed.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Hashtagfeed);
