import React from 'react';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import './index.scss';

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
});

const NewsfeedNoMorePosts = ({ translate }) => (
  <div className="newsfeed-no-more-posts">
    <p className="newsfeed-no-more-posts__message">
      {translate('common.noMore', {
        object: translate('common.reaction.posts'),
      })}
    </p>
  </div>
);

export default connect(mapStateToProps)(NewsfeedNoMorePosts);
