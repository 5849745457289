import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isNil, omitBy } from 'lodash';
import { bindActionCreators } from 'redux';
import { getTranslate } from 'react-localize-redux';
import loadImage from 'blueimp-load-image';
import EditProfileModalView from 'profile/components/editProfileModalView';
import InfoModal from 'common/components/infoModal';
import { fetchUserProfile } from 'profile/redux/actions/userProfileActions';
import { editProfile } from 'profile/api/profileApi';
import { deleteFarm } from 'farm/api/farmsApi';
import { fetchUserData } from 'common/redux/actions/userDataActions';
import { fetchFarmMembers } from 'farm/redux/actions/farmMembersActions';
import requestAttachmentToken from 'common/api/attachmentsApi';
import setProfileErrors from 'profile/utils/profileErrors';

const defaultProps = {
  membersResultsNumber: 0,
};

const propTypes = {
  closeModal: PropTypes.func.isRequired,
  userData: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
  ).isRequired,
  getUserData: PropTypes.func.isRequired,
  getUserProfile: PropTypes.func.isRequired,
  toggleJoinFarmModal: PropTypes.func.isRequired,
  toggleCreateFarmModal: PropTypes.func.isRequired,
  toggleEmailChangeRequestModal: PropTypes.func.isRequired,
  membersResultsNumber: PropTypes.number,
  getFarmMembers: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  membersResultsNumber: state.farmMembers.resultsNumber,
  userData: state.userData.data,
  translate: getTranslate(state.localize),
});

const mapDispatchToProps = dispatch => ({
  getFarmMembers: bindActionCreators(fetchFarmMembers, dispatch),
  getUserData: bindActionCreators(fetchUserData, dispatch),
  getUserProfile: bindActionCreators(fetchUserProfile, dispatch),
});

class EditProfileModal extends Component {
  state = {
    imageLoading: false,
    profileImageUrl: '',
    profileImageToken: '',
    profileImageError: '',
    profileImageLoaded: false,
    profileImageDelete: false,
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      birthDate: '',
      postCode: '',
      birthdateDay: '',
      birthdateMonth: '',
      birthdateYear: '',
      gender: '',
      description: '',
    },
    isDeleteFarmModalVisible: false,
    isFarmDeleteInProgress: false,
    uploadProgress: 0,
    uploadCounter: 0,
    totalUploadTime: 0,
  };

  componentDidMount() {
    const { getFarmMembers, userData } = this.props;
    const {
      first_name: firstName,
      last_name: lastName,
      email,
      post_code: postCode,
      birth_date: birthDate,
      gender,
      description,
      avatar: profileImageUrl,
    } = userData;
    this.setState({
      initialValues: {
        firstName,
        lastName,
        email,
        birthDate,
        postCode,
        // expected date format - YYYY-MM-DD
        birthdateYear: birthDate.slice(0, 4),
        birthdateMonth: birthDate.slice(5, 7),
        birthdateDay: birthDate.slice(8, 10),
        gender,
        description,
      },
      profileImageUrl,
    });
    if (userData.admin_in) {
      getFarmMembers(userData.admin_in);
    }
  }

  updateProgress = evt => {
    this.resetProgressInterval();

    if (evt.lengthComputable) {
      const mb = 1024 * 1024;

      const totalMb = Math.ceil(evt.total / mb);
      const ratio = 2;
      const secs = Math.ceil(totalMb * ratio);

      this.setState({
        totalUploadTime: secs,
      });

      this.interval = setInterval(() => {
        this.updateProgressBar();
      }, 100);
    }
  };

  resetProgressInterval = () => {
    clearInterval(this.interval);

    this.setState({
      uploadCounter: 0,
      totalUploadTime: 0,
      uploadProgress: 0,
    });
  };

  updateProgressBar = () => {
    this.setState({
      uploadCounter: this.state.uploadCounter + 0.1,
    });

    const percentLoaded = Math.round(
      (this.state.uploadCounter / this.state.totalUploadTime) * 100,
    );

    if (percentLoaded <= 95) {
      this.setState({
        uploadProgress: percentLoaded,
      });
    } else {
      this.setState({
        uploadProgress: 95,
      });
    }

    if (this.state.uploadCounter >= this.state.totalUploadTime) {
      clearInterval(this.interval);
    }
  };

  addProfileImage = e => {
    const imageFile = e.target.files[0];
    const reader = new FileReader();
    const image = new Image();
    if (imageFile) {
      reader.onloadstart = this.updateProgress;

      this.setState({
        imageLoading: true,
        profileImageError: '',
      });
      reader.readAsDataURL(imageFile);
      reader.addEventListener('load', () => {
        image.src = reader.result;
        image.addEventListener('load', () => {
          loadImage.parseMetaData(imageFile, data => {
            let orientation = 0;
            if (data.exif) {
              orientation = data.exif.get('Orientation');
            }
            loadImage(
              imageFile,
              canvas => {
                const base64data = canvas.toDataURL(imageFile.type);
                canvas.toBlob(blob => {
                  const imgAsForm = new FormData();
                  imgAsForm.append(
                    'file',
                    imageFile.type === 'image/gif' ? imageFile : blob,
                  );
                  requestAttachmentToken(imgAsForm, 'image')
                    .then(res => {
                      this.resetProgressInterval();
                      this.setState({
                        profileImageUrl:
                          imageFile.type === 'image/gif'
                            ? image.src
                            : base64data,
                        profileImageToken: res.data.token,
                        imageLoading: false,
                      });
                    })
                    .catch(error => {
                      this.resetProgressInterval();
                      this.setState({
                        profileImageError:
                          error.response.data &&
                          error.response.data.errors &&
                          error.response.data.errors.file.join(' '),
                        imageLoading: false,
                      });
                    });
                }, imageFile.type);
              },
              {
                canvas: true,
                orientation,
                maxWidth: 1300
              },
            );
          });
        });
      });
    }
  };

  submitEditProfileForm = (values, actions) => {
    const {
      firstName,
      lastName,
      email,
      postCode,
      birthdateDay,
      birthdateMonth,
      birthdateYear,
      gender,
      description,
      oldPassword,
      password,
      passwordConfirmation,
    } = values;
    const {
      closeModal,
      getUserProfile,
      userData,
      getUserData,
      toggleEmailChangeRequestModal,
    } = this.props;
    const { initialValues, profileImageToken, profileImageDelete } = this.state;

    if (!oldPassword && password && passwordConfirmation) {
      actions.setSubmitting(false);
      return;
    }

    editProfile(
      omitBy(
        {
          first_name: firstName !== initialValues.firstName ? firstName : null,
          last_name: lastName !== initialValues.lastName ? lastName : null,
          email: email !== initialValues.email ? email : null,
          birth_date:
            birthdateDay !== initialValues.birthdateDay ||
            birthdateMonth !== initialValues.birthdateMonth ||
            birthdateYear !== initialValues.birthdateYear
              ? `${birthdateYear}-${birthdateMonth}-${birthdateDay}`
              : null,
          post_code: postCode !== initialValues.postCode ? postCode : null,
          gender: gender !== initialValues.gender ? gender : null,
          description:
            description !== initialValues.description ? description : null,
          avatar: profileImageToken || null,
          remove_avatar: profileImageDelete,
          old_password:
            oldPassword && password && passwordConfirmation
              ? oldPassword
              : null,
          password:
            oldPassword && password && passwordConfirmation ? password : null,
          password_confirmation:
            oldPassword && password && passwordConfirmation
              ? passwordConfirmation
              : null,
        },
        isNil,
      ),
    )
      .then(() => {
        getUserProfile(userData.id);
        getUserData();
        closeModal();
        if (values.email !== initialValues.email) {
          toggleEmailChangeRequestModal();
        }
      })
      .catch(error => {
        actions.setErrors(setProfileErrors(error));
        actions.setSubmitting(false);
      });
  };

  deleteProfilePicture = () => {
    this.setState({
      profileImageUrl: '',
      profileImageToken: '',
      profileImageError: '',
      profileImageLoaded: false,
      profileImageDelete: true,
    });
  };

  toggleDeleteFarmModal = () => {
    const { isDeleteFarmModalVisible } = this.state;
    this.setState({ isDeleteFarmModalVisible: !isDeleteFarmModalVisible });
  };

  deleteFarmProfile = () => {
    const { closeModal, getUserProfile, userData } = this.props;
    this.setState({ isFarmDeleteInProgress: true });
    deleteFarm(userData.admin_in)
      .then(() => {
        this.setState({ isFarmDeleteInProgress: false });
        getUserProfile(userData.id);
        this.toggleDeleteFarmModal();
        closeModal();
      })
      .catch(() => {
        this.setState({ isFarmDeleteInProgress: false });
      });
  };

  render() {
    const {
      closeModal,
      membersResultsNumber,
      userData,
      toggleJoinFarmModal,
      toggleCreateFarmModal,
      translate,
    } = this.props;
    const {
      profileImageUrl,
      profileImageError,
      profileImageToken,
      initialValues,
      profileImageLoaded,
      isLoading,
      profileImageDelete,
      isDeleteFarmModalVisible,
      isFarmDeleteInProgress,
      imageLoading,
      uploadProgress,
    } = this.state;
    return (
      <Fragment>
        <EditProfileModalView
          closeModal={closeModal}
          submitEditProfileForm={this.submitEditProfileForm}
          addProfileImage={this.addProfileImage}
          profileImageUrl={profileImageUrl}
          profileImageError={profileImageError}
          profileImageToken={profileImageToken}
          initialValues={initialValues}
          profileImageLoaded={profileImageLoaded}
          isLoading={isLoading}
          isAdmin={!!userData.admin_in}
          isAdminOrMember={!!(userData.admin_in || userData.member_in)}
          toggleJoinFarmModal={toggleJoinFarmModal}
          toggleCreateFarmModal={toggleCreateFarmModal}
          deleteProfilePicture={this.deleteProfilePicture}
          profileImageDelete={profileImageDelete}
          toggleDeleteFarmModal={this.toggleDeleteFarmModal}
          membersResultsNumber={membersResultsNumber}
          imageLoading={imageLoading}
          uploadProgress={uploadProgress}
        />
        {isDeleteFarmModalVisible && (
          <InfoModal
            closeModal={this.toggleDeleteFarmModal}
            heading={translate('modal.deleteFarm.heading')}
            message={translate('modal.deleteFarm.message')}
            handlePrimaryOptionButtonClick={this.deleteFarmProfile}
            primaryOptionButton={translate('common.yes')}
            isCancelButtonVisible
            primaryOptionButtonDisabled={isFarmDeleteInProgress}
          />
        )}
      </Fragment>
    );
  }
}

EditProfileModal.defaultProps = defaultProps;

EditProfileModal.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditProfileModal);
