import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import debounce from 'lodash/debounce';
import disableScroll from 'common/utils/disableScroll';
import MarketplaceSidebarView from 'marketplace/components/marketplaceSidebarView';
import SellArticleModal from 'post/containers/sellArticleModal';
import GuestModal from 'common/components/guestModal';
import {
  fetchMarketplace,
  updateMarketplaceCategory,
  updateMarketplaceVicinity,
} from 'marketplace/redux/actions/marketplaceActions';
import fetchCategories from 'common/redux/actions/categoriesActions';

const defaultProps = {
  categories: [],
  token: '',
  userData: null,
};

const propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object),
  getCategories: PropTypes.func.isRequired,
  getMarketplace: PropTypes.func.isRequired,
  updateMarketplaceCategory: PropTypes.func.isRequired,
  updateMarketplaceVicinity: PropTypes.func.isRequired,
  token: PropTypes.string,
  userData: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
  ),
};

const mapStateToProps = state => ({
  categories: state.categories.list,
  token: state.auth.token,
  userData: state.userData.data,
});

const mapDispatchToProps = dispatch => ({
  getCategories: bindActionCreators(fetchCategories, dispatch),
  getMarketplace: bindActionCreators(fetchMarketplace, dispatch),
  updateMarketplaceCategory: bindActionCreators(
    updateMarketplaceCategory,
    dispatch,
  ),
  updateMarketplaceVicinity: bindActionCreators(
    updateMarketplaceVicinity,
    dispatch,
  ),
});

class MarketplaceSidebar extends Component {
  state = {
    isSellArticleModalVisible: false,
    isGuestModalVisible: false,
  };

  debouncedGetMarketplace = debounce(() => {
    const { getMarketplace } = this.props;
    getMarketplace();
  }, 250);

  componentDidMount() {
    const { categories, getCategories } = this.props;
    if (!categories.length) {
      getCategories();
    }
  }

  handleCategoryChange = categoryId => {
    const { updateMarketplaceCategory } = this.props;
    updateMarketplaceCategory(categoryId);
    this.debouncedGetMarketplace();
  };

  handleVicinityChange = e => {
    const { updateMarketplaceVicinity } = this.props;
    updateMarketplaceVicinity(e.target.value);
    this.debouncedGetMarketplace();
  };

  toggleSellArticleModal = () => {
    const { isSellArticleModalVisible } = this.state;
    const { userData, token } = this.props;

    if (userData && token) {
      this.setState(
        { isSellArticleModalVisible: !isSellArticleModalVisible },
        disableScroll(!isSellArticleModalVisible),
      );
    } else {
      this.toggleGuestModal();
    }
  };

  toggleGuestModal = () => {
    const { isGuestModalVisible } = this.state;
    this.setState(
      { isGuestModalVisible: !isGuestModalVisible },
      disableScroll(!isGuestModalVisible),
    );
  };

  render() {
    const { categories } = this.props;
    const { isSellArticleModalVisible, isGuestModalVisible } = this.state;
    return (
      <Fragment>
        <MarketplaceSidebarView
          categories={categories}
          handleCategoryChange={this.handleCategoryChange}
          handleVicinityChange={this.handleVicinityChange}
          toggleSellArticleModal={this.toggleSellArticleModal}
        />
        {isSellArticleModalVisible && (
          <SellArticleModal closeModal={this.toggleSellArticleModal} />
        )}
        {isGuestModalVisible && (
          <GuestModal closeModal={this.toggleGuestModal} />
        )}
      </Fragment>
    );
  }
}

MarketplaceSidebar.defaultProps = defaultProps;

MarketplaceSidebar.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MarketplaceSidebar);
