import React from 'react';
import PropTypes from 'prop-types';
import ModalContainer from 'common/components/modalContainer';
import ContactSellerForm from 'post/components/contactSellerForm';
import MainButton from 'common/components/mainButton';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import './index.scss';

const propTypes = {
  closeModal: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
});

const ContactSellerFormModal = ({ closeModal, submitForm, translate }) => (
  <ModalContainer handleOutsideClick={closeModal} fullWidth>
    <div className="contact-seller-form-modal">
      <p className="contact-seller-form-modal__heading">
        {translate('marketplace.contactSeller')}
      </p>
      <ContactSellerForm submitForm={submitForm} />
      <div className="contact-seller-form-modal__cancel-button">
        <MainButton onClick={closeModal} teritary>
          {translate('common.action.cancel')}
        </MainButton>
      </div>
    </div>
  </ModalContainer>
);

ContactSellerFormModal.propTypes = propTypes;

export default connect(mapStateToProps)(ContactSellerFormModal);
