import axios from 'axios';
import * as types from 'post/redux/types';
import { createActionCreator } from 'common/utils/reduxUtils';

const {
  FETCH_ARTICLE_TO_EDIT_REQUEST,
  FETCH_ARTICLE_TO_EDIT_SUCCESS,
  FETCH_ARTICLE_TO_EDIT_FAILURE,
  REMOVE_ARTICLE_TO_EDIT,
} = types;

const fetchArticleToEditRequest = createActionCreator(
  FETCH_ARTICLE_TO_EDIT_REQUEST,
);
const fetchArticleToEditSuccess = createActionCreator(
  FETCH_ARTICLE_TO_EDIT_SUCCESS,
  'data',
);
const fetchArticleToEditFailure = createActionCreator(
  FETCH_ARTICLE_TO_EDIT_FAILURE,
);

export const fetchArticleToEdit = id => dispatch => {
  dispatch(fetchArticleToEditRequest());
  return axios
    .get(`${process.env.REACT_APP_API_URL}/articles/${id}/edit`)
    .then(response => {
      dispatch(fetchArticleToEditSuccess(response.data));
    })
    .catch(() => {
      dispatch(fetchArticleToEditFailure());
    });
};

export const removeArticleToEdit = createActionCreator(REMOVE_ARTICLE_TO_EDIT);
