import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import './index.scss';

const propTypes = {
  time: PropTypes.number.isRequired,
};

// TODO - Load correct Language from .env
require('moment/locale/de');

moment.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s',
    s: 'a few seconds',
    ss: '%d seconds',
    m: 'a minute',
    mm: '%d minutes',
    h: 'an hour',
    hh: '%d hours',
    d: 'a day',
    dd: '%d days',
    M: 'a month',
    MM: '%d months',
    y: 'a year',
    yy: '%d years',
  },
});

moment.updateLocale('de', {
  relativeTime: {
    future: 'in %s',
    past: '%s',
    s: 'vor ein paar Sekunden',
    ss: 'vor %d Sekunden',
    m: 'vor einer Minute',
    mm: 'vor %d Minuten',
    h: 'vor einer Stunde',
    hh: 'vor %d Stunden',
    d: 'vor einem Tag',
    dd: 'vor %d Tagen',
    M: 'vor einem Monat',
    MM: 'vor %d Monaten',
    y: 'vor einem Jahr',
    yy: 'vor %d Jahren',
  },
});

moment.relativeTimeThreshold('s', 59);
moment.relativeTimeThreshold('m', 59);
moment.relativeTimeThreshold('h', 23);

moment.fn.fromNowOrDate = function(time) {
  // 86400 seconds is equal to 24 hours
  if (
    moment()
      .unix()
      .valueOf() -
      time >
    86400
  ) {
    return moment.unix(time).format('DD.MM.YYYY, HH:mm');
  }
  return moment(moment.unix(time)).fromNow();
};

const FromNow = ({ time }) => (
  <div className="from-now">{moment.fn.fromNowOrDate(time)}</div>
);

FromNow.propTypes = propTypes;

export default FromNow;
