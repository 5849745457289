export const FETCH_FARM_DETAILS_REQUEST = 'FETCH_FARM_DETAILS_REQUEST';
export const FETCH_FARM_DETAILS_SUCCESS = 'FETCH_FARM_DETAILS_SUCCESS';
export const FETCH_FARM_DETAILS_FAILURE = 'FETCH_FARM_DETAILS_FAILURE';

export const REMOVE_FARM_DETAILS = 'REMOVE_FARM_DETAILS';

export const FETCH_FARM_NEWSFEED_REQUEST = 'FETCH_FARM_NEWSFEED_REQUEST';
export const FETCH_FARM_NEWSFEED_SUCCESS = 'FETCH_FARM_NEWSFEED_SUCCESS';
export const FETCH_FARM_NEWSFEED_FAILURE = 'FETCH_FARM_NEWSFEED_FAILURE';

export const ADD_CREATED_FARM_POST = 'ADD_CREATED_FARM_POST';
export const UPDATE_FARM_NEWSFEED = 'UPDATE_FARM_NEWSFEED';

export const REMOVE_FARM_NEWSFEED = 'REMOVE_FARM_NEWSFEED';

export const FETCH_FARM_MEMBERS_REQUEST = 'FETCH_FARM_MEMBERS_REQUEST';
export const FETCH_FARM_MEMBERS_SUCCESS = 'FETCH_FARM_MEMBERS_SUCCESS';
export const FETCH_FARM_MEMBERS_FAILURE = 'FETCH_FARM_MEMBERS_FAILURE';

export const REMOVE_FARM_MEMBERS = 'REMOVE_FARM_MEMBERS';
export const UPDATE_FARM_MEMBERS = 'UPDATE_FARM_MEMBERS';

export const FETCH_FARM_MEMBERSHIP_REQUESTS_REQUEST =
  'FETCH_FARM_MEMBERSHIP_REQUESTS_REQUEST';
export const FETCH_FARM_MEMBERSHIP_REQUESTS_SUCCESS =
  'FETCH_FARM_MEMBERSHIP_REQUESTS_SUCCESS';
export const FETCH_FARM_MEMBERSHIP_REQUESTS_FAILURE =
  'FETCH_FARM_MEMBERSHIP_REQUESTS_FAILURE';

export const REMOVE_FARM_MEMBERSHIP_REQUESTS =
  'REMOVE_FARM_MEMBERSHIP_REQUESTS';
export const UPDATE_FARM_MEMBERSHIP_REQUESTS =
  'UPDATE_FARM_MEMBERSHIP_REQUESTS';

export const FARM_FLOW_START = 'FARM_FLOW_START';
export const FARM_FLOW_END = 'FARM_FLOW_END';

export const FETCH_FARMTOUR_DATA_REQUEST = 'FETCH_FARMTOUR_DATA_REQUEST';
export const FETCH_FARMTOUR_DATA_SUCCESS = 'FETCH_FARMTOUR_DATA_SUCCESS';
export const FETCH_FARMTOUR_DATA_FAILURE = 'FETCH_FARMTOUR_DATA_FAILURE';
export const REMOVE_FARMTOUR_DATA = 'REMOVE_FARMTOUR_DATA';

export const FETCH_FARMTOUR_POST_REQUEST = 'FETCH_FARMTOUR_POST_REQUEST';
export const FETCH_FARMTOUR_POST_SUCCESS = 'FETCH_FARMTOUR_POST_SUCCESS';
export const FETCH_FARMTOUR_POST_FAILURE = 'FETCH_FARMTOUR_POST_FAILURE';
export const REMOVE_FARMTOUR_POST = 'REMOVE_FARMTOUR_POST';
