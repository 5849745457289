import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import Input from 'common/components/input/index';
import Button from 'common/components/mainButton';
import validationSchema from 'userAuth/validators/authValidators';
import { Translate, getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import './index.scss';

const largeBreakpoint = 992;

const propTypes = {
  submitLoginForm: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
});

class UserLoginView extends Component {
  constructor() {
    super();
    this.state = {
      width: null,
    };
  }

  componentDidMount() {
    this.setScreenWidth();
    window.addEventListener('resize', this.setScreenWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setScreenWidth);
  }

  setScreenWidth = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { submitLoginForm, translate } = this.props;
    const { width } = this.state;
    return (
      <div className="user-login">
        <Formik
          initialValues={{ username: '', password: '' }}
          validationSchema={validationSchema}
          onSubmit={(values, { setErrors, resetForm, setSubmitting }) =>
            submitLoginForm(values, { setErrors, resetForm, setSubmitting })
          }
          render={({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form
              onSubmit={handleSubmit}
              noValidate
              className="user-login__form"
            >
              <div className="user-login__input">
                <Input
                  type="text"
                  value={values.username}
                  error={errors.username}
                  touched={touched.username}
                  name="username"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder={translate('form.common.eMail')}
                  isIconVisible
                  isUsernameIconVisible
                  withLabel={width < largeBreakpoint}
                />
              </div>
              <div className="user-login__input">
                <Input
                  value={values.password}
                  error={errors.password}
                  touched={touched.password}
                  name="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder={translate('form.authentication.password')}
                  isIconVisible
                  isPasswordIconVisible
                  withLabel={width < largeBreakpoint}
                />
                <Link
                  to="/forgot"
                  className="user-login__forgot-link user-login__forgot-link--desktop"
                >
                  <Translate id="form.authentication.forgotYourAccount" />
                </Link>
              </div>
              <div className="user-login__submit-button">
                <Button type="submit" disabled={isSubmitting}>
                  <Translate
                    id={
                      window.innerWidth > 991
                        ? 'common.action.signIn'
                        : 'page.signIn.title2'
                    }
                  />
                </Button>
              </div>
              {errors.submit && (
                <p className="user-login__submit-error">{errors.submit}</p>
              )}
            </form>
          )}
        />
        <Link
          to="/forgot"
          className="user-login__forgot-link user-login__forgot-link--mobile"
        >
          <Translate id="form.authentication.forgotYourAccount" />
        </Link>
      </div>
    );
  }
}

UserLoginView.propTypes = propTypes;

export default connect(mapStateToProps)(UserLoginView);
