import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Loader from 'common/components/loader';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import './index.scss';

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
});

const defaultProps = {
  isAutoSize: false,
  withBackground: false,
  translate: () => null,
  parent: '',
  hashtaged: false,
  postType: '',
};

const propTypes = {
  image: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ).isRequired,
  isAutoSize: PropTypes.bool,
  withBackground: PropTypes.bool,
  translate: PropTypes.func,
  parent: PropTypes.string,
  hashtaged: PropTypes.bool,
  postType: PropTypes.string,
};

class ImageWithLoader extends Component {
  constructor(props) {
    super(props);
    this.timer = setTimeout(this.enableLoader, 250);
    if (!props.isAutoSize) {
      this.container = React.createRef();
    }
  }

  state = {
    isImageLoaded: false,
    displayLoader: false,
    height: null,
  };

  componentDidMount() {
    const { isAutoSize } = this.props;
    if (!isAutoSize) {
      this.setHeight();
      window.addEventListener('resize', this.setHeight);
    }
  }

  componentWillUnmount() {
    const { isAutoSize } = this.props;
    if (!isAutoSize) {
      clearTimeout(this.timer);
      window.removeEventListener('resize', this.setHeight);
    }
  }

  enableLoader = () => {
    this.setState({ displayLoader: true });
  };

  handleImageLoad = () => {
    this.setState({ isImageLoaded: true });
  };

  setHeight = () => {
    const { image } = this.props;
    this.setState({
      height: (image.height / image.width) * this.container.current.offsetWidth,
    });
  };

  render() {
    const {
      image,
      isAutoSize,
      withBackground,
      translate,
      parent,
      hashtaged,
      postType,
    } = this.props;
    const { isImageLoaded, displayLoader } = this.state;
    let { height } = this.state;

    const width = window.innerWidth;

    const imageClasses = classNames({
      'image-with-loader__image': true,
      'image-with-loader__image--hidden': !isImageLoaded,
      'image-with-loader__image--auto': isAutoSize,
      'image-with-loader__farmtour-post':
        postType && postType === 'farm_farmtour',
      'image-with-loader__image--full-width':
        (parent === 'post' || parent === 'shared') &&
        image.width > 650 &&
        image.height < image.width,
      'image-with-loader__image--full-height':
        (parent === 'post' || parent === 'shared') && image.height > 650,
      'image-with-loader__image--full-width-mobile':
        width < 991 &&
        (parent === 'post' || parent === 'shared' || parent === 'modal') &&
        image.width >= image.height,
    });
    const loaderClasses = classNames({
      'image-with-loader__loader': true,
      'image-with-loader__loader--background': withBackground,
    });

    let imageUrl = image.imageUrl ? image.imageUrl : image.url;

    if (image.type === 'farmtour_video') {
      imageUrl = image.thumbnail;
    }

    const origImageUrl = imageUrl;

    const imageWidthStyle = { width: null };

    if (width > 991) {
      if (parent === 'post' || parent === 'shared') {
        if (!hashtaged) {
          if (image.width && image.height) {
            if (image.height >= image.width) {
              if (image.height >= 650) {
                imageUrl += '?h=650';
                height = 650;
              } else {
                imageUrl += `?h=${image.height}`;
                height = image.height;
              }
            } else if (image.width > 650) {
              imageUrl += '?w=1280';
              imageWidthStyle.width = parent === 'post' ? 640 : 590;
            } else {
              imageUrl += `?w=${image.width}`;
              height = image.height;
            }
          }
        } else {
          imageUrl += '?w=295';
          height = 200;
        }
      } else if (parent === 'modal' || parent === 'profile') {
        if (image.width && image.height) {
          if (image.width >= image.height) {
            if (image.width > 1000) {
              imageUrl += '?w=1000';
            } else {
              imageUrl += `?w=${image.width}`;
            }
          } else {
            imageUrl += `?w=${image.width}`;
          }
        } else {
          imageUrl += '?w=1000';
        }
      }
    } else if (!hashtaged) {
      if (parent === 'modal') {
        imageUrl += `?w=${width * 2}`;
      } else if (image.width && image.height) {
        if (image.width >= image.height) {
          imageUrl += `?w=${width * 2}`;
        } else if (image.height >= 650) {
          if (width > 450) {
            imageUrl += '?h=1300';
            height = 650;
          } else {
            const ration = image.height / image.width;
            const newHeight = width * ration;
            imageUrl += `?h=${newHeight * 2}`;
            height = newHeight;
          }
        } else {
          imageUrl += `?h=${image.height * 2}`;
          height = image.height;
        }
      } else {
        imageUrl += '?h=1300';
        height = 650;
      }
    } else {
      imageUrl += `?w=${width / 3.3}`;
      height = (width / 3.3) * 0.7;
    }

    if (postType && postType === 'farm_farmtour') {
      height = 300;
      imageUrl = `${origImageUrl}?w=500&h=500&fit=crop`;
    }

    return (
      <div
        className="image-with-loader"
        ref={this.container}
        style={{
          height: isAutoSize ? '100%' : height,
          width: imageWidthStyle.width,
        }}
      >
        <LazyLoadImage
          src={imageUrl}
          alt=""
          onLoad={this.handleImageLoad}
          className={imageClasses}
        />
        {!isImageLoaded && displayLoader && (
          <div className={loaderClasses}>
            <Loader
              message={translate('common.load', {
                object: translate('common.reaction.image'),
              })}
            />
          </div>
        )}
      </div>
    );
  }
}

ImageWithLoader.defaultProps = defaultProps;

ImageWithLoader.propTypes = propTypes;

export default connect(mapStateToProps)(ImageWithLoader);
