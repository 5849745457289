export const FETCH_FARMS_LIST_REQUEST = 'FETCH_FARMS_LIST_REQUEST';
export const FETCH_FARMS_LIST_SUCCESS = 'FETCH_FARMS_LIST_SUCCESS';
export const FETCH_FARMS_LIST_FAILURE = 'FETCH_FARMS_LIST_FAILURE';

export const FETCH_ACTIVE_FARMS_SUCCESS = 'FETCH_ACTIVE_FARMS_SUCCESS';

export const REMOVE_FARMS_LIST = 'REMOVE_FARMS_LIST';

export const UPDATE_FARMS_LIST_QUERY = 'UPDATE_FARMS_LIST_QUERY';

export const FETCH_FARMS_TO_JOIN_LIST_REQUEST =
  'FETCH_FARMS_TO_JOIN_LIST_REQUEST';
export const FETCH_FARMS_TO_JOIN_LIST_SUCCESS =
  'FETCH_FARMS_TO_JOIN_LIST_SUCCESS';
export const FETCH_FARMS_TO_JOIN_LIST_FAILURE =
  'FETCH_FARMS_TO_JOIN_LIST_FAILURE';

export const REMOVE_FARMS_TO_JOIN_LIST = 'REMOVE_FARMS_TO_JOIN_LIST';

export const UPDATE_FARMS_TO_JOIN_LIST_QUERY =
  'UPDATE_FARMS_TO_JOIN_LIST_QUERY';

export const FETCH_FOUND_USERS_SUCCESS = 'FETCH_FOUND_USERS_SUCCESS';
export const FETCH_USERS_LIST_REQUEST = 'FETCH_USERS_LIST_REQUEST';
export const FETCH_USERS_LIST_FAILURE = 'FETCH_USERS_LIST_FAILURE';
