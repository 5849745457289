import React from 'react';
import PropTypes from 'prop-types';
import Loader from 'common/components/loader';
import InfiniteScroll from 'react-infinite-scroll-component';
import FarmItem from 'farms/components/farmItem';
import FarmsListMap from 'farm/components/farmsListMapView';
import UserItem from 'farms/components/userItem';
import FarmIcon from 'assets/icons/farm-light-grey.svg';
import FarmGreenIcon from 'assets/icons/farm-green.svg';
import UserIcon from 'assets/icons/user-lightgrey.svg';
import EndMessage from 'common/components/endMessage';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import './index.scss';

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
});

const defaultProps = {
  farmsList: [],
  usersList: [],
  nextPageIndex: null,
  nextUserPageIndex: null,
  translate: () => null,
};

const propTypes = {
  farmsList: PropTypes.arrayOf(PropTypes.object),
  usersList: PropTypes.arrayOf(PropTypes.object),
  getFarmsListNextPage: PropTypes.func.isRequired,
  getUsersListNextPage: PropTypes.func.isRequired,
  nextPageIndex: PropTypes.number,
  nextUserPageIndex: PropTypes.number,
  translate: PropTypes.func,
};

const FarmsListView = ({
  farmsList,
  usersList,
  getFarmsListNextPage,
  getUsersListNextPage,
  nextPageIndex,
  nextUserPageIndex,
  translate,
}) => (
  <div className="search-results-map">
    <div className="maps__header">
      <div className="maps__header-icon-wrapper">
        <img src={FarmGreenIcon} alt="" className="maps__header-icon" />
      </div>
      <h1 className="maps__header-heading">
        <Translate id="page.farmsList.farmsMap" />
      </h1>
    </div>
    <div className="maps-wrapper">
      <FarmsListMap farmsList={farmsList} />
    </div>

    <div className="search-results">
      <div className="farms-list">
        <div className="farms-list__header">
          <div className="farms-list__header-icon-wrapper">
            <img src={FarmIcon} alt="" className="farms-list__header-icon" />
          </div>
          <h1 className="farms-list__header-heading">
            <Translate id="page.farmsList.header" />
          </h1>
        </div>
        <InfiniteScroll
          dataLength={farmsList.length}
          scrollThreshold="400px"
          next={nextPageIndex ? getFarmsListNextPage : null}
          hasMore={!!nextPageIndex}
          loader={
            nextPageIndex === 1 ? (
              <Loader
                message={translate('common.load', {
                  object: translate('common.reaction.farms'),
                })}
              />
            ) : (
              <Loader
                message={translate('common.loadMore', {
                  object: translate('common.reaction.farms'),
                })}
              />
            )
          }
          endMessage={
            farmsList.length < 1 ? (
              <EndMessage
                message={translate('common.noObjects', {
                  objects: translate('common.reaction.farms'),
                })}
              />
            ) : (
              <EndMessage
                message={translate('common.noMore', {
                  object: translate('common.reaction.farms'),
                })}
              />
            )
          }
        >
          {farmsList.map(farm => (
            <div className="farms-list__item" key={farm.id}>
              <FarmItem farm={farm} />
            </div>
          ))}
        </InfiniteScroll>
      </div>

      <div className="farms-list">
        <div className="farms-list__header">
          <div className="farms-list__header-icon-wrapper">
            <img src={UserIcon} alt="" className="farms-list__header-icon" />
          </div>
          <h1 className="farms-list__header-heading">
            <Translate id="common.profile" />
          </h1>
        </div>
        <InfiniteScroll
          dataLength={usersList.length}
          scrollThreshold="400px"
          next={nextUserPageIndex ? getUsersListNextPage : null}
          hasMore={!!nextUserPageIndex}
          loader={
            nextUserPageIndex === 1 ? (
              <Loader
                message={translate('common.load', {
                  object: translate('common.reaction.farms'),
                })}
              />
            ) : (
              <Loader
                message={translate('common.loadMore', {
                  object: translate('common.reaction.farms'),
                })}
              />
            )
          }
          endMessage={
            usersList.length < 1 ? (
              <EndMessage
                message={translate('common.noObjects', {
                  objects: translate('common.reaction.farms'),
                })}
              />
            ) : (
              <EndMessage
                message={translate('common.noMore', {
                  object: translate('common.profiles'),
                })}
              />
            )
          }
        >
          {usersList.map(user => (
            <div className="farms-list__item" key={user.id}>
              <UserItem user={user} />
            </div>
          ))}
        </InfiniteScroll>
      </div>
    </div>
  </div>
);

FarmsListView.defaultProps = defaultProps;

FarmsListView.propTypes = propTypes;

export default connect(mapStateToProps)(FarmsListView);
