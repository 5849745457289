import axios from 'axios';
import * as types from 'farm/redux/types';
import { createActionCreator } from 'common/utils/reduxUtils';

const {
  FETCH_FARM_NEWSFEED_REQUEST,
  FETCH_FARM_NEWSFEED_SUCCESS,
  FETCH_FARM_NEWSFEED_FAILURE,
  ADD_CREATED_FARM_POST,
  UPDATE_FARM_NEWSFEED,
  REMOVE_FARM_NEWSFEED,
} = types;

const fetchFarmNewsfeedRequest = createActionCreator(
  FETCH_FARM_NEWSFEED_REQUEST,
);
const fetchFarmNewsfeedSuccess = createActionCreator(
  FETCH_FARM_NEWSFEED_SUCCESS,
  'data',
);
const fetchFarmNewsfeedFailure = createActionCreator(
  FETCH_FARM_NEWSFEED_FAILURE,
);

export const fetchFarmNewsfeed = (id, page = 1) => (dispatch, getState) => {
  const { currentTimestamp } = getState().farmNewsfeed;
  dispatch(fetchFarmNewsfeedRequest());
  return axios
    .get(`${process.env.REACT_APP_API_URL}/farms/${id}/newsfeed`, {
      params: {
        page,
        to: currentTimestamp,
        farm_id: id,
      },
    })
    .then(response => {
      dispatch(fetchFarmNewsfeedSuccess(response.data));
    })
    .catch(() => {
      dispatch(fetchFarmNewsfeedFailure());
    });
};

export const addCreatedFarmPost = createActionCreator(
  ADD_CREATED_FARM_POST,
  'data',
);

export const updateFarmNewsfeed = createActionCreator(
  UPDATE_FARM_NEWSFEED,
  'data',
);

export const removeFarmNewsfeed = createActionCreator(REMOVE_FARM_NEWSFEED);
