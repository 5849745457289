import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getTranslate } from 'react-localize-redux';
import UserLoginView from 'userAuth/components/userLoginView';
import { loginUser } from 'userAuth/api/authApi';
import {
  setUserAuthToken,
  removeUserAuthToken,
} from 'userAuth/redux/actions/authActions';
import { fetchUserDataFirstTime } from 'common/redux/actions/userDataActions';
import setSubmitErrors from 'userAuth/utils/submitErrors';

const propTypes = {
  removeUserAuthToken: PropTypes.func.isRequired,
  setUserAuthToken: PropTypes.func.isRequired,
  fetchUserDataFirstTime: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => ({
  removeUserAuthToken: bindActionCreators(removeUserAuthToken, dispatch),
  setUserAuthToken: bindActionCreators(setUserAuthToken, dispatch),
  fetchUserDataFirstTime: bindActionCreators(fetchUserDataFirstTime, dispatch),
});

const mapStateToProps = state => ({
  token: state.auth.token,
  userData: state.userData.data,
  translate: getTranslate(state.localize),
});

class UserLogin extends Component {
  submitLoginForm = (values, actions) => {
    const { username, password } = values;
    const {
      history,
      fetchUserDataFirstTime,
      removeUserAuthToken,
      setUserAuthToken,
      translate,
    } = this.props;

    loginUser({
      username,
      password,
      grant_type: 'password',
      client_id: `${process.env.REACT_APP_CLIENT_ID}`,
      client_secret: `${process.env.REACT_APP_CLIENT_SECRET}`,
    })
      .then(response => {
        if (response.data.access_token) {
          const token = response.data.access_token;
          setUserAuthToken(token);

          fetchUserDataFirstTime(token)
            .then(response => {
              if (response) {
                history.push('/');
              } else {
                removeUserAuthToken();
                history.push('/verify');
              }
            })
            .catch(() => {
              actions.setErrors({
                submit: translate('form.error.submit'),
              });
              actions.setSubmitting(false);
            });
        }
      })
      .catch(error => {
        actions.setErrors(setSubmitErrors(error));
        actions.setSubmitting(false);
      });
  };

  render() {
    return <UserLoginView submitLoginForm={this.submitLoginForm} />;
  }
}

UserLogin.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(UserLogin));
