import translateMessage from 'common/utils/translateMessage';

export default function setRegistrationErrors(error) {
  let errors = {
    submit: translateMessage('form.error.submit'),
    birthDate: '',
    email: '',
    password: '',
    postCode: '',
    accepts_terms: '',
  };

  if (error.response.data.errors) {
    if (error.response.data.errors.birth_date) {
      errors = {
        ...errors,
        ...{ birthDate: error.response.data.errors.birth_date.join(' ') },
      };
    }
    if (error.response.data.errors.email) {
      errors = {
        ...errors,
        ...{ email: error.response.data.errors.email.join(' ') },
      };
    }
    if (error.response.data.errors.password) {
      errors = {
        ...errors,
        ...{
          passwordConfirmation: error.response.data.errors.password.join(' '),
        },
      };
    }
    if (error.response.data.errors.post_code) {
      errors = {
        ...errors,
        ...{ postCode: error.response.data.errors.post_code.join(' ') },
      };
    }
    if (error.response.data.errors.accepts_terms) {
      errors = {
        ...errors,
        ...{ terms: error.response.data.errors.accepts_terms.join(' ') },
      };
    }
  }

  return errors;
}
