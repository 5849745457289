import axios from 'axios';
import * as types from 'farm/redux/types';
import { createActionCreator } from 'common/utils/reduxUtils';

const {
  FETCH_FARM_MEMBERSHIP_REQUESTS_REQUEST,
  FETCH_FARM_MEMBERSHIP_REQUESTS_SUCCESS,
  FETCH_FARM_MEMBERSHIP_REQUESTS_FAILURE,
  REMOVE_FARM_MEMBERSHIP_REQUESTS,
  UPDATE_FARM_MEMBERSHIP_REQUESTS,
} = types;

const fetchFarmMembershipRequestsRequest = createActionCreator(
  FETCH_FARM_MEMBERSHIP_REQUESTS_REQUEST,
);
const fetchFarmMembershipRequestsSuccess = createActionCreator(
  FETCH_FARM_MEMBERSHIP_REQUESTS_SUCCESS,
  'data',
);
const fetchFarmMembershipRequestsFailure = createActionCreator(
  FETCH_FARM_MEMBERSHIP_REQUESTS_FAILURE,
);

export const fetchFarmMembershipRequests = (id, page = 1) => dispatch => {
  dispatch(fetchFarmMembershipRequestsRequest());
  return axios
    .get(`${process.env.REACT_APP_API_URL}/farms/${id}/membership-requests`, {
      params: {
        page,
      },
    })
    .then(response => {
      dispatch(fetchFarmMembershipRequestsSuccess(response.data));
    })
    .catch(() => {
      dispatch(fetchFarmMembershipRequestsFailure());
    });
};

export const removeFarmMembershipRequests = createActionCreator(
  REMOVE_FARM_MEMBERSHIP_REQUESTS,
);

export const updateFarmMembershipRequests = createActionCreator(
  UPDATE_FARM_MEMBERSHIP_REQUESTS,
  'data',
);
