import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InfoModal from 'common/components/infoModal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { deleteFarmMember } from 'farm/api/farmsApi';
import { updateFarmMembers } from 'farm/redux/actions/farmMembersActions';
import { getTranslate } from 'react-localize-redux';

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
  userData: state.userData.data,
  farmMembers: state.farmMembers.data,
});

const mapDispatchToProps = dispatch => ({
  updateFarmMembers: bindActionCreators(updateFarmMembers, dispatch),
});

const defaultProps = {
  heading: 'Are you sure you want to delte this member?',
  message:
    "This member will be notified by e-mail. Their posts won't be deleted.",
  onSuccess: () => null,
};

const propTypes = {
  userData: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
  ).isRequired,
  farmMembers: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateFarmMembers: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  memberId: PropTypes.number.isRequired,
  heading: PropTypes.string,
  message: PropTypes.string,
  onSuccess: PropTypes.func,
};

class DeleteFarmMemberModal extends Component {
  state = {
    isDeleteInProgress: false,
  };

  leaveFarm = () => {
    const { userData, memberId, onSuccess } = this.props;
    const { member_in: farmId } = userData;
    this.setState({ isDeleteInProgress: true });

    deleteFarmMember(farmId, memberId)
      .then(() => {
        const { closeModal, updateFarmMembers, farmMembers } = this.props;
        this.setState({ isDeleteInProgress: false });
        updateFarmMembers({
          data: farmMembers.filter(obj => obj.id !== memberId),
          resultsNumber: farmMembers.length - 1,
        });
        onSuccess();
        closeModal();
      })
      .catch(() => {
        this.setState({ isDeleteInProgress: false });
      });
  };

  render() {
    const { closeModal, heading, message, translate } = this.props;
    const { isDeleteInProgress } = this.state;

    return (
      <InfoModal
        closeModal={closeModal}
        heading={heading}
        message={message}
        handlePrimaryOptionButtonClick={this.leaveFarm}
        primaryOptionButton={translate('common.yes')}
        isCancelButtonVisible
        primaryOptionButtonDisabled={isDeleteInProgress}
      />
    );
  }
}

DeleteFarmMemberModal.defaultProps = defaultProps;

DeleteFarmMemberModal.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeleteFarmMemberModal);
