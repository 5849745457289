import React from 'react';
import Logo1 from 'assets/logos/Farmlifes_Bundesministerium_Landwirtschaft_Tourismus_Logo.png';
import Logo2 from 'assets/logos/Farmlifes_LE14-20_Logo.png';
import Logo3 from 'assets/logos/Farmlifes_Land_Salzburg_Logo.png';
import Logo4 from 'assets/logos/Farmlifes_Leader_Region_Hohe_Tauern_Logo.png';
import Logo5 from 'assets/logos/Farmlifes_Europaeische_Union_Logo.png';

import './index.scss';

const PromoLogos = () => (
  <div className="promo-logos">
    <img src={Logo1} alt="" />
    <img src={Logo2} alt="" />
    <img src={Logo3} alt="" />
    <img src={Logo4} alt="" />
    <img src={Logo5} alt="" />
  </div>
);

export default PromoLogos;
