import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import UserAvatar from 'common/components/userAvatar';
import FromNow from 'common/components/fromNow';
import './index.scss';

const propTypes = {
  activity: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  ).isRequired,
};

const ActivityItem = ({ activity }) => (
  <div className="activity-item">
    <div className="activity-item__avatar">
      <UserAvatar
        offset={1000}
        id={activity.author && activity.author.id}
        image={activity.author && activity.author.avatar}
      />
    </div>
    <div>
      {activity.author && (
        <Link
          className="activity-item__link"
          to={`/profile/${activity.author.id}`}
        >
          <span className="activity-item__author">{activity.author.name}</span>
        </Link>
      )}
      <FromNow time={activity.created_at} />
    </div>
  </div>
);

ActivityItem.propTypes = propTypes;

export default ActivityItem;
