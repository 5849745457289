import { createReducer } from 'common/utils/reduxUtils';
import {
  FETCH_USER_PROFILE_SUCCESS,
  REMOVE_USER_PROFILE,
} from 'profile/redux/types';

const initialState = {
  data: {},
};

const userProfileReducer = createReducer(
  { ...initialState },
  {
    [FETCH_USER_PROFILE_SUCCESS]: (state, action) => ({
      ...state,
      data: action.payload.data,
    }),
    [REMOVE_USER_PROFILE]: state => ({
      ...state,
      data: {},
    }),
  },
);

export default userProfileReducer;
