import { createReducer } from 'common/utils/reduxUtils';
import {
  FETCH_FARM_NEWSFEED_SUCCESS,
  ADD_CREATED_FARM_POST,
  UPDATE_FARM_NEWSFEED,
  REMOVE_FARM_NEWSFEED,
} from 'farm/redux/types';

const initialState = {
  data: [],
  currentTimestamp: null,
  nextPageIndex: null,
};

const farmNewsfeedReducer = createReducer(
  { ...initialState },
  {
    [FETCH_FARM_NEWSFEED_SUCCESS]: (state, action) => {
      const { data, meta } = action.payload.data;
      const { currentTimestamp } = state;
      const hasNextPage = meta.current_page < meta.last_page;

      return {
        ...state,
        data: meta.current_page === 1 ? [...data] : [...state.data, ...data],
        currentTimestamp:
          meta.current_page === 1 ? meta.current_timestamp : currentTimestamp,
        nextPageIndex: hasNextPage ? meta.current_page + 1 : null,
      };
    },
    [ADD_CREATED_FARM_POST]: (state, action) => ({
      ...state,
      data: [action.payload.data, ...state.data],
    }),
    [UPDATE_FARM_NEWSFEED]: (state, action) => ({
        ...state,
        data: [...action.payload.data],
      }),
    [REMOVE_FARM_NEWSFEED]: state => ({
      ...state,
      data: [],
      currentTimestamp: null,
      nextPageIndex: null,
    }),
  },
);

export default farmNewsfeedReducer;
