import React, { Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import UserAuth from 'userAuth/containers/userAuth';
import VerifyAccount from 'userAuth/containers/verifyAccount';
import Newsfeed from 'newsfeed/containers/newsfeed';
import Hashtagfeed from 'hashtag/containers/hashtagfeed';
import ForgotAccount from 'userAuth/containers/forgotAccount';
import ResendForgotAccount from 'userAuth/containers/resendForgotAccount';
import ResetPassword from 'userAuth/containers/resetPassword';
import AuthHeader from 'userAuth/components/authHeader';
import Footer from 'common/components/footer';
import MainHeader from 'common/containers/mainHeader';
import CongratulationsView from 'userAuth/components/congratulationsView';
import FarmsList from 'farms/containers/farmsList';
import Farm from 'farm/containers/farm';
import Profile from 'profile/containers/profile';
import SidebarNavigation from 'common/components/sidebarNavigation';
import BottomNavigationBar from 'common/containers/bottomNavigationBar';
import Marketplace from 'marketplace/containers/marketplace';
import MarketplaceSidebar from 'marketplace/containers/marketplaceSidebar';
import AppSidebar from 'common/components/appSidebar';

const DefaultLayout = ({
  path,
  exact,
  component: Component,
  sidebarLeftComponent: SidebarLeftComponent,
  sidebarRightComponent: SidebarRightComponent,
  ...rest
}) => (
  <Route
    path={path}
    exact={exact}
    render={props => (
      <Fragment>
        <MainHeader />
        <div className="default-layout__outer-content">
          <div className="default-layout__sidebar">
            {SidebarLeftComponent && (
              <SidebarLeftComponent {...props} {...rest} />
            )}
          </div>
          <div className="default-layout__main-content">
            <Component {...props} {...rest} />
          </div>
          <div className="default-layout__sidebar default-layout__sidebar--right">
            {SidebarRightComponent && (
              <SidebarRightComponent {...props} {...rest} />
            )}
          </div>
        </div>
        <BottomNavigationBar />
      </Fragment>
    )}
  />
);

const CustomLayout = ({ path, exact, component: Component, ...rest }) => (
  <Route
    path={path}
    exact={exact}
    render={props => (
      <Fragment>
        <MainHeader />
        <Component {...props} {...rest} />
        <BottomNavigationBar />
      </Fragment>
    )}
  />
);

const AuthLayout = ({
  path,
  exact,
  isFooterVisible,
  component: Component,
  ...rest
}) => (
  <Route
    path={path}
    exact={exact}
    render={props => (
      <Fragment>
        <AuthHeader login />
        <Component {...props} {...rest} />
        {isFooterVisible && <Footer />}
      </Fragment>
    )}
  />
);

const VerifyLayout = ({ path, exact, component: Component, ...rest }) => (
  <Route
    path={path}
    exact={exact}
    render={props => (
      <Fragment>
        <AuthHeader />
        <Component {...props} {...rest} />
        <Footer />
      </Fragment>
    )}
  />
);

const Routes = () => (
  <Switch>
    <DefaultLayout
      path="/"
      key={Math.random().toString(10)}
      exact
      component={Newsfeed}
      sidebarLeftComponent={SidebarNavigation}
      sidebarRightComponent={AppSidebar}
    />
    <DefaultLayout
      key={Math.random().toString(10)}
      path="/post/:postId"
      exact
      component={Newsfeed}
      sidebarLeftComponent={SidebarNavigation}
      sidebarRightComponent={AppSidebar}
    />
    <DefaultLayout
      path="/marketplace/:postId"
      exact
      component={Marketplace}
      sidebarLeftComponent={MarketplaceSidebar}
      sidebarRightComponent={AppSidebar}
    />
    <DefaultLayout
      path="/farms"
      exact
      component={FarmsList}
      sidebarLeftComponent={SidebarNavigation}
      sidebarRightComponent={AppSidebar}
    />
    <DefaultLayout
      path="/hashtag"
      exact
      component={Hashtagfeed}
      sidebarLeftComponent={SidebarNavigation}
      sidebarRightComponent={AppSidebar}
    />
    <DefaultLayout
      path="/marketplace"
      exact
      component={Marketplace}
      sidebarLeftComponent={MarketplaceSidebar}
      sidebarRightComponent={AppSidebar}
    />
    <CustomLayout
      key={Math.random().toString(10)}
      path="/profile/:userId"
      exact
      component={Profile}
    />
    <CustomLayout path="/farms/:farmId" exact component={Farm} />
    <AuthLayout path="/farms/:farmId/unauthorized" exact component={Farm} />
    <AuthLayout isFooterVisible path="/auth" exact component={UserAuth} />
    <AuthLayout
      isFooterVisible
      path="/congratulations"
      exact
      component={CongratulationsView}
    />
    <VerifyLayout path="/verify" exact component={VerifyAccount} />
    <VerifyLayout path="/forgot" exact component={ForgotAccount} />
    <VerifyLayout path="/forgot-resend" exact component={ResendForgotAccount} />
    <VerifyLayout path="/password-reset" exact component={ResetPassword} />
  </Switch>
);

export default Routes;
