import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MainHeaderOptionsMenuView from 'common/components/mainHeaderOptionsMenuView';

const propTypes = {
  handleLogOutClick: PropTypes.func.isRequired,
  toggleOptionsMenu: PropTypes.func.isRequired,
  userData: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
  ).isRequired,
};

class MainHeaderOptionsMenu extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
  }

  componentWillMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  handleClick = e => {
    if (this.node.current.contains(e.target) || e.target.id === 'menu-button') {
      return;
    }
    this.handleClickOutside();
  };

  handleClickOutside = () => {
    const { toggleOptionsMenu } = this.props;
    toggleOptionsMenu();
  };

  render() {
    const { handleLogOutClick, toggleOptionsMenu, userData } = this.props;
    return (
      <div ref={this.node}>
        <MainHeaderOptionsMenuView
          handleLogOutClick={handleLogOutClick}
          toggleOptionsMenu={toggleOptionsMenu}
          userData={userData}
        />
      </div>
    );
  }
}

MainHeaderOptionsMenu.propTypes = propTypes;

export default MainHeaderOptionsMenu;
