import React from 'react';
import ReactPlayer from 'react-player';
import { Translate } from 'react-localize-redux';
import 'intersection-observer';
import { useInView } from 'react-intersection-observer';
import './index.scss';

const AboutVideo = () => {
  const [ref, inView] = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  const shouldPlay = false;

  if (inView) {
    // shouldPlay = true;
  }

  return (
    <div className="register-video" ref={ref}>
      <div className="link-to-infopage">
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://info.farmlifes.com/"
        >
          <Translate id="common.action.linkToInfo" />
          <span>www.info.farmlifes.com</span>
        </a>
      </div>

      <ReactPlayer
        width="100%"
        height="600px"
        url="https://www.youtube.com/watch?v=ciomQZ6hZXo"
        controls
        playing={shouldPlay}
      />
    </div>
  );
};

export default AboutVideo;
