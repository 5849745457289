import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InfoModal from 'common/components/infoModal';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { deleteFarm } from 'farm/api/farmsApi';
import { fetchUserData } from 'common/redux/actions/userDataActions';
import { getTranslate } from 'react-localize-redux';

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
});

const mapDispatchToProps = dispatch => ({
  getUserData: bindActionCreators(fetchUserData, dispatch),
});

const defaultProps = {
  heading: 'Are you sure you want to delte this farm?',
  message: 'All of its content will be removed and cannot be restored',
  redirect: false,
  onSuccess: () => null,
};

const propTypes = {
  getUserData: PropTypes.func.isRequired,
  farmId: PropTypes.number.isRequired,
  closeModal: PropTypes.func.isRequired,
  heading: PropTypes.string,
  message: PropTypes.string,
  redirect: PropTypes.bool,
  onSuccess: PropTypes.func,
};

class DeleteFarmModal extends Component {
  state = {
    isDeleteInProgress: false,
  };

  deleteFarm = () => {
    const { farmId } = this.props;
    this.setState({ isDeleteInProgress: true });

    deleteFarm(farmId)
      .then(() => {
        const {
          getUserData,
          closeModal,
          redirect,
          history,
          onSuccess,
        } = this.props;
        this.setState({ isDeleteInProgress: false });
        getUserData();
        onSuccess();
        if (redirect) {
          history.push('/');
        } else {
          closeModal();
        }
      })
      .catch(() => {
        this.setState({ isDeleteInProgress: false });
      });
  };

  render() {
    const { closeModal, heading, message, translate } = this.props;
    const { isDeleteInProgress } = this.state;

    return (
      <InfoModal
        closeModal={closeModal}
        heading={heading}
        message={message}
        handlePrimaryOptionButtonClick={this.deleteFarm}
        primaryOptionButton={translate('common.yes')}
        isCancelButtonVisible
        primaryOptionButtonDisabled={isDeleteInProgress}
      />
    );
  }
}

DeleteFarmModal.defaultProps = defaultProps;

DeleteFarmModal.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(DeleteFarmModal));
