import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Loader from 'common/components/loader';
import Comment from 'post/components/comment';
import TextareaAutosize from 'react-autosize-textarea';
import UserAvatar from 'common/components/userAvatar';
import Button from 'common/components/button';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import './index.scss';

const mapStateToProps = state => ({
  userData: state.userData.data,
  translate: getTranslate(state.localize),
});

const defaultProps = {
  nextPageIndex: null,
  commentMessage: '',
  height: null,
  translate: () => null,
  toggleGuestModal: () => null,
  comments: [],
  isFixedHeight: false,
  userData: {},
  commentCreationInProgress: false,
};

const propTypes = {
  comments: PropTypes.arrayOf(PropTypes.object),
  nextPageIndex: PropTypes.number,
  createNewComment: PropTypes.func.isRequired,
  commentMessage: PropTypes.string,
  getCommentsNextPage: PropTypes.func.isRequired,
  isInitialPageLoading: PropTypes.bool.isRequired,
  initialPageLoaded: PropTypes.bool.isRequired,
  updateCommentMessage: PropTypes.func.isRequired,
  height: PropTypes.number,
  postId: PropTypes.number.isRequired,
  isFixedHeight: PropTypes.bool,
  commentCreationInProgress: PropTypes.bool,
  isNextPageLoading: PropTypes.bool.isRequired,
  isCommentEditable: PropTypes.func.isRequired,
  deleteComment: PropTypes.func.isRequired,
  translate: PropTypes.func,
  toggleGuestModal: PropTypes.func,
  userData: PropTypes.object,
};

class PostCommentsView extends Component {
  constructor(props) {
    super(props);
    this.commentsList = React.createRef();
    this.scrollAtBottom = true;
    this.commentsChanged = false;
    this.topMessage = null;
  }

  state = {
    updated: true,
    scrollHeight: null,
  };

  componentDidMount() {
    this.scrollToBottom();
  }

  componentWillUpdate(nextProps) {
    const { comments } = this.props;
    this.commentsChanged = nextProps.comments.length !== comments.length;
    if (this.commentsChanged) {
      const scrollPos = this.commentsList.scrollTop;
      const scrollBottom =
        this.commentsList.scrollHeight - this.commentsList.clientHeight;
      this.scrollAtBottom = scrollBottom <= 0 || scrollPos === scrollBottom;
    }
    if (!this.scrollAtBottom) {
      const numMessages = this.commentsList.childNodes.length;
      this.topMessage =
        numMessages === 0 ? null : this.commentsList.childNodes[0];
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { nextPageIndex } = this.props;
    if (this.commentsChanged) {
      this.setCommentsListScrollHeight(this.commentsList.scrollHeight);
      if (this.scrollAtBottom) {
        this.scrollToBottom();
      }
      if (this.topMessage) {
        if (nextPageIndex !== prevProps.nextPageIndex) {
          this.commentsList.scrollTop =
            this.commentsList.scrollHeight - prevState.scrollHeight;
        }
      }
      this.setUpdatedAsDone();
    }
  }

  setUpdatedAsDone = () => this.setState({ updated: true });

  setCommentsListScrollHeight = height =>
    this.setState({ scrollHeight: height });

  scrollToBottom = () => {
    const { clientHeight, scrollHeight } = this.commentsList;
    const maxScrollTop = scrollHeight - clientHeight;
    this.commentsList.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
  };

  onScroll = () => {
    const { scrollTop } = this.commentsList;
    const { getCommentsNextPage, nextPageIndex, postId } = this.props;
    const { updated } = this.state;
    if (scrollTop < 20 && scrollTop >= 0 && updated) {
      if (nextPageIndex) {
        this.setState({ updated: false });
        getCommentsNextPage(postId, nextPageIndex);
      }
    }
  };

  reverseComments = arr => {
    const reversedComments = [];
    for (let i = arr.length - 1; i >= 0; i -= 1) {
      reversedComments.push(arr[i]);
    }
    return reversedComments;
  };

  render() {
    const {
      comments,
      createNewComment,
      commentMessage,
      updateCommentMessage,
      isInitialPageLoading,
      initialPageLoaded,
      height,
      isFixedHeight,
      isNextPageLoading,
      isCommentEditable,
      userData,
      translate,
      deleteComment,
      commentCreationInProgress,
      toggleGuestModal,
    } = this.props;
    const createButtonClasses = classNames({
      'post-comments__create-button': true,
      'post-comments__create-button--active': commentMessage,
    });
    const textareaContainerClasses = classNames({
      'post-comments__input-container': true,
      'post-comments__input-container--absolute': isFixedHeight,
    });
    const textareaClasses = classNames({
      'post-comments__input': true,
      'post-comments__input--absolute': isFixedHeight,
    });
    const commentsContainerClasses = classNames({
      'post-comments__comments-container': true,
      'post-comments__comments-container--absolute': isFixedHeight,
    });
    const random = Math.random();
    const reversedComments = this.reverseComments(comments);
    return (
      <div
        className="post-comments"
        style={height ? { height: Number(height) } : {}}
      >
        <div
          id={`comments-container${random}`}
          className={commentsContainerClasses}
          style={{
            height: height && Number(height) - 15,
            maxHeight: height && Number(height) - 15,
          }}
          onScroll={this.onScroll}
          ref={node => {
            this.commentsList = node;
          }}
        >
          {isInitialPageLoading && (
            <div className="">
              <Loader message={translate('common.loading')} />
            </div>
          )}
          {initialPageLoaded && comments.length === 0 && (
            <div className="post-comments__empty">
              {translate('common.noObjectsForObject', {
                objects: translate('common.reaction.comments'),
                object: translate('common.reaction.post'),
              })}
            </div>
          )}
          {isNextPageLoading && (
            <div className="">
              <Loader
                message={translate('common.load', {
                  object: translate('common.reaction.comments'),
                })}
              />
            </div>
          )}
          {reversedComments.map(comment => (
            <div className="post-comments__comment" key={comment.id}>
              <Comment
                comment={comment}
                isCommentEditable={isCommentEditable}
                deleteComment={deleteComment}
              />
            </div>
          ))}
        </div>
        <div className={textareaContainerClasses}>
          <div className="post-comments__avatar">
            {userData && userData.id && (
              <UserAvatar id={userData.id} image={userData.avatar} />
            )}
          </div>
          <TextareaAutosize
            className={textareaClasses}
            placeholder={translate('common.reaction.comment')}
            onChange={updateCommentMessage}
            onFocus={() => !userData.id && toggleGuestModal()}
            value={commentMessage}
            maxRows={3}
          />
          <Button
            className={createButtonClasses}
            onClick={createNewComment}
            disabled={
              !userData.id || !commentMessage || commentCreationInProgress
            }
          />
        </div>
      </div>
    );
  }
}

PostCommentsView.defaultProps = defaultProps;

PostCommentsView.propTypes = propTypes;

export default connect(mapStateToProps)(PostCommentsView);
