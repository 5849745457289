import axios from 'axios';
import * as types from 'post/redux/types';
import { createActionCreator } from 'common/utils/reduxUtils';

const {
  FETCH_LIKES_REQUEST,
  FETCH_LIKES_SUCCESS,
  FETCH_LIKES_FAILURE,
  REMOVE_LIKES,
} = types;

const fetchLikesRequest = createActionCreator(FETCH_LIKES_REQUEST);
const fetchLikesSuccess = createActionCreator(FETCH_LIKES_SUCCESS, 'data');
const fetchLikesFailure = createActionCreator(FETCH_LIKES_FAILURE);

export const fetchLikes = (id, page = 1) => (dispatch, getState) => {
  const { currentTimestamp } = getState().likes;
  dispatch(fetchLikesRequest());
  return axios
    .get(`${process.env.REACT_APP_API_URL}/likes`, {
      params: {
        id,
        page,
        type: 'post',
        to: currentTimestamp,
      },
    })
    .then(response => {
      dispatch(fetchLikesSuccess(response.data));
    })
    .catch(() => {
      dispatch(fetchLikesFailure());
    });
};

export const removeLikes = createActionCreator(REMOVE_LIKES);
