const disableScroll = (shouldBeDisabled, scroll = true) => {
  document.body.style.overflow = shouldBeDisabled ? 'hidden' : 'auto';

  if (scroll) {
    window.innerWidth < 992 &&
      (document.body.style.position = shouldBeDisabled ? 'fixed' : 'static');
  }
};

export default disableScroll;
