import translateMessage from 'common/utils/translateMessage';

export default function setResetPasswordErrors(error) {
  let errors = {
    submit: translateMessage('form.error.passwordReset'),
    token: '',
    password: '',
  };

  if (error.response.data.errors) {
    if (error.response.data.errors.token) {
      errors = {
        ...errors,
        ...{ token: error.response.data.errors.token.join(' ') },
      };
    }

    if (error.response.data.errors.password) {
      errors = {
        ...errors,
        ...{ password: error.response.data.errors.password.join(' ') },
      };
    }
  }

  return errors;
}
