import axios from 'axios';
import * as types from 'farm/redux/types';
import { createActionCreator } from 'common/utils/reduxUtils';

const {
  FETCH_FARM_MEMBERS_REQUEST,
  FETCH_FARM_MEMBERS_SUCCESS,
  FETCH_FARM_MEMBERS_FAILURE,
  REMOVE_FARM_MEMBERS,
  UPDATE_FARM_MEMBERS,
} = types;

const fetchFarmMembersRequest = createActionCreator(FETCH_FARM_MEMBERS_REQUEST);
const fetchFarmMembersSuccess = createActionCreator(
  FETCH_FARM_MEMBERS_SUCCESS,
  'data',
);
const fetchFarmMembersFailure = createActionCreator(FETCH_FARM_MEMBERS_FAILURE);

export const fetchFarmMembers = (id, page = 1) => dispatch => {
  dispatch(fetchFarmMembersRequest());
  return axios
    .get(`${process.env.REACT_APP_API_URL}/farms/${id}/members`, {
      params: {
        page,
        farm_id: id,
      },
    })
    .then(response => {
      dispatch(fetchFarmMembersSuccess(response.data));
    })
    .catch(() => {
      dispatch(fetchFarmMembersFailure());
    });
};

export const removeFarmMembers = createActionCreator(REMOVE_FARM_MEMBERS);

export const updateFarmMembers = createActionCreator(
  UPDATE_FARM_MEMBERS,
  'data',
);
