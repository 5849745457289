import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import debounce from 'lodash/debounce';
import JoinFarmModalView from 'farms/components/joinFarmModalView';
import {
  fetchFarmsToJoinList,
  removeFarmsToJoinList,
  updateFarmsToJoinListQuery,
} from 'farms/redux/actions/farmsToJoinListActions';
import { sendFarmMembershipRequest } from 'farm/api/farmsApi';
import { addNotification } from 'notification/api/notificationsApi';

const defaultProps = {
  farmsList: [],
  nextPageIndex: null,
};

const propTypes = {
  farmsList: PropTypes.arrayOf(PropTypes.object),
  getFarmsList: PropTypes.func.isRequired,
  nextPageIndex: PropTypes.number,
  removeFarmsList: PropTypes.func.isRequired,
  searchQuery: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  updateFarmsListQuery: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  farmsList: state.farmsToJoinList.data,
  nextPageIndex: state.farmsToJoinList.nextPageIndex,
  searchQuery: state.farmsToJoinList.query,
});

const mapDispatchToProps = dispatch => ({
  getFarmsList: bindActionCreators(fetchFarmsToJoinList, dispatch),
  removeFarmsList: bindActionCreators(removeFarmsToJoinList, dispatch),
  updateFarmsListQuery: bindActionCreators(
    updateFarmsToJoinListQuery,
    dispatch,
  ),
});

class JoinFarmModal extends Component {
  state = {
    errorMessage: '',
    isInitialPageLoading: false,
    selectedFarmId: null,
  };

  debouncedGetFarmsList = debounce(() => {
    const { getFarmsList } = this.props;
    getFarmsList();
  }, 250);

  componentDidMount() {
    const { getFarmsList } = this.props;
    this.toggleInitialPageLoading();
    getFarmsList().then(() => {
      this.toggleInitialPageLoading();
    });
  }

  componentWillUnmount() {
    const { removeFarmsList, updateFarmsListQuery } = this.props;
    removeFarmsList();
    updateFarmsListQuery('');
  }

  toggleInitialPageLoading = () => {
    const { isInitialPageLoading } = this.state;
    this.setState({ isInitialPageLoading: !isInitialPageLoading });
  };

  getFarmsListNextPage = () => {
    const { getFarmsList, nextPageIndex } = this.props;
    getFarmsList(nextPageIndex);
  };

  handleFarmCheckboxClick = e => {
    this.setState({ selectedFarmId: Number(e.target.name) });
  };

  handleFarmRowClick = id => {
    this.setState({ selectedFarmId: id });
  };

  sendFarmMembership = () => {
    const { closeModal } = this.props;
    const { selectedFarmId } = this.state;
    this.setState({ errorMessage: '' });
    sendFarmMembershipRequest(selectedFarmId)
      .then(() => {
        addNotification({
          typeAction: 'farm_member_request',
          entityId: selectedFarmId,
        });
        closeModal();
      })
      .catch(error => {
        if (error.response && error.response.data) {
          this.setState({ errorMessage: error.response.data.message });
        }
      });
  };

  onSearchInputChange = e => {
    const { updateFarmsListQuery } = this.props;
    updateFarmsListQuery(e.target.value);
    this.debouncedGetFarmsList();
  };

  render() {
    const { farmsList, nextPageIndex, searchQuery, closeModal } = this.props;
    const { errorMessage, isInitialPageLoading, selectedFarmId } = this.state;
    return (
      <JoinFarmModalView
        farmsList={farmsList}
        getFarmsListNextPage={this.getFarmsListNextPage}
        nextPageIndex={nextPageIndex}
        handleFarmCheckboxClick={this.handleFarmCheckboxClick}
        handleFarmRowClick={this.handleFarmRowClick}
        sendFarmMembership={this.sendFarmMembership}
        selectedFarmId={selectedFarmId}
        onSearchInputChange={this.onSearchInputChange}
        searchQuery={searchQuery}
        isInitialPageLoading={isInitialPageLoading}
        closeModal={closeModal}
        errorMessage={errorMessage}
      />
    );
  }
}

JoinFarmModal.defaultProps = defaultProps;

JoinFarmModal.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(JoinFarmModal);
