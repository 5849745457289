import React, { Component } from 'react';
import SearchInput from 'common/components/searchInput';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import debounce from 'lodash/debounce';
import $ from 'jquery';
import {
  updateFarmsListQuery,
  fetchFarmsList,
  fetchUsersList,
} from 'farms/redux/actions/farmsListActions';

const defaultProps = {
  history: {},
  match: {},
  onFocus: () => null,
};

const propTypes = {
  updateFarmsQuery: PropTypes.func.isRequired,
  fetchFarmsList: PropTypes.func.isRequired,
  fetchUsersList: PropTypes.func.isRequired,
  farmsQuery: PropTypes.string.isRequired,
  history: PropTypes.object,
  match: PropTypes.object,
  onFocus: PropTypes.func,
};

const mapStateToProps = state => ({
  farmsQuery: state.farmsList.query,
});

const mapDispatchToProps = dispatch => ({
  updateFarmsQuery: bindActionCreators(updateFarmsListQuery, dispatch),
  fetchFarmsList: bindActionCreators(fetchFarmsList, dispatch),
  fetchUsersList: bindActionCreators(fetchUsersList, dispatch),
});

class SearchFarms extends Component {
  debouncedFetchFarmsList = debounce(
    page => {
      const { fetchFarmsList, fetchUsersList, history, match } = this.props;
      fetchFarmsList(page);
      fetchUsersList(page);
      if (match.path !== '/farms') {
        history.replace('/farms');
        $('input[name="search"]').focus();
      }
    },
    250,
    {
      leading: false,
      trailing: true,
    },
  );

  onChange = event => {
    const { updateFarmsQuery } = this.props;
    updateFarmsQuery(event.target.value);
    this.debouncedFetchFarmsList();
  };

  searchOnFocus = () => {
    this.debouncedFetchFarmsList();
  };

  render() {
    const { farmsQuery, onFocus, userId } = this.props;
    return (
      <SearchInput
        value={farmsQuery}
        onFocus={() => {
          if (!userId) {
            onFocus();
          } else {
            this.searchOnFocus();
          }
        }}
        onChange={this.onChange}
      />
    );
  }
}

SearchFarms.defaultProps = defaultProps;
SearchFarms.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(SearchFarms));
