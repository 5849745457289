import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import disableScroll from 'common/utils/disableScroll';
import PostActivitiesView from 'post/components/postActivities';
import ActivitiesModal from 'post/containers/activitiesModal';

const defaultProps = {
  feed: '',
  path: '',
  userData: {},
  toggleGuestModal: () => null,
};

const propTypes = {
  post: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
      PropTypes.object,
    ]),
  ).isRequired,
  onCommentsClick: PropTypes.func.isRequired,
  feed: PropTypes.string,
  path: PropTypes.string,
  userData: PropTypes.object,
  toggleGuestModal: PropTypes.func,
};

class PostActivities extends Component {
  state = {
    activeSection: null,
    isActivitiesModalVisible: false,
  };

  toggleActivitiesModal = () => {
    const { isActivitiesModalVisible } = this.state;
    const { userData, toggleGuestModal } = this.props;

    if (userData.id) {
      this.setState(
        {
          isActivitiesModalVisible: !isActivitiesModalVisible,
        },
        () => disableScroll(!isActivitiesModalVisible, false),
      );
    } else {
      toggleGuestModal();
    }
  };

  handleShowLikesClick = () => {
    this.setState({ activeSection: 'likes' });
    this.toggleActivitiesModal();
  };

  handleShowSharesClick = () => {
    this.setState({ activeSection: 'shares' });
    this.toggleActivitiesModal();
  };

  render() {
    const { post, onCommentsClick, feed, path } = this.props;
    const { activeSection, isActivitiesModalVisible } = this.state;
    return (
      <Fragment>
        <PostActivitiesView
          post={post}
          handleShowLikesClick={this.handleShowLikesClick}
          handleShowSharesClick={this.handleShowSharesClick}
          isActivitiesModalVisible={isActivitiesModalVisible}
          toggleActivitiesModal={this.toggleActivitiesModal}
          activeSection={activeSection}
          onCommentsClick={onCommentsClick}
          feed={feed}
          path={path}
        />
        {isActivitiesModalVisible && (
          <ActivitiesModal
            post={post}
            closeModal={this.toggleActivitiesModal}
            activeSection={activeSection}
          />
        )}
      </Fragment>
    );
  }
}

PostActivities.defaultProps = defaultProps;
PostActivities.propTypes = propTypes;

export default PostActivities;
