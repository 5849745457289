import React from 'react';
import PropTypes from 'prop-types';
import SearchInput from 'common/components/searchInput';
import Loader from 'common/components/loader';
import InfiniteScroll from 'react-infinite-scroll-component';
import ModalContainer from 'common/components/modalContainer';
import MainButton from 'common/components/mainButton';
import RadioInput from 'common/components/radioInput';
import EndMessage from 'common/components/endMessage';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import './index.scss';

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
});

const defaultProps = {
  farmsList: [],
  nextPageIndex: 1,
  selectedFarmId: null,
  translate: () => null,
};

const propTypes = {
  farmsList: PropTypes.arrayOf(PropTypes.object),
  getFarmsListNextPage: PropTypes.func.isRequired,
  nextPageIndex: PropTypes.number,
  handleFarmCheckboxClick: PropTypes.func.isRequired,
  handleFarmRowClick: PropTypes.func.isRequired,
  onSearchInputChange: PropTypes.func.isRequired,
  searchQuery: PropTypes.string.isRequired,
  selectedFarmId: PropTypes.number,
  sendFarmMembership: PropTypes.func.isRequired,
  isInitialPageLoading: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  translate: PropTypes.func,
};

const JoinFarmModalView = ({
  closeModal,
  farmsList,
  getFarmsListNextPage,
  handleFarmCheckboxClick,
  handleFarmRowClick,
  nextPageIndex,
  sendFarmMembership,
  selectedFarmId,
  searchQuery,
  onSearchInputChange,
  isInitialPageLoading,
  errorMessage,
  translate,
}) => (
  <ModalContainer handleOutsideClick={closeModal}>
    <div className="join-farm-modal">
      <p className="join-farm-modal__heading">
        {translate('modal.joinFarm.heading')}
      </p>
      <SearchInput value={searchQuery} onChange={onSearchInputChange} />
      <div
        className="join-farm-modal__scroll-container"
        id="join-farm-modal-scroll"
      >
        {isInitialPageLoading && <Loader message="Loading farms..." />}
        {!isInitialPageLoading && farmsList.length === 0 && (
          <p className="join-farm-modal__empty">
            {translate('common.noObjects', {
              objects: translate('common.reaction.farms'),
            })}
          </p>
        )}
        <InfiniteScroll
          dataLength={farmsList.length}
          scrollThreshold="400px"
          next={nextPageIndex ? getFarmsListNextPage : null}
          hasMore={!!nextPageIndex}
          scrollableTarget="join-farm-modal-scroll"
          loader={
            nextPageIndex === 1 ? (
              <Loader
                message={translate('common.load', {
                  objects: translate('common.reaction.farms'),
                })}
              />
            ) : (
              <Loader
                message={translate('common.loadMore', {
                  objects: translate('common.reaction.farms'),
                })}
              />
            )
          }
          endMessage={
            farmsList.length > 0 ? (
              <EndMessage
                message={translate('common.noMore', {
                  object: translate('common.reaction.farms'),
                })}
              />
            ) : null
          }
        >
          {farmsList.map(farm => (
            <div
              role="presentation"
              className="join-farm-modal__farm"
              onClick={() => handleFarmRowClick(farm.id)}
              onKeyDown={() => {}}
              key={farm.id}
            >
              <div className="join-farm-modal__farm-details">
                <div
                  className="join-farm-modal__farm-image"
                  style={
                    farm.profile_image
                      ? {
                          backgroundImage: `url(${farm.profile_image.url})`,
                        }
                      : {}
                  }
                />
                <div>
                  <p className="join-farm-modal__farm-name">{farm.name}</p>
                  <p className="join-farm-modal__farm-members">
                    {farm.members_count > 0 &&
                      ((farm.members_count === 1 &&
                        `${farm.members_count} ${translate(
                          'common.reaction.member',
                        )}`) ||
                        `${farm.members_count} ${translate(
                          'common.reaction.members',
                        )}`)}
                  </p>
                  <p className="join-farm-modal__farm-address">
                    {farm.post_code} 
                    {' '}
                    {farm.city}
                  </p>
                </div>
              </div>
              <div className="join-farm-modal__radio">
                <RadioInput
                  name={`${farm.id}`}
                  onChange={e => {
                    e.stopPropagation();
                    handleFarmCheckboxClick(e);
                  }}
                  checked={selectedFarmId === farm.id}
                />
              </div>
            </div>
          ))}
        </InfiniteScroll>
      </div>
      <div className="join-farm-modal__buttons">
        <MainButton onClick={closeModal} teritary>
          {translate('common.action.cancel')}
        </MainButton>
        <div className="join-farm-modal__submit-button-container">
          <MainButton onClick={sendFarmMembership} disabled={!selectedFarmId}>
            {translate('common.reaction.sendRequest')}
          </MainButton>
          {errorMessage && (
            <p className="join-farm-modal__error">{errorMessage}</p>
          )}
        </div>
      </div>
    </div>
  </ModalContainer>
);

JoinFarmModalView.defaultProps = defaultProps;

JoinFarmModalView.propTypes = propTypes;

export default connect(mapStateToProps)(JoinFarmModalView);
