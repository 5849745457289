import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MainHeaderView from 'common/components/mainHeaderView';
import {
  removeUserAuthToken,
  removeUserEmail,
} from 'userAuth/redux/actions/authActions';
import { removeUserData } from 'common/redux/actions/userDataActions';
import { removeNotifications } from 'notification/redux/actions/notificationActions';
import disableScroll from 'common/utils/disableScroll';
import UserFarmFlow from 'farm/containers/userFarmFlow';
import GuestModal from 'common/components/guestModal';

const defaultProps = {
  token: '',
  userData: {},
  isVisible: false,
  history: {},
};

const propTypes = {
  removeUserAuthToken: PropTypes.func.isRequired,
  removeUserEmail: PropTypes.func.isRequired,
  removeUserData: PropTypes.func.isRequired,
  token: PropTypes.string,
  userData: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
  ),
  farmFlowActive: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool,
  history: PropTypes.object,
  removeNotifications: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  token: state.auth.token,
  userData: state.userData.data,
  farmFlowActive: state.farm.farmFlowActive,
  isVisible: state.notifications.isModalVisible,
});

const mapDispatchToProps = dispatch => ({
  removeUserAuthToken: bindActionCreators(removeUserAuthToken, dispatch),
  removeUserEmail: bindActionCreators(removeUserEmail, dispatch),
  removeUserData: bindActionCreators(removeUserData, dispatch),
  removeNotifications: bindActionCreators(removeNotifications, dispatch),
});

class MainHeader extends Component {
  state = {
    isOptionsMenuVisible: false,
    isGuestModalVisible: false,
  };

  toggleOptionsMenu = () => {
    const { isOptionsMenuVisible } = this.state;
    const { userData } = this.props;

    if (userData.id) {
      if (window.innerWidth < 992) {
        disableScroll(!isOptionsMenuVisible);
      }
      this.setState({ isOptionsMenuVisible: !isOptionsMenuVisible });
    } else {
      this.toggleGuestModal();
    }
  };

  toggleGuestModal = () => {
    const { isGuestModalVisible } = this.state;
    this.setState(
      { isGuestModalVisible: !isGuestModalVisible },
      disableScroll(!isGuestModalVisible),
    );
  };

  handleLogOutClick = () => {
    const {
      removeUserAuthToken,
      removeUserEmail,
      removeUserData,
      removeNotifications,
      history,
    } = this.props;
    disableScroll(false);
    removeUserAuthToken();
    removeUserEmail();
    removeUserData();
    removeNotifications();
    history.push('/auth');
  };

  render() {
    const { token, userData, farmFlowActive, isVisible } = this.props;
    const { isOptionsMenuVisible, isGuestModalVisible } = this.state;

    return (
      <React.Fragment>
        <MainHeaderView
          handleLogOutClick={this.handleLogOutClick}
          token={token}
          userData={userData}
          isOptionsMenuVisible={isOptionsMenuVisible}
          isNotificationsModalVisible={isVisible}
          toggleOptionsMenu={this.toggleOptionsMenu}
          toggleGuestModal={this.toggleGuestModal}
        />
        <UserFarmFlow farmFlowActive={farmFlowActive} />
        {isGuestModalVisible && (
          <GuestModal closeModal={this.toggleGuestModal} />
        )}
      </React.Fragment>
    );
  }
}

MainHeader.defaultProps = defaultProps;

MainHeader.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(MainHeader));
