export const FETCH_LIKES_REQUEST = 'FETCH_LIKES_REQUEST';
export const FETCH_LIKES_SUCCESS = 'FETCH_LIKES_SUCCESS';
export const FETCH_LIKES_FAILURE = 'FETCH_LIKES_FAILURE';

export const REMOVE_LIKES = 'REMOVE_LIKES';

export const FETCH_SHARES_REQUEST = 'FETCH_SHARES_REQUEST';
export const FETCH_SHARES_SUCCESS = 'FETCH_SHARES_SUCCESS';
export const FETCH_SHARES_FAILURE = 'FETCH_SHARES_FAILURE';

export const REMOVE_SHARES = 'REMOVE_SHARES';

export const FETCH_COMMENTS_REQUEST = 'FETCH_COMMENTS_REQUEST';
export const FETCH_COMMENTS_SUCCESS = 'FETCH_COMMENTS_SUCCESS';
export const FETCH_COMMENTS_FAILURE = 'FETCH_COMMENTS_FAILURE';

export const UPDATE_COMMENTS = 'UPDATE_COMMENTS';

export const REMOVE_COMMENTS = 'REMOVE_COMMENTS';

export const SAVE_OPEN_COMMENT_ID = 'SAVE_OPEN_COMMENT_ID';
export const REMOVE_OPEN_COMMENT_ID = 'REMOVE_OPEN_COMMENT_ID';

export const REMOVE_SINGLE_COMMENT = 'REMOVE_SINGLE_COMMENT';
export const REMOVE_SINGLE_COMMENTS_LIST = 'REMOVE_SINGLE_COMMENTS_LIST';

export const FETCH_ARTICLE_TO_EDIT_REQUEST = 'FETCH_ARTICLE_TO_EDIT_REQUEST';
export const FETCH_ARTICLE_TO_EDIT_SUCCESS = 'FETCH_ARTICLE_TO_EDIT_SUCCESS';
export const FETCH_ARTICLE_TO_EDIT_FAILURE = 'FETCH_ARTICLE_TO_EDIT_FAILURE';

export const REMOVE_ARTICLE_TO_EDIT = 'REMOVE_ARTICLE_TO_EDIT';
