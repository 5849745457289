import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './index.scss';

const defaultProps = {
  onBlur: () => null,
  onChange: () => null,
  error: '',
  touched: false,
  value: '',
  name: '',
  placeholder: '',
  disabled: false,
  fullWidth: false,
  withLabel: false,
};

const propTypes = {
  value: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.string,
  touched: PropTypes.bool,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  withLabel: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

class Textarea extends Component {
  state = {
    isFocused: false,
  };

  setFocus = () => {
    this.setState({ isFocused: true });
  };

  removeFocus = () => {
    this.setState({ isFocused: false });
  };

  render() {
    const {
      value,
      onBlur,
      onChange,
      name,
      error,
      touched,
      placeholder,
      disabled,
      fullWidth,
      withLabel,
    } = this.props;
    const { isFocused } = this.state;
    const inputClasses = classNames({
      textarea: true,
      'textarea--focused': isFocused || value,
      'textarea--full-width': fullWidth,
    });
    const inputFieldClasses = classNames({
      textarea__field: true,
      'textarea__field--error': touched && error,
      'textarea__field--full-width': fullWidth,
    });
    return (
      <div className={inputClasses}>
        {withLabel && (
          <label className="textarea__label" htmlFor={name} id={name}>
            {placeholder}
          </label>
        )}
        <textarea
          type="text"
          name={name}
          readOnly={disabled}
          placeholder={withLabel ? '' : placeholder}
          onChange={onChange}
          onBlur={e => {
            onBlur(e);
            this.removeFocus();
          }}
          value={value}
          className={inputFieldClasses}
          onFocus={this.setFocus}
        />
        {touched && error && (
          <div className="textarea__error-message">{error}</div>
        )}
      </div>
    );
  }
}

Textarea.propTypes = propTypes;

Textarea.defaultProps = defaultProps;

export default Textarea;
