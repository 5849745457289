import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from 'common/components/button';
import MainButton from 'common/components/mainButton';
import ProfileImage from 'common/components/profileImage';
import FarmMembers from 'farm/components/farmMembers';
import FarmTourIcon from 'farm/components/farmtourIconView';
import FarmMap from 'farm/components/farmMapView';
import InviteModal from 'common/containers/inviteModal';
import { Translate } from 'react-localize-redux';
import renderHTML from 'react-render-html';
import './index.scss';

const defaultProps = {
  changeTitleImage: null,
  toggleCreateFarmModal: null,
  isAdmin: false,
  isMember: false,
  toggleMembershipRequestsModal: null,
  toggleFarmTourModal: null,
  membersResultsNumber: 0,
  farmsList: [],
};

const propTypes = {
  changeTitleImage: PropTypes.func,
  farm: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.object,
      PropTypes.array,
      PropTypes.bool,
    ]),
  ).isRequired,
  farmMembers: PropTypes.arrayOf(PropTypes.object).isRequired,
  isTitleImageLoaded: PropTypes.bool.isRequired,
  toggleCreateFarmModal: PropTypes.func,
  isAdmin: PropTypes.bool,
  isMember: PropTypes.bool,
  membersResultsNumber: PropTypes.number,
  toggleMembersListModal: PropTypes.func.isRequired,
  toggleMembershipRequestsModal: PropTypes.func,
  toggleImagePreviewModal: PropTypes.func.isRequired,
  toggleCreateFarmTourModal: PropTypes.func.isRequired,
  toggleFarmTourModal: PropTypes.func,
  farmsList: PropTypes.array,
};

const FarmHeaderMobile = ({
  changeTitleImage,
  farm,
  farmMembers,
  isTitleImageLoaded,
  toggleCreateFarmModal,
  toggleMembersListModal,
  isAdmin,
  isMember,
  membersResultsNumber,
  toggleMembershipRequestsModal,
  toggleImagePreviewModal,
  toggleCreateFarmTourModal,
  toggleFarmTourModal,
  farmsList,
}) => {
  const titleImageClasses = classNames({
    'farm-header-mobile__title-image': true,
    'farm-header-mobile__title-image--active':
      farm.title_image && farm.title_image.url,
  });

  let farmTourImage = '';

  if (farm && farm.farmtour_image) {
    if (farm.farmtour_image.thumbnail) {
      farmTourImage = farm.farmtour_image.thumbnail;
    } else if (farm.farmtour_image.imageUrl) {
      farmTourImage = farm.farmtour_image.imageUrl;
    } else {
      farmTourImage = '';
    }
  }

  return (
    <div className="farm-header-mobile">
      <div className="farm-header-mobile__title-image-container">
        <div
          className={titleImageClasses}
          style={
            farm.title_image && isTitleImageLoaded
              ? {
                  backgroundImage: `url(${farm.title_image.imageUrl})`,
                  backgroundSize: 'cover',
                }
              : {}
          }
          role="button"
          tabIndex="0"
          onClick={farm.title_image ? toggleImagePreviewModal : () => null}
          onKeyPress={farm.title_image ? toggleImagePreviewModal : () => null}
        />
        {(isAdmin || isMember) && (
          <label htmlFor="farm-profile-image-input" className="farm__label">
            <div className="farm-header-mobile__edit farm-header-mobile__edit--title" />
            <input
              name="farm-profile-image-input"
              id="farm-profile-image-input"
              className="farm__input"
              type="file"
              accept=".jpeg, .JPEG, .JPG, .jpg, .png, .PNG, .gif, .GIF"
              onChange={changeTitleImage}
            />
          </label>
        )}
      </div>
      <div className="farm-header-mobile__farm-data">
        <div className="farm-header-mobile__top-container">
          <div className="farm-header-mobile__profile-image">
            <ProfileImage
              profileImage={farm.profile_image && farm.profile_image.imageUrl}
            />
          </div>
          <div className="farm-header-mobile__main-data-container">
            <div className="farm-header-mobile__name-container">
              <p className="farm-header-mobile__name">{farm.name}</p>
              {(isAdmin || isMember) && (
                <Button
                  className="farm-header-mobile__edit"
                  onClick={toggleCreateFarmModal}
                />
              )}
            </div>
            {farm.category && (
              <p className="farm-header-mobile__category">
                <Translate id={`farm.categories.${farm.category.name}`} />
              </p>
            )}

            {farm.farm_area && (
              <p className="farm-header-mobile__farm-area-label">
                <Translate id="page.farmProfile.createFarm.size" />
                <span className="farm-header-mobile__farm-area">
                  {' '}
                  {parseInt(farm.farm_area, 10)}
                  {' '}
ha
                </span>
              </p>
            )}

            {farm.posts_count > 0 && (
              <p className="farm-header-mobile__posts">
                {farm.posts_count}
                {' '}
                {farm.posts_count.length === 1 ? 'post' : 'posts'}
              </p>
            )}
            {/* <div className="farm-header-mobile__address-container">
                {farm.adress && (
                  <p className="farm-header-mobile__address">{farm.adress}</p>
                )}
                {farm.city && (
                  <p className="farm-header-mobile__address">{farm.city}</p>
                )}
              </div> */}
          </div>
        </div>

        <div className="farm-header-mobile__middle-container">
          <div className="farm-header-mobile__members-container">
            <FarmMembers
              toggleMembersListModal={toggleMembersListModal}
              membersCount={membersResultsNumber}
              farmMembers={farmMembers}
              isAdmin={isAdmin}
              toggleMembershipRequestsModal={toggleMembershipRequestsModal}
            />
            {(isAdmin || isMember) && window.innerWidth > 991 && (
              <InviteModal farmId={farm.id}>
                {({ handleInviteButtonClick }) => (
                  <MainButton onClick={handleInviteButtonClick}>
                    <Translate id="common.action.inviteFriends" />
                  </MainButton>
                )}
              </InviteModal>
            )}
          </div>
        </div>

        {farm.description && (
          <p className="farm-header-mobile__description">
            {renderHTML(farm.description.replace(/(?:\r\n|\r|\n)/g, '<br>'))}
          </p>
        )}

        <div className="farm-header-mobile__bottom-container">
          <div style={{ width: 100, position: 'relative' }}>
            {farm.lat && farm.lng && (
              <FarmMap
                farm={farm}
                lat={farm.lat}
                lng={farm.lng}
                farmsList={farmsList}
              />
            )}
          </div>

          <FarmTourIcon
            isAdmin={isAdmin}
            toggleCreateFarmTourModal={toggleCreateFarmTourModal}
            toggleFarmTourModal={toggleFarmTourModal}
            farmTourImage={farmTourImage}
          />
        </div>
      </div>
    </div>
  );
};

FarmHeaderMobile.defaultProps = defaultProps;

FarmHeaderMobile.propTypes = propTypes;

export default FarmHeaderMobile;
