import { createReducer } from 'common/utils/reduxUtils';
import { FETCH_SHARES_SUCCESS, REMOVE_SHARES } from 'post/redux/types';

const initialState = {
  data: [],
  hasNextPage: false,
  nextPageIndex: null,
  resultsNumber: null,
  currentTimestamp: null,
};

const sharesReducer = createReducer(
  { ...initialState },
  {
    [FETCH_SHARES_SUCCESS]: (state, action) => {
      const { data, meta } = action.payload.data;
      const { currentTimestamp } = state;
      const hasNextPage = meta.current_page < meta.last_page;
      return {
        ...state,
        data: meta.current_page === 1 ? [...data] : [...state.data, ...data],
        nextPageIndex: hasNextPage ? meta.current_page + 1 : null,
        resultsNumber: meta.total,
        currentTimestamp:
          meta.current_page === 1 ? meta.current_timestamp : currentTimestamp,
      };
    },
    [REMOVE_SHARES]: state => ({
      ...state,
      data: [],
      hasNextPage: false,
      nextPageIndex: null,
      resultsNumber: null,
      currentTimestamp: null,
    }),
  },
);

export default sharesReducer;
