import React from 'react';
import PropTypes from 'prop-types';
import Linkify from 'linkifyjs/react';
import renderHTML from 'react-render-html';
import FromNow from 'common/components/fromNow';
import ImageWithLoader from 'common/components/imageWithLoader';
import VideoWithLoader from 'common/components/videoWithLoader';
import CategoryItem from 'post/components/categoryItem';
import './index.scss';

const defaultProps = {
  farmId: null,
  width: null,
  feed: '',
  translate: () => null,
  logViewedPost: () => null,
  hashtaged: false,
  path: '',
  toggleFarmTourModal: () => null,
  toggleGuestModal: () => null,
  index: null,
  activeFarms: [],
};

const propTypes = {
  sharedContent: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
      PropTypes.object,
    ]),
  ).isRequired,
  shouldPlay: PropTypes.bool,
};

const SharedContent = ({ sharedContent, shouldPlay }) => {
  const linkOption = {
    className: 'inpost-link',
    defaultProtocol: 'https',
    format(value) {
      return value.replace(/(?:http:\/\/|https:\/\/)/g, '');
    },
    formatHref(href, type) {
      let newHref = href;

      if (type === 'hashtag') {
        newHref = `hashtag${newHref}`;
      }
      return newHref;
    },
    target: {
      url: '_blank',
    },
  };

  if (sharedContent.text && sharedContent.text.length > 0) {
    const regexp = /(#\S+)/gi;
    const matches_array = sharedContent.text.match(regexp);

    if (matches_array && matches_array.length > 0) {
      matches_array.forEach((item, i) => {
        const newItem = item
          .replace(/ö/g, 'o')
          .replace(/ä/g, 'a')
          .replace(/ü/g, 'u');
        if (newItem !== item) {
          sharedContent.text = sharedContent.text.replace(item, newItem);
        }
      });
    }
  }

  return (
    <div className="shared-content">
      <span className="shared-content__author">
        {sharedContent.author && sharedContent.author.name}
      </span>
      <div className="shared-content__time">
        <FromNow time={sharedContent.created_at} />
      </div>
      {sharedContent.article && (
        <div>
          <p className="shared-content__article-price">
            {sharedContent.article.price}
            {' '}
&euro;
          </p>
          <p className="shared-content__article-title">
            {sharedContent.article.title}
          </p>
          <div className="shared-content__article-category">
            <CategoryItem category={sharedContent.article.category} />
          </div>
        </div>
      )}
      <div className="shared-content__text">
        {sharedContent.text && sharedContent.text.length > 0 && (
          <Linkify options={linkOption}>
            {renderHTML(
              sharedContent.text &&
                sharedContent.text.replace(/(?:\r\n|\r|\n)/g, '<br>'),
            )}
          </Linkify>
        )}
      </div>
      {sharedContent.attachment && sharedContent.attachment.type === 'image' && (
        <div className="shared-content__image-container">
          <ImageWithLoader
            image={sharedContent.attachment}
            parent="shared"
            withBackground
          />
        </div>
      )}
      {sharedContent.attachment && sharedContent.attachment.type === 'video' && (
        <div className="shared-content__image-container">
          <VideoWithLoader
            shouldPlay={shouldPlay}
            video={sharedContent.attachment}
            withBackground
          />
        </div>
      )}
      {sharedContent.article &&
        sharedContent.article.attachments &&
        sharedContent.article.attachments[0] && (
          <div className="shared-content__image-container">
            <ImageWithLoader
              parent="post"
              image={sharedContent.article.attachments[0]}
              withBackground
            />
          </div>
        )}
    </div>
  );
};

SharedContent.defaultProps = defaultProps;

SharedContent.propTypes = propTypes;

export default SharedContent;
